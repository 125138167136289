export const ACCOUNT_TYPE_INDIVIDUAL = 'individual';
export const ACCOUNT_TYPE_JOINT = 'joint';
export const ACCOUNT_TYPE_ENTITY = 'entity';
export const ACCOUNT_TYPE_IRA = 'ira';

export const JOINT_WITH_RIGHTS_OF_SURVIVORSHIP = 'jointrightsofsurvivorship';
export const JOINT_AS_TENANTS_IN_COMMON = 'jointtenantsincommon';

export const ENTITY_CORPORATE = 'entitycorporate';
export const ENTITY_PARTNERSHIP = 'entitypartnership';
export const ENTITY_TRUST = 'entitytrust';
export const ENTITY_LIMITED_LIABILITY = 'entitylimitedliability';
export const ENTITY_SOLE_PROPRIETORSHIP = 'entitysoleproprietorship';

export const IRA_TRADITIONAL = 'iratraditional';
export const IRA_ROTH = 'iraroth';
export const IRA_SEP = 'irasep';
export const IRA_SIMPLE = 'irasimple';
export const IRA_TRADITIONAL_INHERITED = 'irainheritedtraditional';
export const IRA_ROTH_INHERITED = 'irainheritedroth';

const ACCT_GROUPS_INDIVIDUAL = 'Individual';
const ACCT_GROUPS_JOINT = 'Joint';
const ACCT_GROUPS_ENTITY = 'Entity';
const ACCT_GROUPS_IRA = 'IRA';

export const ACCOUNT_TYPES = [
  { type: ACCOUNT_TYPE_INDIVIDUAL, description: ACCT_GROUPS_INDIVIDUAL },
  { type: ACCOUNT_TYPE_JOINT, description: ACCT_GROUPS_JOINT },
  { type: ACCOUNT_TYPE_ENTITY, description: ACCT_GROUPS_ENTITY },
  { type: ACCOUNT_TYPE_IRA, description: ACCT_GROUPS_IRA },
  { type: JOINT_WITH_RIGHTS_OF_SURVIVORSHIP, description: ACCT_GROUPS_JOINT },
  { type: JOINT_AS_TENANTS_IN_COMMON, description: ACCT_GROUPS_JOINT },
  { type: ENTITY_CORPORATE, description: ACCT_GROUPS_ENTITY },
  { type: ENTITY_PARTNERSHIP, description: ACCT_GROUPS_ENTITY },
  { type: ENTITY_TRUST, description: ACCT_GROUPS_ENTITY },
  { type: ENTITY_LIMITED_LIABILITY, description: ACCT_GROUPS_ENTITY },
  { type: ENTITY_SOLE_PROPRIETORSHIP, description: ACCT_GROUPS_ENTITY },
  { type: IRA_TRADITIONAL, description: ACCT_GROUPS_IRA },
  { type: IRA_ROTH, description: ACCT_GROUPS_IRA },
  { type: IRA_SEP, description: ACCT_GROUPS_IRA },
  { type: IRA_SIMPLE, description: ACCT_GROUPS_IRA },
  { type: IRA_TRADITIONAL_INHERITED, description: ACCT_GROUPS_IRA },
  { type: IRA_ROTH_INHERITED, description: ACCT_GROUPS_IRA },
];

export const ACCOUNT_SUBTYPES = [
  { type: JOINT_WITH_RIGHTS_OF_SURVIVORSHIP, description: 'Joint with rights of survivorship' },
  { type: JOINT_AS_TENANTS_IN_COMMON, description: 'Joint with tenants-in-common' },
  { type: ENTITY_CORPORATE, description: 'Corporate' },
  { type: ENTITY_PARTNERSHIP, description: 'Partnership' },
  { type: ENTITY_TRUST, description: 'Trust' },
  { type: ENTITY_LIMITED_LIABILITY, description: 'Limited Liability' },
  { type: ENTITY_SOLE_PROPRIETORSHIP, description: 'Sole Proprietorship' },
  { type: IRA_TRADITIONAL, description: 'Traditional IRA' },
  { type: IRA_ROTH, description: 'Roth IRA' },
  { type: IRA_SEP, description: 'SEP IRA' },
  { type: IRA_SIMPLE, description: 'SIMPLE IRA' },
  { type: IRA_TRADITIONAL_INHERITED, description: 'Inherited Traditional IRA' },
  { type: IRA_ROTH_INHERITED, description: 'Inherited Roth IRA ' },
];
