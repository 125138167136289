import { getBrowserOrigin } from '../lib/browser';
import Config from '../Config';

const config = new Config();

// allowed logoutRoute parameter:
//
// -> /intro
// -> /account-type
// -> /logout.html
// -> /logout.html?manual

export default function signOutUrl(logoutRoute) {
  const returnTo = getBrowserOrigin() + logoutRoute;
  const redirectUrl = `${config.auth0LogoutUrl}?client_id=${config.auth0ClientId}&returnTo=${returnTo}`;
  const encodedRedirectUrl = encodeURIComponent(redirectUrl);

  // eslint-disable-next-line max-len
  const urlLegacy = `${config.legacyLogoutUrl}?client_id=${config.clientId}&response_type=tradestation_mfa_logout&redirect_uri=${encodedRedirectUrl}`;

  return urlLegacy;
}
