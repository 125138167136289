import React from 'react';
import AccountSubType from './AccountSubType';
import * as accountTypes from './accountTypes';
import Config from './../../Config';

const config = new Config();
const assetTypeRowStyle = config.cryptoEnable ? 'row slim-row' : 'row';

const EntityAccountType = () => {
  return (
    <div className="panel panel-clear no-panel-bottom">
      <div className="panel-body no-panel-bottom">
        <div className={assetTypeRowStyle}>
          <div className="col-md-12">
            <p className="panel-question text-center">
              What type of business/trust account would you like to open?
            </p>
            <div className="row">
              <div className="col col-xs-8 col-xs-offset-2 col-sm-12 col-sm-offset-0 col-md-12 col-lg-10 col-lg-offset-1">
                <div className="row">
                  <div className="col col-sm-4 col-xs-12 entity-acc-type entity-outside-right-button">
                    <div className="form-group form-group-inputs entity-acc-type-group">
                      <AccountSubType
                        id="entity-corporation"
                        name="entityType"
                        text="Corporation"
                        type={accountTypes.ENTITY_CORPORATE}
                      />
                    </div>
                  </div>
                  <div className="col col-sm-4 col-xs-12 entity-acc-type">
                    <div className="form-group form-group-inputs entity-acc-type-group">
                      <AccountSubType
                        id="entity-llc"
                        name="entityType"
                        text="Llc"
                        type={accountTypes.ENTITY_LIMITED_LIABILITY}
                      />
                    </div>
                  </div>
                  <div className="col col-sm-4 col-xs-12 entity-acc-type entity-outside-left-button">
                    <div className="form-group form-group-inputs entity-acc-type-group">
                      <AccountSubType
                        id="entity-sole-proprietorship"
                        name="entityType"
                        text="Sole proprietorship"
                        type={accountTypes.ENTITY_SOLE_PROPRIETORSHIP}
                      />
                    </div>
                  </div>
                  <div className="col col-sm-4 col-sm-offset-2 col-xs-12 entity-acc-type">
                    <div className="form-group form-group-inputs entity-acc-type-group">
                      <AccountSubType
                        id="entity-partnership"
                        name="entityType"
                        text="Partnership"
                        type={accountTypes.ENTITY_PARTNERSHIP}
                      />
                    </div>
                  </div>
                  <div className="col col-sm-4 col-xs-12 entity-acc-type">
                    <div className="form-group form-group-inputs entity-acc-type-group">
                      <AccountSubType
                        id="entity-trust"
                        name="entityType"
                        text="Trust"
                        type={accountTypes.ENTITY_TRUST}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityAccountType;
