import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchPendingApplicationsRequest() {
  return {
    type: actionTypes.FETCH_PENDING_APPLICATIONS_REQUEST,
  };
}

export function fetchPendingApplicationsSuccess(pendingApplications) {
  return {
    type: actionTypes.FETCH_PENDING_APPLICATIONS_SUCCESS,
    pendingApplications,
  };
}

export function fetchPendingApplicationsError(error) {
  return {
    type: actionTypes.FETCH_PENDING_APPLICATIONS_ERROR,
    error,
  };
}

export function fetchUserPendingApplications(authToken) {
  return (dispatch) => {
    dispatch(fetchPendingApplicationsRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/users/userPendingApplications`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchPendingApplicationsSuccess(json)))
      .catch(error => dispatch(fetchPendingApplicationsError(error)));
  };
}
