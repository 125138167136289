import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import configureStore from './store/configureStore';
import * as storageKeys from './actions/storageKeys';
import routes from './routes';
import * as cookies from './lib/cookieManager';
import * as userActions from './actions/userActions';
import signOutUrl from './lib/signOutUrl';
import Config from './Config';

const config = new Config();

const he = require('he');

function getAuthTokenParam() {
  let authToken = null;
  const authCookie = cookies.getCookie(storageKeys.AUTH_TOKEN_PARAM_KEY);
  if (document.referrer === config.autoLogoutURL) {
    userActions.userLogout(authCookie);
    if (window.location.hostname === 'localhost') {
      cookies.deleteCookie(storageKeys.AUTH_TOKEN_PARAM_KEY);
    } else {
      cookies.deleteCookieDomain(storageKeys.AUTH_TOKEN_PARAM_KEY, storageKeys.TRADE_DOMAIN);
    }
    window.location = signOutUrl('/intro');
    return null;
  }

  if (authCookie) {
    authToken = authCookie;
    if (window.location.hostname === 'localhost') {
      cookies.deleteCookie(storageKeys.AUTH_TOKEN_PARAM_KEY);
    } else {
      cookies.deleteCookieDomain(storageKeys.AUTH_TOKEN_PARAM_KEY, storageKeys.TRADE_DOMAIN);
    }
  } else {
    const searchKey = ('[\\?&]').concat(storageKeys.AUTH_TOKEN_PARAM_KEY, '=([^&#]*)');
    const result = new RegExp(searchKey).exec(window.location.href);
    if (result) {
      authToken = he.encode(result[1]);
    }
  }
  return authToken;
}

function getApplicationIdParam() {
  let applicationId = null;
  const searchKey = ('[\\?&]').concat(storageKeys.APPLICATION_ID_PARAM_KEY, '=([^&#]*)');
  const result = new RegExp(searchKey).exec(window.location.href);
  if (result) {
    applicationId = he.encode(result[1]);
  }
  return applicationId;
}

function getUserIdParam() {
  let userId = null;
  const searchKey = ('[\\?&]').concat(storageKeys.USER_ID, '=([^&#]*)');
  const result = new RegExp(searchKey).exec(window.location.href);
  if (result) {
    userId = he.encode(result[1]);
  }
  return userId;
}

function setInitialState() {
  const appId = getApplicationIdParam();
  const applicationId = appId !== null ? he.decode(appId) : appId;
  const authT = getAuthTokenParam();
  const authToken = authT !== null ? he.decode(authT) : authT;
  const user = getUserIdParam();
  const userId = user !== null ? he.decode(user) : user;

  const initialState = {};

  if (applicationId !== null) {
    sessionStorage.setItem(storageKeys.APPLICATION_ID_KEY, applicationId);
    initialState.applicationId = applicationId;
  }

  if (authToken !== null) {
    sessionStorage.setItem(storageKeys.AUTH_TOKEN_KEY, authToken);
    initialState.authToken = authToken;
  }

  if (userId !== null && userId !== 0) {
    initialState.userId = userId;
  }
  return initialState;
}

function onRouterUpdate() {
  window.scrollTo(0, 0);
}

const store = configureStore(setInitialState());

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory} routes={routes} onUpdate={onRouterUpdate} />
  </Provider>,
  document.getElementById('app'),
);
