import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as applicationActions from '../actions/applicationActions';
import * as registrationActions from '../actions/registrationActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as financialActions from '../actions/financialActions';
import InlineFunding from '../components/InlineFunds/InlineFunding';
import Funding from '../components/DepositFunds/Funding';
import Config from '../Config';
import * as userActions from '../actions/userActions';
import optimizeHelper from '../lib/optimizeHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import { deleteCookie } from '../lib/cookieManager';
import * as RouteNavigator from './RouteNavigator';
import { InlineFundingButtons } from '../components/InlineFunds/InlineButtonsTypes';
import TransferService from '../components/InlineFunds/TransferService';
import ACATPage from '../components/InlineFunds/ACATPage';
import DepositCryptoInstructions from '../components/InlineFunds/DepositCryptoInstructions';
import { triggerTimer } from '../lib/tokenTimer';

const config = new Config();

const redirectToClientCenter = () => {
  window.location = config.clientCenterUrl;
};

const toClientCenterListener = (event) => {
  const { data } = event;
  if (data.logout) {
    redirectToClientCenter();
  }
};

export class InlineFundsContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      choosenOption: null,
      shouldDisplayAdditionalOptions: false,
    };
    this.handleBack = this.handleBack.bind(this);
    this.handleBackFunding = this.handleBackFunding.bind(this);
    this.createFundingUrl = this.createFundingUrl.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
    this.displayComponent = this.displayComponent.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      application,
      applicationId,
      authToken,
      registration,
      accountType,
      assetClass,
      identity,
      address,
      tradingProfile,
      financial,
    } = this.props;

    if (!application) actions.fetchApplication(applicationId, authToken);
    if (!registration) actions.fetchRegistration(applicationId, authToken);
    if (
      !registration ||
      !accountType ||
      !assetClass ||
      !identity ||
      !address ||
      !tradingProfile ||
      !financial
    ) {
      if (config.pendingApplication) deleteCookie('pendingApplication');
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
        actions.fetchTradingProfile(applicationId, authToken),
        actions.fetchFinancial(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(aopAnalyticsSteps.THANK_YOU.name, {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
          address,
          identity,
          tradingProfile,
          financial,
        });
      });
    } else {
      pushToAnalytics(aopAnalyticsSteps.THANK_YOU.name, {
        applicationId,
        authToken,
        registration,
        accountType,
        assetClass,
        address,
        identity,
        tradingProfile,
        financial,
      });
    }

    triggerTimer();
    window.addEventListener('message', toClientCenterListener, false);
    optimizeHelper.notify();
  }

  componentWillUnmount() {
    window.removeEventListener('message', toClientCenterListener, false);
  }

  /**
   * Set the flag to true when button is clicked
   * @param {Object} button
   */
  onOptionClick(button, returnToAdditionalOption) {
    this.setState({ choosenOption: button, shouldDisplayAdditionalOptions: returnToAdditionalOption });
  }

  createFundingUrl(accessToken) {
    const { choosenOption } = this.state;
    const { registration: { username } } = this.props;
    const url = choosenOption ? choosenOption.url : '';
    return `${url}/?client_id=aop&user_id=${username}&access_token=${accessToken}`;
  }

  // eslint-disable-next-line class-methods-use-this
  handleBack() {
    RouteNavigator.push('/deposit-funds');
  }
  // eslint-disable-next-line class-methods-use-this
  handleBackFunding() {
    triggerTimer();
    this.setState({ choosenOption: null });
  }

  /**
   * Render the component base on the flag
   * @returns {Component} Funding/InlineFundin/TransferAccount component
   */
  displayComponent() {
    const { choosenOption, shouldDisplayAdditionalOptions } = this.state;
    const { authToken } = this.props;
    switch (true) {
      case (choosenOption && choosenOption.isRedirected): {
        const isLinkYourAccount = choosenOption.id === InlineFundingButtons.linkBankAccount.id;
        return (
          <Funding
            {...this.props}
            fundingUrl={this.createFundingUrl(authToken)}
            shouldDisplayLinkingAccountHelpButton={isLinkYourAccount}
            title={isLinkYourAccount ? 'Add External Account' : null}
            onBack={this.handleBackFunding}
            authToken={authToken}
          />
        );
      }
      case choosenOption && choosenOption.id === InlineFundingButtons.transferAccount.id:
        return (<ACATPage
          onBack={this.handleBackFunding}
          authToken={authToken}
          useAcatExternalSite={config.useAcatExternalSite}
          acatAppUrl={config.acatAppUrl}
        />);
      case choosenOption && choosenOption.id === InlineFundingButtons.transferService.id:
        return (<TransferService onBack={this.handleBackFunding} />);
      case choosenOption && choosenOption.id === InlineFundingButtons.depositCrypto.id:
        return (<DepositCryptoInstructions onBack={this.handleBackFunding} />);
      case choosenOption === null:
      default:
        return (
          <InlineFunding
            {...this.props}
            onBack={this.handleBack}
            onOptionClick={this.onOptionClick}
            displayAdditionalOptions={shouldDisplayAdditionalOptions}
          />
        );
    }
  }

  render() {
    return this.displayComponent();
  }
}

InlineFundsContainer.propTypes = {
  authToken: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  application: PropTypes.shape(),
  registration: PropTypes.shape(),
  actions: PropTypes.shape({
    fetchApplication: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    startApplication: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    fetchFinancial: PropTypes.func.isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  accountType: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  address: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
  financial: PropTypes.shape(),
};

function mapStateToProps(state) {
  return {
    application: state.application,
    applicationId: state.applicationId,
    authToken: state.authToken,
    registration: state.registration,
    userId: state.userId,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
    tradingProfile: state.tradingProfile,
    financial: state.financial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      applicationActions,
      registrationActions,
      userActions,
      accountTypeActions,
      assetClassActions,
      identityActions,
      addressActions,
      tradingProfileActions,
      financialActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InlineFundsContainer);
