import * as actionTypes from './actionTypes';
import Config from '../Config';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { setApplicationId } from './applicationIdActions';
import { setAuthToken } from './authTokenActions';
import { submitPrimaryContactSuccess } from './contactActions';
import { submitRegistrationSuccess } from './registrationActions';
import { attachRecaptchaToken } from './attachRecaptchaToken';
import { setCookie } from '../lib/cookieManager';
import * as storageKeys from './storageKeys';

const config = new Config();

export function submitRegistrationCredentialsError(error) {
  return { type: actionTypes.SUBMIT_REGISTRATION_CREDENTIALS_ERROR, error };
}

export function submitRegistrationCredentialsRequest(registrationCredentials) {
  return { type: actionTypes.SUBMIT_REGISTRATION_CREDENTIALS_REQUEST, registrationCredentials };
}

export function submitRegistrationCredentialsSuccess() {
  return { type: actionTypes.SUBMIT_REGISTRATION_CREDENTIALS_SUCCESS };
}

export function submitRegistrationCredentials(registrationCredentials) {
  return (dispatch) => {
    dispatch(submitRegistrationCredentialsRequest(registrationCredentials));


    return attachRecaptchaToken(registrationCredentials.contact, 'registration')
      .then((contact) => {
        const data = registrationCredentials;
        data.contact = contact;
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          mode: 'cors',
          credentials: 'include',
        };
        return fetch(`${config.apiUrl}/applications/registrationCredentials`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(submitRegistrationCredentialsSuccess());
        dispatch(submitPrimaryContactSuccess(json.contact));
        dispatch(submitRegistrationSuccess(json.registration));
        dispatch(setApplicationId(json.registration.applicationId));
        dispatch(setAuthToken(json.token.id));
        setCookie(storageKeys.PROMO_CODE_COOKIE_NAME, json.contact.promoCode);
      })
      .catch((error) => {
        dispatch(submitRegistrationCredentialsError(error));
        return Promise.reject(error);
      });
  };
}

export function updateRegistrationCredentialsError(error) {
  return { type: actionTypes.SUBMIT_REGISTRATION_CREDENTIALS_ERROR, error };
}

export function updateRegistrationCredentialsRequest(authorizationCode) {
  return { type: actionTypes.UPDATE_REGISTRATION_CREDENTIALS_REQUEST, authorizationCode };
}

export function updateRegistrationCredentialsSuccess() {
  return { type: actionTypes.UPDATE_REGISTRATION_CREDENTIALS_SUCCESS };
}

export function updateRegistrationCredentials(applicationId, authToken, authorizationCode) {
  return (dispatch) => {
    dispatch(updateRegistrationCredentialsRequest(authorizationCode));

    return attachRecaptchaToken(authorizationCode, 'authorizationCode').then((recaptchaAuthorization) => {
      const data = { code: authorizationCode, authorization: { recaptchaAuthorization } };
      const options = {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
        },
        body: JSON.stringify(data),
        mode: 'cors',
      };
      return fetch(`${config.apiUrl}/applications/${applicationId}`, options);
    })
    .then(checkHttpError)
    .then(responseToJson)
    .then((json) => {
      dispatch(updateRegistrationCredentialsSuccess());
      dispatch(setAuthToken(json.token.id));
    })
    .catch((error) => {
      dispatch(updateRegistrationCredentialsError(error));
      return Promise.reject(error);
    });
  };
}

