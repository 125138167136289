import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import Config from '../../Config';
import DocumentsSettings from './DocumentsSettings';
import { mitekValidationErrors } from './mitekErrorTexts';
import ProgressBarUpload from '../ProgressBarUpload';
import DocumentsUploaded from './DocumentsUploaded';
import * as identificationTypes from '../../components/PersonalInfo/Identity/identificationTypes';
import {
  isCounterError,
  mitekValidate,
  removeNewFile,
  viewFile,
  checkIfValidFileName,
  checkIfValidFileType,
  setErrors,
  acceptedFileMimes,
  maxFileSize,
  maxFileSizeIdentity,
} from './DocumentUploadControlHelper';

const config = new Config();
const DL_FRONT = identificationTypes.ID_FRONT;
const DL_BACK = identificationTypes.ID_BACK;
const idSubtypes = [DL_FRONT, DL_BACK];
const DL_FRONT_ERROR = identificationTypes.ID_FRONT_ERROR;
const DL_BACK_ERROR = identificationTypes.ID_BACK_ERROR;
let latestUpload = null;

export class DocumentsUploadControl extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      documentFiles: [],
      errors: [],
      mitekErrorCounter: [0, 0],
    };

    this.setDocuments = this.setDocuments.bind(this);
    this.updateErrorCounter = this.updateErrorCounter.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
    this.latestUpload = this.latestUpload.bind(this);
  }

  setDocuments(documents) {
    this.setState({ documentFiles: documents });
  }

  setErrors(rejectedFiles, isProofOfIdentity, docSubtype, sectionIndex) {
    const errorsArray = setErrors(rejectedFiles, isProofOfIdentity);
    this.props.handleErrors(this.props.input.name, errorsArray, docSubtype, sectionIndex);
  }

  cleanErrors() {
    const errorsArray = [];
    this.props.handleErrors(this.props.input.name, errorsArray);
  }

  updateErrorCounter(index) {
    const newCounter = this.state.mitekErrorCounter;
    newCounter[index] += 1;
    this.setState({ mitekErrorCounter: newCounter });
  }

  resetCounter(index) {
    const newCounter = this.state.mitekErrorCounter;
    newCounter[index] = 0;
    this.setState({ mitekErrorCounter: newCounter });
  }

  showDocumentRequire(error) {
    this.cleanErrors();
    return (<span className="help-block error errorTextFont">{error}</span>);
  }

  /* eslint-disable class-methods-use-this */
  latestUpload(idSubtype) {
    latestUpload = idSubtype;
  }

  handleSubmit(filesToUpload, uploadedFiles, idSubtype) {
    const {
      handleFileSubmit,
      sectionIndex,
    } = this.props;

    this.cleanErrors();
    const files = filesToUpload.filter(file => file.type && file.valid);
    this.setDocuments(uploadedFiles.concat(files));

    if (files.length > 0) {
      handleFileSubmit(sectionIndex, this.props.input.name, files, idSubtype, (err, results) => {
        if (err) {
          const docItems = uploadedFiles.filter(doc => !filesToUpload.includes(doc));
          this.setDocuments(docItems);
          this.props.input.onChange([]);
        } else {
          const resultsUpload = uploadedFiles;
          files.forEach((file) => {
            const item = results.filter(x => x.key === file.preview);
            const newItem = file;
            if (item && item.length > 0) {
              newItem.preview = item[0].newPath;
              newItem.documentId = item[0].id;
              newItem.idSubtype = idSubtype;
              resultsUpload.push(newItem);
            }
          });

          this.setDocuments(resultsUpload);
        }
      });
    }
  }

  render() {
    const {
      authToken,
      name,
      maxFiles,
      handleFileRemove,
      meta: { error, touched },
      maxFileRestriction,
      identificationType,
      sectionIndex,
      progress,
      isReturnedApplication,
      errors,
      ids,
    } = this.props;
    const files = this.state.documentFiles;
    const subtypeFiles = {
      idFront: files.filter(file => file.idSubtype === DL_FRONT),
      idBack: files.filter(file => file.idSubtype === DL_BACK),
    };
    const isProofOfIdentity = this.props.input.name ?
      this.props.input.name.includes(DocumentsSettings.PROOF_OF_IDENTITY)
      : null;
    const documentType = 'proofOfIdentity';
    const isPrimary = sectionIndex === 0 && identificationType && identificationType[0] === 'DL_FRONT';
    const isJoint = sectionIndex === 1 && identificationType && identificationType[1] === 'DL_FRONT';
    const uploaded = [];
    for (let i = 0; i < files.length; i += 1) {
      if (idSubtypes.includes(files[i].idSubtype)) uploaded.push(files[i].idSubtype);
    }

    return (
      <div name={name} className="docUploadMitekIdentityDiv">
        {idSubtypes.map(idSubtype => (
          <div className="docUploadTwoBoxesDiv" style={{ float: idSubtype === DL_FRONT ? 'left' : 'right' }}>
            {isPrimary && idSubtype === DL_FRONT && <p className="idCardLabel"> Front of ID </p>}
            {isJoint && idSubtype === DL_FRONT && <p className="idCardLabel"> Front of ID </p>}
            {isPrimary && idSubtype === DL_BACK && <p className="idCardLabel"> Back of ID </p>}
            {isJoint && idSubtype === DL_BACK && <p className="idCardLabel"> Back of ID </p>}

            <Dropzone
              className="dropzone dz-clickable"
              multiple={false}
              name={name}
              onDrop={(filesToUpload) => {
                this.props.handleErrors(this.props.input.name, [null]); // clear error
                this.latestUpload(idSubtype);
                if (filesToUpload.length <= maxFiles && filesToUpload.length > 0) {
                  const fileRequests = filesToUpload.map(file =>
                    new Promise(resolve => checkIfValidFileType(file, resolve)).then(
                      fileType => new Promise(resolve => checkIfValidFileName(fileType, resolve)),
                    ),
                  );
                  Promise.all(fileRequests).then((resultFiles) => {
                    const validFiles = resultFiles.filter(f => f.valid);
                    const idType =
                      identificationType && identificationType.length > 0
                        ? identificationType[sectionIndex]
                        : null;
                    if (maxFiles === 1 && ((idSubtype === DL_FRONT && error === DL_BACK_ERROR) ||
                    (idSubtype === DL_BACK && error === DL_FRONT_ERROR))) {
                      const errorsArray = ['Only one image allowed.'];
                      this.props.handleErrors(this.props.input.name, errorsArray, idSubtype, sectionIndex);
                    } else if (validFiles && validFiles.length > 0) {
                      mitekValidate(validFiles, isProofOfIdentity, idType)
                        .then((mitekError) => {
                          const errorLimitReached =
                            this.state.mitekErrorCounter[sectionIndex] >= config.mitekErrorLimit;
                          if (mitekError && !errorLimitReached) {
                            if (isCounterError(mitekError)) this.updateErrorCounter(sectionIndex);
                            setTimeout(() => {
                              this.props.handleErrors(
                                this.props.input.name, [mitekValidationErrors[mitekError]], idSubtype, sectionIndex);
                            }, 250);
                          } else {
                            if (validFiles) validFiles[0].idSubtype = idSubtype;
                            this.props.input.onChange(filesToUpload.concat(files));
                            this.resetCounter(sectionIndex);
                            this.handleSubmit(validFiles, files, idSubtype);
                          }
                        });
                    } else {
                      this.setErrors(resultFiles, isProofOfIdentity, idSubtype, sectionIndex);
                    }
                  });
                } else if (maxFiles === 0) {
                  let errorsArray = [`You can only upload up to ${maxFileRestriction} documents in each section.`];
                  if (isProofOfIdentity) {
                    errorsArray = ['Limit of 2 images has been reached.'];
                  }
                  this.props.handleErrors(this.props.input.name, errorsArray, idSubtype, sectionIndex);
                }
              }}
              onDropRejected={(rejectedFiles) => {
                this.setErrors(rejectedFiles, isProofOfIdentity, idSubtype, sectionIndex);
              }}
              accept={acceptedFileMimes}
              maxSize={isProofOfIdentity ? maxFileSizeIdentity : maxFileSize}
            >
              <div name={name} className="newDocUploadButtonFont newDocUploadDropZoneMitekIdentity">
                Click, tap or drop files here to upload
              </div>
            </Dropzone>

            <Field
              name="originalDocuments"
              sectionIndex={sectionIndex}
              component={DocumentsUploaded}
              props={{ sectionIndex, documentType: 'proofOfIdentity', authToken }}
              handleFileRemove={handleFileRemove}
              isReturnedApplication={isReturnedApplication}
              idSubtype={idSubtype}
              handleErrors={this.props.handleErrors}
            />

            {files && subtypeFiles[idSubtype] && Array.isArray(subtypeFiles[idSubtype])
            && subtypeFiles[idSubtype].length > 0 && (
              <table className="table table-bordered table-layout table-layout-mitek">
                <tbody>
                  {subtypeFiles[idSubtype].map((file, i) =>
                    (<tr key={i}>
                      <td className="cell-documentUploadFileMitek">{file.name}</td>
                      <td className="cell-documentUploadControlMitek">{
                        viewFile(file.type, file.preview, file.documentId, authToken, true)}</td>
                      <td className="cell-documentUploadControlMitek">{
                        removeNewFile(this.props.input.value, file.documentId, file.documentId,
                          this.props.input.onChange, handleFileRemove, this.setDocuments,
                          !file.documentId, this.props.input.name, true, this.props.handleErrors)}</td>
                    </tr>),
                  )}
                </tbody>
              </table>
            )}

            {progress > 0 && latestUpload === idSubtype &&
              <Field
                name="progressBar"
                component={ProgressBarUpload}
                props={{ progress }}
              />
            }

            {errors[`${ids}.${documentType}`] && errors.idSubType === idSubtype && (
            <div>
              {isPrimary && errors.sectionIndex === 0 && (
              errors[`${ids}.${documentType}`].map((err, i) =>
                err && <span key={i} className="help-block error wordwrap error-fade-in errorMessageMitekIdentity">
                  {err}
                </span>,
              ))}
              {isJoint && errors.sectionIndex === 1 && (
              errors[`${ids}.${documentType}`].map((err, i) =>
                err && <span key={i} className="help-block error wordwrap error-fade-in errorMessageMitekIdentity">
                  {err}
                </span>,
              ))}
            </div>
            )}
            {touched && error && (!uploaded.includes(idSubtype) && progress === 0) &&
              <div>
                {error !== DL_BACK_ERROR && error !== DL_FRONT_ERROR &&
                  <span className="help-block error errorMessageMitekIdentity">{error}</span>
                }
                {idSubtype === DL_BACK && error === DL_BACK_ERROR &&
                  <span className="help-block error errorMessageMitekIdentity">Image is required</span>
                }
                {idSubtype === DL_FRONT && error === DL_FRONT_ERROR &&
                  <span className="help-block error errorMessageMitekIdentity">Image is required</span>
                }
              </div>
            }

            <div>
              {!uploaded.includes(idSubtype) && maxFiles > 0 &&
                /* eslint-disable react/jsx-closing-bracket-location */
                <p className={latestUpload === idSubtype && progress > 0 ?
                'acceptedFileTypesLabelWithProgressBarMitek' : 'acceptedFileTypesLabelMitek'}>
                  HEIC, JPG, JPEG or PNG
                </p>
              }
            </div>
          </div>
        ))}
      </div>
    );
  }
}

DocumentsUploadControl.propTypes = {
  name: PropTypes.string,
  sectionIndex: PropTypes.number,
  maxFiles: PropTypes.number,
  input: PropTypes.shape(),
  handleFileRemove: PropTypes.func.isRequired,
  handleFileSubmit: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  authToken: PropTypes.string,
  handleErrors: PropTypes.func.isRequired,
  maxFileRestriction: PropTypes.number,
  identificationType: PropTypes.arrayOf(PropTypes.string),
  progress: PropTypes.number,
  isReturnedApplication: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  ids: PropTypes.string.isRequired,
};

export default DocumentsUploadControl;
