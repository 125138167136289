import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Slider from '../lib/rc-slider-custom';
import '../lib/rc-slider-custom/assets/index.css';

export const renderField = (props) => {
  const {
    id,
    input,
    marks,
  } = props;

  return (
    <Slider
      {...input}
      marks={marks}
      step={null}
      defaultValue={0}
      id={id || input.name}
      name={input.name}
      value={input.value}
      onChange={value => input.onChange(value)}
    />
  );
};

renderField.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape(),
  marks: PropTypes.shape(),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
};

const CustomSlider = ({ name, id, marks, onChange }) => {
  return (
    <div className="custom-slider">
      <Field
        id={id}
        name={name}
        marks={marks}
        onChange={(preventDefault, value, previousValue) => onChange(preventDefault, value, previousValue)}
        component={renderField}
      />
    </div>
  );
};

CustomSlider.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.function,
  marks: PropTypes.shape(),
};

export default CustomSlider;
