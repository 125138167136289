const NONE = 'none';
const UNDER_ONE = 'under1year';
const ONE_TO_FIVE = '1to5years';
const ONE_TO_FIVE_MONTHS = '1to5months';
const OVER_FIVE = 'over5years';
const SIX_TO_TWENTY_FIVE = '6to25';
const TWENTY_SIX_TO_HUNDRED = '26to100';
const LIMITED = 'limited';
const MODERATE = 'moderate';
const EXTENSIVE = 'extensive';

const TRADING_PROFILE_OPTIONS = {
  yearsExperience: [
    { text: 'None', value: NONE },
    { text: 'Under 1', value: UNDER_ONE },
    { text: '1-5', value: ONE_TO_FIVE },
    { text: 'Over 5', value: OVER_FIVE },
  ],
  averageMonthly: [
    { text: 'None', value: NONE },
    { text: '1-5', value: ONE_TO_FIVE_MONTHS },
    { text: '6-25', value: SIX_TO_TWENTY_FIVE },
    { text: '26-100+', value: TWENTY_SIX_TO_HUNDRED },
  ],
};

module.exports = {
  NONE,
  UNDER_ONE,
  ONE_TO_FIVE,
  ONE_TO_FIVE_MONTHS,
  OVER_FIVE,
  SIX_TO_TWENTY_FIVE,
  TWENTY_SIX_TO_HUNDRED,
  LIMITED,
  MODERATE,
  EXTENSIVE,
  TRADING_PROFILE_OPTIONS,
};
