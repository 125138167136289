import React from 'react';
import PropTypes from 'prop-types';
import TextBox from '../TextBox';
import IraBeneficiaryHeading from './IraBeneficiaryHeading';
import DropDownList from '../../components/DropDownList';
import { normalizeSSN, onMaskingSSN } from '../PersonalInfo/Identity/normalizeSSN';
import normalizeNumber from './normalizeNumber';
import GenericError from '../GenericError';
import * as beneficiaryTypes from './IraBeneficiaryRelationshipTypes';

const noDOBRelationships =
  [beneficiaryTypes.IRA_RELATIONSHIP_ESTATE, beneficiaryTypes.IRA_RELATIONSHIP_ORGANIZATIONCHARIRY];

const shouldEnableDOB = (relationship) => {
  return noDOBRelationships.indexOf(relationship) === -1;
};

const IraRenderBeneficiaries = (
  {
    fields,
    iraBeneficiaryRelationshipOptions,
    months,
    days,
    yearsBirthdate,
    total,
    beneficiaryType,
    handleOnChangeRelationship,
  },

) => (
  <div>
    {fields.map((iraBeneficiary, index) =>
      (<div key={index}>
        <div className="row col-middle">
          <div className="col col-xs-9 col-md-10">
            <div>
              <IraBeneficiaryHeading name={`${iraBeneficiary}.fullName`} beneficiaryType={beneficiaryType} />
            </div>
          </div>
          {(fields.length > 1 || beneficiaryType === 'contingent') &&
            <div className="col col-xs-3 col-md-2 text-right">
              <button
                type="button"
                id="iraBeneficiaryDelete"
                className="btn btn-default slimv_element slimh_element fitv_element"
                onClick={() => fields.remove(index)}
              >
                <div className="material-icons">delete_forever</div>
              </button>
            </div>
          }
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <TextBox
              name={`${iraBeneficiary}.fullName`}
              className="form-control"
              label="Beneficiary Full Name"
              maxLength={40}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-md-6">
            <DropDownList
              fieldName={`${iraBeneficiary}.relationship`}
              className="form-control"
              label="Select Relationship"
              options={iraBeneficiaryRelationshipOptions}
              handleChange={(event, value) => handleOnChangeRelationship(event, value)}
            />
          </div>
          <div className="col col-md-6">
            <TextBox
              name={`${iraBeneficiary}.share`}
              className="form-control"
              label="Share Percentage %"
              normalize={normalizeNumber}
            />
          </div>
        </div>
        <div
          id={`${iraBeneficiary}.dateOfBirthRow`}
          className={shouldEnableDOB(fields.get(index).relationship) ? 'showBeneficiaryDOB' : 'hideBeneficiaryDOB'}
        >
          <div className="row">
            <div className="col col-xs-12">
              <span className="control-label">Date of Birth</span>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6">
              <div className="row">
                <div className="col col-xs-5 date-divider">
                  <DropDownList
                    fieldName={`${iraBeneficiary}.birthMonth`}
                    className="form-control"
                    label="Month"
                    options={shouldEnableDOB(fields.get(index).relationship) ? months : []}
                  />
                </div>
                <div className="col col-xs-3 date-divider">
                  <DropDownList
                    fieldName={`${iraBeneficiary}.birthDay`}
                    className={'form-control'}
                    label="Day"
                    options={shouldEnableDOB(fields.get(index).relationship) ? days : []}
                  />
                </div>
                <div className="col col-xs-4">
                  <DropDownList
                    fieldName={`${iraBeneficiary}.birthYear`}
                    className="form-control"
                    label="Year"
                    options={shouldEnableDOB(fields.get(index).relationship) ? yearsBirthdate : []}
                  />
                </div>
              </div>
            </div>
            <div className="col col-md-6">
              <TextBox
                name={`${iraBeneficiary}.ssn`}
                className="form-control"
                label="Social Security number"
                maxLength={11}
                normalize={normalizeSSN}
                onMasking={onMaskingSSN}
                disabled={!shouldEnableDOB(fields.get(index).relationship)}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>),
    )}
    <div className="row">
      <div className="col col-xs-3">
        <button
          type="button"
          id={`${beneficiaryType}add_new`}
          className="btn btn-raised btn-success"
          onClick={() => fields.push({})}
        >
          Add New
        </button>
      </div>
      <div className="col col-xs-3">
        <span className="btn btn-label btn-block btn-clear">
          Total: {total}%
        </span>
      </div>
      <div className="col col-xs-6">
        <div className="inside">
          <GenericError name={`${beneficiaryType}ShareTotalError`} />
        </div>
      </div>
    </div>
  </div>
);

IraRenderBeneficiaries.propTypes = {
  fields: PropTypes.shape({
    forEach: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    getAll: PropTypes.func.isRequired,
    insert: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    map: PropTypes.func.isRequired,
    move: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    removeAll: PropTypes.func.isRequired,
    shift: PropTypes.func.isRequired,
    swap: PropTypes.func.isRequired,
    unshift: PropTypes.func.isRequired,
  }),
  iraBeneficiaryRelationshipOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  months: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  days: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  yearsBirthdate: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  total: PropTypes.number.isRequired,
  beneficiaryType: PropTypes.string.isRequired,
  handleOnChangeRelationship: PropTypes.func.isRequired,
};


export default IraRenderBeneficiaries;
