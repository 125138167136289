import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from '../DropDownList';
import TextBox from '../../components/TextBox';
import * as tradingProfileTypes from './tradingProfileTypes';

const TradingProfileExperience = (props) => {
  const {
    title,
    yearsExperience,
    isTSEL,
  } = props;

  return (
    <div>
      <div>
        <div className={`${isTSEL ? 'col-xs-2' : 'col-lg-2 col-xs-3'}`}>
          <p className="trading-profile-experience-title">{title}</p>
        </div>
        <div className={`${isTSEL ? 'col-xs-10 col-sm-5' : 'col-lg-10 col-xs-9'}`}>
          <DropDownList
            fieldName={`yearsExperience${title}`}
            className="form-control"
            label="Years of Experience"
            options={tradingProfileTypes.TRADING_PROFILE_OPTIONS.yearsExperience}
          />
          {yearsExperience === tradingProfileTypes.UNDER_ONE &&
          <div>
            <div className="row">
              <div className="col-xs-offset-2 col-xs-10">
                <span className="trading-profile-experience-title control-label">Please specify:</span>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-offset-2 col-xs-10">
                <TextBox
                  name={`monthsExperience${title}`}
                  className="form-control"
                  label="Number of Months"
                />
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      <div className="row" />

    </div>);
};

TradingProfileExperience.propTypes = {
  title: PropTypes.string.isRequired,
  yearsExperience: PropTypes.string,
  isTSEL: PropTypes.bool,
};

export default TradingProfileExperience;
