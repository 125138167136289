import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import Address from './Address';
import AddressHeader from './AddressHeader';
import CheckboxDoNotContact from './CheckboxDoNotContact';
import CheckboxSameMailingAddress from './CheckboxSameMailingAddress';
import CheckboxSameJointAddress from './CheckboxSameJointAddress';

const AddressFormResidential = (props) => {
  const {
    sectionIndex,
    addressCountries,
    regionsResidential,
    showCopyFromPrimary,
    showDoNotContact,
    showNonProAddressToolTip,
    isDomestic,
    isCryptoApplication,
    handleCopyFromPrimary,
  } = props;

  const showAddress = [true, true];
  if (sectionIndex > 0 && showCopyFromPrimary.length > 0 && showCopyFromPrimary[sectionIndex]) {
    showAddress[sectionIndex] = showCopyFromPrimary[sectionIndex].residential;
  }

  const countryIsReadonly = sectionIndex === 0 || isDomestic || isCryptoApplication;
  const regionIsReadonly = isCryptoApplication;
  const showTooltip = showNonProAddressToolTip[sectionIndex];

  return (
    <div>
      <div className="row">
        <AddressHeader isResidential sectionIndex={sectionIndex} showTooltip={showTooltip} />
        <FormSection name="residential">
          {showAddress[sectionIndex] &&
            <Address
              countries={addressCountries}
              regions={regionsResidential[sectionIndex]}
              countryIsReadonly={countryIsReadonly}
              regionIsReadonly={regionIsReadonly}
            />
          }
          <div className="col col-md-12">
            {sectionIndex > 0 &&
              <CheckboxSameJointAddress
                isResidential
                sectionIndex={sectionIndex}
                handleCopyFromPrimary={handleCopyFromPrimary}
              />
            }
            <CheckboxSameMailingAddress sectionIndex={sectionIndex} />
            {showDoNotContact[sectionIndex] &&
              <CheckboxDoNotContact sectionIndex={sectionIndex} />
            }
          </div>
        </FormSection>
      </div>
    </div>
  );
};

AddressFormResidential.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  addressCountries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regionsResidential: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))).isRequired,
  showCopyFromPrimary: PropTypes.arrayOf(PropTypes.shape({
    residential: PropTypes.bool,
    mailing: PropTypes.bool,
  })).isRequired,
  showDoNotContact: PropTypes.arrayOf(PropTypes.bool).isRequired,
  showNonProAddressToolTip: PropTypes.arrayOf(PropTypes.bool).isRequired,
  isDomestic: PropTypes.bool,
  isCryptoApplication: PropTypes.bool,
  handleCopyFromPrimary: PropTypes.func.isRequired,
};

export default AddressFormResidential;
