const NO_PENDING_APPLICATIONS = 'You don\'t have any pending applications at the moment.';
const PENDING_APPLICATIONS = 'You have pending applications you may want to complete.';
const LOADING_PENDING_APPLICATIONS = 'Loading Pending applications...';
const INCOMPLETE_MESSAGE = '* Incomplete applications will expire 30 days after they are started.';

const COMPLETE_APPLICATION = 'Complete Application';

export default {
  NO_PENDING_APPLICATIONS,
  PENDING_APPLICATIONS,
  LOADING_PENDING_APPLICATIONS,
  COMPLETE_APPLICATION,
  INCOMPLETE_MESSAGE,
};
