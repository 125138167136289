import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { attachRecaptchaToken } from './attachRecaptchaToken';
import { setAuthToken } from './authTokenActions';
import Config from '../Config';

const config = new Config();

export function fetchUserRequest() {
  return { type: actionTypes.FETCH_USER_REQUEST };
}

export function fetchUserError(error) {
  return { type: actionTypes.FETCH_USER_ERROR, error };
}

export function fetchUserSuccess(user) {
  return { type: actionTypes.FETCH_USER_SUCCESS, user };
}

export function submitLogoutRequest() {
  return { type: actionTypes.USER_LOGOUT_REQUEST };
}

export function submitLogoutError(error) {
  return { type: actionTypes.USER_LOGOUT_ERROR, error };
}

export function submitLogoutSuccess() {
  return { type: actionTypes.USER_LOGOUT_SUCCESS };
}

export function userTokenRefreshRequest() {
  return { type: actionTypes.USER_TOKEN_REFRESH_REQUEST };
}

export function userTokenRefreshError(error) {
  return { type: actionTypes.USER_TOKEN_REFRESH_ERROR, error };
}

export function userTokenRefreshSuccess() {
  return { type: actionTypes.USER_TOKEN_REFRESH_SUCCESS };
}

export function userLogout(authToken) {
  return (dispatch) => {
    dispatch(submitLogoutRequest());
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/users/logout`, options)
      .then(checkHttpError)
      .then(dispatch(submitLogoutSuccess()))
      .catch((error) => {
        dispatch(submitLogoutError(error));
      });
  };
}

export function fetchUser(authToken) {
  return (dispatch) => {
    dispatch(fetchUserRequest());
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/users/userIdInfo`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchUserSuccess(json)))
      .catch(error => dispatch(fetchUserError(error)));
  };
}

export function userTokenRefresh(authToken) {
  return (dispatch) => {
    dispatch(userTokenRefreshRequest());
    return attachRecaptchaToken({}, 'token')
      .then((data) => {
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: authToken,
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify(data),
        };
        return fetch(`${config.apiUrl}/users/refresh`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(userTokenRefreshSuccess());
        dispatch(setAuthToken(json.token.id));
      })
      .catch((error) => {
        dispatch(userTokenRefreshError(error));
        return Promise.reject(error);
      });
  };
}

