import * as actionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export function setApplicationId(applicationId) {
  return { type: actionTypes.SET_APPLICATION_ID, applicationId };
}

// eslint-disable-next-line import/prefer-default-export
export function unSetApplicationId() {
  return { type: actionTypes.UNSET_APPLICATION_ID, applicationId: null };
}
