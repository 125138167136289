import React from 'react';
import { withRouter } from 'react-router';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import Config from '../Config';

const config = new Config();
const ONE_LINE_STEP = { position: 'relative !important', top: '6px !important' };

const steps = [
  ['Account', 'Setup'],
  ['Download', 'Application'],
  ['Finished', 'Application'],
];

const currentStep = (location) => {
  const path = location.location.pathname.substring(1);
  return parseInt(location.routes[0].childRoutes.find(r => r.path === path).step, 10);
};

const title = (stepIndex) => {
  return <div><span>{steps[stepIndex][0]}</span><span>{steps[stepIndex][1]}</span></div>;
};

const progressBarSteps = () => steps.map((step, index) => {
  if (index !== 2) {
    return <Step key={index.toString()} title={title(index)} style={ONE_LINE_STEP} />;
  }
  return <Step key={index.toString()} title={title(index)} />;
});


export const EntityProgressBar = (location) => {
  return (
    <section id="progressBar">
      <div className="fitv_element container-fluid">
        <div className="container notranslate progress-steps-container">
          <div className="row">
            {config.entityAccountEnabled ?
              <div className=" col col-md-12 col-lg-12">
                <Steps current={currentStep(location)}>
                  {progressBarSteps()}
                </Steps>
              </div>
              :
              <div className=" col col-md-12 col-lg-10 col-lg-offset-1" >
                <Steps current={currentStep(location)}>
                  {progressBarSteps()}
                </Steps>
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(EntityProgressBar);
