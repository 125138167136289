import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SummarySection from '../../components/Summary/SummarySection';
import * as summaryValueMapper from './summaryValueMapper';
import * as identityActions from '../../actions/identityActions';
import * as addressActions from '../../actions/addressActions';
import * as contactActions from '../../actions/contactActions';
import * as summaryRowTypes from '../../components/Summary/summaryRowTypes';

export class SummaryPersonalInfoContainer extends React.Component {
  componentDidMount() {
    if (!this.props.identity) {
      this.props.actions.fetchIdentity(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.address) {
      this.props.actions.fetchAddress(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryPersonalInfoContainer.propTypes = {
  actions: PropTypes.shape({
    fetchAddress: PropTypes.func,
    fetchIdentity: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  address: PropTypes.shape(),
  identity: PropTypes.shape(),
};

const parseDate = (dateValue) => {
  const d = dateValue ? dateValue.toString().slice(0, 10).split('-') : [];
  return d.length === 3 ? `${d[1]}/${d[2]}/${d[0]}` : '';
};

function findContactByType(contactType, contacts) {
  return contacts.find((c) => { return c.type === contactType; });
}

function findIdentityByContactId(contactId, identities) {
  return identities.find((a) => { return a.contactId === contactId; });
}

function findAddressByContactId(contactId, addresses) {
  return addresses.find((a) => { return a.contactId === contactId; });
}

function mapStateToInitialValues(state, groupIndex) {
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const addresses = (state && state.address && state.address.length > 0) ? state.address : null;
  const identities = (state.identity && state.identity.length > 0) ? state.identity : null;

  let isLoading = true;

  if (state.identity && state.address) {
    isLoading = false;
  }

  let address = null;
  let identity = null;

  const contactType = (groupIndex === 0) ? 'primary' : 'joint';
  const contact = contacts ? findContactByType(contactType, contacts) : null;
  if (contact) {
    address = addresses ? findAddressByContactId(contact.id, addresses) : null;
    identity = identities ? findIdentityByContactId(contact.id, identities) : null;
  }

  let personalInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_BOLD,
      valueType: summaryRowTypes.SUMMARY_ROW_NONE,
      key: 'Identity',
      value: 'Identity',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'COUNTRY OF CITIZENSHIP',
      value: identity ? summaryValueMapper.getCountryName(state.countries, identity.countryOfCitizenship) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'DATE OF BIRTH',
      value: identity ? parseDate(identity.birthDate) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'SOCIAL SECURITY NUMBER',
      value: identity ? identity.identificationSSN : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_BOLD,
      valueType: summaryRowTypes.SUMMARY_ROW_NONE,
      key: 'Residential Address',
      value: 'Residential Address',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'COUNTRY',
      value: address ? summaryValueMapper.getCountryName(state.countries, address.residentialCountry) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'STREET ADDRESS 1',
      value: address ? address.residentialAddress1 : '',
    },
  ];

  if (address && address.residentialAddress2) {
    personalInformation = personalInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'STREET ADDRESS 2',
        value: address ? address.residentialAddress2 : '',
      },
    ]);
  }

  personalInformation = personalInformation.concat([
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'CITY',
      value: address ? address.residentialCity : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'STATE OR PROVINCE',
      value: address ?
        summaryValueMapper.getRegionName(state.countries, address.residentialCountry, address.residentialRegion) :
        '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'ZIP OR POSTAL CODE',
      value: address ? address.residentialZip : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_BOLD,
      valueType: summaryRowTypes.SUMMARY_ROW_NONE,
      key: 'Mailing Address',
      value: 'Mailing Address',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'COUNTRY',
      value: address ? summaryValueMapper.getCountryName(state.countries, address.mailingCountry) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'STREET ADDRESS 1',
      value: address ? address.mailingAddress1 : '',
    },
  ]);

  if (address && address.mailingAddress2) {
    personalInformation = personalInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'STREET ADDRESS 2',
        value: address ? address.mailingAddress2 : '',
      },
    ]);
  }

  personalInformation = personalInformation.concat([
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'CITY',
      value: address ? address.mailingCity : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'STATE OR PROVINCE',
      value: address ?
        summaryValueMapper.getRegionName(state.countries, address.mailingCountry, address.mailingRegion) :
        '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'ZIP OR POSTAL CODE',
      value: address ? address.mailingZip : '',
    },
  ]);

  return {
    isLoading,
    key: 'personal-info',
    value: personalInformation,
    title: 'PERSONAL INFORMATION',
    editLink: '/personal-info',
  };
}

function mapStateToProps(state, ownProps) {
  const groupIndex = ownProps.groupIndex;
  const initialValues = mapStateToInitialValues(state, groupIndex);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, contactActions, addressActions, identityActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPersonalInfoContainer);
