/* eslint-disable max-len */
export const mitekValidationErrors = {
  MITEK_ERROR_FOUR_CORNER: 'We can\'t find the 4 corners of your document. Please take a new photo capturing all 4 corners.',
  MITEK_ERROR_TOO_DARK: 'Please retake the photo in brighter lighting.',
  MITEK_ERROR_FOCUS: 'The image is too blurry. Please retake a clearer photo.',
  MITEK_ERROR_GLARE: 'The image has glare. Please retake a photo without glare.',
  MITEK_ERROR_MIN_PADDING: 'Move the camera further away from your document and retake the photo.',
  MITEK_ERROR_HORIZONTAL_FILL: 'Move the camera closer to your document and retake the photo.',
  MITEK_ERROR_SKEW_ANGLE: 'Document is skewed. Please retake the photo with the document centered.',
  MITEK_ERROR_LOW_CONTRAST: 'Low contrast. Please retake the photo on a dark background.',
  MITEK_ERROR_BUSY_BACKGROUND: 'The background is too busy. Please use a solid background.',
  MITEK_ERROR_MRZ_MISSING: 'We could not capture the information on your document. Please take a new photo capturing all 4 corners.',
  CV_NO_BARCODE_FOUND: 'No Barcode found. Please take a new photo capturing all 4 corners.',
  IMAGE_SMALLER_THAN_MIN_SIZE: 'The Image you provided is too small. Please upload a larger image.',
  CORRUPT_IMAGE: 'The Image you provided is unreadable/corrupted. Please try again.',
  MISNAP_HEAD_SKEWED: 'The Image is skewed. Please retake the photo.',
  MISNAP_HEAD_TOO_CLOSE: 'The Image is too close. Please move farther away and retake the photo.',
  MISNAP_HEAD_TOO_FAR: 'The Image is too far. Please move closer and retake the photo.',
  NO_FACE_FOUND: 'No Face Detected. Please retake the photo.',
};

export const mitekCounterErrors = [
  'MITEK_ERROR_FOUR_CORNER',
  'MITEK_ERROR_TOO_DARK',
  'MITEK_ERROR_FOCUS',
  'MITEK_ERROR_GLARE',
  'MITEK_ERROR_MIN_PADDING',
  'MITEK_ERROR_HORIZONTAL_FILL',
  'MITEK_ERROR_SKEW_ANGLE',
  'MITEK_ERROR_LOW_CONTRAST',
  'MITEK_ERROR_BUSY_BACKGROUND',
  'MITEK_ERROR_MRZ_MISSING',
  'CV_NO_BARCODE_FOUND',
  'MISNAP_HEAD_SKEWED',
  'MISNAP_HEAD_TOO_CLOSE',
  'MISNAP_HEAD_TOO_FAR',
  'NO_FACE_FOUND',
];
