import * as actionTypes from '../actions/actionTypes';
import * as storageKeys from '../actions/storageKeys';

const initialState = window.sessionStorage ? window.sessionStorage.getItem(storageKeys.AUTH_TOKEN_KEY) : null;

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH_TOKEN: {
      sessionStorage.setItem(storageKeys.AUTH_TOKEN_KEY, action.authToken);
      return action.authToken;
    }
    case actionTypes.UNSET_AUTH_TOKEN: {
      sessionStorage.removeItem(storageKeys.AUTH_TOKEN_KEY);
      return null;
    }
    default:
      return state;
  }
}
