import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import DocumentsItem from './DocumentsItem';
import DocumentsSettings from './DocumentsSettings';
import DocumentsInstructionsModal from './DocumentsInstructionsModal';

const DocumentsFormSection = (props) => {
  const {
    isTsel,
    isDomestic,
    isReturnedApplication,
    sectionIndex,
    authToken,
    contactName,
    itemProgress,
    itemStatus,
    itemVisibility,
    maxFiles,
    onFileRemove,
    onFileSubmit,
    errors,
    onHandleErrors,
    ids,
    identificationType,
    clearFilesUploaded,
  } = props;
  /* eslint-disable react/prop-types */
  const [showInstructionsModal, setShowInstructionsModal] = useState(true);
  const onShowInstructionsButtonClick = () => {
    setShowInstructionsModal(true);
  };
  const onModalHide = () => {
    setShowInstructionsModal(false);
  };

  const maximumFiles = {};
  maximumFiles.proofOfIdentity = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].proofOfIdentity
    : 0;
  maximumFiles.proofOfAddress = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].proofOfAddress
    : 0;
  maximumFiles.employerAuthorization = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].employerAuthorization
    : 0;
  maximumFiles.tradingAuthNonTsel = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].tradingAuthNonTsel
    : 0;
  maximumFiles.tradingAuthTsel = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].tradingAuthTsel
    : 0;
  maximumFiles.tradingAdvisoryNonTsel = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].tradingAdvisoryNonTsel
    : 0;
  maximumFiles.tradingAdvisoryTsel = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].tradingAdvisoryTsel
    : 0;
  maximumFiles.sepIRA = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].sepIRA
    : 0;
  maximumFiles.deathCertificate = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].deathCertificate
    : 0;
  maximumFiles.simpleIRA = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].simpleIRA
    : 0;
  maximumFiles.restriction = maxFiles[sectionIndex]
    ? maxFiles[sectionIndex].restriction
    : 0;

  const progress = {};
  progress.proofOfIdentity = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].proofOfIdentity
    : 0;
  progress.proofOfAddress = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].proofOfAddress
    : 0;
  progress.employerAuthorization = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].employerAuthorization
    : 0;
  progress.showTradingAuthNonTsel = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].showTradingAuthNonTsel
    : 0;
  progress.showTradingAuthTsel = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].showTradingAuthTsel
    : 0;
  progress.showTradingAdvisoryNonTsel = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].showTradingAdvisoryNonTsel
    : 0;
  progress.showTradingAdvisoryTsel = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].showTradingAdvisoryTsel
    : 0;
  progress.sepIRA = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].sepIRA
    : 0;
  progress.deathCertificate = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].deathCertificate
    : 0;
  progress.simpleIRA = itemProgress[sectionIndex]
    ? itemProgress[sectionIndex].simpleIRA
    : 0;

  const status = {};
  status.proofOfIdentity = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showProofOfIdentityOK
    : false;
  status.proofOfAddress = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showProofOfAddressOK
    : false;
  status.employerAuthorization = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showEmployerAuthorizationOK
    : false;
  status.showTradingAuthNonTsel = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showTradingAuthNonTselOK
    : false;
  status.showTradingAuthTsel = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showTradingAuthTselOK
    : false;
  status.showTradingAdvisoryNonTsel = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showTradingAdvisoryNonTselOK
    : false;
  status.showTradingAdvisoryTsel = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showTradingAdvisoryTselOK
    : false;
  status.showUserDocuments = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showUserDocumentsOK
    : false;
  status.showAuthorizationDocuments = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showAuthorizationDocumentsOK
    : false;
  status.showIRADocuments = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showIRADocumentsOK
    : false;
  status.showSepIRA = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showSepIraOK
    : false;
  status.showDeathCertificate = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showDeathCertificateOK
    : false;
  status.showSimpleIRA = itemStatus[sectionIndex]
    ? itemStatus[sectionIndex].showSimpleIraOK
    : false;

  const visibility = {};
  visibility.proofOfIdentity = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showProofOfIdentity
    : false;
  visibility.proofOfAddress = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showProofOfAddress
    : false;
  visibility.employerAuthorization = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showEmployerAuthorization
    : false;
  visibility.showTradingAuthNonTsel = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showTradingAuthNonTsel
    : false;
  visibility.showTradingAuthTsel = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showTradingAuthTsel
    : false;
  visibility.showTradingAdvisoryNonTsel = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showTradingAdvisoryNonTsel
    : false;
  visibility.showTradingAdvisoryTsel = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showTradingAdvisoryTsel
    : false;
  visibility.showIRADocuments = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showIRADocuments
    : false;
  visibility.sepIRA = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showSepIRA
    : false;
  visibility.deathCertificate = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showDeathCertificate
    : false;
  visibility.simpleIRA = itemVisibility[sectionIndex]
    ? itemVisibility[sectionIndex].showSimpleIRA
    : false;

  const docTip = {};
  const tooltip = {};
  tooltip.proofOfIdentity =
    '<p>Please verify the document matches the name provided on the application.</p>';

  docTip.proofOfIdentity = `<p>
  <span class='DomesticContent'>Please provide an unexpired legible copy of a government issued photo id 
  (driver’s license, passport, or government issued identification.)</span>
  <span class='NonTselContent'>Upload an image of your government issued photo ID. <br />
  - ID must not be expired or damaged. <br />
  - The name on the ID must match the name on your account application.
  </span>
  <span class='TselContent'>Upload an image of your government issued photo ID. <br />
  - ID must not be expired or damaged. <br />
  - The name on the ID must match the name on your account application.
  </span>
  </p>`;
  docTip.proofOfAddress = `<p>
  <span class='DomesticContent'>Please provide an unexpired legible copy of your driver's
  license or government issued id, a recent utility bill or bank statement to verify your address of residence.
  Must be dated within 60 days from today’s date.</span>

  <span class='NonTselContent'>Upload a copy of your last utility bill or bank statement to verify your address 
  of residence. Must be dated within 60 days from today’s date. The name on the statement must match the name on 
  your account application.
  </span>

  <span class='TselContent'>Upload a copy of your last utility bill or bank statement to verify your address 
  of residence. Must be dated within 60 days from today’s date. The name on the statement must match the name on 
  your account application.
  </span>
  </p>`;
  docTip.employerAuthorization = `<p>
  <span>Upload a copy of your employer authorization letter. As an employee or associate of a 
  NYSE, FINRA and/or NFA registered brokerage firm or US exchange, your employer is required to 
  provide authorization for you to maintain an account with TradeStation.
  </span>
  </p>`;

  tooltip.proofOfAddress = `<p>
    Please verify the document matches the name and address provided on the application.
    </p>`;

  tooltip.employerAuthorization = `<div>
      <p>
        As an employee or associate of NYSE, FINRA and/or NFA registered brokerage firm or a US exchange,
        your employer is required to provide authorization for your to maintain an account with
        TradeStation. Said authorization should be on corporate letterhead, signed by a principal or
        compliance officer and include a contact name and e-mail address to be used for duplicate statements
        and confirms.
      </p>
    </div>`;

  tooltip.tradingAuthNonTsel = `<p>
      You have selected to provide Trading Authorization to a third party. Please
      complete, print and upload a scanned copy of the signed form. Non-U.S. individuals will
      need to provide an unexpired passport copy.
    </p>`;

  tooltip.tradingAuthTsel = `<p>
      You have selected to provide Trading Authorization to a third party. Please complete,
      print and upload a scanned copy of the signed form. Non-U.S. individuals will need to
      provide an unexpired passport copy.
    </p>`;

  tooltip.sepIRA = `<p>
      Please print, complete and upload signed form.
    </p>`;

  tooltip.deathCertificate = `<p>
      Please print, complete and upload signed form.
    </p>`;

  tooltip.simpleIRA = `<p>
      Please print, complete and upload signed form.
    </p>`;

  const collapse1 = `collapse-${sectionIndex}-1`;
  const collapse2 = `collapse-${sectionIndex}-2`;
  const accordion = `accordion-${sectionIndex}`;
  const heading = `heading-${sectionIndex}`;
  const domesticClass = isDomestic[sectionIndex] ? 'DomesticCountry' : '';
  const tselClass = isTsel[sectionIndex] ? 'TselCountry' : 'NonTselCountry';
  let infoAudience = domesticClass || tselClass;
  const newDocUploadFont = 'newDocUploadFont';
  infoAudience += ` ${newDocUploadFont}`;

  return (
    <div className="row">
      <div className="col col-md-12 col-lg-8 col-lg-offset-2">
        <div
          className="panel-group"
          id={accordion}
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="panel panel-default panel-layout">
            <div className="panel-heading">
              <h3 className="panel-title">
                <a
                  className=""
                  role="button"
                  data-toggle="collapse"
                  data-parent={`#${accordion}`}
                  href={`#${collapse1}`}
                  aria-expanded="false"
                  aria-controls={collapse1}
                >
                  <i className="material-icons icon-success">
                    {status.showUserDocuments ? 'check_circle' : ''}
                  </i>
                  Documents for {contactName[sectionIndex]}
                </a>
              </h3>
            </div>
            <div
              id={collapse1}
              className="panel-collapse collapse in"
              role="tabpanel"
              aria-labelledby={heading}
            >
              <div className="panel-body">
                <div className="row">
                  <div className="col-md-12">
                    {showInstructionsModal && (
                      <DocumentsInstructionsModal
                        onModalHide={onModalHide}
                      />
                    )}
                    {visibility.proofOfIdentity && (
                      <div className={infoAudience}>
                        <DocumentsItem
                          authToken={authToken}
                          maxFiles={maximumFiles.proofOfIdentity}
                          name="proofOfIdentity"
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          progress={progress.proofOfIdentity}
                          sectionIndex={sectionIndex}
                          showOk={status.proofOfIdentity}
                          title="Proof of Identity"
                          tooltip={tooltip.proofOfIdentity}
                          docTip={docTip.proofOfIdentity}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                          identificationType={identificationType}
                          clearFilesUploaded={clearFilesUploaded}
                        >
                          {
                            !isDomestic[sectionIndex] && (
                              <Button
                                id="supported-docs-instructions"
                                className="btn-borderless-link"
                                data-toggle="modal"
                                data-target="#flipFlop"
                                onClick={onShowInstructionsButtonClick}
                              >
                                <span className="newDocUploadButtonFont"> How to take the photo </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-box-arrow-up-right boxArrowIcon"
                                  viewBox="0 -1.4 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    stroke="currentColor"
                                    strokeWidth="1.25"
                                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0
                                    1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0
                                    1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    stroke="currentColor"
                                    strokeWidth="1.25"
                                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0
                                    .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                  />
                                </svg>
                              </Button>
                            )}
                        </DocumentsItem>
                        <hr className="hr-clear fitv_element" />
                      </div>
                    )}
                    {visibility.proofOfAddress && (
                      <div className={infoAudience}>
                        <DocumentsItem
                          authToken={authToken}
                          maxFiles={maximumFiles.proofOfAddress}
                          name="proofOfAddress"
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          progress={progress.proofOfAddress}
                          sectionIndex={sectionIndex}
                          showOk={status.proofOfAddress}
                          title="Proof of Address"
                          tooltip={tooltip.proofOfAddress}
                          docTip={docTip.proofOfAddress}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                        <hr className="hr-clear fitv_element" />
                      </div>
                    )}
                    {visibility.employerAuthorization && (
                      <div className={newDocUploadFont}>
                        <DocumentsItem
                          authToken={authToken}
                          maxFiles={maximumFiles.employerAuthorization}
                          name="employerAuthorization"
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          progress={progress.employerAuthorization}
                          sectionIndex={sectionIndex}
                          showOk={status.employerAuthorization}
                          title="Employer Authorization Letter"
                          tooltip={tooltip.employerAuthorization}
                          docTip={docTip.employerAuthorization}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                        <hr className="hr-clear fitv_element" />
                      </div>
                    )}
                    {visibility.showIRADocuments && visibility.sepIRA && (
                      <div className={newDocUploadFont}>
                        <DocumentsItem
                          showOk={status.showSepIRA}
                          name="sepIRA"
                          title="SEP IRA 5305 Form"
                          titleDetail="Download document"
                          titleUri={DocumentsSettings.DownloadableFiles.sepIRA}
                          uploadLater={false}
                          maxFiles={maximumFiles.sepIRA}
                          authToken={authToken}
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          tooltip={tooltip.sepIRA}
                          progress={progress.sepIRA}
                          isReturnedApplication={isReturnedApplication}
                          sectionIndex={sectionIndex}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                        <hr className="hr-clear fitv_element" />
                      </div>
                    )}
                    {visibility.showIRADocuments &&
                      visibility.deathCertificate && (
                        <div className={newDocUploadFont}>
                          <DocumentsItem
                            showOk={status.showDeathCertificate}
                            name="deathCertificate"
                            title="Death Certificate"
                            titleDetail="Download document"
                            titleUri={
                              DocumentsSettings.DownloadableFiles
                                .deathCertificate
                            }
                            uploadLater={false}
                            maxFiles={maximumFiles.deathCertificate}
                            authToken={authToken}
                            onFileSubmit={onFileSubmit}
                            onFileRemove={onFileRemove}
                            tooltip={tooltip.deathCertificate}
                            progress={progress.deathCertificate}
                            isReturnedApplication={isReturnedApplication}
                            sectionIndex={sectionIndex}
                            errors={errors}
                            onHandleErrors={onHandleErrors}
                            ids={ids}
                            maxFileRestriction={maximumFiles.restriction}
                          />
                          <hr className="hr-clear fitv_element" />
                        </div>
                      )}
                    {visibility.showIRADocuments && visibility.simpleIRA && (
                      <div className={newDocUploadFont}>
                        <DocumentsItem
                          showOk={status.showSimpleIRA}
                          name="simpleIRA"
                          title="Simple IRA"
                          titleDetail="Download document"
                          titleUri={
                            DocumentsSettings.DownloadableFiles.simpleIRA
                          }
                          uploadLater={false}
                          maxFiles={maximumFiles.simpleIRA}
                          authToken={authToken}
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          tooltip={tooltip.simpleIRA}
                          progress={progress.simpleIRA}
                          isReturnedApplication={isReturnedApplication}
                          sectionIndex={sectionIndex}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                        <hr className="hr-clear fitv_element" />
                      </div>
                    )}
                    {!visibility.proofOfIdentity &&
                      !visibility.proofOfAddress &&
                      !visibility.employerAuthorization &&
                      !visibility.showIRADocuments && (
                        <div className="row">
                          <div className="col col-xs-8 col-sm-5 col-xs-offset-2 col-sm-offset-1">
                            <div className="inside-half-height">
                              No documents are required.
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {visibility.showAuthorizationDocuments && (
            <div className="panel panel-default panel-layout">
              <div className="panel-heading">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    data-parent={`#${accordion}`}
                    href={`#${collapse2}`}
                    aria-expanded="true"
                    aria-controls={collapse2}
                  >
                    <i className="material-icons icon-success">
                      {status.showAuthorizationDocuments ? 'check_circle' : ''}
                    </i>
                    Documents for Third Party Trader
                  </a>
                </h3>
              </div>
              <div
                id="collapse2"
                className="panel-collapse collapse "
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-12">
                      {visibility.showTradingAuthNonTsel && (
                        <DocumentsItem
                          showOk={status.showTradingAuthNonTsel}
                          name="tradingAuthNonTsel"
                          title="Trading Authorization"
                          titleDetail="Download"
                          titleUri={
                            DocumentsSettings.DownloadableFiles
                              .tradingAuthorizationNonTsel
                          }
                          uploadLater
                          maxFiles={maximumFiles.tradingAuthNonTsel}
                          authToken={authToken}
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          tooltip={tooltip.tradingAuthNonTsel}
                          progress={progress.tradingAuthNonTsel}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                      )}
                      {visibility.showTradingAuthTsel && (
                        <DocumentsItem
                          showOk={status.showTradingAuthTsel}
                          name="tradingAuthTsel"
                          title="Trading Authorization"
                          titleDetail="Download"
                          titleUri={
                            DocumentsSettings.DownloadableFiles
                              .tradingAuthorizationTsel
                          }
                          uploadLater
                          maxFiles={maximumFiles.tradingAuthTsel}
                          authToken={authToken}
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          tooltip={tooltip.tradingAuthTsel}
                          progress={progress.tradingAuthTsel}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                      )}
                      {visibility.showTradingAdvisoryNonTsel && (
                        <DocumentsItem
                          showOk={status.showTradingAdvisoryNonTsel}
                          name="tradingAdvisoryNonTsel"
                          title="Exempt Commodity Trading Advisory Letter"
                          titleDetail="Download"
                          titleUri={
                            DocumentsSettings.DownloadableFiles
                              .exemptCommodityTradingLetterNonTsel
                          }
                          uploadLater
                          maxFiles={maximumFiles.tradingAdvisoryNonTsel}
                          authToken={authToken}
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          tooltip="Please complete, print and upload a scanned copy of the signed document."
                          progress={progress.tradingAdvisoryNonTsel}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                      )}
                      {visibility.showTradingAdvisoryTsel && (
                        <DocumentsItem
                          showOk={status.showTradingAdvisoryTsel}
                          name="tradingAdvisoryTsel"
                          title="Exempt Commodity Trading Advisory Letter"
                          titleDetail="Download"
                          titleUri={
                            DocumentsSettings.DownloadableFiles
                              .exemptCommodityTradingLetterTsel
                          }
                          uploadLater
                          maxFiles={maximumFiles.tradingAdvisoryTsel}
                          authToken={authToken}
                          onFileSubmit={onFileSubmit}
                          onFileRemove={onFileRemove}
                          tooltip="Please complete, print and upload a scanned copy of the signed document."
                          progress={progress.tradingAdvisoryTsel}
                          isReturnedApplication={isReturnedApplication}
                          errors={errors}
                          onHandleErrors={onHandleErrors}
                          ids={ids}
                          maxFileRestriction={maximumFiles.restriction}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DocumentsFormSection.propTypes = {
  contactName: PropTypes.arrayOf(PropTypes.string).isRequired,
  authToken: PropTypes.string.isRequired,
  itemVisibility: PropTypes.arrayOf(
    PropTypes.shape({
      showProofOfIdentity: PropTypes.bool,
      showProofOfAddress: PropTypes.bool,
      showEmployerAuthorization: PropTypes.bool,
      showTradingAuthNonTsel: PropTypes.bool,
      showTradingAuthTsel: PropTypes.bool,
      showTradingAdvisoryTsel: PropTypes.bool,
      showTradingAdvisoryNonTsel: PropTypes.bool,
      showSepIRA: PropTypes.bool,
      showDeathCertificate: PropTypes.bool,
      showSimpleIRA: PropTypes.bool,
    }),
  ),
  itemStatus: PropTypes.arrayOf(
    PropTypes.shape({
      showProofOfIdentityOK: PropTypes.bool,
      showProofOfAddressOK: PropTypes.bool,
      showEmployerAuthorizationOK: PropTypes.bool,
      showTradingAuthNonTselOK: PropTypes.bool,
      showTradingAuthTselOK: PropTypes.bool,
      showTradingAdvisoryTselOK: PropTypes.bool,
      showTradingAdvisoryNonTsel: PropTypes.bool,
      showSepIraOK: PropTypes.bool,
      showDeathCertificateOK: PropTypes.bool,
      showSimpleIraOK: PropTypes.bool,
      showUserDocumentsOK: PropTypes.bool,
      showAuthorizationDocumentsOK: PropTypes.bool,
      showIRADocumentsOK: PropTypes.bool,
    }),
  ),
  maxFiles: PropTypes.arrayOf(
    PropTypes.shape({
      proofOfIdentity: PropTypes.number,
      proofOfAddress: PropTypes.number,
      employerAuthorization: PropTypes.number,
      tradingAuthNonTsel: PropTypes.number,
      tradingAuthTsel: PropTypes.number,
      tradingAdvisoryNonTsel: PropTypes.number,
      tradingAdvisoryTsel: PropTypes.number,
      sepIRA: PropTypes.number,
      deathCertificate: PropTypes.number,
      simpleIRA: PropTypes.number,
    }),
  ),
  itemProgress: PropTypes.arrayOf(
    PropTypes.shape({
      proofOfIdentity: PropTypes.number,
      proofOfAddress: PropTypes.number,
      employerAuthorization: PropTypes.number,
      tradingAuthNonTsel: PropTypes.number,
      tradingAuthTsel: PropTypes.number,
      tradingAdvisoryNonTsel: PropTypes.number,
      tradingAdvisoryTsel: PropTypes.number,
      sepIRA: PropTypes.number,
      deathCertificate: PropTypes.number,
      simpleIRA: PropTypes.number,
    }),
  ),
  onFileRemove: PropTypes.func.isRequired,
  onFileSubmit: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  isTsel: PropTypes.arrayOf(PropTypes.bool),
  isDomestic: PropTypes.arrayOf(PropTypes.bool),
  isReturnedApplication: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onHandleErrors: PropTypes.func.isRequired,
  ids: PropTypes.string.isRequired,
  identificationType: PropTypes.arrayOf(PropTypes.string),
  clearFilesUploaded: PropTypes.func.isRequired,
};

export default DocumentsFormSection;
