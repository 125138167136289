import React from 'react';
import PropTypes from 'prop-types';
import SummaryDetailKey from './SummaryDetailKey';
import SummaryDetailValue from './SummaryDetailValue';

const SummaryRow = (props) => {
  const {
    rowInfo,
    index,
    headerIndex,
    parentTitle,
    authToken,
  } = props;

  return (
    <div className="col-xs-12 summary-row-wrapper " key={`container_${parentTitle}_${index}_${rowInfo.key}`} >
      <SummaryDetailKey
        keyValue={rowInfo.key}
        keyType={rowInfo.keyType}
        index={index}
        headerIndex={headerIndex}
        parentTitle={parentTitle}
      />
      <SummaryDetailValue
        keyValue={rowInfo.key}
        valueType={rowInfo.valueType}
        value={rowInfo.value}
        authToken={authToken}
      />
    </div>
  );
};

SummaryRow.propTypes = {
  rowInfo: PropTypes.shape({
    keyType: PropTypes.string.isRequired,
    valueType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  headerIndex: PropTypes.number.isRequired,
  parentTitle: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default SummaryRow;
