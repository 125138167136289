import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchSalesRepsRequest() {
  return {
    type: actionTypes.FETCH_SALESREPS_REQUEST,
  };
}

export function fetchSalesRepsSuccess(salesReps) {
  return {
    type: actionTypes.FETCH_SALESREPS_SUCCESS,
    salesReps,
  };
}

export function fetchSalesRepsError(error) {
  return {
    type: actionTypes.FETCH_SALESREPS_ERROR,
    error,
  };
}

export function fetchSalesReps() {
  return (dispatch) => {
    dispatch(fetchSalesRepsRequest());
    return fetch(`${config.apiUrl}/salesreps`)
      .then(checkHttpError)
      .then(responseToJson)
      .then(data => dispatch(fetchSalesRepsSuccess(data)))
      .catch(error => dispatch(fetchSalesRepsError(error)));
  };
}
