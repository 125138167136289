import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import * as contactActions from '../actions/contactActions';
import * as RouteNavigator from './RouteNavigator';
import PhoneVerification from '../components/PhoneVerification/PhoneVerification';
import * as contactSearchAndFormat from './contactSearchAndFormat';

export class PhoneVerificationContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
      code: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateCode = this.validateCode.bind(this);
    this.setCode = this.setCode.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      contact,
    } = this.props;

    if (!contact) actions.fetchContact(applicationId, authToken);
  }

  setCode(code) {
    this.setState({ code });
  }

  async validateCode(code) {
    if (!code || code.length < 6) {
      this.setState({ error: 'Please input the 6-digit verification code.' });
      return false;
    }
    this.setState({ error: null });
    return true;
  }

  async handleSubmit(event) {
    event.preventDefault();
    const isValid = await this.validateCode(this.state.code);
    if (isValid) {
      RouteNavigator.push('/account-type');
    }
  }

  render() {
    return (
      <PhoneVerification
        {...this.props}
        onSubmit={this.handleSubmit}
        setCode={this.setCode}
        error={this.state.error}
      />
    );
  }
}

PhoneVerificationContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  contact: PropTypes.shape(),
  actions: PropTypes.shape({
    fetchContact: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToInitialValues(state) {
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  let primaryContactPhone = null;

  if (contacts && contacts.length > 0) {
    const primaryContact = contactSearchAndFormat.findContactByType('primary', contacts);
    primaryContactPhone = primaryContact.phone;
  }

  const values = {
    contacts,
    primaryContactPhone,
  };

  return values;
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);
  return {
    initialValues,
    applicationId: state.applicationId,
    authToken: state.authToken,
    contact: state.contact,
    primaryContactPhone: initialValues.primaryContactPhone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      contactActions),
      dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'phoneVerification',
})(PhoneVerificationContainer));

