import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBrowserOrigin } from './lib/browser';
import Config from './Config';

const config = new Config();

const allowedAutoLoginRoutes = [
  '/',
  '/intro',
  '/registration',
];
// We allowed this routes for in-progress applications as completed steps.
const allowedRedirectRoutes = [
  '/account-type',
  '/personal-info',
  '/employment',
  '/financial',
  '/ira-account',
  '/additional-info',
  '/documents',
];
const cookies = document.cookie;
const url = window.location.search;
const path = window.location.pathname;
const isAllowedRoute = () => {
  let isAllowed = false;
  for (let i = 0; i < allowedAutoLoginRoutes.length; i += 1) {
    const route = allowedAutoLoginRoutes[i];
    if (route === path) isAllowed = true;
  }
  return isAllowed;
};

function requireAutoLogin(Component) {
  class AutoLoginComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { shouldAutoLogin: true };

      this.shouldAutoLogin = this.shouldAutoLogin.bind(this);
    }

    componentDidMount() {
      this.shouldAutoLogin();
    }

    shouldAutoLogin() {
      const { isAuthenticated } = this.props;

      if (cookies.indexOf(' .RUN_AUTH=') > -1 && url.indexOf('login=0') === -1 && isAllowedRoute && !isAuthenticated) {
        const redirectTo = getBrowserOrigin();
        let redirectPath = '/account-type'; // default redirect path
        if (path && allowedRedirectRoutes.includes(path)) redirectPath = path;
        window.location =
          encodeURI(`${config.apiUrl}/auth/tradestation?redirectTo=`.concat(`${redirectTo}${redirectPath}`));
      } else {
        this.setState({ shouldAutoLogin: false });
      }
    }

    render() {
      const { shouldAutoLogin } = this.state;

      return (
        <div>{shouldAutoLogin ?
          null : <Component />}</div>
      );
    }
  }

  AutoLoginComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  function mapStateToProps(state) {
    const isAuthenticated = state.authToken !== null;

    return {
      isAuthenticated,
    };
  }

  return connect(mapStateToProps)(AutoLoginComponent);
}

export default requireAutoLogin;
