import React from 'react';
import PropTypes from 'prop-types';
import TooltipBrokerDealer from './TooltipBrokerDealer';

const IdentityHeader = ({ sectionIndex }) => {
  return (
    <div className="col col-md-12">
      <div>
        <TooltipBrokerDealer />
        {sectionIndex === 0 &&
          <h2 className="h2_subHeading"> Identity</h2>
        }
        {sectionIndex === 1 &&
          <h2 className="h2_subHeading"> Identity <span className="blue-text">(Joint)</span></h2>
        }
      </div>

    </div>
  );
};

IdentityHeader.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
};

export default IdentityHeader;
