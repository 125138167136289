import * as actionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export function setRecaptchaVerified() {
  return { type: actionTypes.SET_RECAPTCHA_VERIFIED, recapchaVerified: true };
}

// eslint-disable-next-line import/prefer-default-export
export function unSetRecaptchaVerified() {
  return { type: actionTypes.UNSET_RECAPTCHA_VERIFIED, recapchaVerified: false };
}
