import React from 'react';
import PropTypes from 'prop-types';

const OptionsStrategiesList = ({ optionsLevel }) => {
  return (
    <div className="options-strategies-section">
      <div className="row options-strategies-row">
        <div className="col col-xs-6">
          <span>&bull; Sell Covered Calls</span>
        </div>
        <div className="col col-xs-6">
          <span>&bull; Protective Puts</span>
        </div>
      </div>
      {optionsLevel > 0 &&
        <div className="row options-strategies-row">
          <div className="col col-xs-6">
            <span>&bull; Buy Calls and Puts</span>
          </div>
          <div className="col col-xs-6">
            <span>&bull; Covered Puts</span>
          </div>
        </div>
      }
      {optionsLevel > 25 && optionsLevel < 75 &&
        <div className="row options-strategies-row">
          <div className="col col-xs-6">
            <span>&bull; Call and Put Spreads</span>
          </div>
          <div className="col col-xs-6" />
        </div>
      }
      {optionsLevel > 50 &&
        <div className="row options-strategies-row">
          <div className="col col-xs-6">
            <span>&bull; Call and Put Spreads</span>
          </div>
          <div className="col col-xs-6">
            <span>&bull; Sell Uncovered Puts</span>
          </div>
        </div>
      }
      {optionsLevel > 75 &&
        <div className="row options-strategies-row">
          <div className="col col-xs-6">
            <span>&bull; Sell Uncovered Calls</span>
          </div>
          <div className="col col-xs-6">
            <span>&bull; Straddles</span>
          </div>
        </div>
      }
    </div>
  );
};

OptionsStrategiesList.propTypes = {
  optionsLevel: PropTypes.number.isRequired,
};

export default OptionsStrategiesList;

