import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, SubmissionError, change } from 'redux-form';
import * as registrationActions from '../actions/registrationActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as idVerifyActions from '../actions/idVerifyActions';
import * as addressActions from '../actions/addressActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as tradingAuthorizationActions from '../actions/tradingAuthorizationActions';
import * as trustedContactActions from '../actions/trustedContactActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as accountTypes from '../components/AccountType/accountTypes';
import * as documentsRequiredActions from '../actions/documentsRequiredActions';
import * as countriesActions from '../actions/countriesActions';
import * as contactActions from '../actions/contactActions';
import TradingAuthorizationForm from '../components/TradingAuthorization/TradingAuthorizationForm';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as RouteNavigator from './RouteNavigator';
import { mapCountryOptions,
  mapRegionOptions,
  filterAndSortAllowedCountriesAndRegions } from '../lib/sortFilterMapCountriesRegions';
import validatePhone from '../validations/PhoneValidation';
import optimizeHelper from '../lib/optimizeHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import validateEmail from '../validations/EmailValidation';

const defaultTradingAuthorization = {
  isOtherPeopleAuthorized: 'false',
  provideTrustedPerson: 'false',
};

export function mapValuesToTradingAuthorization(values) {
  const tradingAuthorization = {
    isOtherPeopleAuthorized: values.isOtherPeopleAuthorized,
    provideTrustedPerson: values.provideTrustedPerson,
  };
  return tradingAuthorization;
}

export function mapValuesToTrustedContact(values) {
  const trustedContact = {
    fullName: values.fullName,
    relationship: values.relationship,
    email: values.email,
    phone: values.phone,
    countryOfResidence: values.country || null,
    regionOfResidence: values.region || null,
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    zip: values.zip,
  };

  return trustedContact;
}

export const validate = (values) => {
  const errors = {};

  if (values) {
    if (values.isOtherPeopleAuthorized === undefined) {
      errors.isOtherPeopleAuthorized = 'Required';
    }

    if (values.provideTrustedPerson === 'true') {
      if (values.fullName === undefined || values.fullName === '') {
        errors.fullName = 'Required';
      } else {
        const fullName = values.fullName.trim();
        if (!/^[a-zA-Z][a-zA-Z0-9ñáéíóúü.'\s-]{1,30}$/i.test(fullName)) {
          errors.fullName = 'Not a valid Full Name';
        }
      }

      if (values.relationship === undefined || values.relationship === '') {
        errors.relationship = 'Required';
      } else {
        const relationship = values.relationship.trim();
        if (!/^[a-zA-Z0-9ñáéíóúü /.\s-]{1,30}$/i.test(relationship)) {
          errors.relationship = 'Not a valid Relationship';
        }
      }

      if ((values.email === undefined || values.email === '') && (values.phone === undefined || values.phone === '')) {
        errors.email = 'Please provide either an Email Address or a Phone Number.';
      }

      if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Not a valid Email Address';
      }

      if (values.phone) {
        errors.phone = validatePhone(values.phone);
      }
    }
  }

  return errors;
};

const checkIdVerify = (props) => {
  if ((!props.idVerify || props.idVerify.length < 1)) {
    return props.actions.fetchIdVerify(props.applicationId, props.authToken);
  }
  return 0;
};

const checkEmail = (props) => {
  if (props.contact[0] && !validateEmail(props.contact[0].email)) {
    return false;
  } else if (props.contact.length > 1 && !validateEmail(props.contact[1].email)) {
    return false;
  }
  return true;
};

const submitTradingAuthorization = (values, props) => {
  const tradingAuthorization = mapValuesToTradingAuthorization(values);
  const submit = !props.tradingAuthorizationExists ?
    props.actions.submitTradingAuthorization :
    props.actions.updateTradingAuthorization;
  return submit(tradingAuthorization, props.applicationId, props.authToken);
};

const submitTrustedContact = (values, props) => {
  if (values.provideTrustedPerson === 'true') {
    const trustedContact = mapValuesToTrustedContact(values);
    const submit = !props.trustedContactExists ?
      props.actions.submitTrustedContact :
      props.actions.updateTrustedContact;
    return submit(trustedContact, props.applicationId, props.authToken);
  } else if (props.trustedContactExists) {
    return props.actions.deleteTrustedContact(props.applicationId, props.authToken);
  }
  return null;
};

export class TradingAuthorizationFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleOnChangeCountry = this.handleOnChangeCountry.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      countries,
      tradingAuthorization,
      trustedContact,
      registration,
      accountType,
      assetClass,
      identity,
      address,
      tradingProfile,
      contact,
    } = this.props;

    if (!tradingAuthorization) actions.fetchTradingAuthorization(applicationId, authToken);
    if (!trustedContact) actions.fetchTrustedContact(applicationId, authToken);
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (countries.length === 0) actions.fetchCountries();
    if (!registration || !accountType || !assetClass || !identity || !address || !tradingProfile) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
        actions.fetchTradingProfile(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.ADDITIONAL_INFO.name,
          {
            applicationId,
            authToken,
            registration,
            accountType,
            assetClass,
            address,
            identity,
            tradingProfile,
          },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.ADDITIONAL_INFO.name,
        {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
          address,
          identity,
          tradingProfile,
        },
      );
    }
    actions.fetchIdVerify(applicationId, authToken);
    actions.fetchDocumentsRequired(applicationId, authToken);
    optimizeHelper.notify();
  }

  handleOnChangeCountry(event) {
    if (event) {
      const region = event.replace(/country$/i, 'region');
      this.props.dispatch(change('tradingAuthorization', region, ''));
    }
  }

  // eslint-disable-next-line
  handleError(error) {
    if (error.response) {
      return error.response.json()
        .then((json) => {
          const errorMessage = json.error.message;
          throw new SubmissionError({ _error: errorMessage });
        });
    }
    throw new SubmissionError({ _error: error.message });
  }

  handleSubmit(values) {
    return submitTradingAuthorization(values, this.props)
      .then(() => submitTrustedContact(values, this.props))
      .then(() => checkIdVerify(this.props))
      .then(() => {
        if (this.props.idVerify && this.props.idVerify[0] && checkEmail(this.props)) {
          const idResubmit = this.props.idVerify[0].aditionalInformation || [];
          const idResubmit2 = this.props.idVerify.length > 1 ? this.props.idVerify[1].aditionalInformation : null;
          if (idResubmit && (idResubmit.includes('SSN') || idResubmit.includes('ADD') || idResubmit.includes('DOB'))) {
            RouteNavigator.push('/resubmit');
          } else if (idResubmit2 && (idResubmit2.includes('SSN') || idResubmit2.includes('ADD')
          || idResubmit2.includes('DOB'))) {
            RouteNavigator.push('/resubmit');
          } else if (idResubmit.length === 0 && (!idResubmit2 || idResubmit2.length === 0)) {
            RouteNavigator.push('/id-verify');
          } else if (this.props.isDocumentUploadRequired) {
            RouteNavigator.push('/documents');
          } else {
            RouteNavigator.push('/summary');
          }
        } else if (this.props.isDocumentUploadRequired) {
          RouteNavigator.push('/documents');
        } else {
          RouteNavigator.push('/summary');
        }
      })
      .catch(this.handleError);
  }

  // eslint-disable-next-line
  handleBack() {
    if (this.props.isIraApplication && this.props.isEquitiesApplication) {
      RouteNavigator.push('/ira-account');
    } else {
      RouteNavigator.push('/financial');
    }
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    const {
      countries,
      countryOfResidence,
    } = this.props;

    return (
      <TradingAuthorizationForm
        {...this.props}
        countries={mapCountryOptions(countries)}
        regions={mapRegionOptions(countries, countryOfResidence, true)}
        handleOnChangeCountry={this.handleOnChangeCountry}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

TradingAuthorizationFormContainer.propTypes = {
  tradingAuthorization: PropTypes.string,
  trustedContact: PropTypes.shape(),
  countryOfResidence: PropTypes.string,
  tradingAuthorizationExists: PropTypes.bool,
  trustedContactExists: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  actions: PropTypes.shape({
    fetchTradingAuthorization: PropTypes.func.isRequired,
    submitTradingAuthorization: PropTypes.func.isRequired,
    updateTradingAuthorization: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchDocumentsRequired: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchTrustedContact: PropTypes.func.isRequired,
    submitTrustedContact: PropTypes.func.isRequired,
    checkIdVerify: PropTypes.func.isRequired,
    updateTrustedContact: PropTypes.func.isRequired,
    deleteTrustedContact: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchIdVerify: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
  }).isRequired,
  accountType: PropTypes.shape(),
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  isIraApplication: PropTypes.bool.isRequired,
  isEquitiesApplication: PropTypes.bool.isRequired,
  isDocumentUploadRequired: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  registration: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  idVerify: PropTypes.shape(),
  address: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
  contact: PropTypes.shape(),
};

export function mapStateToInitialValues(state) {
  let initialValues = defaultTradingAuthorization;

  if (state.tradingAuthorization !== undefined && state.tradingAuthorization !== null) {
    initialValues = state.tradingAuthorization;
    initialValues.isOtherPeopleAuthorized =
      (initialValues.isOtherPeopleAuthorized === true ||
        initialValues.isOtherPeopleAuthorized === 'true') ? 'true' : 'false';
    initialValues.provideTrustedPerson =
      (initialValues.provideTrustedPerson === true ||
        initialValues.provideTrustedPerson === 'true') ? 'true' : 'false';
  }

  // Initial Value for trusted Contact
  if (state.trustedContact !== undefined && state.trustedContact !== null) {
    initialValues.fullName = state.trustedContact.fullName;
    initialValues.relationship = state.trustedContact.relationship;
    initialValues.email = state.trustedContact.email;
    initialValues.phone = state.trustedContact.phone;
    initialValues.country = state.trustedContact.countryOfResidence;
    initialValues.region = state.trustedContact.regionOfResidence;
    initialValues.address1 = state.trustedContact.address1;
    initialValues.address2 = state.trustedContact.address2;
    initialValues.city = state.trustedContact.city;
    initialValues.zip = state.trustedContact.zip;
  }
  return initialValues;
}

export function mapStateToProps(state) {
  let isIraApplication = false;
  let isEquitiesApplication = false;
  let isDocumentUploadRequired = false;

  const iraAccountTypes = [
    accountTypes.IRA_SEP,
    accountTypes.IRA_SIMPLE,
    accountTypes.IRA_ROTH,
    accountTypes.IRA_ROTH_INHERITED,
    accountTypes.IRA_TRADITIONAL,
    accountTypes.IRA_TRADITIONAL_INHERITED,
  ];
  let allowedCountries = state.countries;

  if (state.accountType) {
    if (iraAccountTypes.includes(state.accountType.accountType)) {
      isIraApplication = true;
    }

    if (state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES)) {
      isEquitiesApplication = true;
    }
  }

  if (state.documentsRequired) {
    for (let i = 0; i < state.documentsRequired.length; i += 1) {
      if (state.documentsRequired[i].requiredDocsUpload.length > 0) {
        isDocumentUploadRequired = true;
        if (state.documentsRequired[i].requiredDocsUpload.length === 1
          && isIraApplication
          && !isEquitiesApplication) isDocumentUploadRequired = false;
      }
    }
  }

  if (state.countries && state.countries.length > 0) {
    allowedCountries = filterAndSortAllowedCountriesAndRegions(state.countries);
  }

  const selector = formValueSelector('tradingAuthorization');
  const trustedContactRadio = selector(state, 'provideTrustedPerson');
  const countryOfResidence = selector(state, 'country');
  const trustedContactSelected = trustedContactRadio === 'true';

  return {
    initialValues: mapStateToInitialValues(state),
    applicationId: state.applicationId,
    authToken: state.authToken,
    tradingAuthorizationExists: !!state.tradingAuthorization,
    isIraApplication,
    isEquitiesApplication,
    isDocumentUploadRequired,
    trustedContactExists: !!state.trustedContact,
    showTrustedContact: true,
    trustedContactSelected,
    countries: allowedCountries,
    countryOfResidence,
    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    idVerify: state.idVerify,
    address: state.address,
    tradingProfile: state.tradingProfile,
    contact: state.contact,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      tradingAuthorizationActions,
      documentsRequiredActions,
      countriesActions,
      trustedContactActions,
      registrationActions,
      accountTypeActions,
      assetClassActions,
      identityActions,
      idVerifyActions,
      addressActions,
      tradingProfileActions,
      contactActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'tradingAuthorization',
  validate,
})(TradingAuthorizationFormContainer));
