import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import AddressFormResidential from './AddressFormResidential';
import AddressFormMailing from './AddressFormMailing';
import AddressPlaceHolder from './AddressPlaceHolder';

const AddressForm = (props) => {
  const { isJointApplication, showMailingAddress } = props;

  return (
    <div className="row">
      <div className="col col-md-6 col-xs-12">
        <FormSection name="addresses[0]">
          <AddressFormResidential {...props} sectionIndex={0} isJointApplication={isJointApplication} />
        </FormSection>
        <FormSection name="addresses[0]">
          <AddressFormMailing {...props} sectionIndex={0} isJointApplication={isJointApplication} />
        </FormSection>
      </div>
      <div className="col col-md-6 col-xs-12">
        <FormSection name="addresses[1]">
          <div>
            {isJointApplication &&
              <AddressFormResidential {...props} sectionIndex={1} isJointApplication={isJointApplication} />
            }
            {!isJointApplication &&
              <AddressPlaceHolder isResidential />
            }
          </div>
        </FormSection>
        <FormSection name="addresses[1]">
          <div>
            {isJointApplication &&
              <AddressFormMailing {...props} sectionIndex={1} isJointApplication={isJointApplication} />
            }
            {!isJointApplication && !!showMailingAddress[0] &&
              <AddressPlaceHolder src="images/mailing-address.png" isResidential={false} />
            }
          </div>
        </FormSection>
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
  showMailingAddress: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

export default AddressForm;
