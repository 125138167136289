import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import DocumentsFormSection from './DocumentsFormSection';
import ExitModal from '../ExitModal';

const DocumentsForm = (props) => {
  const {
    isJointApplication,
    submittingFile,
    handleSubmit,
    onSubmit,
    onBack,
    error,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

  const principalIds = 'docs[0]';
  const jointIds = 'docs[1]';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection name={principalIds}>
        <DocumentsFormSection {...props} ids={principalIds} sectionIndex={0} />
      </FormSection>
      {isJointApplication &&
        <FormSection name={jointIds}>
          <DocumentsFormSection {...props} ids={jointIds} sectionIndex={1} />
        </FormSection>
      }
      <div className="row">
        <div className="col col-md-12 col-lg-8 col-lg-offset-2 no-side-padding">
          {error && <ErrorMessage error={error} />}
        </div>
      </div>
      {showExitModal && (
        <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
      )}
      <div className="col col col-md-12 col-lg-8 col-lg-offset-2  no-side-padding">
        <Navigation onBack={onBack} submittingFile={submittingFile} onExitModalOn={onExitModalOn} />
      </div>
    </form>
  );
};

DocumentsForm.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
  submittingFile: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  error: PropTypes.string,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default DocumentsForm;
