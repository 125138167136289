import React from 'react';
import EntityApplicationContainer from '../../containers/EntityApplicationContainer';
import EntityProgressBar from '../EntityProgressBar';

const EntityApplicationPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <EntityProgressBar />
      <div className="container">
        <div className="row">
          <div id="applicationPage" className="col col-md-12 col-lg-12">
            <h1 id="page-header" className="h1_secondary text-center">
              Application
            </h1>
            <EntityApplicationContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntityApplicationPage;
