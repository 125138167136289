import React from 'react';
import SummaryFormContainer from '../../containers/SummaryFormContainer';
import ProgressBar2 from '../ProgressBar2';

export class SummaryPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoader: false,
    };
    this.handleLoaderFlag = this.handleLoaderFlag.bind(this);
  }

  handleLoaderFlag(value) {
    this.setState({ showLoader: value });
  }

  render() {
    return (
      <section id="summary-section" className="form-content-section-primary">
        {this.state.showLoader &&
          <div id="full-screen-loader" className="full-screen-loader">
            <h1 className="center loading">Processing</h1>
          </div>
        }
        <ProgressBar2 />
        <div id="summary-form" className="container">
          <div className="row">
            <div id="appSummary" className="col col-md-12 col-lg-8 col-lg-offset-2 no-side-padding">
              <div className="text-center">
                <h1 className="h1_secondary">Account Summary</h1>
                <p>
                  You&apos;re all set to create your account, take some time to review all the data you&apos;ve filled
                  here or continue when ready.
                </p>
              </div>
              <SummaryFormContainer handleLoaderFlag={this.handleLoaderFlag} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SummaryPage;
