import React from 'react';

const AgreementsInstructions = () => {
  return (
    <div className="agreements-instructions">
      <div className="row">
        <div className="col col-md-12">
          <p>
            <b><i>
              Please carefully review all of the content on this page and each of the
              required documents below
            </i></b> (click on the title of each document to access its
            content). <b><i>PLEASE READ ALL DOCUMENTS CAREFULLY.</i></b>&nbsp;If this is a joint account
            application, “you” means each joint-account applicant, jointly and severally.
            Once you have reviewed and agreed with each document, check the box below next
            to the section which includes such document. We strongly recommend that you
            print a copy of each document for your records, as your acknowledgment and
            acceptance of these agreements and other documents are legally binding on you
            and create estoppel and affirmative defenses against you, and you agree that
            they do so no differently than if each one was individually signed by you in
            ink and delivered to TradeStation in person.
          </p>
          <p>
            You understand and agree that the respective customer account agreements which
            will apply to, and govern, your account relationships with TradeStation
            companies are as follows: (1) for an <b>Equities</b> account
            (including for equity/index options trading), the
            <b> TradeStation Securities, Inc. Customer Account Agreement for Equities</b>, together with the
            <b> Master Securities Lending Agreement</b>, and (2) for a
            <b> Futures</b> and/or <b>Futures Options</b> account, the&nbsp;
            <b>TradeStation Securities, Inc. Account Agreement for Futures</b>.
            Also, there are supplemental agreements, disclosures and other
            documents which will apply to one or more of your accounts (as set forth below)
            which you must agree to, accept and acknowledge.
          </p>
          <p>
            The <b>TradeStation Technologies, Inc. Subscription Agreement</b> and the <b>data subscription/subscriber agreements </b>
            are separate license agreements governing your use of TradeStation and third-party software
            and market data/content, and the
            <b> User Agreement (Websites, Electronic Services, Social Media and Education) </b>
            applies to your use of those products and services
            offered by any of the TradeStation companies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AgreementsInstructions;
