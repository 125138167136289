import React from 'react';
import PropTypes from 'prop-types';

const IdVerifySDK = (props) => {
  const {
    loadingScreenSDK,
  } = props;

  const showSDK = {
    visibility: loadingScreenSDK ? 'hidden' : 'visible',
  };

  return (
    <div>
      <div>
        <div>
          {loadingScreenSDK && (
            <div className="spinnerSDKDiv">
              <span
                id="loadingSpinner"
                className="glyphicon glyphicon-refresh spinning spinnerSDK"
              />
            </div>
          )}
          <div id="socureWebSDK" style={showSDK} />
        </div>
      </div>
    </div>
  );
};

IdVerifySDK.propTypes = {
  loadingScreenSDK: PropTypes.bool.isRequired,
};

export default IdVerifySDK;
