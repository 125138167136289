import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

function fetchEntitiesAction(actionType, payload) {
  const result = {
    type: actionType,
  };

  if (payload) {
    result[payload.name] = payload.value;
  }

  return result;
}

export default function fetchEntities(entityName, payloadName, actionsTypes, applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchEntitiesAction(actionsTypes.request));

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
    };
    return fetch(`${config.apiUrl}/applications/${applicationId}/${entityName}`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchEntitiesAction(actionsTypes.success, { value: json, name: payloadName })))
      .catch(error => dispatch(fetchEntitiesAction(actionsTypes.error, { value: error, name: 'error' })));
  };
}
