import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_TRUSTED_CONTACT_SUCCESS:
      return action.trustedContact;
    case actionTypes.SUBMIT_TRUSTED_CONTACT_SUCCESS:
      return action.trustedContact;
    case actionTypes.DELETE_TRUSTED_CONTACT_SUCCESS:
      return null;
    default:
      return state;
  }
}
