import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class CryptoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: props.showCryptoModal,
    };

    this.handleHide = this.handleHide.bind(this);
  }

  getAssetsText() {
    let text = 'equities';
    if (this.props.isFuturesSelected && !this.props.isFuturesOptionsSelected) {
      text = text.concat(' and futures');
    } else if (this.props.isFuturesSelected || this.props.isFuturesOptionsSelected) {
      text = text.concat(', futures and futures options');
    }
    return text;
  }

  handleHide() {
    this.setState({ showModal: false });
    this.props.handleCryptoModalAcknowledgeOff();
    this.props.handleCryptoModalToggleOff();
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton>
          <div>
            <h3>
              Information about your TradeStation Crypto account application
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>
            TradeStation Crypto is working hard to offer cryptocurrency accounts to residents of your state.
            You will be notified as soon as it is ready.
            <br />
          </p>
          {(this.props.isFuturesSelected || this.props.isFuturesOptionsSelected) &&
            <p>
              In the meantime, please proceed to open the other account types you have requested,
              including a TradeStation Securities Equities account, which is required to open a TradeStation Crypto
              account (
              <a
                className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
                href="https://uploads.tradestation.com/uploads/requirement-to-open-tradestation-crypto-account.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
              &nbsp;to understand why), and get started trading {this.getAssetsText()} today.
            </p>
          }
          {(!this.props.isFuturesSelected && !this.props.isFuturesOptionsSelected) &&
            <p>
              In the meantime, please proceed to open a TradeStation Securities Equities account, which is required
              to open a TradeStation Crypto account (
              <a
                className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
                href="https://uploads.tradestation.com/uploads/requirement-to-open-tradestation-crypto-account.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
              &nbsp;to understand why), and get started trading {this.getAssetsText()} today.
            </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center intro-legacy-message">
            <button
              form="accounTypeForm"
              key="submit"
              className="btn btn-raised btn-default btn-crypto-modal"
              id="cyptoWaitListModalOkBtn"
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

CryptoModal.propTypes = {
  showCryptoModal: PropTypes.bool,
  handleCryptoModalToggleOff: PropTypes.func,
  handleCryptoModalAcknowledgeOff: PropTypes.func,
  isFuturesSelected: PropTypes.bool,
  isFuturesOptionsSelected: PropTypes.bool,
};

export default CryptoModal;
