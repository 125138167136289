import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { attachRecaptchaToken } from './attachRecaptchaToken';
import Config from '../Config';

const config = new Config();

export function fetchDocumentsError(error) {
  return { type: actionTypes.FETCH_DOCUMENTS_ERROR, error };
}

export function fetchDocumentsRequest() {
  return { type: actionTypes.FETCH_DOCUMENTS_REQUEST };
}

export function fetchDocumentsSuccess(documents) {
  return { type: actionTypes.FETCH_DOCUMENTS_SUCCESS, documents };
}

export function fetchDocuments(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchDocumentsRequest());
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/documents`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchDocumentsSuccess(json)))
      .catch(error => dispatch(fetchDocumentsError(error)));
  };
}

export function submitDocumentsError(error) {
  return { type: actionTypes.SUBMIT_DOCUMENTS_ERROR, error };
}

export function submitDocumentsRequest(documents) {
  return { type: actionTypes.SUBMIT_DOCUMENTS_REQUEST, documents };
}

export function submitDocumentsSuccess(documents) {
  return { type: actionTypes.SUBMIT_DOCUMENTS_SUCCESS, documents };
}

// eslint-disable-next-line no-underscore-dangle
function _submitDocuments(documents, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitDocumentsRequest(documents));

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(documents.length > 1 ? documents : documents[0]),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/documents`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitDocumentsSuccess(json)))
      .catch((error) => {
        dispatch(submitDocumentsError(error));
        return Promise.reject(error);
      });
  };
}

// eslint-disable-next-line no-underscore-dangle
function _submitDocument(document, applicationId, authToken) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(document),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/applications/${applicationId}/documents`, options)
    .then(checkHttpError)
    .then(responseToJson)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// eslint-disable-next-line no-underscore-dangle
function _updateDocument(document, applicationId, authToken) {
  const options = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(document),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/applications/${applicationId}/documents/${document.id}`, options)
    .then(checkHttpError)
    .then(responseToJson)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// eslint-disable-next-line no-underscore-dangle
function _updateDocuments(documents, applicationId, authToken) {
  return (dispatch) => {
    dispatch(submitDocumentsRequest(documents));

    const puts = documents.map((d) => {
      const submitOrUpdate = d.id && d.id > 0 ? _updateDocument : _submitDocument;
      return submitOrUpdate(d, applicationId, authToken)
        .then((json) => {
          return Promise.resolve(json);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });

    return Promise.all(puts)
      .then(json => dispatch(submitDocumentsSuccess(json)))
      .catch((error) => {
        dispatch(submitDocumentsError(error));
        return Promise.reject(error);
      });
  };
}

export function submitDocuments(documents, applicationId, authToken) {
  return _submitDocuments(documents, applicationId, authToken, 'POST');
}

export function updateDocuments(documents, applicationId, authToken) {
  return _updateDocuments(documents, applicationId, authToken, 'PUT');
}

export function deleteDocumentsError(error) {
  return { type: actionTypes.DELETE_DOCUMENTS_ERROR, error };
}

export function deleteDocumentsRequest() {
  return { type: actionTypes.DELETE_DOCUMENTS_REQUEST };
}

export function deleteDocumentsSuccess() {
  return { type: actionTypes.DELETE_DOCUMENTS_SUCCESS };
}

export function deleteDocuments(applicationId, authToken) {
  return (dispatch) => {
    dispatch(deleteDocumentsRequest());

    const options = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/documents`, options)
      .then(checkHttpError)
      .then(() => dispatch(deleteDocumentsSuccess()))
      .catch((error) => {
        dispatch(deleteDocumentsError(error));
        return Promise.reject(error);
      });
  };
}

export function submitDocumentFile(documents, applicationId, authToken) {
  return () => {
    return attachRecaptchaToken({}, 'documents')
      .then((data) => {
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: authToken,
          },
          body: documents,
        };
        if (data.authorization) options.headers.RecaptchaAuthorization = data.authorization;
        return fetch(`${config.apiUrl}/applications/${applicationId}/documentFiles`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
