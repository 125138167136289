import React from 'react';
import PropTypes from 'prop-types';
import TooltipNonPro from './TooltipNonPro';

const AddressHeader = ({ isResidential, sectionIndex, showTooltip }) => {
  const title = isResidential ? 'Residential' : 'Mailing';

  return (
    <div className="col col-md-12">
      <div>
        {showTooltip &&
          <TooltipNonPro />
        }
        {sectionIndex === 0 &&
          <h2 className="h2_subHeading"> {title} Address</h2>
        }
        {sectionIndex === 1 &&
          <h2 className="h2_subHeading"> {title} Address <span className="blue-text">(Joint)</span></h2>
        }
      </div>

    </div>
  );
};

AddressHeader.propTypes = {
  isResidential: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  showTooltip: PropTypes.bool,
};

export default AddressHeader;
