import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Config from '../Config';

const config = new Config();

class ExistingAccountModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: true,
    };

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>{this.props.message}.</p>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-primary"
            href={`${config.apiUrl}/auth/tradestation`}
            role="button"
          >
            Login
          </a>
        </Modal.Footer>
      </Modal>
    );
  }
}

ExistingAccountModal.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ExistingAccountModal;
