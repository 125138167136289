import * as tradingProfileTypes from '../components/TradingProfile/tradingProfileTypes';

const MAXIMUM_ANNUAL_INCOME = '50,000.00';
const MAXIMUM_NET_WORTH = '75,000.00';

export function validateMoney(value) {
  if (/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/.test(value)) {
    const number = value.replace(/[^0-9.]/g, '');
    return parseFloat(number);
  }
  return NaN;
}

function validateSpecified(value, maximum, maximumIncluded) {
  let isValid = true;
  if (value) {
    const amount = validateMoney(value);
    const max = validateMoney(maximum);
    if (isNaN(amount) || amount < 0 || (!maximumIncluded && amount >= max) || (maximumIncluded && amount > max)) {
      isValid = false;
    }
  } else {
    isValid = false;
  }
  return isValid;
}

const validateMonthNumber = (value) => {
  if (/^\d+$/.test(value)) {
    return parseFloat(value);
  }
  return NaN;
};

const validate = (values) => {
  const formErrors = { financials: [], tradingProfiles: [] };

  if (values.financials && values.financials.length > 0) {
    values.financials.forEach((financial, ix) => {
      const errors = {};

      if (!financial.annualIncome) {
        errors.annualIncome = 'Required';
      }
      if (financial.annualIncome === '1') {
        if (!financial.annualIncomeSpecified) {
          errors.annualIncomeSpecified = 'Required';
        } else if (!validateSpecified(financial.annualIncomeSpecified, MAXIMUM_ANNUAL_INCOME)) {
          errors.annualIncomeSpecified = 'Must be a valid number under $50,000';
        }
      }
      if (!financial.totalNetWorth) {
        errors.totalNetWorth = 'Required';
      }
      if (financial.totalNetWorth === '1') {
        if (!financial.totalNetWorthSpecified) {
          errors.totalNetWorthSpecified = 'Required';
        } else if (!validateSpecified(financial.totalNetWorthSpecified, MAXIMUM_NET_WORTH)) {
          errors.totalNetWorthSpecified = 'Must be a valid number under $75,000';
        }
      }
      if (!financial.liquidNetWorth) {
        errors.liquidNetWorth = 'Required';
      }
      if (financial.liquidNetWorth > financial.totalNetWorth) {
        errors.liquidNetWorth = 'Liquid Net Worth cannot be more than your Total Net Worth.';
      }
      if (financial.liquidNetWorth === '1') {
        if (!financial.liquidNetWorthSpecified) {
          errors.liquidNetWorthSpecified = 'Required';
        } else if (!validateSpecified(financial.liquidNetWorthSpecified, MAXIMUM_NET_WORTH)) {
          errors.liquidNetWorthSpecified = 'Must be a valid number under $75,000';
        } else if (financial.totalNetWorth === '1' &&
          !validateSpecified(financial.liquidNetWorthSpecified, financial.totalNetWorthSpecified, true)) {
          errors.liquidNetWorthSpecified = 'Liquid Net Worth cannot be more than your Total Net Worth.';
        }
      }
      if (!financial.sourceOfIncome) {
        errors.sourceOfIncome = 'Required';
      }
      if (!financial.sourceOfAssetsForTrading) {
        errors.sourceOfAssetsForTrading = 'Required';
      }
      formErrors.financials[ix] = errors;
    });
  }

  if (values.tradingProfiles && values.tradingProfiles.length > 0) {
    const tradingProfilesArrayErrors = [];

    values.tradingProfiles.forEach((tradingProfile, tpx) => {
      const tradingProfileErrors = {};

      if (tradingProfile.yearsExperienceStocks === tradingProfileTypes.UNDER_ONE) {
        if (!tradingProfile.monthsExperienceStocks) {
          tradingProfileErrors.monthsExperienceStocks = 'Months required';
        } else if (!validateMonthNumber(tradingProfile.monthsExperienceStocks)) {
          tradingProfileErrors.monthsExperienceStocks = 'Must be a valid number';
        } else
            if (tradingProfile.monthsExperienceStocks &&
            (tradingProfile.monthsExperienceStocks < 1 || tradingProfile.monthsExperienceStocks > 11)) {
              tradingProfileErrors.monthsExperienceStocks = 'Invalid Months';
            }
      }

      if (tradingProfile.yearsExperienceOptions === tradingProfileTypes.UNDER_ONE) {
        if (!tradingProfile.monthsExperienceOptions) {
          tradingProfileErrors.monthsExperienceOptions = 'Months required';
        } else if (!validateMonthNumber(tradingProfile.monthsExperienceOptions)) {
          tradingProfileErrors.monthsExperienceOptions = 'Must be a valid number';
        } else
            if (tradingProfile.monthsExperienceOptions &&
            (tradingProfile.monthsExperienceOptions < 1 || tradingProfile.monthsExperienceOptions > 11)) {
              tradingProfileErrors.monthsExperienceOptions = 'Invalid Months';
            }
      }

      if (tradingProfile.yearsExperienceFutures === tradingProfileTypes.UNDER_ONE) {
        if (!tradingProfile.monthsExperienceFutures) {
          tradingProfileErrors.monthsExperienceFutures = 'Months required';
        } else if (!validateMonthNumber(tradingProfile.monthsExperienceFutures)) {
          tradingProfileErrors.monthsExperienceFutures = 'Must be a valid number';
        } else
            if (tradingProfile.monthsExperienceFutures &&
            (tradingProfile.monthsExperienceFutures < 1 || tradingProfile.monthsExperienceFutures > 11)) {
              tradingProfileErrors.monthsExperienceFutures = 'Invalid Months';
            }
      }

      if (tradingProfile.haveOtherTradeStationAccountNumberSelection === 'true') {
        if (!tradingProfile.otherTradeStationAccountNumber) {
          tradingProfileErrors.otherTradeStationAccountNumber = 'Account required';
        }
      }

      tradingProfilesArrayErrors[tpx] = tradingProfileErrors;
    });

    if (tradingProfilesArrayErrors.length > 0) {
      formErrors.tradingProfiles = tradingProfilesArrayErrors;
    }
  }
  return formErrors;
};

export default validate;
