import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

function upsertEntitiesAction(actionType, payload) {
  const result = {
    type: actionType,
  };

  if (payload) {
    result[payload.name] = payload.value;
  }

  return result;
}

// eslint-disable-next-line no-underscore-dangle
function upsertEntity(entity, method, entityName, applicationId, authToken) {
  const options = {
    method,
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entity),
    mode: 'cors',
  };

  const id = entity.id;

  return fetch(`${config.apiUrl}/applications/${applicationId}/${entityName}${id ? `/${id}` : ''}`, options)
    .then(checkHttpError)
    .then(responseToJson)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// eslint-disable-next-line no-underscore-dangle
export default function upsertEntities(entities, entityName, payloadName, actionsTypes, applicationId, authToken) {
  return (dispatch) => {
    dispatch(upsertEntitiesAction(actionsTypes.request, { value: entities, name: payloadName }));

    const methods = entities.map((i) => { return i.id && i.id > 0 ? 'PUT' : 'POST'; });
    const postsOnly = methods.indexOf('PUT') === -1;

    if (postsOnly || entities.length === 0) {
      return upsertEntity(entities, 'POST', entityName, applicationId, authToken)
        .then(json => dispatch(upsertEntitiesAction(actionsTypes.success, { value: json, name: payloadName })))
        .catch((error) => {
          dispatch(upsertEntitiesAction(actionsTypes.error, { value: error, name: 'error' }));
          return Promise.reject(error);
        });
    }
    const postAndPuts = entities.map((f, index) => {
      return upsertEntity(f, methods[index], entityName, applicationId, authToken);
    });

    return Promise.all(postAndPuts)
      .then(json => dispatch(upsertEntitiesAction(actionsTypes.success, { value: json, name: payloadName })))
      .catch((error) => {
        dispatch(upsertEntitiesAction(actionsTypes.error, { value: error, name: 'error' }));
        return Promise.reject(error);
      });
  };
}
