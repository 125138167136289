import React from 'react';

const EmploymentProfessionalModal = () => {
  return (
    <div
      className="modal fade"
      id="employment-professional-subscriber"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="EmploymentProfessionalSubscriber"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 className="modal-title" id="myModalLabel">Professional Subscribers</h3>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col col-xs-12">
                <p>
                  Some exchanges require that Professional Subscribers who do not otherwise qualify as Non
                  Professional or Private Individual Subscribers must pay higher fees for real-time data and other
                  market data services.
                </p>
                <p>
                  The relevant fees are displayed at &nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.tradestation.com/pricing/market-data-pricing"
                  >
                    Market Data Pricing
                  </a>.
                </p>
                <p>
                  You are generally deemed a <b>Non-Professional</b> or a <b>Private Individual Subscriber </b>
                  if you meet the following requirements:
                </p>
                <ul>
                  <li>You are an individual acting in a personal capacity;</li>
                  <li>You are not acting as a principal, officer, partner, employee or agent of any business; and</li>
                  <li>You do not otherwise qualify as a &quot;Professional&quot; subscriber (See below).</li>
                </ul>
                <p>
                  You are deemed a <b>Professional Subscriber</b> if you meet one or more of the
                  following requirements
                </p>
                <ul>
                  <li>You are subscribing on behalf of a firm, corporation, partnership, trust, or association.</li>
                  <li>You use the information in connection with any trade or business activities.</li>
                  <li>You plan to furnish the information to any other person(s).</li>
                  <li>You are a securities broker-dealer, registered representative, investment advisor, investment
                    banker, futures commission merchant, retail foreign exchange dealer, commodities introducing
                    broker or commodity trading advisor, money manager, member of any securities exchange or
                    association or futures contract market, or any owner, partner or associated person of
                    the foregoing.
                  </li>
                  <li>You are employed by a bank or an insurance company (or an affiliate of either) to perform
                    functions related to securities or commodity futures investment or trading activity.
                  </li>
                </ul>
                <p className="footnote"> <small>*The definition of a Professional Subscriber might vary somewhat
                  depending the Exchange User Agreement(s) to which you elect to subscribe. Please read each
                  such agreement carefully before making your attestation.</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentProfessionalModal;
