import * as accountTypes from './accountTypes';

const accountTypesTexts = [
  { value: '', text: '' },
  { value: accountTypes.ACCOUNT_TYPE_INDIVIDUAL, text: 'Individual' },
  { value: accountTypes.ACCOUNT_TYPE_JOINT, text: 'Joint' },
  { value: accountTypes.ACCOUNT_TYPE_ENTITY, text: 'Entity' },
  { value: accountTypes.ACCOUNT_TYPE_IRA, text: 'IRA' },
  { value: accountTypes.JOINT_WITH_RIGHTS_OF_SURVIVORSHIP, text: 'Joint with right of survivorship' },
  { value: accountTypes.JOINT_AS_TENANTS_IN_COMMON, text: 'Joint as tenants-in-common' },
  { value: accountTypes.ENTITY_CORPORATE, text: 'Corporate' },
  { value: accountTypes.ENTITY_PARTNERSHIP, text: 'Partnership' },
  { value: accountTypes.ENTITY_TRUST, text: 'Trust' },
  { value: accountTypes.ENTITY_LIMITED_LIABILITY, text: 'Limited liability company' },
  { value: accountTypes.ENTITY_SOLE_PROPRIETORSHIP, text: 'Sole proprietorship' },
  { value: accountTypes.IRA_ROTH, text: 'Roth IRA' },
  { value: accountTypes.IRA_ROTH_INHERITED, text: 'Inherited Roth IRA' },
  { value: accountTypes.IRA_SEP, text: 'SEP IRA' },
  { value: accountTypes.IRA_SIMPLE, text: 'SIMPLE IRA' },
  { value: accountTypes.IRA_TRADITIONAL, text: 'Traditional IRA' },
  { value: accountTypes.IRA_TRADITIONAL_INHERITED, text: 'Inherited Traditional IRA' },
];

export default accountTypesTexts;
