import React from 'react';
import ReactTooltip from 'react-tooltip';

const TooltipNonPro = () => {
  return (
    <div className="help-circle">
      <a data-tip data-for="marginOrCash">
        <i className="material-icons title-help">help</i>
      </a>
      <ReactTooltip id="marginOrCash" place="left" class="tooltip">
        <span>
          If you will be using market data solely for your personal trading, please note that the use of
          a business mailing or email address could indicate professional activity in the eyes of the various
          data providers. This could result in their requiring you to be designated as a professional, which
          typically carries higher fees for real-time data and/or other market data services.
        </span>
      </ReactTooltip>
    </div>
  );
};

export default TooltipNonPro;
