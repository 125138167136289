import React from 'react';
import PropTypes from 'prop-types';
import TextBox from '../TextBox';
import Address from '../PersonalInfo/Address/Address';

const TrustedContact = (props) => {
  const {
    countries,
    regions,
    handleOnChangeCountry,
  } = props;

  return (
    <div className="row">
      <div className="contact">
        <h1 className="h2_subHeading">
          Trusted Contact Information:
        </h1>
        <TextBox
          name="fullName"
          className="form-control"
          label="Full Name"
          maxLength={60}
        />
        <TextBox
          name="relationship"
          className="form-control"
          label="Relationship"
          maxLength={60}
        />
        <TextBox
          type="email"
          name="email"
          className="form-control"
          label="Email Address"
          maxLength={50}
        />
        <TextBox
          name="phone"
          className="form-control"
          label="Phone Number"
          maxLength={25}
        />
        <br />
      </div>
      <h1 className="h2_subHeading adress-sub">
        Address:
      </h1>
      <Address
        countries={countries}
        regions={regions}
        handleOnChangeCountry={handleOnChangeCountry}
      />
    </div>
  );
};

TrustedContact.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))).isRequired,
  handleOnChangeCountry: PropTypes.func.isRequired,
};

export default TrustedContact;

