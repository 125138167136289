import React from 'react';
import ReactTooltip from 'react-tooltip';
import Config from '../../Config';
import getFileType from '../../lib/file-type';
import { mitekCounterErrors } from './mitekErrorTexts';
import * as identificationTypes from '../../components/PersonalInfo/Identity/identificationTypes';

const config = new Config();
export const acceptedFileMimes = `image/*,image/tiff,image/jpg,image/jpeg,image/gif,
      image/png,image/bmp,application/pdf,application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
const acceptedFileExtensions = 'tiff,jpg,gif,png,bmp,pdf,doc,docx';
export const maxFileSize = 10000001;
export const maxFileSizeIdentity = 5000000;
const maxFileNameLength = 48;
const fileNameRegex = /[^A-Za-z0-9_.\-@]/i;
const mitekFailure = 'failure';

export const isImage = (mimeType) => {
    return mimeType.indexOf('image/') !== -1;
  };
export const isCounterError = (error) => {
  return mitekCounterErrors.includes(error);
};

export const mitekValidate = async (files, isProofOfIdentity, docType) => {
  let error = null;
  const mitekDocType = docType || identificationTypes.MITEK_DRIVERS_LICENSE;
  return new Promise((resolve) => {
    if (isProofOfIdentity) {
      window.mitekScienceSDK.cmd('PROCESS_FRAME', {
        documentType: mitekDocType,
        mitekSDKPath: './js/vendor/mitekSDK/',
        options: {
          frame: files[0],
          qualityPercent: 80,
        },
      });
      window.mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', (res) => {
        if (res.status && res.status === mitekFailure) {
          error = res.key;
          resolve(error);
        } else resolve(null);
      });
      window.mitekScienceSDK.on('FRAME_CAPTURE_RESULT', (res) => {
        if (res.response && res.response.status === mitekFailure) {
          error = res.response.warnings.key;
          resolve(error);
        } else resolve(null);
      });
      window.mitekScienceSDK.on('SDK_ERROR', (err) => {
        // eslint-disable-next-line no-console
        console.log('SDK_ERROR', err);
        resolve(null);
      });
    } else resolve(null);
  });
};

export const removeNewFile = (files, id, key, onChange, onFileRemove, setDocuments, isDisabled,
  fileName, isSmallBox = false, handleErrors = null) => {
  function handleClick(e) {
    e.preventDefault();

    if (isDisabled) return;

    const file = files.filter(x => x.documentId === id);
    const value = files.filter(x => x !== file[0]);
    onChange(value);

    const matches = fileName.match(/^docs\[(\d*)\]\.(.*)$/);
    onFileRemove(matches[1], matches[2], key);
    setDocuments(value);
    if (handleErrors && isSmallBox) {
      handleErrors(fileName, []);
    }
  }

  return (
    <a href="#" onClick={handleClick} alt="Remove">
      {isSmallBox &&
        <i className="material-icons removeFileIcon">delete_forever</i>
      }
      {!isSmallBox &&
        <i className="material-icons">delete_forever</i>
      }
    </a>
  );
};

export const viewFile = (fileType, filePath, fileDocumentId, authToken, isSmallBox = false) => {
  const fileURL = `${config.apiUrl}${filePath}`.concat('?access_token=', authToken);

  if (isImage(fileType)) {
    return (
      <span>
        <a data-tip data-for={fileDocumentId}>
          {isSmallBox &&
            <i className="material-icons viewFileIcon">zoom_in</i>
          }
        </a>
        <a data-tip data-for={fileDocumentId}>
          {!isSmallBox &&
            <i className="material-icons">zoom_in</i>
          }
        </a>
        <ReactTooltip id={fileDocumentId} place="left" class="tooltip">
          <div className="preview">
            <img src={fileURL} alt="" />
          </div>
        </ReactTooltip>
      </span>
    );
  }

  return (<a href={fileURL} target="blank"><i className="material-icons">zoom_in</i></a>);
};

export function checkIfValidFileName(file, callBack) {
  const resultFile = file;
  let accepted = !fileNameRegex.test(file.name);
  accepted = accepted && file.name.length <= maxFileNameLength;
  accepted = accepted && file.size <= maxFileSize;
  accepted = accepted && file.type !== '' && acceptedFileMimes.indexOf(file.type) > -1;
  resultFile.valid = resultFile.valid && accepted;
  callBack(resultFile);
}

export function checkIfValidFileType(file, callBack) {
  const fileReader = new FileReader();
  const resultFile = file;
  fileReader.addEventListener('load', (loadEvent) => {
    const buffer = loadEvent.target.result;
    const fileType = getFileType(buffer);
    let accepted = fileType && acceptedFileMimes.indexOf(fileType.mime) > -1;
    accepted = accepted && fileType && acceptedFileExtensions.indexOf(fileType.ext) > -1;
    resultFile.valid = accepted;
    callBack(resultFile);
  });
  fileReader.readAsArrayBuffer(file);
}

export function setErrors(rejectedFiles, isProofOfIdentity) {
  let errorsArray = [];
  rejectedFiles.forEach((file) => {
    if (isProofOfIdentity && file.size > maxFileSizeIdentity) {
      errorsArray = errorsArray.concat('File size exceeds the maximum of 5Mb');
    } else if (file.size > maxFileSize) {
      errorsArray = errorsArray.concat('File size exceeds the maximum of 10Mb');
    }
    if (file.type === '' || acceptedFileMimes.indexOf(file.type) < 0) {
      const errorMessage = isProofOfIdentity ?
      'Not a valid file type' : 'Not a valid file type (images/pdf/msword only)';
      errorsArray = errorsArray.concat(errorMessage);
    }
    if (file.name.length > maxFileNameLength) {
      errorsArray = errorsArray.concat('File name is too long');
    }
    if (fileNameRegex.test(file.name)) {
      errorsArray = errorsArray.concat('File name may not include spaces or special characters');
    }
  });
  return errorsArray;
}
