import React from 'react';
import EntityProgressBar from '../EntityProgressBar';

const FinishedPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <EntityProgressBar />
      <div className="container">
        <div className="row">
          <p> Finished Page </p>
        </div>
      </div>
    </section>
  );
};

export default FinishedPage;
