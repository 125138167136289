import { Cookies } from 'react-cookie';
import Config from '../Config';

const config = new Config();

export const setRemindDate = () => {
  const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
};

export const getCookie = (name) => {
  const cookies = new Cookies();
  return cookies.get(name) || null;
};

export const deleteCookie = (name) => {
  const cookies = new Cookies();
  cookies.remove(name);
};

export const setCookie = (name, value, expireDays, sameSite) => {
  if (value) {
    const expirationDays = expireDays || config.promoCodeCookieExpirationDays;
    const cookies = new Cookies();
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + expirationDays);
    if (sameSite) {
      const cookieValue = JSON.stringify(value);
      document.cookie = `${name}=${cookieValue}; expires=${expireDate}; sameSite=${sameSite}; domain=tradestation.com;`;
    } else {
      cookies.set(name, value, { expires: expireDate });
    }
  }
};

export const deleteCookieDomain = (name, domain) => {
  document.cookie = `${name}=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=${domain};`;
};
