import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import IdentityHeader from './IdentityHeader';
import IdentityFormSection from './IdentityFormSection';

const IdentityForm = (props) => {
  const { isJointApplication } = props;

  const imgStyle = {
    marginTop: '70px',
  };

  return (
    <div className="row">
      <div className="col col-md-6 col-xs-12">
        <div className="row">
          <FormSection name="identities[0]">
            <IdentityHeader sectionIndex={0} />
            <IdentityFormSection {...props} sectionIndex={0} />
          </FormSection>
        </div>
      </div>
      <div className="col col-md-6 col-xs-12">
        <div className="row">
          <FormSection name="identities[1]">
            <div>
              {isJointApplication &&
                <div>
                  <IdentityHeader sectionIndex={1} />
                  <IdentityFormSection {...props} sectionIndex={1} />
                </div>
              }
              {!isJointApplication &&
                <div className="col col-md-8 col-md-offset-2 hidden-xs hidden-sm text-center">
                  <img
                    src="images/verify-identity-individual.png"
                    style={imgStyle}
                    alt="Identity"
                    className="centered hidden-sm hidden-xs"
                  />
                </div>
              }
            </div>
          </FormSection>
        </div>
      </div>
    </div>
  );
};

IdentityForm.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
};

export default IdentityForm;
