import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import * as RouteNavigator from './RouteNavigator';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as registrationActions from '../actions/registrationActions';
import * as applicationsActions from '../actions/applicationActions';
import optimizeHelper from '../lib/optimizeHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';
import EntityApplication from '../components/EntityApplication/EntityApplication';

export class EntityApplicationContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExistingAccountModal: false,
      showExitModal: false,
      isDownloaded: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      registration,
      accountType,
      assetClass,
    } = this.props;

    window.scrollTo(0, 0);

    if (!registration || !accountType || !assetClass) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(aopAnalyticsSteps.APPLICATION.name, {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
        });
      });
    } else {
      pushToAnalytics(aopAnalyticsSteps.PERSONAL_INFO.name, {
        applicationId,
        authToken,
        registration,
        accountType,
        assetClass,
      });
    }

    optimizeHelper.notify();
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  handleOnClick() {
    this.setState({ isDownloaded: true });
  }

  handleSubmit() {
    const { authToken, applicationId } = this.props;
    this.props.actions.submitEntityApplication({}, applicationId, authToken)
      .then(() => {
        RouteNavigator.push('/finish-business-application');
      })
      .catch(this.handleError);
  }

  // eslint-disable-next-line class-methods-use-this
  handleBack() {
    RouteNavigator.push('/account-type');
  }

  render() {
    return (
      <EntityApplication
        {...this.props}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showExistingAccountModal={this.state.showExistingAccountModal}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
        handleOnClick={this.handleOnClick}
        isDownloaded={this.state.isDownloaded}
      />
    );
  }
}

EntityApplicationContainer.propTypes = {};

export function mapStateToProps(state) {
  if (!state) return null;
  return {
    application: state.application,
    applicationId: state.applicationId,
    authToken: state.authToken,
    userId: state.userId,
    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
    tradingProfile: state.tradingProfile,
    financial: state.financial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      accountTypeActions,
      registrationActions,
      assetClassActions,
      applicationsActions,
    ), dispatch),
  };
}

export function scrollToFirstError(errors) {
  let errs = [];
  if (errors && errors.identities) {
    errors.identities.forEach((e) => {
      errs = Object.keys(e);
    });
  }

  if (errs && errs.length > 5) {
    const el = document.querySelector(`[name="identities[0].${errs[0]}"]`);
    if (el) {
      const top = (el.getBoundingClientRect().top + document.documentElement.scrollTop) - 100;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }
}

EntityApplicationContainer.propTypes = {
  actions: PropTypes.shape({
    fetchApplication: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    submitEntityApplication: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  registration: PropTypes.shape(),
  accountType: PropTypes.shape(),
  assetClass: PropTypes.shape(),
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'entityApplicationForm',
})(EntityApplicationContainer));

