import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_TYPE_SUCCESS:
      return action.accountType;
    case actionTypes.SUBMIT_ACCOUNT_TYPE_SUCCESS:
      return action.accountType;
    default:
      return state;
  }
}
