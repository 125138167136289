import DocumentsSettings from '../components/Documents/DocumentsSettings';
import * as IdentificationTypes from '../components/PersonalInfo/Identity/identificationTypes';

const PROOF_OF_IDENTITY = DocumentsSettings.PROOF_OF_IDENTITY;
const PROOF_OF_ADDRESS = DocumentsSettings.PROOF_OF_ADDRESS;
const EMPLOYER_AUTHORIZATION = DocumentsSettings.EMPLOYER_AUTHORIZATION;
const SIMPLE_IRA = DocumentsSettings.SIMPLE_IRA;
const SEP_IRA = DocumentsSettings.SEP_IRA;
const DEATH_CERTIFICATE = DocumentsSettings.DEATH_CERTIFICATE;
const PASSPORT = IdentificationTypes.MITEK_PASSPORT;
const DL_FRONT = IdentificationTypes.MITEK_DRIVERS_LICENSE;
const DL_FRONT_ERROR = IdentificationTypes.ID_FRONT_ERROR;
const DL_BACK_ERROR = IdentificationTypes.ID_BACK_ERROR;
const ID_FRONT = IdentificationTypes.ID_FRONT;
const ID_BACK = IdentificationTypes.ID_BACK;
const ID_PASSPORT = IdentificationTypes.ID_PASSPORT;
const acceptedFileMimes = `image/*,image/tiff,image/jpg,image/jpeg,image/gif,
      image/png,image/bmp,application/pdf,application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
const maxFileNameLength = 48;
const maxIdCardFiles = 2;
const fileNameRegex = /[^A-Za-z0-9_.\-@]/i;

const haveGoodFilename = (files) => {
  const haveGood = [];

  files.forEach((f, i) => {
    haveGood[i] = true;
    haveGood[i] = haveGood[i] && (f.type !== '' && acceptedFileMimes.indexOf(f.type) > -1);
    haveGood[i] = haveGood[i] && (f.name && f.name.length <= maxFileNameLength);
    haveGood[i] = haveGood[i] && (!fileNameRegex.test(f.name));
  });

  return haveGood.some(e => e === true);
};

const validate = (values) => {
  const errors = {};

  if (values.docs && values.docs.length > 0) {
    const docsArrayErrors = [];

    values.docs.forEach((d, sectionIndex) => {
      const docsErrors = {};

      const notRequiredForApprovalDocs = d.notRequiredForApprovalDocuments ? d.notRequiredForApprovalDocuments : [];
      let requiredDocs = d.documentsRequired ? d.documentsRequired : [];
      requiredDocs = requiredDocs.filter(doc => !notRequiredForApprovalDocs.includes(doc));
      const originalDocs = d.originalDocuments ? d.originalDocuments.map(c => c.documentType) : [];
      if (requiredDocs.includes(PROOF_OF_IDENTITY)) {
        if (!d.proofOfIdentity || d.proofOfIdentity.length === 0) {
          if (!originalDocs.includes(PROOF_OF_IDENTITY)) {
            if (d.identificationType === DL_FRONT || !d.identificationType) {
              docsErrors.proofOfIdentity = 'Image is required';
              docsArrayErrors[sectionIndex] = docsErrors;
            }
            if (d.identificationType === PASSPORT) {
              docsErrors.proofOfIdentity = 'At least one image is required for this ID type';
              docsArrayErrors[sectionIndex] = docsErrors;
            }
          } else {
            const totalDocs = originalDocs.filter(v => (v === PROOF_OF_IDENTITY)).length;
            const originalMitekDocs = values.docs[sectionIndex].originalDocuments.filter(
              v => (v.documentType === PROOF_OF_IDENTITY));
            if (totalDocs < maxIdCardFiles && d.identificationType === DL_FRONT) {
              const idFrontFiles = originalMitekDocs.filter(file => file.documentSubType === ID_FRONT);
              const idBackFiles = originalMitekDocs.filter(file => file.documentSubType === ID_BACK);
              if (idFrontFiles.length === 0) {
                docsErrors.proofOfIdentity = DL_FRONT_ERROR;
              } else if (idBackFiles.length === 0) {
                docsErrors.proofOfIdentity = DL_BACK_ERROR;
              }
              docsArrayErrors[sectionIndex] = docsErrors;
            }
          }
        } else {
          if (!haveGoodFilename(d.proofOfIdentity)) {
            if (!originalDocs.includes(PROOF_OF_IDENTITY)) {
              docsErrors.proofOfIdentity = 'Document is required';
              docsArrayErrors[sectionIndex] = docsErrors;
            }
          }
          if (d.identificationType === DL_FRONT) {
            const idFrontFiles = d.proofOfIdentity.filter(file => file.idSubtype === ID_FRONT);
            const idBackFiles = d.proofOfIdentity.filter(file => file.idSubtype === ID_BACK);
            const idFrontOriginalDocuments = d.originalDocuments.filter(file => file.documentSubType === ID_FRONT);
            const idBackOriginalDocuments = d.originalDocuments.filter(file => file.documentSubType === ID_BACK);
            const totalFront = idFrontOriginalDocuments.length + idFrontFiles.length;
            const totalBack = idBackOriginalDocuments.length + idBackFiles.length;

            if (d.proofOfIdentity
                && d.proofOfIdentity.length < maxIdCardFiles
                && !originalDocs.includes(PROOF_OF_IDENTITY)) {
              if (idFrontFiles.length === 0 && idBackFiles.length === 1) {
                docsErrors.proofOfIdentity = DL_FRONT_ERROR;
              } else if (idBackFiles.length === 0 && idFrontFiles.length === 1) {
                docsErrors.proofOfIdentity = DL_BACK_ERROR;
              } else if (idFrontFiles.length === 0 && idBackFiles.length === 0) {
                docsErrors.proofOfIdentity = 'Image is required';
              }
              docsArrayErrors[sectionIndex] = docsErrors;
            } else {
              if (idFrontFiles.length < 1 && idFrontOriginalDocuments.length < 1) {
                docsErrors.proofOfIdentity = DL_FRONT_ERROR;
              }
              if (idBackFiles.length < 0 && idBackOriginalDocuments.length < 1) {
                docsErrors.proofOfIdentity = DL_BACK_ERROR;
              }
              if (totalFront > 1) {
                docsErrors.proofOfIdentity = 'Only 1 front image is allowed';
              } else if (totalBack > 1) {
                docsErrors.proofOfIdentity = 'Only 1 back image is allowed';
              }
              docsArrayErrors[sectionIndex] = docsErrors;
            }
          } else if (d.identificationType === PASSPORT) {
            const passportFiles = d.proofOfIdentity.filter(file => file.idSubtype === ID_PASSPORT);
            const passportOriginalDocuments = d.originalDocuments.filter(
              file => file.documentSubType === ID_PASSPORT);
            const totalPassport = passportOriginalDocuments.length + passportFiles.length;
            if (totalPassport > maxIdCardFiles) {
              docsErrors.proofOfIdentity = 'Only 2 passport images are allowed';
              docsArrayErrors[sectionIndex] = docsErrors;
            }
          }
        }
      }

      if (requiredDocs.includes(PROOF_OF_ADDRESS)) {
        if (!d.proofOfAddress || d.proofOfAddress.length === 0) {
          if (!originalDocs.includes(PROOF_OF_ADDRESS)) {
            docsErrors.proofOfAddress = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        } else if (!haveGoodFilename(d.proofOfAddress)) {
          if (!originalDocs.includes(PROOF_OF_ADDRESS)) {
            docsErrors.proofOfAddress = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        }
      }

      if (requiredDocs.includes(EMPLOYER_AUTHORIZATION)) {
        if (!d.employerAuthorization || d.employerAuthorization.length === 0) {
          if (!originalDocs.includes(EMPLOYER_AUTHORIZATION)) {
            docsErrors.employerAuthorization = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        } else if (!haveGoodFilename(d.employerAuthorization)) {
          if (!originalDocs.includes(EMPLOYER_AUTHORIZATION)) {
            docsErrors.employerAuthorization = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        }
      }

      if (requiredDocs.includes(SIMPLE_IRA)) {
        if (!d.simpleIRA || d.simpleIRA.length === 0) {
          if (!originalDocs.includes(SIMPLE_IRA)) {
            docsErrors.simpleIRA = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        } else if (!haveGoodFilename(d.simpleIRA)) {
          if (!originalDocs.includes(SIMPLE_IRA)) {
            docsErrors.simpleIRA = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        }
      }

      if (requiredDocs.includes(SEP_IRA)) {
        if (!d.sepIRA || d.sepIRA.length === 0) {
          if (!originalDocs.includes(SEP_IRA)) {
            docsErrors.sepIRA = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        } else if (!haveGoodFilename(d.sepIRA)) {
          if (!originalDocs.includes(SEP_IRA)) {
            docsErrors.sepIRA = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        }
      }

      if (requiredDocs.includes(DEATH_CERTIFICATE)) {
        if (!d.deathCertificate || d.deathCertificate.length === 0) {
          if (!originalDocs.includes(DEATH_CERTIFICATE)) {
            docsErrors.deathCertificate = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        } else if (!haveGoodFilename(d.deathCertificate)) {
          if (!originalDocs.includes(DEATH_CERTIFICATE)) {
            docsErrors.deathCertificate = 'Document is required';
            docsArrayErrors[sectionIndex] = docsErrors;
          }
        }
      }
    });

    if (docsArrayErrors.length > 0) {
      errors.docs = docsArrayErrors;
    }
  }
  return errors;
};

export default validate;
