import React from 'react';
import PropTypes from 'prop-types';
import SummaryRow from './SummaryRow';
import FuturesIraDisclaimer from '../FuturesIraDisclaimer';

const SummarySection = (props) => {
  const {
    groupIndex,
    sectionIndex,
    title,
    summaryInfo,
    editLink,
    authToken,
    application,
    entity,
    errorMessage,
    editDisabled,
    iraHasFutures,
    iraHasOnlyFutures,
  } = props;

  let headerIndex = 0;
  const editClasses = `btn btn-xs btn-info slimv_element ${(editDisabled ? 'disabled' : '')}`;
  const detailRows = summaryInfo.map((rowInfo, rowIndex) => {
    if (rowInfo.keyType === 'IDX') {
      headerIndex += 1;
    }
    return (
      <SummaryRow
        key={`summaryInfo_${title}_${rowIndex}_${rowInfo.key}`}
        rowInfo={rowInfo}
        index={rowIndex}
        headerIndex={headerIndex}
        parentTitle={title}
        authToken={authToken}
      />
    );
  });

  const ent = entity === 'personal-info' ? 'address' : entity;

  const applicationStatus = application && application.status ? application.status : '';
  const isReturned = applicationStatus === 'returned' &&
    application.unlockedResources &&
    application.unlockedResources.indexOf(ent) > -1;
  const canEdit = (applicationStatus === 'in-progress' || isReturned)
    && ent !== 'registration' && ent !== 'securityCredentials' && !iraHasOnlyFutures;
  const accordionStyle = (errorMessage && errorMessage.length > 0) ? '' : 'collapsed';
  const accordionAriaExpanded = (errorMessage && errorMessage.length > 0) ? 'false' : 'true';
  const accordionDivStyle = (errorMessage && errorMessage.length > 0)
    ? 'panel-collapse collapse panel-summary in'
    : 'panel-collapse collapse panel-summary';

  return (
    <div className="panel">
      <div className="panel-heading" >
        <h3 className="panel-title">
          <a
            className={accordionStyle}
            role="button"
            data-toggle="collapse"
            data-parent={`#accordion-${groupIndex}`}
            href={`#collapse-${groupIndex}-${sectionIndex}`}
            aria-expanded={accordionAriaExpanded}
            aria-controls={`collapse-${groupIndex}-${sectionIndex}`}
          >
            <i className={`material-icons icon-success ${isReturned ? 'icon-danger' : ''}`}>
              {isReturned ? 'error' : 'check_circle'}
            </i>{title}
            <span className="pull-right clickable" />
          </a>
        </h3>
      </div>
      <div
        id={`collapse-${groupIndex}-${sectionIndex}`}
        className={accordionDivStyle}
        role="tabpanel"
        aria-labelledby="headingOne"
      >
        <div className="panel-body">
          <div className="row">
            {detailRows}
          </div>
          <div className="row summary-row">
            {iraHasFutures && <FuturesIraDisclaimer page="summary" iraHasOnlyFutures={iraHasOnlyFutures} />}
            <div className="col-xs-12 summary-edit-wrapper">
              <div className="col-xs-6 text-left">
                {errorMessage && errorMessage.length > 0 &&
                  <div className="error-text">
                    {errorMessage}
                  </div>
                }
              </div>
              {canEdit &&
                <div className="col-xs-6 slimv_element text-right">
                  <a className={editClasses} href={`${editLink}?redirectTo=/summary`}>
                    Edit
                    <i className="material-icons">mode_edit</i>
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SummarySection.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  summaryInfo: PropTypes.arrayOf(PropTypes.shape({
    keyType: PropTypes.string.isRequired,
    valueType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
  editLink: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  application: PropTypes.shape(),
  entity: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  editDisabled: PropTypes.bool,
  iraHasFutures: PropTypes.bool,
  iraHasOnlyFutures: PropTypes.bool,
};

export default SummarySection;
