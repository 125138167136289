import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import ExistingAccountModal from '../ExistingAccountModal';
import ExitModal from '../ExitModal';
import IdentityForm from './Identity/IdentityForm';
import AddressForm from './Address/AddressForm';
import DuplicateRemediationModal from '../DuplicateRemediationModal';

import Config from '../../Config';

const config = new Config();

const PersonalInfoForm = (props) => {
  const {
    showExistingAccountModal,
    handleSubmit,
    onSubmit,
    onBack,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
    error,
  } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showExistingAccountModal && (
        <div>
          {config.duplicateRemediationEnabled &&
            <DuplicateRemediationModal page="personal-info" />
          }
          {!config.duplicateRemediationEnabled &&
            <ExistingAccountModal
              message="An account with this personal information already exists. Please login to continue."
            />
          }
        </div>
      )}
      {showExitModal && (
        <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
      )}
      <div className="side-padding">
        <IdentityForm {...props} />
        <AddressForm {...props} />
        {error && <ErrorMessage error={error} />}
        <Navigation onBack={onBack} onExitModalOn={onExitModalOn} />
      </div>
    </form>
  );
};

PersonalInfoForm.propTypes = {
  showExistingAccountModal: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  error: PropTypes.string,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default PersonalInfoForm;
