
function getBrowserOrigin() {
  let origin = window.location.origin;
  if (!window.location.origin) {
    origin = window.location.protocol.concat(
      '//', window.location.hostname, (window.location.port ? ':'.concat(window.location.port) : ''));
  }
  return origin;
}

module.exports = {
  getBrowserOrigin,
};
