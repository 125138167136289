import * as assetTypes from '../../../components/AssetClass/assetTypes';
import * as summaryRowTypes from '../../../components/Summary/summaryRowTypes';
import * as summaryValueMapper from '../../../containers/Summary/summaryValueMapper';
import * as assetClassTexts from '../../../components/AssetClass/assetClassTexts';

export function removeAndPlaceRow(rowList, key, elementToPush, placeAfterKey, removeKey) {
  const newRowList = rowList.reduce(
    (accumulator, element) => {
      if (element[key] === placeAfterKey) {
        accumulator.push(element);
        accumulator.push(elementToPush);
        return accumulator;
      } else if (element[key] === removeKey) {
        return accumulator;
      }
      accumulator.push(element);
      return accumulator;
    },
    [],
  );
  return newRowList;
}

export function removeRow(rowList, key, removeKey) {
  const newRowList = rowList.reduce(
    (accumulator, element) => {
      if (element[key] === removeKey) return accumulator;
      accumulator.push(element);
      return accumulator;
    },
    [],
  );
  return newRowList;
}

export const shouldShowTsgo = (state) => {
  let showTsgo = false;
  if (state.contact && state.employment && state.assetClass && state.registration) {
    showTsgo =
      (state.assetClass.commissionSchedule === assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSGO ||
        (state.assetClass.commissionSchedule === assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT &&
          (!state.registration.isExistingCustomer ||
            state.employment.reduce((acc, ele) => !ele.isPro, false)))) &&
      state.contact.reduce((acc, ele) => ele.countryOfResidence === 'US', false);
  }
  return showTsgo;
};

export const getAssetClass = (assetClasses, assetClassCode) => {
  return summaryValueMapper.getTextFromValue(assetClasses, assetClassCode);
};

export const getAccountType = (accountTypes, accountTypeCode) => {
  return summaryValueMapper.getTextFromValue(accountTypes, accountTypeCode);
};

export const joinArray = (array, texts) => {
  if (array) {
    return array.map((value) => { return summaryValueMapper.getTextFromValue(texts, value); }).join('||');
  }
  return array;
};

export const applyTsgoChanges = (state, assetInformation) => {
  let newAssetInformation = JSON.parse(JSON.stringify(assetInformation));
  if (shouldShowTsgo(state) || state.contact.countryOfResidence !== 'US') {
    const newCommissionRow = {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'COMMISSION PLAN',
    };
    if (shouldShowTsgo(state)) {
      newCommissionRow.value = state.assetClass ?
      getAssetClass(assetClassTexts.assetClassTexts, state.assetClass.commissionSchedule) : '';
    } else {
      newCommissionRow.value = 'International';
    }
    newAssetInformation = removeAndPlaceRow(
      newAssetInformation,
      'key',
      newCommissionRow,
      'NUMBER OF ACCOUNTS',
      'EQUITY COMMISSION SCHEDULE',
    );
  } else {
    newAssetInformation = removeRow(newAssetInformation, 'key', 'EQUITY COMMISSION SCHEDULE');
  }
  return newAssetInformation;
};
