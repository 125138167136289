import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import * as RouteNavigator from '../containers/RouteNavigator';

class ExitModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: true,
    };

    this.handleHide = this.handleHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleHide() {
    this.props.onExitModalOff();
  }

  handleSave() {
    this.props.onExitModalOn();
    RouteNavigator.push('/pending-applications');
  }

  render() {
    return (
      <Modal className="duplicateModal" show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton>
          <img
            className="img-responsive duplicateModalIcon"
            src="images/icon-question-mark.png"
            alt="Question Icon"
          />
          <h2 className="text-center duplicateModalHeader">Are you sure you want to exit?</h2>
        </Modal.Header>
        <Modal.Body className="duplicateModalBody">
          <p className="text-center duplicateModalTextPersonalInfo">
            You&apos;re account will be saved automatically.<br />
            You may resume later by clicking the &quot;Open Account&quot; button on Tradestation.com and signing in.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <div className="col col-md-6 col-lg-6 col-sm-12 text-center">
              <button
                className="btn btn-raised btn-default do_capitalize intro-btn intro duplicateModalButton"
                onClick={this.handleHide}
              >
                No
              </button>
            </div>
            <div className="col col-md-6 col-lg-6 col-sm-12 text-center">
              <button
                className="btn btn-raised btn-white do_capitalize intro-btn intro duplicateModalButton"
                onClick={this.handleSave}
              >
                Yes, Save for Later
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ExitModal.propTypes = {
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default ExitModal;
