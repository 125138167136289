import React from 'react';
import PropTypes from 'prop-types';
import TextBox from '../TextBox';
import normalizeNumber from '../IraAccount/normalizeNumber';

const PhoneVerification = (props) => {
  const {
    onSubmit,
    setCode,
    error,
    primaryContactPhone,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <div id="phoneVerificationDiv">
        <h1 id="page-header" className="h1_secondary text-center">
          Account Setup
        </h1>
        <div className="panel-body">
          <h1 className="h2_subHeading" id="phoneVerificationHeader">Please verify your phone number</h1>
          <p id="phoneVerificationText">
            We sent a verification code to <b>+{primaryContactPhone}</b>
          </p>
          <div id="phoneVerificationInputDiv">
            <TextBox
              id="verificationCode"
              name="verificationCode"
              className="form-control"
              label="Verification Code"
              normalize={normalizeNumber}
              onChange={e => setCode(e.target.value)}
              maxLength={6}
            />
            { error && <p className="help-block error" id="phoneVerificationError">{error}</p> }
            <button
              className="btn btn-img btn-img-auto form-group col-xs-12"
              id="phoneVerificationButton"
              type="button"
            >
              <img src="images/ico_resend.svg" id="resendIcon" alt="Resend/Undo icon" />
              <span id="resendCodeBtnText"> Resend Code </span>
            </button>
            <button
              className="btn btn-img btn-img-auto form-group col-xs-12"
              id="phoneVerificationButton"
              type="button"
            >
              <img src="images/ico_voice.svg" id="voiceIcon" alt="Audio wave icon" />
              <span id="verifyVoiceBtnText"> Verify via voice </span>
            </button>
          </div>
        </div>
        <div className="form-navigation" id="phoneVerificationNavigationDiv">
          <div className="navigation-container">
            <div className="col col-xs-12 border-top navigation-btn-container" id="navigationBorder">
              <div id="phoneVerificationNavigationBtnDiv">
                <button
                  type="button"
                  id="changePhoneButton"
                  className="btn btn-raised btn-white do_capitalize"
                >
                  Change Phone
                </button>
                <button
                  type="submit"
                  id="verifyCodeButton"
                  className="btn btn-raised btn-default do_capitalize"
                >
                  &nbsp;&nbsp; Verify Code &nbsp;&nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    );
  };

PhoneVerification.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setCode: PropTypes.func.isRequired,
    primaryContactPhone: PropTypes.string,
    error: PropTypes.string,
};

export default PhoneVerification;
