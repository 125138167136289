export function getCountryName(countries, countryCode) {
  if (!countries || countries.length === 0) {
    return countryCode;
  }

  const country = countries.filter(c => c.countryCode === countryCode);
  if (country.length === 0) {
    return countryCode;
  }

  return country.slice(0, 1)
    .map((c) => { return c.country; })
    .pop();
}

export function getRegionName(countries, countryCode, regionCode) {
  if (!countries || countries.length === 0) {
    return regionCode;
  }

  const country = countries.filter(c => c.countryCode === countryCode);
  if (country.length === 0) {
    return regionCode;
  }

  const region = country.slice(0, 1)[0].regions.filter(r => r.regionCode === regionCode);
  if (region.length === 0) {
    return regionCode;
  }

  return region.map((r) => { return r.region; }).pop();
}

export function getHearAboutUsName(options, code) {
  if (!options || options.length === 0) {
    return code;
  }

  return options
    .filter(o => o.key === code)
    .slice(0, 1)
    .map((o) => { return o.title; })
    .pop();
}

export function getTextFromValue(options, code) {
  if (!options || options.length === 0) {
    return code;
  }

  return options
    .filter(o => o.value === code)
    .slice(0, 1)
    .map((o) => { return o.text; })
    .pop();
}
