import validateAddress from '../lib/containerHelpers/address/validateAddress';
import validateIdentity from '../lib/containerHelpers/identity/validateIdentity';

export const validate = (values) => {
  const errors = {};

  const identityErrors = validateIdentity(values);
  if (identityErrors.length > 0) {
    errors.identities = identityErrors;
  }

  const addressErrors = validateAddress(values);
  if (addressErrors.length > 0) {
    errors.addresses = addressErrors;
  }

  return errors;
};

export default validate;
