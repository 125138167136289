import React from 'react';
import LogOffContainer from '../../containers/LogOffContainer';

const LogOffPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <div className="container">
        <div className="row">
          <div id="logoff" >
            <LogOffContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogOffPage;
