import * as actionTypes from '../actions/actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_HEARABOUTUS_SUCCESS:
      return [...action.hearAboutUs];
    default:
      return state;
  }
}
