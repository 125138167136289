import React from 'react';
import IdVerifyContainer from '../../containers/IdVerifyContainer';
import ProgressBar2 from '../ProgressBar2';

const IdVerifyPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <IdVerifyContainer />
        </div>
      </div>
    </section>
  );
};

export default IdVerifyPage;
