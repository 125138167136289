import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Config from '../../Config';
import DocumentsSettings from './DocumentsSettings';
import { mitekValidationErrors } from './mitekErrorTexts';
import * as identificationTypes from '../../components/PersonalInfo/Identity/identificationTypes';
import {
  isCounterError,
  mitekValidate,
  removeNewFile,
  viewFile,
  checkIfValidFileName,
  checkIfValidFileType,
  setErrors,
  acceptedFileMimes,
  maxFileSize,
  maxFileSizeIdentity,
} from './DocumentUploadControlHelper';

const config = new Config();
const ID_PASSPORT = identificationTypes.ID_PASSPORT;

export class DocumentsUploadControl extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      documentFiles: [],
      errors: [],
      mitekErrorCounter: [0, 0],
    };

    this.setDocuments = this.setDocuments.bind(this);
    this.updateErrorCounter = this.updateErrorCounter.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
  }

  setDocuments(documents) {
    this.setState({ documentFiles: documents });
  }

  setErrors(rejectedFiles, isProofOfIdentity) {
    const errorsArray = setErrors(rejectedFiles, isProofOfIdentity);
    this.props.handleErrors(this.props.input.name, errorsArray);
  }

  cleanErrors() {
    const errorsArray = [];
    this.props.handleErrors(this.props.input.name, errorsArray);
  }

  updateErrorCounter(index) {
    const newCounter = this.state.mitekErrorCounter;
    newCounter[index] += 1;
    this.setState({ mitekErrorCounter: newCounter });
  }

  resetCounter(index) {
    const newCounter = this.state.mitekErrorCounter;
    newCounter[index] = 0;
    this.setState({ mitekErrorCounter: newCounter });
  }

  showDocumentRequire(error) {
    this.cleanErrors();
    return (<span className="help-block error errorTextFont">{error}</span>);
  }

  handleSubmit(filesToUpload, uploadedFiles, idSubtype) {
    const {
      handleFileSubmit,
      sectionIndex,
    } = this.props;

    this.cleanErrors();
    const files = filesToUpload.filter(file => file.type && file.valid);
    this.setDocuments(uploadedFiles.concat(files));

    if (files.length > 0) {
      handleFileSubmit(sectionIndex, this.props.input.name, files, idSubtype, (err, results) => {
        if (err) {
          const docItems = uploadedFiles.filter(doc => !filesToUpload.includes(doc));
          this.setDocuments(docItems);
          this.props.input.onChange([]);
        } else {
          const resultsUpload = uploadedFiles;
          files.forEach((file) => {
            const item = results.filter(x => x.key === file.preview);
            const newItem = file;
            if (item && item.length > 0) {
              newItem.preview = item[0].newPath;
              newItem.documentId = item[0].id;
              newItem.idSubtype = idSubtype;
              resultsUpload.push(newItem);
            }
          });

          this.setDocuments(resultsUpload);
        }
      });
    }
  }

  render() {
    const {
      authToken,
      name,
      maxFiles,
      handleFileRemove,
      meta: { error, touched },
      maxFileRestriction,
      identificationType,
      sectionIndex,
      idSubtype,
    } = this.props;
    const files = this.state.documentFiles;
    const isProofOfIdentity = this.props.input.name ?
      this.props.input.name.includes(DocumentsSettings.PROOF_OF_IDENTITY)
      : null;

    return (
      <div name={name}>
        {idSubtype === ID_PASSPORT && <p className="idCardLabel hideLabel"> Passport </p>}
        <Dropzone
          className="dropzone dz-clickable"
          multiple
          name={name}
          onDrop={(filesToUpload) => {
            this.props.handleErrors(this.props.input.name, [null]); // clear error
            if (filesToUpload.length <= maxFiles && filesToUpload.length > 0) {
              const fileRequests = filesToUpload.map(file =>
                new Promise(resolve => checkIfValidFileType(file, resolve)).then(
                  fileType => new Promise(resolve => checkIfValidFileName(fileType, resolve)),
                ),
              );
              Promise.all(fileRequests).then((resultFiles) => {
                const validFiles = resultFiles.filter(f => f.valid);
                const idType =
                  identificationType && identificationType.length > 0
                    ? identificationType[sectionIndex]
                    : null;
                if (validFiles && validFiles.length > 0) {
                  mitekValidate(validFiles, isProofOfIdentity, idType)
                    .then((mitekError) => {
                      const errorLimitReached = this.state.mitekErrorCounter[sectionIndex] >= config.mitekErrorLimit;
                      if (mitekError && !errorLimitReached) {
                        if (isCounterError(mitekError)) this.updateErrorCounter(sectionIndex);
                        setTimeout(() => {
                          this.props.handleErrors(this.props.input.name, [mitekValidationErrors[mitekError]]);
                        }, 250);
                      } else {
                        if (validFiles && idSubtype === ID_PASSPORT) validFiles[0].idSubtype = idSubtype;
                        this.props.input.onChange(filesToUpload.concat(files));
                        this.resetCounter(sectionIndex);
                        this.handleSubmit(validFiles, files, idSubtype);
                      }
                    });
                } else {
                  this.setErrors(resultFiles, isProofOfIdentity);
                }
              });
            } else if (maxFiles === 0) {
              let errorsArray = [`You can only upload up to ${maxFileRestriction} documents in each section.`];
              if (isProofOfIdentity) {
                errorsArray = ['Limit of 2 images has been reached.'];
              }
              this.props.handleErrors(this.props.input.name, errorsArray);
            }
          }}
          onDropRejected={(rejectedFiles) => {
            this.setErrors(rejectedFiles, isProofOfIdentity);
          }}
          accept={acceptedFileMimes}
          maxSize={isProofOfIdentity ? maxFileSizeIdentity : maxFileSize}
        >
          <div name={name} className="newDocUploadButtonFont newDocUploadDropZone">
            Click, tap or drop files here to upload
          </div>
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0 && (
          <table className="table table-bordered table-layout">
            <tbody>
              {files.map((file, i) =>
                (<tr key={i}>
                  <td className="cell-documentUploadFile">{file.name}</td>
                  <td className="cell-documentUploadControl">{
                    viewFile(file.type, file.preview, file.documentId, authToken, false)}</td>
                  <td className="cell-documentUploadControl">{
                    removeNewFile(this.props.input.value, file.documentId, file.documentId,
                      this.props.input.onChange, handleFileRemove, this.setDocuments,
                      !file.documentId, this.props.input.name)}</td>
                </tr>),
              )}
            </tbody>
          </table>
        )}
        {touched && error &&
          <span className="help-block error">{error}</span>
        }
      </div>
    );
  }
}

DocumentsUploadControl.propTypes = {
  name: PropTypes.string,
  sectionIndex: PropTypes.number,
  maxFiles: PropTypes.number,
  input: PropTypes.shape(),
  handleFileRemove: PropTypes.func.isRequired,
  handleFileSubmit: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  authToken: PropTypes.string,
  handleErrors: PropTypes.func.isRequired,
  maxFileRestriction: PropTypes.number,
  identificationType: PropTypes.arrayOf(PropTypes.string),
  idSubtype: PropTypes.string,
};

export default DocumentsUploadControl;
