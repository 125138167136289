const hasAccountsOrFake = (application) => {
  let hasCarmaAccounts = true;
  let hasFakeOrNoMoreBusiness = false;
  let hasBypassedCarmaAccounts = false;

  const accountsCreated = application && application.accountsCreated ? application.accountsCreated : null;
  if (accountsCreated && accountsCreated.length > 0) {
    const failedCarmaAccounts = accountsCreated.some(e => ['FAILED_FUNDING'].includes(e));
    hasBypassedCarmaAccounts = accountsCreated.some(e => ['BYPASS_FUNDING'].includes(e));
    hasFakeOrNoMoreBusiness = accountsCreated.some(e => ['FAKE', 'NMB'].includes(e));
    hasCarmaAccounts = !failedCarmaAccounts && !hasFakeOrNoMoreBusiness && !hasBypassedCarmaAccounts;
  } else {
    hasCarmaAccounts = false;
  }

  return { hasCarmaAccounts, hasFakeOrNoMoreBusiness, hasBypassedCarmaAccounts };
};

export default hasAccountsOrFake;
