import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
    PW_LENGTH,
    PW_REQUIRES_NO_SPACES,
    PW_REQUIRES_UPPER,
    PW_REQUIRES_LOWER,
    PW_REQUIRES_DIGIT,
    PW_REQUIRES_SYMBOL,
    PW_CONTAINS_UNSUPPORTED_SYMBOL,
    PW_INCLUDES_FIRSTNAME,
    PW_INCLUDES_LASTNAME,
    PW_INCLUDES_USERNAME,
    PW_INCLUDES_EMAIL,
  }
  from '../../validations/PasswordValidationV2';

/* eslint-disable max-len */

function handlePasswordErrors(error) {
  const passwordErrors = {
    validCharAmount: false,
    validUppercase: false,
    validLowercase: false,
    validDigit: false,
    validSymbol: false,
    noSpaces: true,
  };

  if (error && error !== 'Required' && error !== 'Not a valid password') {
    passwordErrors.validCharAmount = !error.includes(PW_LENGTH);
    passwordErrors.validUppercase = !error.includes(PW_REQUIRES_UPPER);
    passwordErrors.validLowercase = !error.includes(PW_REQUIRES_LOWER);
    passwordErrors.validDigit = !error.includes(PW_REQUIRES_DIGIT);
    passwordErrors.validSymbol = !error.includes(PW_REQUIRES_SYMBOL);
    passwordErrors.noSpaces = !error.includes(PW_REQUIRES_NO_SPACES);
  }
  if (!error) {
    passwordErrors.validCharAmount = true;
    passwordErrors.validUppercase = true;
    passwordErrors.validLowercase = true;
    passwordErrors.validDigit = true;
    passwordErrors.validSymbol = true;
    passwordErrors.noSpaces = true;
  }

  return passwordErrors;
}

function getNumberOfRequirementsMet(passwordErrors, error) {
  let numberOfRequirementsMet = 0;
  Object.keys(passwordErrors).forEach((requirement) => {
    if (passwordErrors[requirement]) numberOfRequirementsMet += 1;
  });

  // deduct 1 if there's a special error that doesn't have a checkbox
  if (error && numberOfRequirementsMet > 2 && (error.includes(PW_INCLUDES_FIRSTNAME) || error.includes(PW_INCLUDES_LASTNAME) ||
  error.includes(PW_INCLUDES_USERNAME) || error.includes(PW_INCLUDES_EMAIL) || error.includes(PW_CONTAINS_UNSUPPORTED_SYMBOL))) {
    numberOfRequirementsMet -= 1;
  }

  return numberOfRequirementsMet;
}

function handleConfirmPasswordFillStyle(error, id) {
  let inputPercent;
  let color;
  const element = document.getElementById(id);

  if (error) {
    color = error.confirmPasswordIsValidSoFar ? '#9BF47D' : '#FF7979';
    inputPercent = error.confirmPasswordProgressPercentage ? error.confirmPasswordProgressPercentage : 0;
  } else {
    color = '#00B500';
    inputPercent = 100;
  }

  if (element) {
    element.style.setProperty('--confirmPasswordInputPercentage', `${inputPercent}%`);
    element.style.setProperty('--confirmPasswordColor', color);
  }
}

function getConfirmPasswordFillStyle(input) {
  return input.length === 0 ? 'confirmPasswordFillEmpty' : 'confirmPasswordFill';
}

function handleCannotIncludeNameUsernameOrEmailError(error) {
  if (error) {
    if (error.includes(PW_INCLUDES_FIRSTNAME)) return PW_INCLUDES_FIRSTNAME;
    if (error.includes(PW_INCLUDES_LASTNAME)) return PW_INCLUDES_LASTNAME;
    if (error.includes(PW_INCLUDES_USERNAME)) return PW_INCLUDES_USERNAME;
    if (error.includes(PW_INCLUDES_EMAIL)) return PW_INCLUDES_EMAIL;
  }
  return '';
}

export function getFieldType(passwordVisible) {
  return passwordVisible ? 'text' : 'password';
}

export function getIconCssClass(passwordVisible) {
  return !passwordVisible ? 'visibility' : 'visibility_off';
}

export const SuccessIcon = ({ text }) => (
  <div>
    {/* eslint-disable-next-line */}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="14px" min-width="14px" height="14px" fill="#39b54a" className="passwordSuccessIcon">
      <path className="b" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8,8-3.6,8-8S12.4,0,8,0m0,14.4c-3.53,0-6.4-2.87-6.4-6.4S4.47,1.6,8,1.6s6.4,2.87,6.4,6.4-2.87,6.4-6.4,6.4m3.67-9.94l-5.27,5.27-2.07-2.06-1.13,1.13,3.2,3.2,6.4-6.4-1.13-1.14Z" />
    </svg>
    <span className="ml-2 text-nowrap passwordSuccessIconText">{text}</span>
  </div>
);

SuccessIcon.propTypes = {
  text: PropTypes.string,
};

export const FailIcon = ({ text }) => (
  <div className="failIconDiv">
    <svg height="22" width="22">
      <circle cx="10" cy="10" r="6" stroke="red" strokeWidth="1.8" fill="white" />
    </svg>
    <span className="ml-2 text-nowrap passwordFailIconText">{text}</span>
  </div>
  );

FailIcon.propTypes = {
  text: PropTypes.string,
};

export const renderField = (props) => {
  const {
    className,
    label,
    labelMobile,
    input,
    maxLength,
    meta: { error, touched },
    id,
    tooltip,
    readOnly = false,
    onMasking,
    disabled,
    pwdVisible,
    handleShowHidePassword,
    showPasswordIcons,
    handleShowHidePasswordIcons,
    handleSupportedSymbolsModalToggleOn,
  } = props;

  let passwordErrors;
  let passwordLabelStyle;
  let passwordFillStyle = '';
  let numberOfRequirementsMet = 1;
  const fieldType = getFieldType(pwdVisible);
  const iconClass = getIconCssClass(pwdVisible);
  const normalStyle = 'form-group label-floating';
  const errorStyle = 'form-group label-floating has-error';
  const showErrorStyle = touched && error && error !== 'Required' && error.error !== 'Required';
  const showRequiredErrorMessage = touched && error && input.value.length === 0 && !showPasswordIcons;
  let errorBorderStyle = '';
  let cannotIncludeNameUsernameOrEmailError = '';
  let showSymbolsErrorMessage = false;
  let showConfirmPasswordMismatchError = false;

  if (id === 'password') {
    passwordErrors = handlePasswordErrors(error);
    numberOfRequirementsMet = getNumberOfRequirementsMet(passwordErrors, error);
    passwordFillStyle = `passwordFill${numberOfRequirementsMet}RequirementsMet`;
    passwordLabelStyle = numberOfRequirementsMet > 1 ? 'passwordLabel' : '';
    cannotIncludeNameUsernameOrEmailError = handleCannotIncludeNameUsernameOrEmailError(error);
    showSymbolsErrorMessage = error && error.includes(PW_CONTAINS_UNSUPPORTED_SYMBOL);
    errorBorderStyle = showSymbolsErrorMessage || cannotIncludeNameUsernameOrEmailError.length > 0 ? 'containsError' : '';
  }
  if (id === 'confirmPassword') {
    handleConfirmPasswordFillStyle(error, id);
    passwordFillStyle = getConfirmPasswordFillStyle(input.value);
    passwordLabelStyle = passwordFillStyle !== 'confirmPasswordFillEmpty' ? 'passwordLabel' : '';
    showConfirmPasswordMismatchError = error && error.confirmPasswordProgressPercentage > 0 && !error.confirmPasswordIsValidSoFar;
  }

  function handleOnFocus(e) {
    if (id === 'password') handleShowHidePasswordIcons(true, input.value);
    if (onMasking) {
      const val = input.value;
      e.target.value = val;
      input.onFocus(e);
    }
    e.target.select();
  }

  function handleOnBlur(e) {
    const val = input.value;
    if (id === 'password') handleShowHidePasswordIcons(false, val);
    if (onMasking) {
      e.target.value = onMasking(val, true);
      e.preventDefault();
    } else {
      input.onBlur(e);
    }
  }

  return (
    <div className={showErrorStyle ? errorStyle : normalStyle}>
      <label className="control-label" id={passwordLabelStyle} htmlFor={input.name}>
        <span className={labelMobile ? 'hidden-xs hidden-sm visible-lg visible-md' : ''}>{label}</span>
        {labelMobile && <span className="hidden-lg hidden-md visible-xs visible-sm">{labelMobile}</span>}
      </label>
      <div className={tooltip && 'input-group'}>
        <input
          {...input}
          type={fieldType}
          id={id || input.name}
          name={input.name}
          className={`${className} ${passwordFillStyle} ${errorBorderStyle}`}
          maxLength={maxLength}
          readOnly={readOnly}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={input.value}
          disabled={disabled}
        />
        {tooltip &&
          <span
            role="button"
            tabIndex="-1"
            className="input-group-addon"
            data-tip
            data-for={input.name}
            onClick={handleShowHidePassword}
          >
            <i className="material-icons">{iconClass}</i>
          </span>
        }
      </div>

      {showRequiredErrorMessage &&
        <div className="messages"><p className="help-block error">Required</p></div>
      }
      {showConfirmPasswordMismatchError &&
        <div className="messages"><p className="help-block error">Password does not match</p></div>
      }
      {showSymbolsErrorMessage &&
        <div className="messages errorMarginBottom">
          <p className="help-block error">Unsupported symbol.
            {/* eslint-disable-next-line */}
            <a href="javascript:;" onClick={handleSupportedSymbolsModalToggleOn}>
              &nbsp;Learn more&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                fill="currentColor"
                className="bi bi-box-arrow-up-right boxArrowIcon"
                viewBox="0 -1.4 16 16"
              >
                <path
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0
                  1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0
                  1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                />
                <path
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeWidth="1.25"
                  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0
                  .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                />
              </svg>
            </a>
          </p>
        </div>
      }
      {cannotIncludeNameUsernameOrEmailError.length > 0 &&
        <div className="messages errorMarginBottom"><p className="help-block error">{cannotIncludeNameUsernameOrEmailError}</p></div>
      }

      {showPasswordIcons &&
      <div className="d-none d-md-block pr-3 small-text passwordIconContainer">
        <div className="row passwordIconDiv">
          <div className="passwordIconRow">
            <div className="d-flex col-sm-5 passwordIcon1 mobileRow1">
              {!passwordErrors.validCharAmount &&
                <FailIcon text="9 - 23 characters" />
              }
              {passwordErrors.validCharAmount &&
                <SuccessIcon text="9 - 23 characters" />
              }
            </div>

            <div className="d-flex col-sm-4 passwordIcon2">
              {!passwordErrors.validLowercase &&
                <FailIcon text="lowercase" />
              }
              {passwordErrors.validLowercase &&
                <SuccessIcon text="lowercase" />
              }
            </div>

            <div className="d-flex col-sm-4 passwordIcon2">
              {!passwordErrors.validUppercase &&
                <FailIcon text="uppercase" />
              }
              {passwordErrors.validUppercase &&
                <SuccessIcon text="uppercase" />
              }
            </div>
          </div>
          <div className="passwordIconRow">
            <div className="d-flex col-sm-3 passwordIcon3 mobileRow1">
              {!passwordErrors.validDigit &&
                <FailIcon text="number" />
              }
              {passwordErrors.validDigit &&
                <SuccessIcon text="number" />
              }
            </div>

            <div className="d-flex col-sm-5 passwordIcon3">
              {!passwordErrors.validSymbol &&
                <FailIcon text="symbol" />
              }
              {passwordErrors.validSymbol &&
                <SuccessIcon text="symbol" />
              }
            </div>

            <div className="d-flex col-sm-5 passwordIcon2">
              {!passwordErrors.noSpaces &&
                <FailIcon text="no spaces" />
              }
              {passwordErrors.noSpaces &&
                <SuccessIcon text="no spaces" />
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

renderField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape(),
  label: PropTypes.string,
  labelMobile: PropTypes.string,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.shape(),
    ]),
    touched: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onMasking: PropTypes.func,
  disabled: PropTypes.bool,
  pwdVisible: PropTypes.bool,
  handleShowHidePassword: PropTypes.func,
  handleShowHidePasswordIcons: PropTypes.func,
  handleSupportedSymbolsModalToggleOn: PropTypes.func,
};

const
  PasswordFieldV2 = (
    { className,
      label,
      labelMobile,
      name,
      maxLength,
      tooltip,
      type,
      readOnly,
      normalize,
      id,
      onMasking,
      disabled,
      pwdVisible,
      handleShowHidePassword,
      handleShowHidePasswordIcons,
      showPasswordIcons,
      handleSupportedSymbolsModalToggleOn,
    },
  ) => {
    return (
      <Field
        type={type}
        id={id}
        name={name}
        component={renderField}
        className={className}
        label={label}
        labelMobile={labelMobile}
        maxLength={maxLength}
        tooltip={tooltip}
        readOnly={readOnly}
        normalize={normalize}
        onMasking={onMasking}
        disabled={disabled}
        pwdVisible={pwdVisible}
        handleShowHidePassword={handleShowHidePassword}
        handleShowHidePasswordIcons={handleShowHidePasswordIcons}
        showPasswordIcons={showPasswordIcons}
        handleSupportedSymbolsModalToggleOn={handleSupportedSymbolsModalToggleOn}
      />
    );
  };

PasswordFieldV2.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelMobile: PropTypes.string,
  maxLength: PropTypes.number,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  normalize: PropTypes.func,
  onMasking: PropTypes.func,
  disabled: PropTypes.bool,
  pwdVisible: PropTypes.bool,
  handleShowHidePassword: PropTypes.func,
  showPasswordIcons: PropTypes.bool,
  handleShowHidePasswordIcons: PropTypes.func,
  handleSupportedSymbolsModalToggleOn: PropTypes.func,
};

export default PasswordFieldV2;
