import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const renderField = (props) => {
  const {
    meta: { error, touched },
  } = props;

  return (
    <div className={touched && error ? 'form-group label-floating has-error' : 'form-group label-floating'}>
      {touched && error && <div className="messages"><p className="help-block error">{error}</p></div>}
    </div>
  );
};

renderField.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
};

const GenericError = ({ className, label, name, maxLength, tooltip, type, readOnly, normalize }) => {
  return (
    <Field
      type={type}
      name={name}
      component={renderField}
      className={className}
      label={label}
      maxLength={maxLength}
      tooltip={tooltip}
      readOnly={readOnly}
      normalize={normalize}
    />
  );
};

GenericError.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  normalize: PropTypes.func,
};

export default GenericError;
