import React from 'react';
import DocumentsFormContainer from '../../containers/DocumentsFormContainer';
import ProgressBar2 from '../ProgressBar2';

const DocumentsPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div className="col col-md-12 col-lg-8 col-lg-offset-2">
            <h1 className="h1_secondary text-center">Upload Documents</h1>
            <p className="text-center ">
              We need some additional information to approve your application.
            </p>
          </div>
        </div>
        <div id="appDocuments">
          <DocumentsFormContainer />
        </div>
      </div>
    </section>
  );
};

export default DocumentsPage;
