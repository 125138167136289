/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';

const AgreementsArbitrationEquities = ({ equitiesAgreement }) => {
  return (
    <div className="agreements-arbitration">
      <div className="row">
        <div className="col col-md-12">
          <p>
            <b>AGREEMENT TO ARBITRATION OF DISPUTES RELATING TO
            EQUITIES ACCOUNTS: </b>
            If you are applying for an <b>Equities (Equities &amp; Options)</b> account,
            you acknowledge and affirm that you have read and agree to the <b>pre-dispute
            arbitration provisions</b> set forth in &nbsp;
            <a target="_blank" rel="noopener noreferrer" href={equitiesAgreement} >
              section 38 of the TradeStation Securities, Inc. Customer Account Agreement for Equities
            </a>.
          </p>
        </div>
      </div>
    </div>
  );
};

AgreementsArbitrationEquities.propTypes = {
  equitiesAgreement: PropTypes.string,
};

export default AgreementsArbitrationEquities;
