import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DropDownList from './DropDownList';
import GDPRDisclaimer from './Intro/GDPRDisclaimer';
import Config from '../Config';

const config = new Config();

export const Navigation = (
  { showBack = true,
    showSaveForLater = true,
    showNext = true,
    showSalesRep = false,
    haveErrors = false,
    isEntityPage = false,
    onBack,
    onNext,
    onExitModalOn,
    submittingFile,
    isSubmitting,
    salesReps,
    submit,
    isExistingCustomer = false,
    saveButtonId = 'save_btn',
    backButtonId = 'back_btn',
    nextButtonId = 'next_btn',
  },
) => {
  const styleShowSaveForLater = showSaveForLater
    ? 'col col-md-12 col-xs-12 text-right'
    : 'col col-md-12 col-xs-12 text-right';
  const styleShowSalesRep = showSalesRep
    ? 'col col-sm-12 text-right no-side-padding'
    : styleShowSaveForLater;
  const styleForTripleButton = showSaveForLater && showBack && !showSalesRep && !isEntityPage ? 'triple-button-style' : '';
  return (
    <div className="form-navigation">
      <div className="navigation-container">
        {showSalesRep &&
          <div className="col-xs-12 no-side-padding hau-container">
            <div className="hear-about-us">
              <DropDownList
                id="hearAboutUsSalesRep"
                fieldName="hearAboutUsSalesRep"
                className="form-control text-center"
                label="Working with a Sales Representative?"
                options={salesReps}
              />
            </div>
          </div>
        }
        { config.introPageVersion === 'V2' && isExistingCustomer && showSalesRep &&
          <div className="text-center">
            <div className="col cols-xs-12 show-lg show-md consent-text">
              <GDPRDisclaimer useLineBreak />
            </div>
          </div>
        }
        <div className={`${styleShowSalesRep} border-top navigation-btn-container ${styleForTripleButton}`}>
          {showSaveForLater &&
            <div className={`col col-md-3 col-xs-5 text-left no-side-padding ${styleForTripleButton}`}>
              <button
                type="button"
                id={saveButtonId}
                className="btn btn-default do_capitalize save-btn"
                onClick={onExitModalOn}
              >
                <i className="material-icons-outlined">save</i>
                <span>Save for later</span>
              </button>
            </div>
          }
          {showBack &&
            <button
              type="button"
              id={backButtonId}
              className={`btn btn-default do_capitalize back-button ${styleForTripleButton}`}
              disabled={isSubmitting}
              onClick={onBack}
            >
              back
            </button>}
          {onNext &&
            <button
              id={nextButtonId}
              onClick={onNext}
              className={`btn btn-raised btn-default do_capitalize ${styleForTripleButton}`}
              disabled={isSubmitting || submittingFile || haveErrors}
            >
              next
              {isSubmitting && <span className="glyphicon glyphicon-refresh spinning" />}
            </button>
          }
          {showNext && !onNext &&
            <button
              type="submit"
              id={nextButtonId}
              className={`btn btn-raised btn-default do_capitalize ${styleForTripleButton}`}
              disabled={isSubmitting || submittingFile || haveErrors}
            >
              {submit || 'next'}
              {isSubmitting && <span className="glyphicon glyphicon-refresh spinning" />}
            </button>
          }
        </div>
      </div>
    </div>
  );
};

Navigation.propTypes = {
  haveErrors: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submittingFile: PropTypes.bool,
  showBack: PropTypes.bool,
  showNext: PropTypes.bool,
  showSaveForLater: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onExitModalOn: PropTypes.func,
  showSalesRep: PropTypes.bool,
  isEntityPage: PropTypes.bool,
  submit: PropTypes.string,
  isExistingCustomer: PropTypes.bool,
  salesReps: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  saveButtonId: PropTypes.string,
  backButtonId: PropTypes.string,
  nextButtonId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

export default connect(mapStateToProps)(Navigation);
