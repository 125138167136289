import * as actionTypes from './actionTypes';
import Config from '../Config';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { setApplicationId } from './applicationIdActions';
import { setAuthToken } from './authTokenActions';

const config = new Config();

export function submitRegistrationError(error) {
  return { type: actionTypes.SUBMIT_REGISTRATION_ERROR, error };
}

export function submitRegistrationRequest(registration) {
  return { type: actionTypes.SUBMIT_REGISTRATION_REQUEST, registration };
}

export function submitRegistrationSuccess(registration) {
  return { type: actionTypes.SUBMIT_REGISTRATION_SUCCESS, registration };
}

export function submitRegistration(registration) {
  return (dispatch) => {
    dispatch(submitRegistrationRequest(registration));

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(registration),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/registration`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(submitRegistrationSuccess(json.registration));
        dispatch(setApplicationId(json.registration.applicationId));
        dispatch(setAuthToken(json.token.id));
      })
      .catch((error) => {
        dispatch(submitRegistrationError(error));
        return Promise.reject(error);
      });
  };
}

export function fetchRegistrationError(error) {
  return { type: actionTypes.FETCH_REGISTRATION_ERROR, error };
}

export function fetchRegistrationRequest(applicationId, authToken) {
  return { type: actionTypes.FETCH_REGISTRATION_REQUEST, applicationId, authToken };
}

export function fetchRegistrationSuccess(registration) {
  return { type: actionTypes.FETCH_REGISTRATION_SUCCESS, registration };
}

export function fetchRegistration(applicationId, authToken) {
  const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
  };

  if (authToken) {
    header.Authorization = authToken;
  }

  return (dispatch) => {
    dispatch(fetchRegistrationRequest(applicationId, authToken));
    const options = {
      method: 'GET',
      headers: header,
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/registration`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((result) => {
        dispatch(fetchRegistrationSuccess(result));
      })
      .catch(error => dispatch(fetchRegistrationError(error)));
  };
}
