import React from 'react';
import PropTypes from 'prop-types';

const LogOff = (props) => {
  const {
    logInWithDifferentUser,
  } = props;

  return (
    <div className="cancelled-body modal-dialog" id="cancelled-application">
      <div className="cancelled-header">
        <img
          className="img-responsive duplicateModalIcon"
          id="cancelledImageIcon"
          src="images/duplicate-remediation-modal-icon.png"
          alt="Person Icon"
        />
        <h2 className="text-center h1_secondary">
          We&apos;re sorry
        </h2>
      </div>
      <div className="cancelled-body">
        <br />
        <p className="text-center duplicateModalTextPersonalInfo">
          For your protection, the new user credentials entered during your login request cannot be used to open a new TradeStation account because you have a pre-existing relationship with TradeStation.
        </p>
        <br />
        <p className="text-center duplicateModalTextPersonalInfo">
          To add a new account you must first log in with your pre-existing credentials to verify your identity. If you need help logging in, please try the login recovery options below or <a href="https://www.tradestation.com/contact-us" target="_blank" rel="noopener noreferrer">contact us</a> for assistance.
        </p>
      </div>
      <div className="cancelled-footer text-center duplicateModalFooter">
        <button
          className="btn btn-raised btn-default do_capitalize intro-btn intro duplicateModalButton"
          id="loginCancelledApplication"
          onClick={logInWithDifferentUser}
        >
          Log In
        </button>
        <div className="help-links text-center duplicateModalTextPersonalInfo">
          <div className="col-md-4 col-md-offset-2 border-intro">
            <a
              href="https://security.tradestation.com/credentials/recover-username"
              target="_blank"
              rel="noopener noreferrer"
            >
              Recover Username
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://security.tradestation.com/credentials/reset-password"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reset Password
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

LogOff.propTypes = {
  logInWithDifferentUser: PropTypes.func.isRequired,
};

export default LogOff;
