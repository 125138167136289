import React from 'react';

const AgreementsInformation = () => {
  return (
    <div className="row">
      <div className="col col-md-12">
        <p>
          <strong>
            U.S. citizens and U.S. residents only &mdash; Tax Withholding
            Certification.
          </strong>
          &nbsp;Under penalty of perjury, you certify that: (1)
          the number provided by you in this account application is your correct taxpayer
          identification number; (2) you are not subject to backup withholding because
          (a) you are exempt from backup withholding, or (b) you have not been notified by
          the Internal Revenue Service (IRS) that you are subject to backup withholding
          as a result of a failure to report all interest or dividends, or (c) the IRS
          has notified you that you are no longer subject to backup withholding; (3) you
          are a U.S. person (which includes being a U.S. resident alien); and (4) the
          FATCA code(s) entered in this account application (if any) indicating that you
          are exempt from FATCA reporting is correct. You understand that if you are not
          a U.S. person, or have been notified by the IRS that you are currently subject
          to backup withholding because you have failed to report all interest and
          dividends on your tax return, you may not sign below and you must contact us to
          complete your application so that we can apply any required backup
          withholding.&nbsp;
          <strong>
            Note: The IRS does not require your consent to any
            provision of this document other than the certification required to avoid
            backup withholding. If this is a joint account, each joint account owner is
            separately making the certification.
          </strong>
        </p>
        <p>
          You confirm and affirm
          that all information, representations, warranties, affirmations,
          acknowledgements and agreements that you have provided or made in this
          application are voluntarily and knowingly given and made, are true, complete
          and accurate, and not misleading in any respect,&nbsp;
          <em>
            and you will promptly
            notify us in writing if any of the information or any representation you have
            provided materially changes or ceases to be true, complete and accurate
          </em>.
        </p>
      </div>
    </div>
  );
};
export default AgreementsInformation;
