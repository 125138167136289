import * as actionTypes from './actionTypes';
import Config from '../Config';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { setApplicationId } from './applicationIdActions';

const config = new Config();

export function fetchContactError(error) {
  return { type: actionTypes.FETCH_CONTACT_ERROR, error };
}

export function fetchContactRequest(applicationId, authToken) {
  return { type: actionTypes.FETCH_CONTACT_REQUEST, applicationId, authToken };
}

export function fetchContactSuccess(contact) {
  return { type: actionTypes.FETCH_CONTACT_SUCCESS, contact };
}

export function fetchContact(applicationId, authToken) {
  const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
  };

  if (authToken) {
    header.Authorization = authToken;
  }

  return (dispatch) => {
    dispatch(fetchContactRequest(applicationId, authToken));
    const options = {
      method: 'GET',
      headers: header,
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/contacts`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((result) => {
        dispatch(fetchContactSuccess(result));
        dispatch(setApplicationId(result[0].applicationId));
      })
      .catch(error => dispatch(fetchContactError(error)));
  };
}

export function submitPrimaryContactError(error) {
  return { type: actionTypes.SUBMIT_PRIMARY_CONTACT_ERROR, error };
}

export function submitPrimaryContactRequest(contact) {
  return { type: actionTypes.SUBMIT_PRIMARY_CONTACT_REQUEST, contact };
}

export function submitPrimaryContactSuccess(contact) {
  return { type: actionTypes.SUBMIT_PRIMARY_CONTACT_SUCCESS, contact };
}

export function submitPrimaryContact(contact) {
  return (dispatch) => {
    dispatch(submitPrimaryContactRequest(contact));

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/contact`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(submitPrimaryContactSuccess(json));
        dispatch(setApplicationId(json.applicationId));
      })
      .catch((error) => {
        dispatch(submitPrimaryContactError(error));
        return Promise.reject(error);
      });
  };
}

export function submitContactError(error) {
  return { type: actionTypes.SUBMIT_CONTACT_ERROR, error };
}

export function submitContactRequest(contact) {
  return { type: actionTypes.SUBMIT_CONTACT_REQUEST, contact };
}

export function submitContactSuccess(contact) {
  return { type: actionTypes.SUBMIT_CONTACT_SUCCESS, contact };
}

// eslint-disable-next-line no-underscore-dangle
function _submitContact(contact, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitContactRequest(contact));

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
      mode: 'cors',
    };

    let url = `${config.apiUrl}/applications/${applicationId}/contacts`;
    if (method === 'PUT') {
      url = `${url}/${contact.id}`;
    }

    return fetch(url, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(submitContactSuccess(json));
        return Promise.resolve(json);
      })
      .catch((error) => {
        dispatch(submitContactError(error));
        return Promise.reject(error);
      });
  };
}

export function submitContact(contact, applicationId, authToken) {
  return _submitContact(contact, applicationId, authToken, 'POST');
}

export function updateContact(contact, applicationId, authToken) {
  return _submitContact(contact, applicationId, authToken, 'PUT');
}
