import DocumentsSettings from './DocumentsSettings';

const documentsTypesTexts = [
  { value: '', text: '' },
  { value: DocumentsSettings.PROOF_OF_IDENTITY, text: 'Proof Of Identity' },
  { value: DocumentsSettings.PROOF_OF_ADDRESS, text: 'Proof Of Address' },
  { value: DocumentsSettings.TRADING_AUTHORIZATION_TSEL, text: 'Trading Authorization' },
  { value: DocumentsSettings.TRADING_AUTHORIZATION_NON_TSEL, text: 'Trading Authorization' },
  { value: DocumentsSettings.TRADING_ADVISORY_TSEL, text: 'Trading Advisory' },
  { value: DocumentsSettings.TRADING_ADVISORY_NON_TSEL, text: 'Trading Advisory' },
  { value: DocumentsSettings.EMPLOYER_AUTHORIZATION, text: 'Employer Authorization Letter' },
  { value: DocumentsSettings.SEP_IRA, text: 'SEP IRA' },
  { value: DocumentsSettings.SIMPLE_IRA, text: 'SIMPLE IRA' },
  { value: DocumentsSettings.DEATH_CERTIFICATE, text: 'Death Certificate' },
];

export default documentsTypesTexts;
