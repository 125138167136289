import React from 'react';

const AgreementsInformationSubmit = () => {
  return (
    <div className="row">
      <div className="col col-md-12">
        <p>
          <strong>By clicking “Submit,” I (we) understand I (we) will submit this account
            application, agree to electronic delivery, and submit my
            electronically-signed agreement, confirming that I have read,
            understood, and acknowledge the important information provided herein.</strong>
        </p>
      </div>
    </div>
  );
};
export default AgreementsInformationSubmit;
