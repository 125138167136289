export const DRIVERS_LICENSE = 'DL';
export const PASSPORT = 'PP';
export const OTHER = 'OG';
export const MITEK_DRIVERS_LICENSE = 'DL_FRONT';
export const MITEK_PASSPORT = 'PASSPORT';

// Mitek Identity Subtypes
export const ID_FRONT = 'idFront';
export const ID_BACK = 'idBack';
export const ID_PASSPORT = 'passport';
export const ID_FRONT_ERROR = 'Front is required';
export const ID_BACK_ERROR = 'Back is required';
