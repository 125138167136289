import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const renderField = ({
  className, label, input, meta: { error, touched }, id, options, tooltip, handleChange, disabled,
}) => {
  const normalStyle = 'form-group label-floating';
  const errorStyle = 'form-group label-floating has-error';

  return (
    <div className={touched && error && error !== 'Required' ? errorStyle : normalStyle}>
      <label className="control-label" htmlFor={input.fieldName}>{label}</label>
      <div className={tooltip ? 'dropTarget input-group' : 'dropTarget'}>
        <select
          {...input}
          name={input.name}
          id={id || input.name}
          className={className}
          onChange={(event) => {
            input.onChange(event);
            if (handleChange) {
              handleChange(input.fieldName, event.target.value);
            }
          }}
          disabled={disabled}
        >
          {options && options.map(option => (
            <option
              key={option.text}
              selected={option.selected && 'selected'}
              value={option.value}
            >{option.text}</option>
          ))}
        </select>
        {tooltip &&
          <span className="input-group-addon" data-tip data-for={input.fieldName || id}>
            <i className="material-icons">help</i>
          </span>
        }
      </div>
      {touched && error && <div className="messages"><p className="help-block error">{error}</p></div>}
    </div>
  );
};

renderField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape(),
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    selected: PropTypes.bool,
    text: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
  tooltip: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const DropDownList =
  ({ className, label, fieldName, maxLength, options, tooltip, type, id, handleChange, disabled }) => {
    return (
      <Field
        type={type}
        id={id}
        name={fieldName}
        component={renderField}
        className={className}
        label={label}
        maxLength={maxLength}
        options={options}
        tooltip={tooltip}
        handleChange={handleChange}
        disabled={disabled}
      />
    );
  };

DropDownList.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DropDownList;
