import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

function removeEscapeChars(value) {
  if (value && typeof value === 'string' && value.replace) {
    let newValue = value.replace(/:+/g, '');
    newValue = newValue.replace(/(\\)+/g, '');
    return newValue;
  }
  return value;
}

export function getFieldType(passwordVisible) {
  return passwordVisible ? 'text' : 'password';
}

export function getIconCssClass(passwordVisible) {
  return !passwordVisible ? 'visibility' : 'visibility_off';
}

export const renderField = (props) => {
  const {
    className,
    label,
    labelMobile,
    input,
    maxLength,
    meta: { error, touched },
    id,
    tooltip,
    readOnly = false,
    onMasking,
    disabled,
    pwdVisible,
    handleShowHidePassword,
  } = props;


  const fieldType = getFieldType(pwdVisible);
  const iconClass = getIconCssClass(pwdVisible);
  const normalStyle = 'form-group label-floating';
  const errorStyle = 'form-group label-floating has-error';

  function handleOnFocus(e) {
    if (onMasking) {
      const val = input.value;
      e.target.value = val;
      input.onFocus(e);
    }
    e.target.select();
  }

  function handleOnBlur(e) {
    const val = input.value;
    if (onMasking) {
      e.target.value = onMasking(val, true);
      e.preventDefault();
    } else {
      input.onBlur(e);
    }
  }

  return (
    <div className={touched && error && error !== 'Required' ? errorStyle : normalStyle}>
      <label className="control-label" htmlFor={input.name}>
        <span className={labelMobile ? 'hidden-xs hidden-sm visible-lg visible-md' : ''}>{label}</span>
        {labelMobile && <span className="hidden-lg hidden-md visible-xs visible-sm">{labelMobile}</span>}
      </label>
      <div className={tooltip && 'input-group'}>
        <input
          {...input}
          type={fieldType}
          id={id || input.name}
          name={input.name}
          className={className}
          maxLength={maxLength}
          readOnly={readOnly}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={removeEscapeChars(input.value)}
          disabled={disabled}
        />
        {tooltip &&
          <span
            role="button"
            tabIndex="-1"
            className="input-group-addon"
            data-tip
            data-for={input.name}
            onClick={handleShowHidePassword}
          >
            <i className="material-icons">{iconClass}</i>
          </span>
        }
      </div>
      {touched && error && <div className="messages"><p className="help-block error">{error}</p></div>}
    </div>
  );
};

renderField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape(),
  label: PropTypes.string,
  labelMobile: PropTypes.string,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onMasking: PropTypes.func,
  disabled: PropTypes.bool,
  pwdVisible: PropTypes.bool,
  handleShowHidePassword: PropTypes.func,
};

const
  PasswordField = (
    { className,
      label,
      labelMobile,
      name,
      maxLength,
      tooltip,
      type,
      readOnly,
      normalize,
      id,
      onMasking,
      disabled,
      pwdVisible,
      handleShowHidePassword,
    },
  ) => {
    return (
      <Field
        type={type}
        id={id}
        name={name}
        component={renderField}
        className={className}
        label={label}
        labelMobile={labelMobile}
        maxLength={maxLength}
        tooltip={tooltip}
        readOnly={readOnly}
        normalize={normalize}
        onMasking={onMasking}
        disabled={disabled}
        pwdVisible={pwdVisible}
        handleShowHidePassword={handleShowHidePassword}
      />
    );
  };

PasswordField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelMobile: PropTypes.string,
  maxLength: PropTypes.number,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  normalize: PropTypes.func,
  onMasking: PropTypes.func,
  disabled: PropTypes.bool,
  pwdVisible: PropTypes.bool,
  handleShowHidePassword: PropTypes.func,
};

export default PasswordField;
