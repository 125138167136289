import * as storageKeys from '../actions/storageKeys';

export function startCryptoSession(cryptoSelected, sessionStorage = window.sessionStorage) {
  if (sessionStorage) {
    if (cryptoSelected) {
      sessionStorage.setItem(storageKeys.CRYPTO_SESSION, Math.random().toString(36).slice(2));
    }
  }
}

export function isCryptoSession(sessionStorage = window.sessionStorage) {
  let result = false;
  if (sessionStorage) {
    result = sessionStorage.getItem(storageKeys.CRYPTO_SESSION);
  }
  return !!result;
}

export function clearCryptoSession(sessionStorage = window.sessionStorage) {
  if (sessionStorage) {
    sessionStorage.removeItem(storageKeys.CRYPTO_SESSION);
  }
}
