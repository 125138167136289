import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';
import { attachRecaptchaToken } from './attachRecaptchaToken';

const config = new Config();

export function fetchAgreementsRequest() {
  return {
    type: actionTypes.FETCH_AGREEMENTS_REQUEST,
  };
}

export function fetchAgreementsSuccess(agreements) {
  return {
    type: actionTypes.FETCH_AGREEMENTS_SUCCESS,
    agreements,
  };
}

export function fetchAgreementsError(error) {
  return {
    type: actionTypes.FETCH_AGREEMENTS_ERROR,
    error,
  };
}

export function fetchAgreements(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchAgreementsRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/agreement`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchAgreementsSuccess(json)))
      .catch(error => dispatch(fetchAgreementsError(error)));
  };
}

export function submitAgreementsError(error) {
  return { type: actionTypes.SUBMIT_AGREEMENTS_ERROR, error };
}

export function submitAgreementsRequest(agreements) {
  return { type: actionTypes.SUBMIT_AGREEMENTS_REQUEST, agreements };
}

export function submitAgreementsSuccess(agreements) {
  return { type: actionTypes.SUBMIT_AGREEMENTS_SUCCESS, agreements };
}

// eslint-disable-next-line no-underscore-dangle
function _submitAgreements(agreements, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitAgreementsRequest(agreements));

    return attachRecaptchaToken(agreements, 'agreements')
      .then((data) => {
        const options = {
          method,
          headers: {
            Accept: 'application/json',
            Authorization: authToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          mode: 'cors',
        };

        return fetch(`${config.apiUrl}/applications/${applicationId}/agreement`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitAgreementsSuccess(json)))
      .catch((error) => {
        dispatch(submitAgreementsError(error));
        return Promise.reject(error);
      });
  };
}

export function submitAgreements(agreements, applicationId, authToken) {
  return _submitAgreements(agreements, applicationId, authToken, 'POST');
}

export function updateAgreements(agreements, applicationId, authToken) {
  return _submitAgreements(agreements, applicationId, authToken, 'PUT');
}
