import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../Config';

const config = new Config();

const ApprovalStatus = (props) => {
  const { hasCarmaAccounts, hasFakeOrNoMoreBusiness, hasBypassedCarmaAccounts } = props;

  const noBorderClass = 'no-border-funding-nav';

  const handleLetsGoButtonClicked = () => {
    window.location = `${config.hubBaseUrl}`;
  };

  const handleOkButtonClicked = () => {
    window.location = 'https://www.tradestation.com';
  };

  return (
    <div>
      <section className="flow-text">
        <div className="container">
          <div className="row text-center">
            <img
              src="images/encircled-checkmark.svg"
              alt="Checkmark Icon"
              className="thankYouCheckMarx"
            />
          </div>
          <div className="row">
            <div className="col col-md-12 col-lg-8 col-lg-offset-2">
              <h1 className="h1_secondary_thanks text-center">Thank you for choosing</h1>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
                <br /><img src="images/tradestation.png" alt="" className="ts-logo-thankyou-page" /><br /><br />
                <p className="p_leadForm">
                  Your account application is currently being reviewed. You will receive an
                  email notification as soon as your account is approved including funding
                  instructions and other important information.
                </p>
              </div>
            </div>
            { (hasCarmaAccounts || hasFakeOrNoMoreBusiness || hasBypassedCarmaAccounts) &&
              <div className="form-navigation justify-content-between">
                <div className={`inline-funding-navigation-container ${noBorderClass}`}>
                  <div className="text-center">
                    <button
                      type="button"
                      id="letsOk"
                      className="btn btn-lg btn-raised btn-default do_capitalize"
                      onClick={handleOkButtonClicked}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            }
            { (!hasCarmaAccounts && !hasFakeOrNoMoreBusiness && !hasBypassedCarmaAccounts) &&
              <div className="form-navigation justify-content-between">
                <div className={`inline-funding-navigation-container ${noBorderClass}`}>
                  <div className="text-center">
                    <p className="assistance-container-ready-normal">
                      While you wait, let&apos;s get you ready for trading.
                    </p>
                    <button
                      type="button"
                      id="letsGo"
                      className="btn btn-lg btn-raised btn-default do_capitalize"
                      onClick={handleLetsGoButtonClicked}
                    >
                      Let&#39;s Go&#33;
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
};

ApprovalStatus.propTypes = {
  hasCarmaAccounts: PropTypes.bool,
  hasFakeOrNoMoreBusiness: PropTypes.bool,
  hasBypassedCarmaAccounts: PropTypes.bool,
};

export default ApprovalStatus;
