import React from 'react';
import PendingApplicationsContainer from '../../containers/PendingApplicationsContainer';

const paddingTop = {
  paddingTop: '90px',
};

const PendingApplicationsPage = () => {
  return (
    <section className="form-content-section-primary flow-text" style={paddingTop}>
      <div className="container">
        <div className="row">
          <div className="col col-md-12 col-lg-8 col-lg-offset-2">
            <PendingApplicationsContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PendingApplicationsPage;
