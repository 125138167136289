import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_TRADING_AUTHORIZATION_SUCCESS:
      return action.tradingAuthorization;
    case actionTypes.SUBMIT_TRADING_AUTHORIZATION_SUCCESS:
      return action.tradingAuthorization;
    default:
      return state;
  }
}
