import * as beneficiaryTypes from './IraBeneficiaryRelationshipTypes';

export const IraBeneficiaryTexts = [
  {
    value: '',
    text: '',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_SPOUSE,
    text: 'Spouse',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_SON,
    text: 'Son',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_DAUGTHER,
    text: 'Daughter',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_OTHERRELATIVE,
    text: 'Other Relative',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_FRIEND,
    text: 'Friend',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_ESTATE,
    text: 'Estate',
  },
  {
    value: beneficiaryTypes.IRA_RELATIONSHIP_ORGANIZATIONCHARIRY,
    text: 'Organization/Charity',
  },
];

export default IraBeneficiaryTexts;

