export function getOfferCodes(currentCode, urlPromoCode, promoCodeCookie, utmCode) {
  const promoCodes = [];
  if (currentCode) {
    promoCodes.push(currentCode);
  }
  if (urlPromoCode && urlPromoCode !== currentCode) {
    promoCodes.push(urlPromoCode);
  }
  if (utmCode) {
    promoCodes.push(utmCode);
  }
  if (promoCodeCookie) {
    promoCodes.push(promoCodeCookie);
  }
  return promoCodes;
}

export function getPromoCode(currentCode, urlPromoCode, promoCodeCookie, utmCode) {
  let offerCode = null;
  if (currentCode) {
    offerCode = currentCode;
  } else if (urlPromoCode && urlPromoCode !== currentCode) {
    offerCode = urlPromoCode;
  } else if (utmCode) {
    offerCode = utmCode;
  } else if (promoCodeCookie) {
    offerCode = promoCodeCookie;
  }
  return offerCode;
}
