import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchEmploymentRequest() {
  return {
    type: actionTypes.FETCH_EMPLOYMENT_REQUEST,
  };
}

export function fetchEmploymentSuccess(employment) {
  return {
    type: actionTypes.FETCH_EMPLOYMENT_SUCCESS,
    employment,
  };
}

export function fetchEmploymentError(error) {
  return {
    type: actionTypes.FETCH_EMPLOYMENT_ERROR,
    error,
  };
}

export function fetchEmployment(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchEmploymentRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/employments`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchEmploymentSuccess(json)))
      .catch(error => dispatch(fetchEmploymentError(error)));
  };
}

export function submitEmploymentError(error) {
  return { type: actionTypes.SUBMIT_EMPLOYMENT_ERROR, error };
}

export function submitEmploymentRequest(employment) {
  return { type: actionTypes.SUBMIT_EMPLOYMENT_REQUEST, employment };
}

export function submitEmploymentSuccess(employment) {
  return { type: actionTypes.SUBMIT_EMPLOYMENT_SUCCESS, employment };
}

// eslint-disable-next-line no-underscore-dangle
function _submitEmployment(employment, applicationId, authToken) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(employment),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/applications/${applicationId}/employments`, options)
    .then(checkHttpError)
    .then(responseToJson)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// eslint-disable-next-line no-underscore-dangle
function _updateEmployment(employment, applicationId, authToken) {
  const options = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(employment),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/applications/${applicationId}/employments/${employment.id}`, options)
    .then(checkHttpError)
    .then(responseToJson)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// eslint-disable-next-line no-underscore-dangle
function _updateEmployments(employment, applicationId, authToken) {
  return (dispatch) => {
    dispatch(submitEmploymentRequest(employment));

    const puts = employment.map((i) => {
      const submitOrUpdate = i.id && i.id > 0 ? _updateEmployment : _submitEmployment;
      return submitOrUpdate(i, applicationId, authToken)
        .then((json) => {
          return Promise.resolve(json);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    });

    return Promise.all(puts)
      .then(json => dispatch(submitEmploymentSuccess(json)))
      .catch((error) => {
        dispatch(submitEmploymentError(error));
        return Promise.reject(error);
      });
  };
}

// eslint-disable-next-line no-underscore-dangle
function _submitEmployments(employment, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitEmploymentRequest(employment));

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(employment.length > 1 ? employment : employment[0]),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/employments`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(submitEmploymentSuccess(json));
      })
      .catch((error) => {
        dispatch(submitEmploymentError(error));
        return Promise.reject(error);
      });
  };
}

export function updateEmployment(employment, applicationId, authToken) {
  return _updateEmployments(employment, applicationId, authToken);
}

export function submitEmployment(employment, applicationId, authToken) {
  return _submitEmployments(employment, applicationId, authToken, 'POST');
}
