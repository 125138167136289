const isUS = (country) => {
  return country.countryCode === 'US';
};

const sortRegions = (countries) => {
  let result = [];
  let sortedRegions = [];
  let country;

  try {
    if (countries && countries.length > 0) {
      result = [...countries].map((c) => {
        if (c.regions && c.regions.length > 0) {
          sortedRegions = [...c.regions].sort((a, b) => a.region.localeCompare(b.region));
        } else sortedRegions = [];

        country = Object.assign({}, c, { regions: sortedRegions });
        return country;
      });
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const sortCountries = (countries) => {
  let result = [];

  try {
    if (countries && countries.length > 0) {
      result = [...countries].sort((a, b) => (a.country > b.country) || (a.country === b.country) - 1);
      const index = result.findIndex(isUS);
      result.splice(0, 0, result.splice(index, 1)[0]);
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const filterNotAllowedRegions = (countries) => {
  let result = [];
  let filteredRegions = [];
  let country;

  try {
    if (countries && countries.length > 0) {
      result = [...countries].map((c) => {
        if (c.regions && c.regions.length > 0) {
          filteredRegions = [...c.regions].filter(region => !region.notAllowed);
        } else filteredRegions = [];

        country = Object.assign({}, c, { regions: filteredRegions });
        return country;
      });
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const filterAllowedCountries = (countries) => {
  let result = [];

  try {
    if (countries && countries.length > 0) {
      result = [...countries].filter(country => country.isAllowed);
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const sortCountriesAndRegions = (countries) => {
  let result = [];

  try {
    if (countries && countries.length > 0) {
      result = sortCountries(sortRegions([...countries]));
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const filterCountriesAndRegions = (countries) => {
  let result = [];

  try {
    if (countries && countries.length > 0) {
      result = filterAllowedCountries(filterNotAllowedRegions([...countries]));
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const filterAndSortAllowedCountriesAndRegions = (countries) => {
  let result = [];

  try {
    if (countries && countries.length > 0) {
      result = sortCountriesAndRegions(filterCountriesAndRegions([...countries]));
    }
  } catch (error) {
    throw error;
  }

  return result;
};

const mapCountryOptions = (countries) => {
  let options = [{ value: '', text: '' }];

  try {
    if (countries && countries.length > 0) {
      options = options.concat(countries
        .map((country) => { return { value: country.countryCode, text: country.country }; }),
      );
    }
  } catch (error) {
    throw error;
  }

  return options;
};

const mapRegionOptions = (countries, selectedCountry, shouldFilterNotAllowedRegions = false) => {
  let options = [{ value: '', text: '' }];
  let filteredAndSortedCountriesAndRegions;

  try {
    if (countries && countries.length > 0 && selectedCountry) {
      filteredAndSortedCountriesAndRegions = [...countries];

      if (shouldFilterNotAllowedRegions) {
        filteredAndSortedCountriesAndRegions = filterAndSortAllowedCountriesAndRegions(
          filteredAndSortedCountriesAndRegions,
        );
      }

      const country = filteredAndSortedCountriesAndRegions.find(c => c.countryCode === selectedCountry);

      if (selectedCountry !== 'CA' && country && country.regions) {
        options = options.concat(
          country.regions
            .map((region) => {
              return {
                value: region.regionCode,
                text: region.region,
              };
            }),
        );
      } else {
        options.push({ value: 'ON', text: 'Ontario' });
      }
    }
  } catch (error) {
    throw error;
  }

  return options;
};

export {
  mapCountryOptions,
  mapRegionOptions,
  sortRegions,
  sortCountries,
  filterNotAllowedRegions,
  filterAllowedCountries,
  sortCountriesAndRegions,
  filterAndSortAllowedCountriesAndRegions,
};
