import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import Address from './Address';
import AddressHeader from './AddressHeader';
import CheckboxSameJointAddress from './CheckboxSameJointAddress';

const AddressFormMailing = (props) => {
  const {
    sectionIndex,
    addressCountries,
    regionsMailing,
    showCopyFromPrimary,
    showMailingAddress,
    handleCopyFromPrimary,
  } = props;

  const showAddress = [true, true];
  if (sectionIndex > 0 && showCopyFromPrimary.length > 0 && showCopyFromPrimary[sectionIndex]) {
    showAddress[sectionIndex] = showCopyFromPrimary[sectionIndex].mailing;
  }

  return (
    <div >
      <div className="row">
        {showMailingAddress[sectionIndex] &&
          <div>
            <AddressHeader isResidential={false} sectionIndex={sectionIndex} />
            <FormSection name="mailing">
              {showAddress[sectionIndex] &&
                <Address
                  countries={addressCountries}
                  regions={regionsMailing[sectionIndex]}
                  countryIsReadonly={false}
                  regionIsReadonly={false}
                />
              }
              <div className="col col-md-12">
                {sectionIndex > 0 &&
                  <CheckboxSameJointAddress
                    isResidential={false}
                    sectionIndex={sectionIndex}
                    handleCopyFromPrimary={handleCopyFromPrimary}
                  />
                }
              </div>
            </FormSection>
          </div>
        }
      </div>
    </div>
  );
};

AddressFormMailing.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  addressCountries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regionsMailing: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))).isRequired,
  showCopyFromPrimary: PropTypes.arrayOf(PropTypes.shape({
    residential: PropTypes.bool,
    mailing: PropTypes.bool,
  })).isRequired,
  showMailingAddress: PropTypes.arrayOf(PropTypes.bool).isRequired,
  handleCopyFromPrimary: PropTypes.func.isRequired,
};

export default AddressFormMailing;
