import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchIndustriesRequest() {
  return {
    type: actionTypes.FETCH_INDUSTRIES_REQUEST,
  };
}

export function fetchIndustriesSuccess(industries) {
  return {
    type: actionTypes.FETCH_INDUSTRIES_SUCCESS,
    industries,
  };
}

export function fetchIndustriesError(error) {
  return {
    type: actionTypes.FETCH_INDUSTRIES_ERROR,
    error,
  };
}

export function fetchIndustries() {
  return (dispatch) => {
    dispatch(fetchIndustriesRequest());
    return fetch(`${config.apiUrl}/industries`)
      .then(checkHttpError)
      .then(responseToJson)
      .then(data => dispatch(fetchIndustriesSuccess(data)))
      .catch(error => dispatch(fetchIndustriesError(error)));
  };
}
