import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_ERRORS_SUCCESS:
      return action.applicationErrors;
    default:
      return state;
  }
}
