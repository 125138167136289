import React from 'react';
import TradingAuthorizationFormContainer from '../../containers/TradingAuthorizationFormContainer';
import ProgressBar2 from '../ProgressBar2';

const TradingAuthorizationPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appAdditionalInfo" className="col col-md-12 col-lg-8 col-lg-offset-2 no-side-padding">
            <h1 className="h1_secondary text-center">Now for the extras!</h1>
            <TradingAuthorizationFormContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TradingAuthorizationPage;
