import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchHearAboutUsRequest() {
  return {
    type: actionTypes.FETCH_HEARABOUTUS_REQUEST,
  };
}

export function fetchHearAboutUsSuccess(hearAboutUs) {
  return {
    type: actionTypes.FETCH_HEARABOUTUS_SUCCESS,
    hearAboutUs,
  };
}

export function fetchHearAboutUsError(error) {
  return {
    type: actionTypes.FETCH_HEARABOUTUS_ERROR,
    error,
  };
}

export function fetchHearAboutUs() {
  return (dispatch) => {
    dispatch(fetchHearAboutUsRequest());
    return fetch(`${config.apiUrl}/hearaboutus`)
      .then(checkHttpError)
      .then(responseToJson)
      .then(data => dispatch(fetchHearAboutUsSuccess(data)))
      .catch(error => dispatch(fetchHearAboutUsError(error)));
  };
}
