/* eslint-disable react/jsx-indent */
import React from 'react';

const style = {
  paddingTop: '15px',
};

const AgreementsFooter = () => {
  return (
    <div className="row">
      <div className="col col-xs-12" style={style}>
        <b>
          TradeStation Securities, Inc.
        </b> and&nbsp;
        <b>TradeStation Technologies, Inc.
        </b> are each wholly owned subsidiaries of <b>TradeStation Group, Inc.</b>,
        both operating, and providing products and services, under the TradeStation brand and trademark.&nbsp;
        <b className="italic">
          When applying for, or
          purchasing, accounts, subscriptions, products and services, it is important that
          you know which company you will be dealing with.
        </b>
        <b>&nbsp;
          <a
            className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
            tabIndex="-1"
            href="https://uploads.tradestation.com/uploads/tradestation-group-inc-companies.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
          &nbsp;for further important information explaining what this means.</b>
      </div>
    </div>
  );
};

export default AgreementsFooter;
