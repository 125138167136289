import React from 'react';
import PropTypes from 'prop-types';
import TradingProfileExperience from './TradingProfileExperience';
import * as tradingProfileTypes from './tradingProfileTypes';

const TradingProfileForm = (props) => {
  let isTSEL = false;
  if (props.tradingProfile && props.tradingProfile[props.sectionIndex]) {
    isTSEL = props.tradingProfile[props.sectionIndex].isTSEL;
  }

  const yearsExperienceOptions =
    props.optionSelections[props.sectionIndex] ?
      props.optionSelections[props.sectionIndex].yearsExperience : tradingProfileTypes.NONE;

  const yearsExperienceStock =
    props.stockSelections[props.sectionIndex] ?
      props.stockSelections[props.sectionIndex].yearsExperience : tradingProfileTypes.NONE;

  const yearsExperienceFutures =
    props.futuresSelections[props.sectionIndex] ?
      props.futuresSelections[props.sectionIndex].yearsExperience : tradingProfileTypes.NONE;

  const {
    sectionIndex,
  } = props;

  const isPrimary = sectionIndex === 0;

  return (
    <div>
      <div className="row">
        <div className="col col-md-12">
          <h1 className="h2_subHeading ">
            Trading Experience <span className="blue-text">{isPrimary ? '' : '(Joint)'}</span>
          </h1>
        </div>
      </div>
      <div className="row">
        <TradingProfileExperience
          title="Stocks"
          isTSEL={isTSEL}
          yearsExperience={yearsExperienceStock}
        />
        <TradingProfileExperience
          title="Options"
          isTSEL={isTSEL}
          yearsExperience={yearsExperienceOptions}
        />
        <TradingProfileExperience
          title="Futures"
          isTSEL={isTSEL}
          yearsExperience={yearsExperienceFutures}
        />

      </div>
    </div>
  );
};

TradingProfileForm.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  tradingProfile: PropTypes.arrayOf(PropTypes.shape()),
  stockSelections: PropTypes.arrayOf(
    PropTypes.shape({ yearsExperience: PropTypes.string }),
  ).isRequired,
  optionSelections: PropTypes.arrayOf(
    PropTypes.shape({ yearsExperience: PropTypes.string }),
  ).isRequired,
  futuresSelections: PropTypes.arrayOf(
    PropTypes.shape({ yearsExperience: PropTypes.string }),
  ).isRequired,
};

export default TradingProfileForm;
