import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const CheckboxSameMailingAddress = ({ sectionIndex }) => {
  const sameAddress = `addresses[${sectionIndex}].residential.sameAddress`;

  return (
    <div className="form-group label-floating small">
      <div className="checkbox small">
        <label htmlFor={sameAddress}>
          <Field
            id={sameAddress}
            type="checkbox"
            name="sameAddress"
            component="input"
            value={sameAddress}
          />
          I have a different mailing address
        </label>
      </div>
    </div>
  );
};

CheckboxSameMailingAddress.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
};

export default CheckboxSameMailingAddress;
