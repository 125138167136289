import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const LeavingTradeStationModal = (props) => {
  const {
    modalTimeLeft,
    endTimerAndModal,
  } = props;

  return (
    <Modal
      className="leavingTSModal"
      show
      onHide={() => endTimerAndModal(false)}
    >
      <Modal.Header closeButton>
        <p className="text-center leavingTSModalHeader">You are now leaving TradeStation.com</p>
      </Modal.Header>
      <Modal.Body className="leavingTSModalBody">
        <p className="text-center leavingTSModalText">
          In a few moments you will be redirected to a website hosted solely by
          our affiliate, <b>Midland Trust Company</b>.
          <br /><br />
          When on <b>Midland Trust’s</b> website, you are subject to the
          privacy policy and terms of use they have outlined.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center leavingTSModalFooter">
          <button
            className="btn btn-raised do_capitalize intro-btn intro leavingTSModalButton btn-white do_capitalize"
            onClick={() => endTimerAndModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-raised btn-default do_capitalize intro-btn intro leavingTSModalButton"
            onClick={() => endTimerAndModal(true)}
          >
            Continue ({modalTimeLeft})
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

LeavingTradeStationModal.propTypes = {
  modalTimeLeft: PropTypes.number,
  endTimerAndModal: PropTypes.func,
};

export default LeavingTradeStationModal;
