import * as actionTypes from './actionTypes';
import fetchEntities from './fetchEntitiesAction';
import upsertEntities from './upsertEntitiesAction';

const entityName = 'financialProfiles';
const payloadName = 'financial';

export function fetchFinancial(applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.FETCH_FINANCIAL_REQUEST,
    success: actionTypes.FETCH_FINANCIAL_SUCCESS,
    error: actionTypes.FETCH_FINANCIAL_ERROR,
  };
  return fetchEntities(entityName, payloadName, actionsTypes, applicationId, authToken);
}

export function upsertFinancial(financial, applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.UPSERT_FINANCIAL_REQUEST,
    success: actionTypes.UPSERT_FINANCIAL_SUCCESS,
    error: actionTypes.UPSERT_FINANCIAL_ERROR,
  };
  return upsertEntities(financial, entityName, payloadName, actionsTypes, applicationId, authToken);
}
