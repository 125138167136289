import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_SECURITY_CREDENTIALS_SUCCESS:
      return action.registration.userId;
    case actionTypes.FETCH_USER_SUCCESS:
      return action.user.userId;
    default:
      return state;
  }
}
