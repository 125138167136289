import React from 'react';
import ApprovalStatusContainer from '../../containers/ApprovalStatusContainer';
import ProgressBar2 from '../ProgressBar2';

const ThankYouPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appThankYou" >
            <ApprovalStatusContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYouPage;
