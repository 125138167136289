import React from 'react';
import IraAccountFormContainer from '../../containers/IraAccountFormContainer';
import ProgressBar2 from '../ProgressBar2';

const IraAccountPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appIraAccount" className="col col-md-12 col-lg-8 col-lg-offset-2">
            <IraAccountFormContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IraAccountPage;
