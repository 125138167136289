import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_AGREEMENTS_SUCCESS:
      return action.agreements;
    case actionTypes.SUBMIT_AGREEMENTS_SUCCESS:
      return action.agreements;
    default:
      return state;
  }
}
