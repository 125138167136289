import React from 'react';
import PropTypes from 'prop-types';

const ContactHeading = ({ contactName, sectionIndex }) => {
  const contactTitle = `${sectionIndex < 1 ? 'Primary' : 'Joint'} Account Holder`;

  return (
    <div>
      {contactName && contactName.length > 0 &&
        <h2 className="contactHeading">{contactName}</h2>
      }
      <h2 className="contactSubHeading">{contactTitle}</h2>
    </div>
  );
};

ContactHeading.propTypes = {
  contactName: PropTypes.string,
  sectionIndex: PropTypes.number.isRequired,
};

export default ContactHeading;
