function isCustomerDomestic(address, identity) {
  let result = false;
  if (address && address.residentialCountry === 'US'
      && identity && !!identity.identificationSSN) {
    result = true;
  }
  return result;
}

module.exports = {
  isCustomerDomestic,
};

