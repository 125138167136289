import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Config from './Config';
import * as applicationActions from './actions/applicationActions';
import * as applicationIdActions from './actions/applicationIdActions';
import * as authTokenActions from './actions/authTokenActions';
import * as userActions from './actions/userActions';
import * as pendingApplicationsActions from './actions/pendingApplicationsActions';
import * as storageKeys from './actions/storageKeys';
import * as RouteNavigator from './containers/RouteNavigator';
import * as applicationStatusTypes from './components/ApplicationStatusTypes';
import { fillAllSteps } from './lib/analytics';
import { clearCryptoSession } from './lib/cryptoSessionHelper';

const config = new Config();

const mapResourceName = {
  address: 'address',
  identity: 'identity',
  employment: 'employment',
  financial: 'financialProfile',
  'trading-profile': 'tradingProfile',
  documents: 'documents',
};

function isResourceUnlocked(unlockedArray) {
  let currentPage = RouteNavigator.getURLPathName();
  currentPage = currentPage === 'personal-info' ? 'address' : currentPage;

  if (currentPage === 'agreements' || currentPage === 'summary') {
    return true;
  }

  if (unlockedArray.includes(mapResourceName[currentPage])) {
    return true;
  }
  return false;
}

function isPage(page) {
  const currentPage = RouteNavigator.getURLPathName();
  if (currentPage === page) {
    return true;
  }
  return false;
}

function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { validStatus: false, failedStatus: false };

      this.checkApplicationStatus = this.checkApplicationStatus.bind(this);
      this.validateApplicationStatus = this.validateApplicationStatus.bind(this);
    }

    componentDidMount() {
      this.checkAuth();
      if (!this.props.isAuthenticated) {
        return;
      }
      this.validateApplicationStatus();
      if (this.props.authToken) {
        this.props.actions.fetchUser(this.props.authToken)
          .then((res) => {
            if (res.error || (!this.props.applicationId && this.props.userId)) {
              RouteNavigator.push('/pending-applications');
            }
          });
      }
    }

    validateApplicationStatus() {
      if (this.props.applicationId) {
        this.props.actions
          .fetchApplication(this.props.applicationId, this.props.authToken)
          .then(this.checkApplicationStatus);
      } else if (window.location.href.indexOf('pending-applications') >= 0) {
        this.setState({ validStatus: true });
      }
    }

    checkAuth() {
      if (!this.props.isAuthenticated) {
        const applicationId = this.props.applicationId;
        const applicationParam = applicationId ? `&${storageKeys.APPLICATION_ID_PARAM_KEY}=${applicationId}` : '';
        window.location =
          encodeURI(`${config.apiUrl}/auth/tradestation?redirectTo=${window.location.href}${applicationParam}`);
      }
    }

    clearUserData() {
      this.props.actions.userLogout(this.props.authToken);
      this.props.actions.unSetAuthToken();
      this.props.actions.unSetApplicationId();
      clearCryptoSession();
    }

    checkApplicationStatus() {
      const sts = this.props.application ? this.props.application.status : null;
      switch (sts) {
        case applicationStatusTypes.APPLICATION_STATUS_IN_PROGRESS:
          this.setState({ validStatus: true });
          break;
        case applicationStatusTypes.APPLICATION_STATUS_APPROVED:
          this.clearUserData();
          RouteNavigator.push('/registration');
          break;
        case applicationStatusTypes.APPLICATION_STATUS_REJECTED:
          this.clearUserData();
          RouteNavigator.push('/registration');
          break;
        case applicationStatusTypes.APPLICATION_STATUS_RETURNED: {
          const unlockedArray = this.props.application.unlockedResources.split(',');
          if (!isResourceUnlocked(unlockedArray)) {
            fillAllSteps(this.props.applicationId);
            RouteNavigator.push('/summary?status=returned');
          } else {
            this.setState({ validStatus: true });
          }
          break;
        }
        case applicationStatusTypes.APPLICATION_STATUS_SUBMITTED: {
          if (isPage('account-type')) {
            this.setState({ validStatus: true });
          } else if (!isPage('thank-you') && !isPage('finish-business-application') && !isPage('deposit-funds') && !isPage('funding')) {
            this.clearUserData();
            RouteNavigator.push('/registration');
          } else {
            this.setState({ validStatus: true });
          }
          break;
        }
        case null: {
          this.setState({ failedStatus: true });
          RouteNavigator.push('/pending-applications');
          break;
        }
        default:
          break;
      }
    }

    render() {
      return (
        <div>{this.props && this.props.isAuthenticated &&
            (this.state && (this.state.validStatus || this.state.failedStatus))
            ?
              <Component /> : <div>Loading...</div> }
        </div>
      );
    }
  }

  AuthenticatedComponent.propTypes = {
    applicationId: PropTypes.string,
    authToken: PropTypes.string,
    userId: PropTypes.number,
    isAuthenticated: PropTypes.bool.isRequired,
    application: PropTypes.shape(),
    actions: PropTypes.shape({
      fetchApplication: PropTypes.func.isRequired,
      fetchUser: PropTypes.func.isRequired,
      unSetApplicationId: PropTypes.func.isRequired,
      unSetAuthToken: PropTypes.func.isRequired,
      userLogout: PropTypes.func.isRequired,
    }).isRequired,
  };

  function mapStateToProps(state) {
    const isAuthenticated = state.authToken !== null;

    return {
      isAuthenticated,
      applicationId: state.applicationId,
      authToken: state.authToken,
      application: state.application,
      userId: state.userId,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Object.assign({},
        applicationActions,
        applicationIdActions,
        authTokenActions,
        userActions,
        pendingApplicationsActions,
      ), dispatch),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}

export default requireAuthentication;
