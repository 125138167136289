export const APPLICATION_ID_KEY = 'applicationId';
export const APPLICATION_ID_PARAM_KEY = 'application_id';
export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_TOKEN_PARAM_KEY = 'access-token';
export const USER_ID = 'user_id';
export const PROMO_CODE_PARAM_KEY = 'offer';
export const PROMO_CODE_COOKIE_NAME = '.AOP_OFFER';
export const DEST_PARAM_KEY = 'dest';
export const DEFAULT_PROMO_CODE_MAP = '00000000';
export const SALES_REP_PARAM_KEY = 'sales_rep';
export const LOGIN = 'login';
export const UTM_CAMPAIGN_COOKIE_NAME = 'utm_campaign';
export const UTM_CONTENT_COOKIE_NAME = 'utm_content';
export const UTM_MEDIUM_COOKIE_NAME = 'utm_medium';
export const UTM_OFFER_COOKIE_NAME = 'utm_offer';
export const UTM_SOURCE_COOKIE_NAME = 'utm_source';
export const UTM_TERM_COOKIE_NAME = 'utm_term';
export const CRYPTO_SESSION = 'crypto_session';
export const TRADE_DOMAIN = '.tradestation.com';
export const PROMO_CODE_SESSION = 'offer_session';
export const SALES_REP_SESSION = 'sales_rep_session';
