import * as actionTypes from './actionTypes';
import fetchEntities from './fetchEntitiesAction';
import upsertEntities from './upsertEntitiesAction';


const entityName = 'tradingprofiles';
const payloadName = 'tradingProfile';

export function fetchTradingProfile(applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.FETCH_TRADING_PROFILE_REQUEST,
    success: actionTypes.FETCH_TRADING_PROFILE_SUCCESS,
    error: actionTypes.FETCH_TRADING_PROFILE_ERROR,
  };
  return fetchEntities(entityName, payloadName, actionsTypes, applicationId, authToken);
}

export function upsertTradingProfile(tradingProfile, applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.UPSERT_TRADING_PROFILE_REQUEST,
    success: actionTypes.UPSERT_TRADING_PROFILE_SUCCESS,
    error: actionTypes.UPSERT_TRADING_PROFILE_ERROR,
  };
  return upsertEntities(tradingProfile, entityName, payloadName, actionsTypes, applicationId, authToken);
}

