import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchIraAccountRequest() {
  return {
    type: actionTypes.FETCH_IRA_ACCOUNT_REQUEST,
  };
}

export function fetchIraAccountSuccess(iraAccount) {
  return {
    type: actionTypes.FETCH_IRA_ACCOUNT_SUCCESS,
    iraAccount,
  };
}

export function fetchIraAccountError(error) {
  return {
    type: actionTypes.FETCH_IRA_ACCOUNT_ERROR,
    error,
  };
}

export function fetchIraAccount(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchIraAccountRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/iraAccountInfo`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchIraAccountSuccess(json)))
      .catch(error => dispatch(fetchIraAccountError(error)));
  };
}

export function submitIraAccountError(error) {
  return { type: actionTypes.SUBMIT_IRA_ACCOUNT_ERROR, error };
}

export function submitIraAccountRequest(iraAccount) {
  return { type: actionTypes.SUBMIT_IRA_ACCOUNT_REQUEST, iraAccount };
}

export function submitIraAccountSuccess(iraAccount) {
  return { type: actionTypes.SUBMIT_IRA_ACCOUNT_SUCCESS, iraAccount };
}

// eslint-disable-next-line no-underscore-dangle
function _submitIraAccount(iraAccount, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitIraAccountRequest(iraAccount));

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(iraAccount),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/iraAccountInfo`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitIraAccountSuccess(json)))
      .catch((error) => {
        dispatch(submitIraAccountError(error));
        return Promise.reject(error);
      });
  };
}

export function submitIraAccount(iraAccount, applicationId, authToken) {
  return _submitIraAccount(iraAccount, applicationId, authToken, 'POST');
}

export function updateIraAccount(iraAccount, applicationId, authToken) {
  return _submitIraAccount(iraAccount, applicationId, authToken, 'PUT');
}
