import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Config from '../../Config';

const config = new Config();

const LinkAccountModal = (props) => {
  const { handleClose, isShow, onBack } = props;

  const handleBack = () => {
    handleClose();
    onBack();
  };

  return (
    <Modal show={isShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <p className="mt-3 link-account-modal-title">Trouble Linking Your Account?</p>
      </Modal.Header>
      <Modal.Body>
        <p className="link-account-body">
          Although we can support real-time account
          verification and linking for many customers, some external accounts, especially
          those held outside the United States, will have to be linked offline.
        </p>
        <p className="link-account-body">
          To continue
          with funding by linking your bank account please download and submit the
          authorization from provided below. Alternatively, you can choose to fund by another method.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="row mb-4 link-account-button-container">
          <div className="col col-sm-12 col-md-12 col-lg-8 col-lg-offset-2 text-center">
            <button
              id="download-linking-form"
              className="btn inline-btn do_capitalize inline-funds-button-primary"
            >
              <a href={config.onlineTransferUrl} target="_blank" rel="noopener noreferrer" >
                Download Linking Form
              </a>
            </button>
          </div>
          <div className="col col-sm-12 col-md-12 col-lg-8 col-lg-offset-2 text-center">
            <button
              type="submit"
              id="choose-another-method"
              onClick={handleBack}
              className="btn inline-btn do_capitalize inline-funds-button-second"
            >
              Choose Another Method
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

LinkAccountModal.propTypes = {
  isShow: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default LinkAccountModal;
