import React from 'react';
import PropTypes from 'prop-types';

const ApprovalEntity = (props) => {
  const {
    startNewApplication,
    finishApplication,
  } = props;

  return (
    <div>
      <section className="flow-text">
        <div className="container">
          <div className="row">
            <div className="col col-md-12 col-lg-8 col-lg-offset-2">
              <h1 className="h1_secondary text-center">Thank you for choosing</h1>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
                <br /><img src="images/tradestation.png" alt="" className="ts-logo-thankyou-page" /><br /><br />
                <p className="p_leadForm">
                  Once you have completed the application and submitted it to&nbsp;
                  <a className="email-link" href="mailto:clientservice@tradestation.com" >
                    newaccountservices@tradestation.com
                  </a>,<br /> a representative will contact you to complete the approval process.
                </p>
              </div>
            </div>
            <div className="row pending-btn-group">
              <div className="col col-md-4 col-md-offset-2 col-lg-4 col-lg-offset-2 col-sm-12 border-intro text-center">
                <button
                  type="submit"
                  id="next_btn"
                  className="btn btn-raised btn-default btn-left do_capitalize ty-btn"
                  onClick={finishApplication}
                >
                  I&apos;m Finished
                </button>
              </div>
              <div className="col col-md-4 col-lg-4 col-sm-12 text-center">
                <button
                  type="submit"
                  id="next_btn"
                  className="btn btn-raised btn-white btn-right do_capitalize ty-btn"
                  onClick={startNewApplication}
                >
                  Add Another Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ApprovalEntity.propTypes = {
  startNewApplication: PropTypes.func.isRequired,
  finishApplication: PropTypes.func.isRequired,
};

export default ApprovalEntity;
