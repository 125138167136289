import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from '../../containers/Summary/summaryValueMapper';
import SummarySection from '../../components/Summary/SummarySection';
import * as assetClassActions from '../../actions/assetClassActions';
import * as accountTypeActions from '../../actions/accountTypeActions';
import * as assetClassTexts from '../../components/AssetClass/assetClassTexts';
import * as summaryRowTypes from '../../components/Summary/summaryRowTypes';
import {
  applyTsgoChanges,
} from '../../lib/containerHelpers/summary/summaryAccountTypeAssetFormContainerHelper';

export class SummaryAssetContainer extends React.Component {
  componentDidMount() {
    if (!this.props.assetClass) {
      this.props.actions.fetchAssetClass(this.props.applicationId, this.props.authToken);
    }

    if (!this.props.accountType) {
      this.props.actions.fetchAccountType(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryAssetContainer.propTypes = {
  actions: PropTypes.shape({
    fetchAssetClass: PropTypes.func,
    fetchAccountType: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  assetClass: PropTypes.shape(),
  accountType: PropTypes.shape(),
};

const getAssetClass = (assetClasses, assetClassCode) => {
  return summaryValueMapper.getTextFromValue(assetClasses, assetClassCode);
};

const joinArray = (array, texts) => {
  if (array) {
    return array.map((value) => { return summaryValueMapper.getTextFromValue(texts, value); }).join('||');
  }

  return array;
};

function mapStateToInitialValues(state) {
  let isLoading = true;

  if (state.assetClass) {
    isLoading = false;
  }

  const assetClass = state.assetClass;
  const assetTypes = state.accountType ? state.accountType.assetTypes : [];
  const numberOfAccounts = assetTypes.length;

  let assetInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_BOX,
      key: 'ASSET TYPE',
      value: assetTypes ? joinArray(assetTypes, assetClassTexts.assetClassTexts) : '',
    },
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'NUMBER OF ACCOUNTS',
      value: numberOfAccounts,
    },
  ];

  if (assetTypes.indexOf('equities') >= 0) {
    assetInformation = assetInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'EQUITY ACCOUNT CLASS',
        value: assetClass && assetClass.marginAccount ?
          getAssetClass(assetClassTexts.assetClassTexts, 'margin') :
          getAssetClass(assetClassTexts.assetClassTexts, 'cash'),
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'EQUITY COMMISSION SCHEDULE',
        value: assetClass ? getAssetClass(assetClassTexts.assetClassTexts, assetClass.commissionSchedule) : '',
      },
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'TRADE STOCK OPTIONS',
        value: assetClass && assetClass.tradeStockOptions ? 'Yes' : 'No',
      },
    ]);

    if (assetClass && assetClass.tradeStockOptions) {
      assetInformation = assetInformation.concat([
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_BOX,
          key: 'INVESTMENT RISK TOLERANCE',
          value: assetClass ? assetClassTexts.investmentRiskTolerance(assetClass.optionsInvestmentGoals) : '',
        },
        {
          keyType: summaryRowTypes.SUMMARY_ROW_TXT,
          valueType: summaryRowTypes.SUMMARY_ROW_BOX,
          key: 'STOCK OPTIONS TRADING LEVEL',
          value: assetClass ? assetClassTexts.stockOptionsTradingLevel(assetClass.optionsStrategies) : '',
        },
      ]);
    }

    assetInformation = assetInformation.concat([
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: 'FULLY PAID LENDING PROGRAM',
        value: assetClass && assetClass.fullyPaidLendingProgram ? 'Yes' : 'No',
      },
    ]);
  }

  assetInformation = applyTsgoChanges(state, assetInformation);

  return {
    isLoading,
    key: 'assetClass',
    value: assetInformation,
    title: 'ASSET TYPE',
    editLink: '/account-type',
  };
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, assetClassActions, accountTypeActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAssetContainer);
