import moment from 'moment';
import * as identificationTypes from '../../../components/PersonalInfo/Identity/identificationTypes';

const isValidSSN = (value) => {
  return /^(?!666|000|9\d{2})\d{3}[- ](?!00)\d{2}[- ](?!0{4})\d{4}$/.test(value);
};

const isValidITIN = (value) => {
  return /^(9\d{2})-(5\d|6[0-5]|7\d|8[0-8]|9[0-2]|9[4-9])-(\d{4})$/.test(value);
};

const validateIdentificationSSN = (countryOfResidence, countryOfCitizenship, identificationSSN) => {
  const isUSResidence = countryOfResidence === 'US';
  const isUSCitizen = countryOfCitizenship === 'US';
  let isValid = true;
  let validITIN = false;

  if (isUSResidence && !isUSCitizen) {
    validITIN = isValidITIN(identificationSSN);
  }

  if (isUSResidence) {
    isValid = validITIN || isValidSSN(identificationSSN);
  } else if (isUSCitizen) {
    isValid = isValidSSN(identificationSSN);
  }

  return isValid;
};

export const validateSSN = (identificationSSN, key, values) => {
  let result = null;

  if (!identificationSSN || identificationSSN.trim().length < 1) {
    if (values.countryOfCitizenship === 'US' || values.countryOfResidence === 'US') {
      result = 'Required';
    }
  } else {
    const isValid = validateIdentificationSSN(
      values.countryOfResidence,
      values.countryOfCitizenship,
      identificationSSN);
    if (!isValid) result = 'Not a valid SSN or tax ID';
  }

  return result;
};

export const validateIDNumber = (value) => {
  if (!value || value.trim().length < 1) {
    return 'Required';
  }
  const pattern = /^[a-zA-Z0-9-]/g;
  if (pattern.test(value.trim())) {
    return null;
  }
  return 'Identification number must contain only letters and numbers.';
};

export const validateIdentificationRegion = (value, key, attributes) => {
  if (attributes.identificationType === identificationTypes.DRIVERS_LICENSE) {
    if (!value || value.trim().length < 1) {
      return 'Required';
    }
  }

  return null;
};

export const validateBirthDate = (year, month, day) => {
  const birthDate = moment([year, month - 1, day]);
  if (!birthDate.isValid()) return 'Not a valid date';

  return (birthDate.isSameOrAfter(moment().subtract(21, 'years')))
    ? 'You must be 21 years of age or older'
    : null;
};

export const validateExpirationDate = (year, month, day) => {
  const expirationDate = moment([year, month - 1, day]);
  if (!expirationDate.isValid()) return 'Not a valid date';

  return (expirationDate.isSameOrBefore(moment()))
    ? 'Expiration date must be greater than today'
    : null;
};

export default function validate(values) {
  const errors = [];

  if (values.identities && values.identities.length > 0) {
    values.identities.forEach((i, ix) => {
      const identityErrors = {};

      if (!i.countryOfCitizenship) {
        identityErrors.countryOfCitizenship = 'Required';
        errors[ix] = identityErrors;
      }

      if (i.birthMonth && i.birthDay && i.birthYear) {
        const birthMonthError = validateBirthDate(
          i.birthYear,
          i.birthMonth,
          i.birthDay,
        );
        if (birthMonthError) {
          identityErrors.birthMonth = birthMonthError;
          errors[ix] = identityErrors;
        }
      } else {
        if (!i.birthMonth) {
          identityErrors.birthMonth = 'Required';
          errors[ix] = identityErrors;
        }
        if (!i.birthDay) {
          identityErrors.birthDay = 'Required';
          errors[ix] = identityErrors;
        }
        if (!i.birthYear) {
          identityErrors.birthYear = 'Required';
          errors[ix] = identityErrors;
        }
      }

      if (!i.identificationSSN.includes('*')) {
        const identificationSSNError = validateSSN(i.identificationSSN, 'identificationSSN', i);
        if (identificationSSNError) {
          identityErrors.identificationSSN = identificationSSNError;
          errors[ix] = identityErrors;
        }
      }

      if (!i.identificationType) {
        identityErrors.identificationType = 'Required';
        errors[ix] = identityErrors;
      }

      const idNumberError = validateIDNumber(i.identificationNumber);
      if (!i.identificationNumber) {
        identityErrors.identificationNumber = 'Required';
        errors[ix] = identityErrors;
      } else if (idNumberError !== null) {
        identityErrors.identificationNumber = idNumberError;
        errors[ix] = identityErrors;
      } else if (i.identificationNumber.length > 25 || i.identificationNumber.length < 2) {
        identityErrors.identificationNumber = 'Minimum length is 2, maximum 25';
        errors[ix] = identityErrors;
      }

      const identificationRegionError = validateIdentificationRegion(
        i.identificationRegion, 'identificationRegion', values,
      );
      if (identificationRegionError) {
        identityErrors.identificationRegion = identificationRegionError;
        errors[ix] = identityErrors;
      }

      if (i.expirationMonth && i.expirationDay && i.expirationYear) {
        const expirationMonthError = validateExpirationDate(
          i.expirationYear,
          i.expirationMonth,
          i.expirationDay,
        );
        if (expirationMonthError) {
          identityErrors.expirationMonth = expirationMonthError;
          errors[ix] = identityErrors;
        }
      } else {
        if (!i.expirationMonth) {
          identityErrors.expirationMonth = 'Required';
          errors[ix] = identityErrors;
        }
        if (!i.expirationDay) {
          identityErrors.expirationDay = 'Required';
          errors[ix] = identityErrors;
        }
        if (!i.expirationYear) {
          identityErrors.expirationYear = 'Required';
          errors[ix] = identityErrors;
        }
      }
    });

    if (values.identities.length > 1 && values.identities[0].identificationSSN) {
      if (values.identities[0].identificationSSN === values.identities[1].identificationSSN) {
        errors[1].identificationSSN = 'Primary and Joint SSN/tax ID must not match';
      }
    }
  }

  return errors;
}
