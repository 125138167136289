import React from 'react';
import PropTypes from 'prop-types';

const ProgressBarUpload = (props) => {
  return (
    <div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          id="progressbarupload"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: props.progress.toString().concat('%') }}
        >
          <span className="sr-only" />
        </div>
      </div>
    </div>
  );
};

ProgressBarUpload.propTypes = {
  progress: PropTypes.number,
};


export default ProgressBarUpload;
