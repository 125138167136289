import React from 'react';

const JointContactHeading = () => {
  return (
    <div >
      <h1 className="h2_subHeading">Joint Account Holder</h1>
    </div>
  );
};

export default JointContactHeading;
