import * as actionTypes from './actionTypes';
import fetchEntities from './fetchEntitiesAction';
import upsertEntities from './upsertEntitiesAction';


const entityName = 'identities';
const payloadName = 'identity';

export function fetchIdentity(applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.FETCH_IDENTITY_REQUEST,
    success: actionTypes.FETCH_IDENTITY_SUCCESS,
    error: actionTypes.FETCH_IDENTITY_ERROR,
  };
  return fetchEntities(entityName, payloadName, actionsTypes, applicationId, authToken);
}

export function upsertIdentity(identity, applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.UPSERT_IDENTITY_REQUEST,
    success: actionTypes.UPSERT_IDENTITY_SUCCESS,
    error: actionTypes.UPSERT_IDENTITY_ERROR,
  };
  return upsertEntities(identity, entityName, payloadName, actionsTypes, applicationId, authToken);
}

