import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import SummaryInformation from '../components/Summary/SummaryInformation';
import * as RouteNavigator from './RouteNavigator';
import * as registrationActions from '../actions/registrationActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as assetClassActions from '../actions/assetClassActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as tradingProfileActions from '../actions/tradingProfileActions';
import * as applicationActions from '../actions/applicationActions';
import * as applicationErrorsActions from '../actions/applicationErrorsActions';
import * as contactActions from '../actions/contactActions';
import * as documentsActions from '../actions/documentsActions';
import * as documentsRequiredActions from '../actions/documentsRequiredActions';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as accountTypes from '../components/AccountType/accountTypes';
import optimizeHelper from '../lib/optimizeHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';

export class SummaryFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      application,
      contact,
      registration,
      accountType,
      assetClass,
      identity,
      address,
      tradingProfile,
    } = this.props;

    if (!application) actions.fetchApplication(applicationId, authToken);
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!registration || !accountType || !assetClass || !identity || !address || !tradingProfile) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
        actions.fetchTradingProfile(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.SUMMARY.name,
          { applicationId, authToken, registration, accountType, assetClass, address, identity, tradingProfile },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.SUMMARY.name,
        {
          applicationId,
          authToken,
          registration,
          accountType,
          assetClass,
          address,
          identity,
          tradingProfile,
        },
      );
    }

    actions.fetchApplicationErrors(applicationId, authToken);
    optimizeHelper.notify();
  }

  handleNext() {
    this.props.handleLoaderFlag(true);
    browserHistory.push('/agreements');
  }

  // eslint-disable-next-line
  handleBack() {
    if (this.props.isDocumentUploadRequired) {
      RouteNavigator.push('/documents');
    } else {
      RouteNavigator.push('/additional-info');
    }
  }

  render() {
    return (
      <SummaryInformation {...this.props} handleBack={this.handleBack} handleNext={this.handleNext} />
    );
  }
}

SummaryFormContainer.propTypes = {
  actions: PropTypes.shape({
    submitDocumentFile: PropTypes.func.isRequired,
    fetchApplication: PropTypes.func,
    fetchApplicationErrors: PropTypes.func,
    fetchRegistration: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchTradingProfile: PropTypes.func.isRequired,
  }).isRequired,
  application: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  handleLoaderFlag: PropTypes.func.isRequired,
  isDocumentUploadRequired: PropTypes.bool.isRequired,
  registration: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  identity: PropTypes.shape(),
  address: PropTypes.shape(),
  tradingProfile: PropTypes.shape(),
};

function formatContactName(contact) {
  const { firstName, middleInitial, lastName } = contact;
  return (middleInitial)
    ? `${firstName} ${middleInitial} ${lastName}`
    : `${firstName} ${lastName}`;
}

function mapContact(contact) {
  return {
    id: contact ? contact.id : null,
    sort: contact && contact.type === 'primary' ? 0 : 1,
    type: contact ? contact.type : 'primary',
    firstName: contact ? contact.firstName : '',
    middleInitial: contact ? contact.middleInitial : '',
    lastName: contact ? contact.lastName : '',
    email: contact ? contact.email : '',
    phone: contact ? contact.phone : '',
  };
}

export function mapStateToInitialValues(state) {
  const dbApplicationErrors = (state && state.applicationErrors) ? state.applicationErrors : null;
  const dbAccountType = (state && state.accountType) ? state.accountType : null;
  const dbContacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;

  const isJointApplication = !!(dbAccountType && dbAccountType.accountType.indexOf('joint') === 0);
  const isEntityApplication = !!(dbAccountType && dbAccountType.accountType.indexOf('entity') === 0);
  const isIRAApplication = !!(dbAccountType && dbAccountType.accountType.indexOf('ira') === 0);

  const contacts = [];
  if (dbContacts && dbContacts.length > 0) {
    dbContacts.forEach((contact) => {
      contacts.push(mapContact(contact));
    });
  }

  contacts.sort((a, b) => {
    return a.sort - b.sort;
  });

  let haveErrors = false;
  if (dbApplicationErrors && Object.keys(dbApplicationErrors).length > 0) {
    haveErrors = true;
  }

  return { isJointApplication, isEntityApplication, isIRAApplication, contacts, haveErrors };
}

function mapStateToProps(state) {
  const contactName = [];
  const selector = formValueSelector('summary');
  const contacts = selector(state, 'contacts');
  let isDocumentUploadRequired = false;
  let isIraApplication = false;
  let isEquitiesApplication = false;
  const iraAccountTypes = [
    accountTypes.IRA_SEP,
    accountTypes.IRA_SIMPLE,
    accountTypes.IRA_ROTH,
    accountTypes.IRA_ROTH_INHERITED,
    accountTypes.IRA_TRADITIONAL,
    accountTypes.IRA_TRADITIONAL_INHERITED,
  ];

  if (state.accountType) {
    if (iraAccountTypes.includes(state.accountType.accountType)) {
      isIraApplication = true;
    }

    if (state.accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES)) {
      isEquitiesApplication = true;
    }
  }

  if (contacts && contacts.length > 0) {
    for (let i = 0; i < contacts.length; i += i + 1) {
      contactName[i] = formatContactName(contacts[i]);
    }
  }

  if (state.documentsRequired) {
    for (let i = 0; i < state.documentsRequired.length; i += 1) {
      if (state.documentsRequired[i].requiredDocsUpload.length > 0) {
        isDocumentUploadRequired = true;
        if (state.documentsRequired[i].requiredDocsUpload.length === 1
          && isIraApplication
          && !isEquitiesApplication) isDocumentUploadRequired = false;
      }
    }
  }
  const initialValues = mapStateToInitialValues(state);

  return {
    application: state.application,
    applicationId: state.applicationId,
    authToken: state.authToken,
    contactName,
    initialValues,
    isEntityApplication: initialValues.isEntityApplication,
    isJointApplication: initialValues.isJointApplication,
    haveErrors: initialValues.haveErrors,
    isIRAApplication: initialValues.isIRAApplication,
    isDocumentUploadRequired,
    registration: state.registration,
    accountType: state.accountType,
    assetClass: state.assetClass,
    identity: state.identity,
    address: state.address,
    tradingProfile: state.tradingProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      accountTypeActions,
      applicationActions,
      applicationErrorsActions,
      contactActions,
      documentsActions,
      documentsRequiredActions,
      registrationActions,
      assetClassActions,
      identityActions,
      addressActions,
      tradingProfileActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'summary',
})(SummaryFormContainer));
