export const normalizeSSN = (value, previousValue) => {
  if (!value) {
    return value;
  }
  if (value.includes('*')) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 3) {
      return `${onlyNums}-`;
    }
    if (onlyNums.length === 5) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }

  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 9)}`;
};

export const normalizeIDNumber = (value, previousValue) => {
  if (!value) {
    return previousValue;
  }
  if (value.includes('*')) {
    return value;
  }
  const sanitizedIdNumber = value.replace(/[^\w -]|_/g, '');
  if (sanitizedIdNumber.slice(0, 1) === '-') {
    return `${sanitizedIdNumber.slice(1, sanitizedIdNumber.length)}`;
  }
  return `${sanitizedIdNumber}`;
};

export const onMaskingElement = (value, isSSN) => {
  if (value.includes('*')) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (isSSN) {
    if (!onlyNums || onlyNums.length < 9) {
      return value;
    }
  } else if (!value || value.length < 4) {
    return value;
  }

  let result = '';
  if (isSSN) {
    result = '***-**-'.concat(onlyNums.slice(onlyNums.length - 4));
  } else {
    result = '*'.repeat(value.length - 4).concat(value.slice(value.length - 4));
  }
  return result;
};

export function onMaskingSSN(value) {
  return onMaskingElement(value, true);
}

export function onMaskingIDNumber(value) {
  return onMaskingElement(value, false);
}
