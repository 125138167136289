import * as actionTypes from './actionTypes';
import fetchEntities from './fetchEntitiesAction';
import upsertEntities from './upsertEntitiesAction';

const entityName = 'addresses';
const payloadName = 'address';

export function fetchAddress(applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.FETCH_ADDRESS_REQUEST,
    success: actionTypes.FETCH_ADDRESS_SUCCESS,
    error: actionTypes.FETCH_ADDRESS_ERROR,
  };
  return fetchEntities(entityName, payloadName, actionsTypes, applicationId, authToken);
}

export function upsertAddress(identity, applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.UPSERT_ADDRESS_REQUEST,
    success: actionTypes.UPSERT_ADDRESS_SUCCESS,
    error: actionTypes.UPSERT_ADDRESS_ERROR,
  };
  return upsertEntities(identity, entityName, payloadName, actionsTypes, applicationId, authToken);
}
