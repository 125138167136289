import React from 'react';
import FinancialFormContainer from '../../containers/FinancialFormContainer';
import ProgressBar2 from '../ProgressBar2';

const FinancialPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appFinancial" className="col col-md-12 col-lg-8 col-lg-offset-2 no-side-padding">
            <FinancialFormContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinancialPage;
