import moment from 'moment';
import * as identificationTypes from '../../../components/PersonalInfo/Identity/identificationTypes';

const constructDate = (year, month, day) => {
  return moment([year, month - 1, day]);
};

export const mapValuesToIdentity = (identity) => {
  const i = {};

  i.id = identity.id || null;
  i.contactId = identity.contact.id || null;
  i.countryOfCitizenship = identity.countryOfCitizenship;
  i.identificationSSN = (identity.countryOfCitizenship === 'US' || identity.countryOfResidence === 'US')
    ? identity.identificationSSN
    : '';
  i.identificationType = identity.identificationType;
  i.identificationNumber = identity.identificationNumber;
  i.identificationRegion = identity.identificationType === identificationTypes.DRIVERS_LICENSE
    ? identity.identificationRegion
    : null;
  i.birthDate = `${identity.birthYear}-${identity.birthMonth}-${identity.birthDay}`;
  i.identificationExpirationDate = constructDate(
    identity.expirationYear,
    identity.expirationMonth,
    identity.expirationDay,
  );

  return i;
};

export function map(values) {
  const identities = [];
  values.identities.forEach((i) => {
    identities.push(mapValuesToIdentity(i));
  });

  return identities;
}
