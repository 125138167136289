import React from 'react';

const DocumentsInstructionsModal = () => {
  return (
    <div
      className="modal fade"
      id="flipFlop"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div id="masterhead">
              <div className="slideshow">
                <div
                  id="slideshow"
                  className="carousel slide"
                  data-ride="carousel"
                  data-wrap="false"
                  data-interval="false"
                >
                  <ol className="carousel-indicators docCarouselDotsList">
                    <li data-target="#slideshow" data-slide-to="0" className="active docCarouselDots" />
                    <li data-target="#slideshow" data-slide-to="1" className="docCarouselDots" />
                    <li data-target="#slideshow" data-slide-to="2" className="docCarouselDots" />
                    <li data-target="#slideshow" data-slide-to="3" className="docCarouselDots" />
                    <li data-target="#slideshow" data-slide-to="4" className="docCarouselDots" />
                    <li data-target="#slideshow" data-slide-to="5" className="docCarouselDots" />
                    <li data-target="#slideshow" data-slide-to="6" className="docCarouselDots" />
                  </ol>
                  <button type="button" className="close docModalCloseButton" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" >&times;</span>
                  </button>
                  <div className="carousel-inner">
                    <div className="item active">
                      <h3 className="docModalH3Style">Proof of Identity Upload Instructions</h3>
                      <p className="docModalParagraph docModalParagraphFirstSlide">
                        For best results we recommend taking a photo of your ID with your smartphone.
                        Doing so will help ensure your image meets the minimum image quality requirements.</p>
                      <img className="img-responsive docModalImg1" src="images/docModal1.png" alt="First Slide" />
                    </div>
                    <div className="item">
                      <h3 className="docModalH3Style">Place Your ID on a Dark Surface</h3>
                      <p className="docModalParagraph">
                        Photograph your ID against a dark surface to ensure the boundaries
                        of the ID are clearly distinguishable.
                      </p>
                      <img className="img-responsive docModalImg" src="images/docModal2.png" alt="Second Slide" />
                      <p className="text-center text-danger docModalBottomText">
                        <img src="images/error-icon.png" className="docModalBottomIcon" alt="warning icon" />
                        Background is too light
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="docModalH3Style">Center Your ID in the Photograph</h3>
                      <p className="docModalParagraph">
                        Your entire ID must be visible in the photo with all four edges completely shown.
                      </p>
                      <img className="img-responsive docModalImg" src="images/docModal3.png" alt="Third slide" />
                      <p className="text-center text-danger docModalBottomText">
                        <img src="images/error-icon.png" className="docModalBottomIcon" alt="warning icon" />
                        ID is cropped in the photo
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="docModalH3Style">Focus Your Camera</h3>
                      <p className="docModalParagraph">
                        The image of your ID should be sharp and clear, so that all of the text on the ID
                        can be easily read.
                      </p>
                      <img className="img-responsive docModalImg" src="images/docModal4.png" alt="Fourth slide" />
                      <p className="text-center text-danger docModalBottomText">
                        <img src="images/error-icon.png" className="docModalBottomIcon" alt="warning icon" />
                        Image is too blurry
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="docModalH3Style">Take a Full Color Photograph</h3>
                      <p className="docModalParagraph">
                        Do not use a special camera mode or apply special effects to the photograph
                        that alter the color.
                      </p>
                      <img className="img-responsive docModalImg" src="images/docModal5.png" alt="Fifth slide" />
                      <p className="text-center text-danger docModalBottomText">
                        <img src="images/error-icon.png" className="docModalBottomIcon" alt="warning icon" />
                        Image is black and white
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="docModalH3Style">Upload with Confidence</h3>
                      <p className="docModalParagraph">
                        When you have captured a photograph of your ID correctly the photo should
                        look something like the image below.
                      </p>
                      <img className="img-responsive docModalImg" src="images/docModal6.png" alt="Sixth slide" />
                      <p className="text-center text-success docModalBottomText">
                        <img src="images/checkmark-icon.png" className="docModalBottomSuccessIcon" alt="success icon" />
                        This image is ideal
                      </p>
                    </div>
                    <div className="item">
                      <h3 className="docModalH3Style">Does Your ID Have Two Sides?</h3>
                      <p className="docModalParagraph">
                        If your ID has two sides you are required to upload an image of the front and back of the ID.
                      </p>
                      <img className="img-responsive docModalImg" src="images/docModal7.png" alt="Seventh slide" />
                      <p className="text-center text-success docModalBottomText">
                        <img src="images/checkmark-icon.png" className="docModalBottomSuccessIcon" alt="success icon" />
                        Upload the back of the ID
                      </p>
                    </div>
                  </div>
                  <div className="controlsBlock">
                    <div className="controls">
                      <a className="left carousel-control docModalLeftArrow" href="#slideshow" data-slide="prev">
                        <span className="glyphicon glyphicon-chevron-left docModalArrowHover" />
                      </a>
                      <a className="right carousel-control docModalRightArrow" href="#slideshow" data-slide="next">
                        <span className="glyphicon glyphicon-chevron-right docModalArrowHover" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsInstructionsModal;
