import * as actionTypes from '../actions/actionTypes';
import * as storageKeys from '../actions/storageKeys';

const initialState = window.sessionStorage ? window.sessionStorage.getItem(storageKeys.APPLICATION_ID_KEY) : null;

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.START_APPLICATION_SUCCESS:
    case actionTypes.SET_APPLICATION_ID: {
      sessionStorage.setItem(storageKeys.APPLICATION_ID_KEY, action.applicationId);
      return action.applicationId;
    }
    case actionTypes.UNSET_APPLICATION_ID: {
      sessionStorage.removeItem(storageKeys.APPLICATION_ID_KEY);
      return null;
    }
    default:
      return state;
  }
}
