import * as actionTypes from './actionTypes';
import Config from '../Config';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { attachRecaptchaToken } from './attachRecaptchaToken';
import { setAuthToken } from './authTokenActions';

const config = new Config();

export function checkUsernameAvailabilityError(error) {
  return { type: actionTypes.CHECK_USERNAME_AVAILABILITY_ERROR, error };
}

export function checkUsernameAvailabilityRequest(applicationId, username) {
  return { type: actionTypes.CHECK_USERNAME_AVAILABILITY_REQUEST, applicationId, username };
}

export function checkUsernameAvailabilitySuccess(isUsernameAvailable) {
  return { type: actionTypes.CHECK_USERNAME_AVAILABILITY_SUCCESS, isUsernameAvailable };
}

export function checkUsernameAvailability(applicationId, username) {
  const data = { id: username };
  return (dispatch) => {
    dispatch(checkUsernameAvailabilityRequest(username));
    return attachRecaptchaToken(data, 'username')
      .then((requestData) => {
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
          mode: 'cors',
        };
        return fetch(`${config.apiUrl}/applications/username`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        const isUsernameAvailable = !json.userExists;
        dispatch(checkUsernameAvailabilitySuccess(isUsernameAvailable));

        if (!isUsernameAvailable) {
          return Promise.reject({ username: 'Username already exists' });
        }

        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(checkUsernameAvailabilityError(error));

        return Promise.reject(error);
      });
  };
}

export function checkAccountAvailabilityError(error) {
  return { type: actionTypes.CHECK_ACCOUNT_AVAILABILITY_ERROR, error };
}

export function checkAccountAvailabilityRequest(userInfo) {
  return { type: actionTypes.CHECK_ACCOUNT_AVAILABILITY_REQUEST, userInfo };
}

export function checkAccountAvailabilitySuccess(isAccountAvailable) {
  return { type: actionTypes.CHECK_ACCOUNT_AVAILABILITY_SUCCESS, isAccountAvailable };
}

export function checkAccountAvailability(userInfo) {
  const data = { userInfo };
  return (dispatch) => {
    dispatch(checkAccountAvailabilityRequest(userInfo));
    return attachRecaptchaToken(data, 'isAccountAvailable')
      .then((requestData) => {
        const options = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
          mode: 'cors',
        };
        return fetch(`${config.apiUrl}/applications/isAccountAvailable`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        const isAccountAvailable = json.isAccountAvailable;
        dispatch(checkAccountAvailabilitySuccess(isAccountAvailable));

        return Promise.resolve(isAccountAvailable);
      })
      .catch((error) => {
        dispatch(checkAccountAvailabilityError(error));

        return Promise.reject(error);
      });
  };
}

export function submitSecurityCredentialsError(error) {
  return { type: actionTypes.SUBMIT_SECURITY_CREDENTIALS_ERROR, error };
}

export function submitSecurityCredentialsRequest(securityCredentials) {
  return { type: actionTypes.SUBMIT_SECURITY_CREDENTIALS_REQUEST, securityCredentials };
}

export function submitSecurityCredentialsSuccess(registration) {
  return { type: actionTypes.SUBMIT_SECURITY_CREDENTIALS_SUCCESS, registration };
}

export function purgeSecurityCredentials() {
  return { type: actionTypes.PURGE_SECURITY_CREDENTIALS };
}

export function submitSecurityCredentials(securityCredentials, applicationId) {
  return (dispatch) => {
    dispatch(submitSecurityCredentialsRequest(securityCredentials));

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(securityCredentials),
      mode: 'cors',
      credentials: 'include',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/credentials`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => {
        dispatch(submitSecurityCredentialsSuccess(json.registration));
        dispatch(purgeSecurityCredentials());
        dispatch(setAuthToken(json.token.id));
      })
      .catch((error) => {
        dispatch(submitSecurityCredentialsError(error));
        return Promise.reject(error);
      });
  };
}
