import React from 'react';
import PropTypes from 'prop-types';
import TextBox from '../TextBox';

const AgreementsSignature = ({ isJoint }) => {
  const heading = `${isJoint ? 'Joint' : 'Primary'} Account Holder`;
  const prefix = `${isJoint ? 'joint' : 'primary'}`;

  return (
    <div>
      <div className="col col-md-6">
        <h4>{heading}</h4>
        <TextBox
          id={`${prefix}AccountOwner`}
          name={`${prefix}AccountOwner`}
          className="form-control"
          label="Account Owner"
          readOnly
        />
        <TextBox
          id={`${prefix}AccountOwnerSignatureDate`}
          name={`${prefix}AccountOwnerSignatureDate`}
          className="form-control"
          label="Date: XX / XX / XXXX"
          readOnly
        />
      </div>
    </div>
  );
};

AgreementsSignature.propTypes = {
  isJoint: PropTypes.bool.isRequired,
};

export default AgreementsSignature;
