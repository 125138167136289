import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENTS_SUCCESS:
      return action.documents;
    case actionTypes.SUBMIT_DOCUMENTS_SUCCESS:
      return action.documents;
    default:
      return state;
  }
}
