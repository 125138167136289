import validatePhone from '../validations/PhoneValidation';
import validateEmail from '../validations/EmailValidation';

function validateContact(jointContact, primaryContact, values, props) {
  const errors = {};

  if (values.accountType === 'joint') {
    if (primaryContact.email && jointContact.email) {
      if (primaryContact.email.toLowerCase() === jointContact.email.toLowerCase()) {
        errors.email = 'Please use a different email address.';
      }
    } else {
      errors.email = 'Required';
    }
  }

  if (!jointContact.email) {
    errors.email = 'Required';
  } else if (!validateEmail(jointContact.email)) {
    errors.email = 'Not a valid email address';
  }

  if (!jointContact.firstName) {
    errors.firstName = 'Required';
  } else {
    const firstName = jointContact.firstName.trim();
    if (!/^[a-zA-Z][a-zA-Z0-9ñáéíóúü.'\s-]{1,30}$/i.test(firstName)) {
      errors.firstName = 'Not a valid first name';
    }
  }

  if (!jointContact.lastName) {
    errors.lastName = 'Required';
  } else {
    const lastName = jointContact.lastName.trim();
    if (!/^[a-zA-Z][a-zA-Z0-9ñáéíóúü.'\s-]{1,30}$/i.test(lastName)) {
      errors.lastName = 'Not a valid last name';
    }
  }

  if (!jointContact.phone) {
    errors.phone = 'Required';
  } else {
    errors.phone = validatePhone(jointContact.phone, jointContact.countryOfResidence);
  }

  if (jointContact.middleInitial) {
    if (!/^[a-zA-Z]?$/i.test(jointContact.middleInitial)) {
      errors.middleInitial = 'Not a valid initial';
    }
  }

  if (jointContact.countryOfResidence) {
    const country = props.countries.find(c => c.countryCode === jointContact.countryOfResidence);
    if (country.regions && country.regions.length > 0) {
      if (!jointContact.regionOfResidence) {
        errors.regionOfResidence = 'Required';
      }
    }
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  return null;
}

const validate = (values, props) => {
  const errors = {};
  if (!values.assetTypeEquities && !values.assetTypeFutures && !values.assetTypeFuturesOptions) {
    errors.assetTypeError = 'Selection required';
    if (!values.assetTypeEquities) {
      errors.assetTypeEquities = 'Selection required';
    }

    if (!values.assetTypeFutures) {
      errors.assetTypeFutures = 'Selection required';
    }

    if (!values.assetTypeFuturesOptions) {
      errors.assetTypeFuturesOptions = 'Selection required';
    }
  }

  if (values.accountType === 'joint') {
    if (values.jointContact) {
      const error = validateContact(values.jointContact, values.primaryContact, values, props);
      if (error) {
        errors.jointContact = error;
      }
    }
  }

  if (values.assetTypeEquities) {
    if (!values.commissionSchedule) {
      errors.commissionSchedule = 'Required';
    }

    if (!values.fullyPaidLendingProgram) {
      errors.fullyPaidLendingProgram = 'Required';
    }

    if (values.tradeStockOptions) {
      if (!values.optionsStraddles &&
        !values.optionsSellingUncoveredCalls &&
        !values.optionsSellingUncoveredPuts &&
        !values.optionsCallAndPutSpreads &&
        !values.optionsCoveredPuts &&
        !values.optionsBuyingCallsAndPuts &&
        !values.optionsSellingCoveredCalls &&
        !values.optionsProtectivePuts) {
        errors.optionsCovered = 'Required';
        errors.optionsStrategiesError = 'Selection required';
      }

      if (!values.investmentObjectiveGrowth &&
        !values.investmentObjectiveIncome &&
        !values.investmentObjectiveSpeculation) {
        errors.investmentObjectiveGrowth = 'Required';
        errors.investmentObjectiveError = 'Selection required';
      }
    }
  }

  return errors;
};

export default validate;
