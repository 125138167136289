import React from 'react';

const EmploymentFormHeader = () => {
  return (
    <div className="row">
      <div className="col col-md-12">
        <h1 className="h1_secondary text-center">Employment Information</h1>
      </div>
    </div>
  );
};

export default EmploymentFormHeader;
