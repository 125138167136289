import React from 'react';
import PropTypes from 'prop-types';
import InlineFundingNavigation from '../InlineFundingNavigation';
import InlineFundingNavigationTransition from '../InlineFundingNavigationTransition';
import Config from '../../Config';

const config = new Config();

const TransferService = (props) => {
  const { onBack } = props;
  const {
    ofxTransferServiceUrl,
    currencyFairTransferServiceUrl,
    stcPayTransferServiceUrl,
    payoneerTransferServiceUrl,
    niumTransferServiceUrl,
  } = config;

  const handleTransferButtonClicked = () => {
    window.location = `${config.getStartedBaseUrl}/funding`;
  };
  const handleLetsGoButtonClicked = () => {
    window.location = `${config.hubBaseUrl}`;
  };

  return (
    <div className="transfer-container">
      <div className="col col-sm-12 col-md-12 col-md-offset-2 col-lg-8 col-lg-offset-2 text-center">
        <h3 className="transfer-title">3rd Party Transfer Services</h3>
        <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <p className="transfer-text transfer-title-text pb-7" >
            The Following 3rd party transfer services are supported for transferring funds
            from a bank outside of the United States.
          </p>
        </div>
        <br />
        <div className="col col-xs-4 mb-4">
          <a href={payoneerTransferServiceUrl} target="_blank" rel="noopener noreferrer">
            <img src="images/payoneer-logo.png" alt="Payoneer Logo" className="logo-img" /> <br />
            <span className="transfer-text">Payoneer</span>
          </a>
        </div>
        <div className="col col-xs-4 mb-4">
          <a href={ofxTransferServiceUrl} target="_blank" rel="noopener noreferrer">
            <img src="images/ofx-logo.png" alt="OFXLogo" className="logo-img" /> <br />
            <span className="transfer-text">OFX</span>
          </a>
        </div>
        <div className="col col-xs-4 mb-4">
          <a href={currencyFairTransferServiceUrl} target="_blank" rel="noopener noreferrer">
            <img src="images/currencyfair-logo.png" alt="Currency Fair Logo" className="logo-img" /> <br />
            <span className="transfer-text">CurrencyFair</span>
          </a>
        </div>
        <div className="col col-xs-offset-2 col-xs-4 mb-4">
          <a href={stcPayTransferServiceUrl} target="_blank" rel="noopener noreferrer">
            <img src="images/stcpay-logo.png" alt="STC Pay Logo" className="logo-img" /> <br />
            <span className="transfer-text">STC Pay</span>
          </a>
        </div>
        <div className="col col-xs-4 mb-4">
          <a href={niumTransferServiceUrl} target="_blank" rel="noopener noreferrer">
            <img src="images/nium-logo.png" alt="Nium Logo" className="logo-img" />
            <br />
            <span className="transfer-text">Nium</span>
          </a>
        </div>
        <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5 pb-7">
          <p className="transfer-text transfer-disclaimer-text">
            <strong>Important:</strong> TradeStation does not endorse or recommend any 3rd party
            transfer service. Proof of external account ownership may still be required before
            funds can be accepted. Exchange rates and fees are typically charged by these vendors,
            and each vendor serves different geographic communities. Please carefully review information
            provided by the vendor before attempting to initiate a transfer.
          </p>
        </div>

      </div>
      <>
        {(config.inlineFundingTransition) && (
          <div className="row">
            <InlineFundingNavigationTransition
              assistenceText="Ready to get started with your new account?"
              onNewTransferButtonClicked={handleTransferButtonClicked}
              onLetsGoButtonClicked={handleLetsGoButtonClicked}
            />
          </div>
        )}
        <div className="row">
          <InlineFundingNavigation
            backButtonId="transfer-service-back-btn"
            hideBackButton={config.inlineFundingTransition}
            onBack={onBack}
          />
        </div>
      </>
    </div>
  );
};

TransferService.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default TransferService;
