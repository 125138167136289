import * as employmentQuestions from './employmentQuestions';

const employmentTexts = [
  {
    value: '',
    text: '',
  },
  {
    value: employmentQuestions.EMPLOYMENT_QUESTION_NYSE,
    text: 'Are you or your spouse associated with or employed by a NYSE, FINRA, and/or NFA registered brokerage firm?',
  },
  {
    value: employmentQuestions.EMPLOYMENT_QUESTION_DIRECTOR,
    text: 'Are you a director, 10% shareholder, or policy-making officer of a publicly-owned company?',
  },
  {
    value: employmentQuestions.EMPLOYMENT_QUESTION_PRO,
    text: `For the purpose of exchange fees, are you deemed a professional subscriber by the exchanges
      and therefore required to pay higher exchange fees?`,
  },
  {
    value: employmentQuestions.EMPLOYMENT_QUESTION_MARKET_DATA,
    text: `Do you use market data for your business or any other entity, instead of your own personal investments?
      This includes if you advise, teach, or sell investment or trading strategies commercially`,
  },
  {
    value: employmentQuestions.EMPLOYMENT_QUESTION_REGISTERED_SECURITIES,
    text: `Are you currently registered or qualified with any securities agency, any securities exchange, association
      or regulatory body, or any commodities or futures contract market,association or regulatory body,
      in the United States or elsewhere? For example, SEC (Securities Exchange) or the CFTC`,
  },
  {
    value: employmentQuestions.EMPLOYMENT_QUESTION_INVESTMENT_ADVISOR,
    text: `Are you engaged as an "investment advisor", providing advice and/or using capital of any other individual or
    entity in the conduct of your trading? This would include your sharing in the profit, using capital of any other
    individual or receiving compensation of trading activity`,
  },
];

export default employmentTexts;
