import React from 'react';
import PropTypes from 'prop-types';
import AccountSubType from './AccountSubType';
import * as accountTypes from './accountTypes';

const IRAAccountType = ({ showInheritedIras }) => {
  return (
    <div className="row">
      <div className="col col-xs-12">
        <div className="panel panel-clear no-panel-bottom">
          <div className="panel-body no-panel-bottom">
            <div className="row">
              <div className="col-md-12">
                <p className="panel-question text-center">
                  What type of IRA account would you like to open?&nbsp;
                  <button
                    type="button"
                    className="btn-link btn-wrap fitv_element slimh_element "
                    rel="noopener noreferrer"
                    href="#"
                    data-target="#ira-learn-more"
                    data-toggle="modal"
                  >
                    Learn More
                  </button>
                </p>
                <div className="row">
                  <div className="col col-sm-10 col-sm-offset-1 col-xs-8 col-xs-offset-2">
                    <div className="row">
                      <div className="col col-sm-6 col-xs-12 ira-acc-type">
                        <div className="form-group form-group-inputs ira-acc-type-group">
                          <AccountSubType
                            id="ira-traditional"
                            name="iraType"
                            text="Traditional"
                            type={accountTypes.IRA_TRADITIONAL}
                          />
                        </div>
                      </div>
                      <div className="col col-sm-6 col-xs-12 ira-acc-type">
                        <div className="form-group form-group-inputs ira-acc-type-group">
                          <AccountSubType
                            id="ira-roth"
                            name="iraType"
                            text="Roth"
                            type={accountTypes.IRA_ROTH}
                          />
                        </div>
                      </div>
                      <div className="col col-sm-6 col-xs-12 ira-acc-type">
                        <div className="form-group form-group-inputs ira-acc-type-group">
                          <AccountSubType
                            id="ira-sep"
                            name="iraType"
                            text="SEP"
                            type={accountTypes.IRA_SEP}
                          />
                        </div>
                      </div>
                      <div className="col col-sm-6 col-xs-12 ira-acc-type">
                        <div className="form-group form-group-inputs ira-acc-type-group">
                          <AccountSubType
                            id="ira-simple"
                            name="iraType"
                            text="SIMPLE"
                            type={accountTypes.IRA_SIMPLE}
                          />
                        </div>
                      </div>
                      {showInheritedIras &&
                        <div className="col col-sm-6 col-xs-12 ira-acc-type">
                          <div className="form-group form-group-inputs ira-acc-type-group">
                            <AccountSubType
                              id="ira-inherited-traditional"
                              name="iraType"
                              text="Inherited Traditional IRA (Equities only)"
                              type={accountTypes.IRA_TRADITIONAL_INHERITED}
                            />
                          </div>
                        </div>
                      }
                      {showInheritedIras &&
                        <div className="col col-sm-6 col-xs-12 ira-acc-type">
                          <div className="form-group form-group-inputs ira-acc-type-group">
                            <AccountSubType
                              id="ira-inherited-roth"
                              name="iraType"
                              text="Inherited Roth IRA (Equities only)"
                              type={accountTypes.IRA_ROTH_INHERITED}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="ira-learn-more"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="modal-title" id="myModalLabel">Individual Retirement Arrangement (IRA)</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="tab-content col col-xs-12">
                    <div role="tabpanel" className="tab-pane active" id="jFT">
                      <div name="futures">
                        <p>
                          U.S. taxpayers may be eligible to open different types of IRA’s with TradeStation Securities.
                        </p>
                        <ul>
                          <li>
                            <b>Traditional IRA:</b> You can contribute to a Traditional IRA if you are under 70 ½ and
                            you (or your spouse, if filing jointly) have taxable compensation. Contributions are
                            deductible for qualified individuals and are limited based on IRS rules. Traditional IRA
                            assets may grow tax deferred until they are withdrawn. Distributions from a Traditional
                            IRA are taxable and may be subject to penalties. Minimum annual distributions are required
                            following the year in which you turn 70 1/2.
                            <br /><br />
                          </li>
                          <li>
                            <b>Roth IRA:</b> You can contribute to a Roth IRA, at any age, if you (or your spouse, if
                            filing jointly) have taxable compensation and your modified adjusted gross income is below
                            certain amounts prescribed by the IRS. Contributions to a Roth IRA are not deductible and
                            are limited based on IRS rules. Qualified distributions and withdrawals, as defined by IRS
                            rules, are not taxable. The original Roth IRA owner is not required to take minimum annual
                            distributions.
                            <br /><br />
                          </li>
                          <li>
                            <b>SEP IRA:</b> A Simplified Employee Pension (SEP) IRA allows employers to contribute to
                            Traditional IRAs (SEP-IRAs) set up for employees. A business of any size, even
                            self-employed, can establish a SEP. (See, Traditional IRA above).
                            <br /><br />
                          </li>
                          <li>
                            <b>SIMPLE IRA:</b> A Savings Incentive Match Plan for Employees (SIMPLE IRA) allows
                            employees and employers to contribute to Traditional IRAs set up for employees. It is
                            ideally suited as a start-up retirement savings plan for small employers not currently
                            sponsoring a retirement plan. (See, Traditional IRA above).
                            <br /><br />
                          </li>
                        </ul>
                        <p>
                          <b>Note:</b> The information provided above is very basic and should not be considered tax
                          and/or legal advice. We strongly recommend that you learn more about these retirement account
                          options at the&nbsp;
                          <a href="https://www.irs.gov/retirement-plans" target="_blank" rel="noopener noreferrer">
                            IRS website
                          </a> and to consult with your independent tax or legal advisor. For more information about
                          opening an IRA account at TradeStation, please&nbsp;
                          <a href="https://www.tradestation.com/contact-us" target="_blank" rel="noopener noreferrer">
                            contact us.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IRAAccountType.propTypes = {
  showInheritedIras: PropTypes.bool.isRequired,
};

export default IRAAccountType;
