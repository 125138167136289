import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import EmploymentFormHeader from './EmploymentFormHeader';
import EmploymentFormSection from './EmploymentFormSection';
import EmplomentTsgoNote from './EmplomentTsgoNote';
import ExitModal from '../ExitModal';

const EmploymentForm = (props) => {
  const {
    isJointApplication,
    handleSubmit,
    onSubmit,
    onBack,
    error,
    shouldShowTSGONote,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EmploymentFormHeader />
      <FormSection name="employments[0]">
        <EmploymentFormSection {...props} sectionIndex={0} />
      </FormSection>
      {isJointApplication &&
        <FormSection name="employments[1]">
          <EmploymentFormSection {...props} sectionIndex={1} />
        </FormSection>
      }
      <div className="row">
        <div className="col col-xs-12">
          {error && <ErrorMessage error={error} />}
        </div>
      </div>
      {shouldShowTSGONote && <EmplomentTsgoNote />}
      {showExitModal && (
        <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
      )}
      <div className="col col-xs-12">
        <Navigation onBack={onBack} onExitModalOn={onExitModalOn} />
      </div>
    </form>
  );
};

EmploymentForm.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  shouldShowTSGONote: PropTypes.bool,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default EmploymentForm;
