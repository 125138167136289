import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_IDENTITY_SUCCESS:
      return action.identity;
    case actionTypes.UPSERT_IDENTITY_SUCCESS:
      return action.identity;
    default:
      return state;
  }
}
