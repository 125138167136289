import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchApplicationErrorsRequest() {
  return {
    type: actionTypes.FETCH_APPLICATION_ERRORS_REQUEST,
  };
}

export function fetchApplicationErrorsSuccess(applicationErrors) {
  return {
    type: actionTypes.FETCH_APPLICATION_ERRORS_SUCCESS,
    applicationErrors,
  };
}

export function fetchApplicationErrorsError(error) {
  return {
    type: actionTypes.FETCH_APPLICATION_ERRORS_ERROR,
    error,
  };
}

export function fetchApplicationErrors(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchApplicationErrorsRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/errors?validate=summary`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchApplicationErrorsSuccess(json)))
      .catch(error => dispatch(fetchApplicationErrorsError(error)));
  };
}
