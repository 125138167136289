import React from 'react';
import PropTypes from 'prop-types';

const AddressPlaceHolder = ({ isResidential, src = 'images/residential-address-map.png' }) => {
  const imgStyle = {
    marginTop: isResidential ? '96px' : '245px',
  };

  return (
    <div id="residentialImgBig" className="col col-md-8 col-md-offset-2 hidden-xs hidden-sm text-center" >
      <img src={src} style={imgStyle} alt="Address" />
    </div>
  );
};

AddressPlaceHolder.propTypes = {
  isResidential: PropTypes.bool,
  src: PropTypes.string,
};

export default AddressPlaceHolder;
