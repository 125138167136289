import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InlineFundingNavigation from '../InlineFundingNavigation';
import Config from '../../Config';

const config = new Config();

const DepositCryptoInstructions = (props) => {
  const { onBack } = props;
  const { depositCryptoDesktopAppUrl, depositCryptoMobileAppUrl } = config;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const getDepositCryptoAppUrl = () => {
    return isMobile ? depositCryptoMobileAppUrl : depositCryptoDesktopAppUrl;
  };

  return (
    <div className="acat-container">
      <div className="col col-sm-12 col-md-12 col-md-offset-2 col-lg-8 col-lg-offset-2 text-center">
        <h3 className="m-2 acat-title">How to Deposit Crypto</h3>
        <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <p className="acat-text acat-title-text">
            In order to deposit cryptocurrencies into your TradeStation Crypto account you must first
            generate a unique deposit address from within the TradeStation Crypto app.
          </p>
        </div>
        <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
          <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
            <h6 className="acat-text pr-0 mt-3 step">
              Step 1:
            </h6>
          </div>
          <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <p className="acat-text step-text">
              <a
                href={getDepositCryptoAppUrl()}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 'inherit' }}
              >Log in</a>nbsp;to the TradeStation Crypto app.
            </p>
          </div>
        </div>
        <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
          <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
            <h6 className="acat-text pr-0 mt-3 step">
              Step 2:
            </h6>
          </div>
          <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <p className="acat-text step-text">
              Click the &quot;+ New Deposit&quot; button.
            </p>
          </div>
        </div>
        <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
          <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
            <h6 className="acat-text pr-0 mt-3 step">
              Step 3:
            </h6>
          </div>
          <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <p className="acat-text step-text">
              Select the cryptocurrency you want to deposit.
            </p>
          </div>
        </div>
        <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
          <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
            <h6 className="acat-text pr-0 mt-3 step">
              Step 4:
            </h6>
          </div>
          <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <p className="acat-text step-text">
              Click the &quot;Deposit&quot; button.
            </p>
          </div>
        </div>
        <div className="col col-xs-12 col-sm-12 col-sm-offset-2 col-md-12 col-lg-12 pl-0 m-1">
          <div className="col col-xs-2 col-sm-2 col-md-2 col-lg-2 pr-0">
            <h6 className="acat-text pr-0 mt-3 step">
              Step 5:
            </h6>
          </div>
          <div className="col col-xs-10 col-sm-10 col-md-10 col-lg-10">
            <p className="acat-text step-text">
              Locate your unique deposit address by expanding the new deposit transfer row. You
              will need this address to setup the transfer with your external account provider
              where the cryptocurrency is currently being held.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <InlineFundingNavigation backButtonId="acat-back-btn" onBack={onBack} />
      </div>
    </div>
  );
};

DepositCryptoInstructions.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default DepositCryptoInstructions;
