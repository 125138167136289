import * as actionTypes from '../actions/actionTypes';

export default function (state = false, action) {
  switch (true) {
    case action.type === actionTypes.SUBMIT_REGISTRATION_SUCCESS:
      return true;
    case action.type.endsWith('_SUCCESS'):
    case action.type.endsWith('_ERROR'):
      return false;
    case action.type.endsWith('_REQUEST'):
      return true;
    default:
      return state;
  }
}
