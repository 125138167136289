export default function (state = false, action) {
  switch (true) {
    case action.type === 'SET_RECAPTCHA_VERIFIED':
      return true;
    case action.type === 'UNSET_RECAPTCHA_VERIFIED':
      return false;
    default:
      return state;
  }
}
