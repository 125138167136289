import auth0 from 'auth0-js';
import Config from '../Config';

const config = new Config();

function auth0Login(contact, credentials) {
  return new Promise((resolve, reject) => {
    const params = Object.assign({
      overrides: {
        __tenant: config.auth0Tenant,
        __token_issuer: config.auth0Tenant,
      },
      domain: config.auth0Tenant,
      clientID: config.auth0ClientId,
      redirectUri: config.auth0RedirectUri,
      responseType: 'code',
    });
    const webAuth = new auth0.WebAuth(params);
    webAuth.login(
      {
        connection: config.auth0Connection,
        audience: config.auth0Audience,
        scope: config.auth0Scopes,
        password: credentials.password,
        username: credentials.username,
        email: contact.email,
      },
      (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      },
    );
  });
}

export default auth0Login;
