import DocumentsSettings from '../components/Documents/DocumentsSettings';

export default function getSortOrder(documentType) {
  const sortOrders = [
    { key: DocumentsSettings.PROOF_OF_IDENTITY, value: 1 },
    { key: DocumentsSettings.PROOF_OF_ADDRESS, value: 2 },
    { key: DocumentsSettings.EMPLOYER_AUTHORIZATION, value: 3 },
    { key: DocumentsSettings.TRADING_AUTHORIZATION_TSEL, value: 4 },
    { key: DocumentsSettings.TRADING_AUTHORIZATION_NON_TSEL, value: 5 },
    { key: DocumentsSettings.TRADING_ADVISORY_TSEL, value: 6 },
    { key: DocumentsSettings.TRADING_ADVISORY_NON_TSEL, value: 7 },
    { key: DocumentsSettings.SIMPLE_IRA, value: 8 },
    { key: DocumentsSettings.SEP_IRA, value: 9 },
    { key: DocumentsSettings.DEATH_CERTIFICATE, value: 10 },
  ];
  const ord = sortOrders.find(s => s.key === documentType);
  return ord ? ord.value : 0;
}
