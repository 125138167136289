import React from 'react';
import TextBox from '../TextBox';

const PromoCodeBanner = () => {
  return (
    <section className="top-banner-section">
      <div className="row">
        <div className="col col-lg-12 text-center">
          <span className="banner-heading">
            HAVE A PROMO CODE?
          </span>
          <div className="banner-promo">
            <div className="form-group promo-code">
              <TextBox
                id="promoCode"
                name="promoCode"
                className="form-control"
                label="Promo Code"
                maxLength={8}
              />
              <div className="messages" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromoCodeBanner;
