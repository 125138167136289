import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return action.user;
    default:
      return state;
  }
}
