import React from 'react';
import PropTypes from 'prop-types';

const IdVerifyResubmitJoint = (props) => {
  const {
    initialValues,
  } = props;

  return (
    <div>
      {(initialValues.primaryNeedsResubmit && initialValues.isJointApp) && (
        <div className="row docVTab idVerifyResubmitNameDiv">
          <div className="jointAccountTab idVerifyResubmitCustomerName">
            <div style={{ display: 'inline-block' }} >
              <h4 className={'h2_subHeading h2_subHeadingBorderless text-center idVerifyResubmitNameText'}>
                {initialValues.primaryAccountOwner}
              </h4>
              <br />
              { initialValues.isJointApp && (
                <h5 className="idVerifyResubmitSubheader">(Primary Account Holder)</h5>
              )}
            </div>
          </div>
        </div>
      )}

      {(initialValues.jointNeedsResubmit && initialValues.isJointApp) && (
        <div className="row docVTab idVerifyResubmitNameDiv">
          <div className="jointAccountTab idVerifyResubmitCustomerName">
            <div style={{ display: 'inline-block' }} >
              <h4 className={'h2_subHeading h2_subHeadingBorderless text-center idVerifyResubmitNameText'}>
                {initialValues.jointAccountOwner}
              </h4>
              <br />
              { initialValues.isJointApp && (
                <h5 className="idVerifyResubmitSubheader">(Joint Account Holder)</h5>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

IdVerifyResubmitJoint.propTypes = {
  initialValues: PropTypes.shape(),
};

export default IdVerifyResubmitJoint;
