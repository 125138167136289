import React from 'react';
import PropTypes from 'prop-types';
import Config from '../Config';

const config = new Config();
const rowClass = config.entityAccountEnabled ? 'col col-md-12 col-lg-12' : 'col col-md-12 col-lg-10 col-lg-offset-1';
const Header = (
  {
    showUserMenu,
    userDescription,
    onItemLogout,
    location,
  },
) => {
  return (
    <section className="header-section">
      <div className="">
        <div className="navbar navbar-light fitv_element navbar-fixed-top container-fluid">
          <div className="container notranslate">
            <div className="row">
              <div className={rowClass}>
                <div className="navbar-header navbar-header-right">
                  {showUserMenu && <button
                    type="button"
                    id="menu_btn"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#collapseTools"
                    aria-expanded="true"
                    aria-controls="collapseTools"
                  >
                    <i className="material-icons">person</i>
                  </button>}
                </div>
                { location !== '/intro' &&
                <a
                  className="navbar-brand-desktop navbar-brand col-md-6 col-xs-9 "
                  tabIndex="-1"
                >
                  <img className="img-responsive" src="images/logo.png" alt="TradeStation" />
                </a>
                  }
              </div>
            </div>
            {showUserMenu && <div
              className="menuTools col col-xs-6 col-xs-offset-6 col-sm-4 col-sm-offset-8
              col-md-4 col-lg-offset-md col-lg-4 col-lg-offset-7"
            >
              <div className="collapse" id="collapseTools">
                <div className="row ">
                  <div className="menuLayer arrow_box">
                    <div className="row ">
                      <div className="col col-xs-12 center-block">
                        <span className="userName">User: {userDescription}</span>
                        <br />
                        <button
                          id="logout_btn"
                          type="button"
                          className="btn btn-default btn-sm do_capitalize"
                          onClick={onItemLogout}
                        >log out</button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  showUserMenu: PropTypes.bool,
  userDescription: PropTypes.string,
  onItemLogout: PropTypes.func,
  location: PropTypes.string,
};

export default Header;
