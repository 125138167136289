import moment from 'moment';
import * as beneficiaryTypes from '../components/IraAccount/IraBeneficiaryRelationshipTypes';

const noDOBRelationships =
  [beneficiaryTypes.IRA_RELATIONSHIP_ESTATE, beneficiaryTypes.IRA_RELATIONSHIP_ORGANIZATIONCHARIRY];

const shouldRequireDOB = (relationship) => {
  return noDOBRelationships.indexOf(relationship) === -1;
};

export function validateMoney(value) {
  if (/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{0,2})?$/.test(value)) {
    const number = value.replace(/[^0-9.]/g, '');
    return parseFloat(number);
  }
  return NaN;
}

export const validateSSN = (value) => {
  if (!value || value.trim().length < 1) {
    return 'Required';
  }
  if (/^\d{3}-\d{2}-\d{4}$/.test(value.trim())) {
    return null;
  }
  return 'Not a valid ssn';
};

const validateBeneficiaries = (beneficiaries) => {
  const beneficiaryErrors = [];
  let total = 0;
  beneficiaries.forEach((element, index) => {
    const currentErrors = {};
    let relationship = '';

    if (!element.fullName || element.fullName === '') {
      currentErrors.fullName = 'Required';
      beneficiaryErrors[index] = currentErrors;
    }

    if (!element.share || element.share === '') {
      currentErrors.share = 'Required';
      beneficiaryErrors[index] = currentErrors;
    } else if (element.share.length > 3) {
      currentErrors.share = 'Maximum length is 3';
      beneficiaryErrors[index] = currentErrors;
    } else {
      const share = parseInt(element.share, 10);
      if (share <= 0 || share > 100) {
        currentErrors.share = 'Percentage unit should be between 1% and 100%';
        beneficiaryErrors[index] = currentErrors;
      }
    }

    if (!element.relationship || element.relationship === '') {
      currentErrors.relationship = 'Required';
      beneficiaryErrors[index] = currentErrors;
    } else {
      relationship = element.relationship;
    }

    if (shouldRequireDOB(relationship)) {
      if (!element.ssn) {
        currentErrors.ssn = 'Required';
      }

      const ssnValidation = validateSSN(element.ssn);
      if (ssnValidation !== null) {
        currentErrors.ssn = ssnValidation;
      }

      if ((!element.birthDay || element.birthDay === '')) {
        currentErrors.birthDay = 'Required';
      }

      if ((!element.birthMonth || element.birthMonth === '')) {
        currentErrors.birthMonth = 'Required';
      }

      if ((!element.birthYear || element.birthYear === '')) {
        currentErrors.birthYear = 'Required';
      }

      if (element.birthDay && element.birthMonth && element.birthYear) {
        const dob = moment();
        const now = moment();
        dob.date(element.birthDay);
        dob.month(element.birthMonth - 1);
        dob.year(element.birthYear);
        if (!dob.isBefore(now, 'day')) {
          currentErrors.birthMonth = 'Date of Birth must be in the past';
        }
      }
    }

    if (Object.keys(currentErrors).length !== 0) {
      beneficiaryErrors[index] = currentErrors;
    }

    if (!element.share || element.share) {
      total = parseInt(total, 10) + parseInt(element.share, 10);
    }
  }, this);
  return { beneficiaryErrors, total };
};

const validate = (values, props) => {
  const errors = {};
  let primaryBeneficiaryValidation = [];
  let contingentBeneficiaryValidation = [];

  if (props.showBeneficiaries) {
    if (values.iraPrimaryBeneficiaries) {
      primaryBeneficiaryValidation = validateBeneficiaries(values.iraPrimaryBeneficiaries);
    }

    if (values.iraContingentBeneficiaries) {
      contingentBeneficiaryValidation = validateBeneficiaries(values.iraContingentBeneficiaries);
    }

    if (primaryBeneficiaryValidation.total !== 100) {
      errors.primaryShareTotalError = 'Percentage should total 100%';
    }

    if (values.iraContingentBeneficiaries.length > 0 && contingentBeneficiaryValidation.total !== 100) {
      errors.contingentShareTotalError = 'Percentages should total 100%';
    }

    if (primaryBeneficiaryValidation.beneficiaryErrors.length > 0) {
      errors.iraPrimaryBeneficiaries = primaryBeneficiaryValidation.beneficiaryErrors;
    }

    if (contingentBeneficiaryValidation.beneficiaryErrors.length > 0) {
      errors.iraContingentBeneficiaries = contingentBeneficiaryValidation.beneficiaryErrors;
    }
  }

  return errors;
};


export default validate;
