import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as summaryValueMapper from './summaryValueMapper';
import SummarySection from '../../components/Summary/SummarySection';
import * as accountTypeActions from '../../actions/accountTypeActions';
import * as accountTypesCodes from '../../components/AccountType/accountTypes';
import accountTypesTexts from '../../components/AccountType/accountTypesTexts';
import * as AccountTypeFormContainer from '../../containers/AccountTypeFormContainer';
import * as summaryRowTypes from '../../components/Summary/summaryRowTypes';

export class SummaryAccountTypeContainer extends React.Component {
  componentDidMount() {
    if (!this.props.accountType) {
      this.props.actions.fetchAccountType(this.props.applicationId, this.props.authToken);
    }
  }

  render() {
    return (
      <SummarySection {...this.props} />
    );
  }
}

SummaryAccountTypeContainer.propTypes = {
  actions: PropTypes.shape({
    fetchAccountType: PropTypes.func,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  accountType: PropTypes.shape(),
};

const getAccountType = (accountTypes, accountTypeCode) => {
  return summaryValueMapper.getTextFromValue(accountTypes, accountTypeCode);
};

function mapStateToInitialValues(state) {
  let isLoading = true;

  if (state.accountType) {
    isLoading = false;
  }

  const accountTypesValues = AccountTypeFormContainer.mapStateToInitialValues(state);
  accountTypesValues.accountType = getAccountType(accountTypesTexts, accountTypesValues.accountType);
  accountTypesValues.entityType = getAccountType(accountTypesTexts, accountTypesValues.entityType);
  accountTypesValues.jointType = getAccountType(accountTypesTexts, accountTypesValues.jointType);
  accountTypesValues.iraType = getAccountType(accountTypesTexts, accountTypesValues.iraType);

  const accountTypeValue = accountTypesValues.accountType;
  const accountInformation = [
    {
      keyType: summaryRowTypes.SUMMARY_ROW_TXT,
      valueType: summaryRowTypes.SUMMARY_ROW_TXT,
      key: 'ACCOUNT TYPE',
      value: accountTypeValue,
    },
  ];

  const INDIVIDUAL_TEXT = getAccountType(accountTypesTexts, accountTypesCodes.ACCOUNT_TYPE_INDIVIDUAL);
  const ENTITY_TEXT = getAccountType(accountTypesTexts, accountTypesCodes.ACCOUNT_TYPE_ENTITY);
  const IRA_TEXT = getAccountType(accountTypesTexts, accountTypesCodes.ACCOUNT_TYPE_IRA);

  if (accountTypeValue !== INDIVIDUAL_TEXT) {
    let subTypeValue = '';
    if (accountTypeValue === ENTITY_TEXT) {
      subTypeValue = accountTypesValues.entityType;
    } else if (accountTypeValue === IRA_TEXT) {
      subTypeValue = accountTypesValues.iraType;
    } else {
      subTypeValue = accountTypesValues.jointType;
    }

    accountInformation.splice(1, 0,
      {
        keyType: summaryRowTypes.SUMMARY_ROW_TXT,
        valueType: summaryRowTypes.SUMMARY_ROW_TXT,
        key: `TYPE OF ${accountTypeValue.toUpperCase()}`,
        value: subTypeValue,
      },
    );
  }

  return {
    isLoading,
    key: 'accountType',
    value: accountInformation,
    title: 'ACCOUNT TYPE',
    editLink: '/account-type',
  };
}

function mapStateToProps(state) {
  const initialValues = mapStateToInitialValues(state);
  const errorMessage = '';

  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    isLoading: initialValues.isLoading,
    entity: initialValues.key,
    summaryInfo: initialValues.value,
    title: initialValues.title,
    editLink: initialValues.editLink,
    errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, accountTypeActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAccountTypeContainer);
