import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class CommissionModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: props.showCommissionModal,
    };
    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ showModal: false });
    this.props.handleCommissionModalToggleOff();
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton>
          <div>
            <h3 className="text-center">
              Commission Plans Comparison
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="commision-plans">
            <table className="table commision-plans-table">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col" className="commision-plans-table-head">TS GO</th>
                  <th scope="col" className="commision-plans-table-head">TS SELECT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Equities Trades</td>
                  <td>$0</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td>Options contracts</td>
                  <td>$.50</td>
                  <td>$.60</td>
                </tr>
                <tr>
                  <td>Futures contracts</td>
                  <td>$.85</td>
                  <td>$1.50</td>
                </tr>
                <tr>
                  <td>Futures Options contracts</td>
                  <td>$1.50</td>
                  <td>$1.50</td>
                </tr>
                <tr>
                  <td>Minimum Deposit</td>
                  <td>Any Amount</td>
                  <td>Any Amount</td>
                </tr>
                <tr>
                  <td>Trade on our Mobile, Web, and Desktop apps</td>
                  <td><img src="images/iconSelected.png" alt="Icon Selected" /></td>
                  <td><img src="images/iconSelected.png" alt="Icon Selected" /></td>
                </tr>
                <tr>
                  <td><b>Desktop Platform trade ticket fee</b></td>
                  <td><b>$10</b></td>
                  <td><b>$0</b></td>
                </tr>
              </tbody>
            </table>
            <div className="commision-plans-disclaimer">
              <p>The choice you make will apply to your Equities and Futures accounts.
                &nbsp;All trading fees and commission are subject to change without notice.
                &nbsp;Other fees may apply. See the&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://www.tradestation.com/pricing" >
                  TradeStation website
                </a> for more information.</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

CommissionModal.propTypes = {
  showCommissionModal: PropTypes.bool,
  handleCommissionModalToggleOff: PropTypes.func,
};

export default CommissionModal;
