import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import DocumentsUploadControl from './DocumentsUploadControl';
import DocumentsUploadControlMitekIdentity from './DocumentsUploadControlMitekIdentity';
import DocumentsUploaded from './DocumentsUploaded';
import ProgressBarUpload from '../ProgressBarUpload';
import DocumentsSettings from './DocumentsSettings';
import * as identificationTypes from '../../components/PersonalInfo/Identity/identificationTypes';

const DocumentsItem = (props) => {
  const {
    sectionIndex,
    showOk,
    title,
    titleDetail,
    titleUri,
    uploadLater,
    name,
    maxFiles,
    authToken,
    onFileSubmit,
    onFileRemove,
    tooltip,
    docTip,
    progress,
    isReturnedApplication,
    errors,
    onHandleErrors,
    ids,
    maxFileRestriction,
    children,
    identificationType,
    clearFilesUploaded,
  } = props;

  const isProofOfIdentity = name.includes(DocumentsSettings.PROOF_OF_IDENTITY);
  const isPassport = identificationType && ((sectionIndex === 0 && identificationType[0].includes('PASSPORT'))
  || (sectionIndex === 1 && identificationType[1].includes('PASSPORT')));
  const isDriversLicense = identificationType && ((sectionIndex === 0 && identificationType[0].includes('DL_FRONT'))
  || (sectionIndex === 1 && identificationType[1].includes('DL_FRONT')));
  const ID_FRONT = identificationTypes.ID_FRONT;
  const ID_BACK = identificationTypes.ID_BACK;
  const ID_PASSPORT = identificationTypes.ID_PASSPORT;
  const PROOF_OF_IDENTITY = DocumentsSettings.PROOF_OF_IDENTITY;
  const dropZoneGridClass =
    isProofOfIdentity ?
    'col col-xs-10 col-xs-offset-2 col-sm-offset-7 col-sm-5 no-left-padding fix-marging' :
    'col col-xs-10 col-xs-offset-2 col-sm-offset-0 col-sm-5 no-left-padding';

  const rbDocTypePassport = `${ids}.docTypePassport`;
  const rbDocTypeDriversLicense = `${ids}.docTypeDriversLicense`;

  return (
    <div>
      <div className="row">
        <div className="col col-xs-2 col-sm-1">
          <div className="inside-half-height">
            <i className="material-icons icon-success">
              {showOk ? 'check_circle' : ''}
            </i>
          </div>
        </div>
        <div className="col col-xs-8 col-sm-5 no-left-padding">
          <div className="inside-half-height aligment-padding">
            {title}
            {docTip &&
              // eslint-disable-next-line react/no-danger
              <div className="info2" dangerouslySetInnerHTML={{ __html: docTip }} />
            }
            {titleDetail && titleUri && !uploadLater &&
              <div><a target="blank" href={titleUri}>{titleDetail}</a>, fill out and upload</div>
            }
            {titleDetail && titleUri && uploadLater &&
              <div>
                <small>(this can be signed and uploaded later)</small><br />
                <div><a target="blank" href={titleUri}>{titleDetail}</a>, fill out and upload</div>
              </div>
            }
            {children}
          </div>
        </div>
        <div className="col col-xs-2 col-sm-1">
          {name !== 'proofOfIdentity' &&
          name !== 'proofOfAddress' && name !== 'employerAuthorization' && (
          <div className="inside-half-height">
            <div>
              <a data-tip data-for={name}>
                <i className="material-icons">info</i>
              </a>
              <ReactTooltip id={name} place="left" class="tooltip" html>{tooltip}</ReactTooltip>
            </div>
          </div>)}
        </div>
        {isProofOfIdentity && (
          <div className="col col-xs-10 col-xs-offset-2 col-sm-offset-0 col-sm-5 no-left-padding selectIdTypeDiv">
            <p>
              Select ID type
            </p>
            <div className="row">
              <div className="col col-xs-12">
                <div className="form-group form-group-inputs">
                  <div className="radio">
                    <label htmlFor={rbDocTypePassport}>
                      <Field
                        name="identificationType"
                        id={rbDocTypePassport}
                        component="input"
                        type="radio"
                        value={identificationTypes.MITEK_PASSPORT}
                        onClick={() => clearFilesUploaded(PROOF_OF_IDENTITY, [ID_PASSPORT], sectionIndex)}
                      />
                      Passport
                    </label>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12">
                <div className="form-group form-group-inputs">
                  <div className="radio">
                    <label htmlFor={rbDocTypeDriversLicense}>
                      <Field
                        name="identificationType"
                        id={rbDocTypeDriversLicense}
                        component="input"
                        type="radio"
                        value={identificationTypes.MITEK_DRIVERS_LICENSE}
                        onClick={() => clearFilesUploaded(PROOF_OF_IDENTITY, [ID_FRONT, ID_BACK], sectionIndex)}
                      />
                      Driver License / ID Card
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={dropZoneGridClass}>
          {isProofOfIdentity && isDriversLicense && (
            <div>
              <Field
                name={name}
                sectionIndex={sectionIndex}
                component={DocumentsUploadControlMitekIdentity}
                maxFiles={maxFiles}
                authToken={authToken}
                handleFileSubmit={onFileSubmit}
                handleFileRemove={onFileRemove}
                handleErrors={onHandleErrors}
                maxFileRestriction={maxFileRestriction}
                identificationType={identificationType}
                progress={progress}
                isReturnedApplication={isReturnedApplication}
                errors={errors}
                ids={ids}
              />
            </div>
          )}
          {(!isProofOfIdentity || isPassport) && (<div>
            <Field
              name={name}
              sectionIndex={sectionIndex}
              component={DocumentsUploadControl}
              maxFiles={maxFiles}
              authToken={authToken}
              handleFileSubmit={onFileSubmit}
              handleFileRemove={onFileRemove}
              handleErrors={onHandleErrors}
              maxFileRestriction={maxFileRestriction}
              identificationType={identificationType}
              idSubtype={isPassport ? ID_PASSPORT : ''}
            />
            {errors[`${ids}.${name}`] && errors[`${ids}.${name}`].map((err, i) =>
              err && <span key={i} className="help-block error wordwrap error-fade-in">{err}</span>,
            )}
            <Field
              name="originalDocuments"
              sectionIndex={sectionIndex}
              component={DocumentsUploaded}
              props={{ sectionIndex, documentType: name, authToken }}
              handleFileRemove={onFileRemove}
              isReturnedApplication={isReturnedApplication}
            />
            {progress > 0 &&
              <Field
                name="progressBar"
                component={ProgressBarUpload}
                props={{ progress }}
              />
            }
            {identificationType &&
            (identificationType.includes('DL_FRONT') || identificationType.includes('PASSPORT')) && (
              <p className={progress === 0 ? 'acceptedFileTypesLabel' : 'acceptedFileTypesLabelWithProgressBar'}>
                HEIC, JPG, JPEG or PNG
              </p>
            )}
          </div>
                    )}
        </div>
      </div>
    </div>
  );
};

DocumentsItem.propTypes = {
  sectionIndex: PropTypes.number,
  uploadLater: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  titleDetail: PropTypes.string,
  titleUri: PropTypes.string,
  progress: PropTypes.number,
  showOk: PropTypes.bool,
  tooltip: PropTypes.string,
  docTip: PropTypes.string,
  maxFiles: PropTypes.number,
  onFileRemove: PropTypes.func.isRequired,
  onFileSubmit: PropTypes.func.isRequired,
  authToken: PropTypes.string,
  isReturnedApplication: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onHandleErrors: PropTypes.func.isRequired,
  ids: PropTypes.string.isRequired,
  maxFileRestriction: PropTypes.number,
  children: PropTypes.element,
  identificationType: PropTypes.arrayOf(PropTypes.string),
  clearFilesUploaded: PropTypes.func.isRequired,
};

export default DocumentsItem;
