import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const AccountType = ({ accountType, requirementsUrl, id, text, modalTargetID, handleOnChangeAccountType }) => {
  return (
    <a href="#" className="btn btn-img radio btn-img-auto form-group  col-xs-12">
      {/* eslint-disable-next-line */}
      <label className="row" htmlFor={id}>
        <Field
          id={id}
          component="input"
          name="accountType"
          type="radio"
          value={accountType}
          onClick={() => handleOnChangeAccountType(accountType)}
        />
        <div className="col col-md-12">
          <div className="btn-label">{text}</div>
        </div>
        <div className="btn-footer col-xs-6 col-sm-7 col-md-12">
          <div className="row">
            <div className="col col-xs-12">
              <button
                type="button"
                className="btn btn-sm btn-link btn-wrap fitv_element slimh_element "
                rel="noopener noreferrer"
                href={requirementsUrl}
                data-target={modalTargetID}
                data-toggle="modal"
              >
                Account/Documents <br /> Requirements
              </button>
            </div>
          </div>
        </div>
      </label>
    </a>
  );
};

AccountType.propTypes = {
  accountType: PropTypes.string.isRequired,
  requirementsUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  modalTargetID: PropTypes.string.isRequired,
  handleOnChangeAccountType: PropTypes.func,
};

export default AccountType;
