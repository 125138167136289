import * as actionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export function setAuthToken(authToken) {
  return { type: actionTypes.SET_AUTH_TOKEN, authToken };
}

// eslint-disable-next-line import/prefer-default-export
export function unSetAuthToken() {
  return { type: actionTypes.UNSET_AUTH_TOKEN, authToken: null };
}
