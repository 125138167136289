import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import IdVerify from '../components/IdVerify/IdVerify';
import * as contactActions from '../actions/contactActions';
import * as idVerifyActions from '../actions/idVerifyActions';
import * as addressActions from '../actions/addressActions';
import * as identityActions from '../actions/identityActions';
import * as countriesActions from '../actions/countriesActions';
import * as documentsRequiredActions from '../actions/documentsRequiredActions';
import * as RouteNavigator from './RouteNavigator';
import * as contactSearchAndFormat from './contactSearchAndFormat';
import Config from '../Config';
import { postDocumentValidationInfo } from '../lib/containerHelpers/idVerify/postdocumentValidationInfo';
import reasonCodes from '../lib/containerHelpers/idVerify/docVReasonCodes';

const config = new Config();
let SDKResult = 'none';
let SDKResultJoint = 'none';
let referenceId = null;
let documentUuid = null;
let resubmitReasons = null;
let resubmitReasonsJoint = null;
let jointInProgress = null;
let postInfo = null;
let docVAttemptsPrimary = 0;
let docVAttemptsJoint = 0;
let docVAttemptsPrimaryR827 = 0;
let docVAttemptsJointR827 = 0;
let appId = null;
let loadingScreenSDK = true;
let jointReferenceId = null;
let primaryReferenceId = null;

function sdkResultState() {
  return {
    webSDKResult: SDKResult,
    webSDKResultJoint: SDKResultJoint,
    referenceId,
    documentUuid,
    resubmitReasons,
    resubmitReasonsJoint,
    loadingScreenSDK,
  };
}

export class IdVerifyContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
        showNextBtn: false,
        webSDKInProgress: false,
        webSDKResult: 'none',
        webSDKResultJoint: 'none',
        referenceId: null,
        documentUuid: null,
        accountTypeInProgress: null,
        resubmitReasons: null,
        resubmitReasonsJoint: null,
        loadingScreenSDK: true,
        showExitModal: false,
    };

    this.handleBack = this.handleBack.bind(this);
    this.toggleNextBtn = this.toggleNextBtn.bind(this);
    this.toggleWebSDK = this.toggleWebSDK.bind(this);
    this.startWebSDK = this.startWebSDK.bind(this);
    this.endWebSDK = this.endWebSDK.bind(this);
    this.formatInputData = this.formatInputData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.postDocumentValidation = this.postDocumentValidation.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);

    if (!appId) {
      appId = this.props.applicationId;
    } else if (appId !== this.props.applicationId) {
      appId = this.props.applicationId;
      SDKResult = 'none';
      SDKResultJoint = 'none';
      docVAttemptsPrimary = 0;
      docVAttemptsJoint = 0;
      docVAttemptsPrimaryR827 = 0;
      docVAttemptsJointR827 = 0;
    }
  }

  componentDidMount() {
    this.handleResult();

    const {
      actions,
      applicationId,
      authToken,
      contact,
      idVerify,
      address,
      identity,
      countries,
    } = this.props;

    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!idVerify) actions.fetchIdVerify(applicationId, authToken);
    if (!address) actions.fetchAddress(applicationId, authToken);
    if (!identity) actions.fetchIdentity(applicationId, authToken);
    if (!countries) actions.fetchCountries();
    actions.fetchDocumentsRequired(applicationId, authToken);
    this.checkResult = setInterval(this.handleResult, 500);
  }

  componentWillUnmount() {
    clearInterval(this.checkResult);
  }

  handleResult() {
    this.setState(sdkResultState);
    if (this.state.accountTypeInProgress === 'primary') {
      if (this.state.webSDKResult === 'accept') {
        this.endWebSDK('accept');
      }
      if (this.state.webSDKResult === 'error') {
        this.endWebSDK('error');
      }
      if (this.state.webSDKResult === 'reject') {
        this.endWebSDK('reject');
      }
      if (this.state.webSDKResult === 'resubmit') {
        this.endWebSDK('resubmit');
      }
    } else if (this.state.accountTypeInProgress === 'joint') {
      if (this.state.webSDKResultJoint === 'accept') {
        this.endWebSDK('accept', 'joint');
      }
      if (this.state.webSDKResultJoint === 'error') {
        this.endWebSDK('error', 'joint');
      }
      if (this.state.webSDKResultJoint === 'reject') {
        this.endWebSDK('reject', 'joint');
      }
      if (this.state.webSDKResultJoint === 'resubmit') {
        this.endWebSDK('resubmit', 'joint');
      }
    }
    if (!this.props.initialValues.isJointApp && (this.state.webSDKResult === 'accept'
    || this.state.webSDKResult === 'reject')) {
      this.setState({ showNextBtn: true });
    }
    if ((this.state.webSDKResult === 'accept' || this.state.webSDKResult === 'reject') &&
    (this.state.webSDKResultJoint === 'accept' || this.state.webSDKResultJoint === 'reject')) {
      this.setState({ showNextBtn: true });
    }
    if (this.props.initialValues.isJointApp && !this.props.initialValues.primaryNeedsDocV &&
    (this.state.webSDKResultJoint === 'accept' || this.state.webSDKResultJoint === 'reject')) {
      this.setState({ showNextBtn: true });
    }
    if (this.props.initialValues.isJointApp && !this.props.initialValues.jointNeedsDocV &&
      (this.state.webSDKResult === 'accept' || this.state.webSDKResult === 'reject')) {
        this.setState({ showNextBtn: true });
    }
  }

  handleSubmit() {
    if (!this.state.webSDKInProgress) {
      if (this.props.isDocumentUploadRequired) {
        RouteNavigator.push('/documents');
      } else {
        RouteNavigator.push('/summary');
      }
    }
  }

  handleBack() {
    if (this.state.webSDKInProgress) {
      this.toggleWebSDK();
      loadingScreenSDK = true;
    } else {
      if (this.state.webSDKResult === 'error') {
        SDKResult = 'none';
        this.setState({ webSDKResult: 'none' });
      }
      if (this.state.webSDKResultJoint === 'error') {
        SDKResultJoint = 'none';
        this.setState({ webSDKResultJoint: 'none' });
      }
      RouteNavigator.push('/additional-info');
    }
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  toggleNextBtn() {
    this.setState({ showNextBtn: !this.state.showNextBtn });
  }

  startWebSDK(accountType = 'primary') {
    let inputData = {};
    if (accountType === 'joint') {
      this.setState({ accountTypeInProgress: 'joint' });
      jointInProgress = true;
      inputData = this.formatInputData(this.props.contact[1], this.props.identity[1], this.props.address[1]);
      docVAttemptsJoint += 1;
    } else {
      this.setState({ accountTypeInProgress: 'primary' });
      jointInProgress = null;
      inputData = this.formatInputData(this.props.contact[0], this.props.identity[0], this.props.address[0]);
      docVAttemptsPrimary += 1;
    }

    if (this.state.webSDKResult === 'resubmit' || this.state.webSDKResult === 'error') {
      SDKResult = 'none';
      this.setState({ webSDKResult: 'none' });
    }
    if (this.state.webSDKResultJoint === 'resubmit' || this.state.webSDKResultJoint === 'error') {
      SDKResultJoint = 'none';
      this.setState({ webSDKResultJoint: 'none' });
    }
    this.setState({ webSDKInProgress: true });
    resubmitReasons = null;
    resubmitReasonsJoint = null;

    window.SocureInitializer.init(config.socureSDKKey).then((lib) => {
      let socureStatus = '';

      // eslint-disable-next-line no-unused-vars
      function onProgress(progress) {}

      function onSuccess(response) {
        if (response && response.status === 'VERIFICATION_COMPLETE'
        && response.verifyResult && socureStatus === 'inProgress') {
          socureStatus = 'success';
          lib.reset();
          let decision = response.verifyResult.documentVerification.decision.value;
          referenceId = response.verifyResult.referenceId;
          documentUuid = response.documentUuid;
          const socureReasonCodes = response.verifyResult.documentVerification.reasonCodes;

          // check for R827 reason code
          if (decision === 'reject' && socureReasonCodes && socureReasonCodes.includes('R827')) {
            if (jointInProgress) {
              docVAttemptsJointR827 += 1;
              if (docVAttemptsJointR827 < config.docvExpiredIdLimit) {
                decision = 'resubmit';
              }
            } else {
              docVAttemptsPrimaryR827 += 1;
              if (docVAttemptsPrimaryR827 < config.docvExpiredIdLimit) {
                decision = 'resubmit';
              }
            }
          }

          if (decision === 'accept' || decision === 'reject' || decision === 'resubmit') {
            if (jointInProgress) {
              SDKResultJoint = decision;
              postInfo = decision !== 'resubmit' ? 'joint' : null;
            } else {
              SDKResult = decision;
              postInfo = decision !== 'resubmit' ? 'primary' : null;
            }
          }
          if (decision === 'resubmit') {
            if (docVAttemptsPrimary >= config.docvAttemptLimit && !jointInProgress) {
              SDKResult = 'reject';
              postInfo = 'primary';
            } else if (docVAttemptsJoint >= config.docvAttemptLimit && jointInProgress) {
              SDKResultJoint = 'reject';
              postInfo = 'joint';
            } else {
              const reason = response.verifyResult.documentVerification.reasonCodes;
              const reasonsText = [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < reason.length; i++) {
                if (reason[i] && reason[i] !== undefined && reason[i] !== 'undefined' && reasonCodes[reason[i]]) {
                  reasonsText.push(reasonCodes[reason[i]]);
                }
              }
              if (jointInProgress) {
                resubmitReasonsJoint = reasonsText;
              } else {
                resubmitReasons = reasonsText;
              }
            }
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      function onError(error) {
        if (jointInProgress) {
          SDKResultJoint = 'error';
        } else {
          SDKResult = 'error';
        }
        lib.reset();
      }

      const socureWebSDKConfig = {
        onProgress,
        onSuccess,
        onError,
        qrCodeNeeded: true,
      };

      lib.init(config.socureSDKKey, '#socureWebSDK', socureWebSDKConfig).then(() => {
        lib.start(2, inputData).then(() => {
          loadingScreenSDK = false;
          socureStatus = 'inProgress';
        });
      });
    });
  }

  toggleWebSDK() {
    this.setState({ webSDKInProgress: !this.state.webSDKInProgress });
  }

  endWebSDK(result, accountType = 'primary') {
    loadingScreenSDK = true;
    this.setState({ webSDKInProgress: false });
    this.setState({ accountTypeInProgress: null });
    if (accountType === 'joint') {
      jointInProgress = null;
    }

    if (result === 'accept' || result === 'reject') {
      if (postInfo === 'primary') {
        this.postDocumentValidation(this.props.contact, this.props.identity);
      } else if (postInfo === 'joint') {
        this.postDocumentValidation(this.props.contact, this.props.identity, 'joint');
      }
      postInfo = null;
    }
  }

  postDocumentValidation(contact, identity, type = 'primary') {
    if (type === 'joint') {
      const jointContact = (contact && contact[1]) ? contact[1] : null;
      const jointIdentity = (identity && identity[1]) ? identity[1] : null;
      if (jointContact && jointIdentity && jointContact.countryOfResidence === 'US' &&
      jointIdentity.identificationSSN && this.props.idVerify) {
        const data = {
          applicationId: this.props.applicationId,
          contactId: jointContact.id,
          documentUuid: this.state.documentUuid,
          previousReferenceId: this.props.idVerify.length > 1 ? this.props.idVerify[1].referenceId :
          this.props.idVerify[0].referenceId,
          referenceId: this.state.referenceId,
        };
        postDocumentValidationInfo(this.props.applicationId, this.props.authToken, data);
      }
    } else {
      const primaryContact = (contact && contact[0]) ? contact[0] : null;
      const primaryIdentity = (identity && identity[0]) ? identity[0] : null;
      if (primaryContact && primaryIdentity && primaryContact.countryOfResidence === 'US' &&
      primaryIdentity.identificationSSN && this.props.idVerify) {
        const data = {
          applicationId: this.props.applicationId,
          contactId: primaryContact.id,
          documentUuid: this.state.documentUuid,
          previousReferenceId: this.props.idVerify[0].referenceId,
          referenceId: this.state.referenceId,
        };
        postDocumentValidationInfo(this.props.applicationId, this.props.authToken, data);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatInputData(contact, identity, address) {
    let message = null;
    if (contact && identity && address) {
      const dob = new Date(identity.birthDate);

      message = {
        firstName: contact.firstName,
        surName: contact.lastName,
        state: address.residentialRegion,
        dob: dob ? dob.toISOString().split('T')[0] : '',
        country: address.residentialCountry,
        customerUserId: contact.applicationId,
      };
    }

    return message;
  }

  render() {
    return (
      <IdVerify
        {...this.props}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        showNextBtn={this.state.showNextBtn}
        webSDKInProgress={this.state.webSDKInProgress}
        accountTypeInProgress={this.state.accountTypeInProgress}
        toggleNextBtn={this.toggleNextBtn}
        toggleWebSDK={this.toggleWebSDK}
        startWebSDK={this.startWebSDK}
        endWebSDK={this.endWebSDK}
        webSDKResult={this.state.webSDKResult}
        webSDKResultJoint={this.state.webSDKResultJoint}
        resubmitReasons={this.state.resubmitReasons}
        resubmitReasonsJoint={this.state.resubmitReasonsJoint}
        loadingScreenSDK={this.state.loadingScreenSDK}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

IdVerifyContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  userId: PropTypes.number,
  contact: PropTypes.shape(),
  idVerify: PropTypes.shape(),
  address: PropTypes.shape(),
  identity: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  isDocumentUploadRequired: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    fetchContact: PropTypes.func.isRequired,
    fetchIdVerify: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchDocumentsRequired: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToInitialValues(state) {
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const idVerifies = (state.idVerify && state.idVerify.length > 0) ? state.idVerify : null;
  const addresses = (state && state.address && state.address.length > 0) ? state.address : null;
  const identities = (state && state.identity && state.identity.length > 0) ? state.identity : null;
  let isJointApp = false;
  let primaryNeedsDocV = false;
  let jointNeedsDocV = false;
  let bothNeedDocV = false;

  let primaryAccountOwner = '';
  let jointAccountOwner = '';
  if (contacts && contacts.length > 0) {
    const primaryContact = contactSearchAndFormat.findContactByType('primary', contacts);
    primaryAccountOwner = contactSearchAndFormat.formatContactName(primaryContact);
    if (contacts.length > 1) {
      const jointContact = contactSearchAndFormat.findContactByType('joint', contacts);
      jointAccountOwner = contactSearchAndFormat.formatContactName(jointContact);
    }
  }

  if (contacts && contacts.length > 0 && idVerifies && idVerifies.length > 0) {
    if (contacts.length > 1) {
      isJointApp = true;
      if (idVerifies.length > 1) {
        // both accounts need idVerify
        primaryNeedsDocV = true;
        jointNeedsDocV = true;
        bothNeedDocV = true;
      } else if (idVerifies.length === 1) {
        // check which user needs idVerify (primary or joint)
        // compare contactId in idVerifies to contactId in contacts
        if (contacts[0].id === idVerifies[0].contactId) {
          if (contacts[0].type === 'primary') primaryNeedsDocV = true;
          if (contacts[0].type === 'joint') jointNeedsDocV = true;
        } else if (contacts[1].id === idVerifies[0].contactId) {
          if (contacts[1].type === 'primary') primaryNeedsDocV = true;
          if (contacts[1].type === 'joint') jointNeedsDocV = true;
        }
      }
    } else {
      primaryNeedsDocV = true;
    }

    // check if primaryContact's idVerify referenceId has changed. If so, reset the primary docV values
    if (primaryNeedsDocV) {
      const currentPrimaryRefId = idVerifies[0].referenceId;
      if (!primaryReferenceId) {
        primaryReferenceId = currentPrimaryRefId;
      } else if (primaryReferenceId !== currentPrimaryRefId) {
        SDKResult = 'none';
        docVAttemptsPrimary = 0;
        docVAttemptsPrimaryR827 = 0;
        primaryReferenceId = currentPrimaryRefId;
      }
    }

    // check if jointContact's idVerify referenceId has changed. If so, reset the joint docV values
    if (isJointApp && jointNeedsDocV) {
      const currentJointRefId = idVerifies.length > 1 ? idVerifies[1].referenceId : idVerifies[0].referenceId;
      if (!jointReferenceId) {
        jointReferenceId = currentJointRefId;
      } else if (jointReferenceId !== currentJointRefId) {
        SDKResultJoint = 'none';
        docVAttemptsJoint = 0;
        docVAttemptsJointR827 = 0;
        jointReferenceId = currentJointRefId;
      }
    }
  }

  const values = {
    primaryAccountOwner,
    jointAccountOwner,
    contacts,
    idVerifies,
    addresses,
    identities,
    isJointApp,
    primaryNeedsDocV,
    jointNeedsDocV,
    bothNeedDocV,
  };

  return values;
}

function mapStateToProps(state) {
  let isDocumentUploadRequired = false;
  if (state.documentsRequired) {
    for (let i = 0; i < state.documentsRequired.length; i += 1) {
      if (state.documentsRequired[i].requiredDocsUpload.length > 0) {
        isDocumentUploadRequired = true;
      }
    }
  }
  return {
    initialValues: mapStateToInitialValues(state),
    applicationId: state.applicationId,
    authToken: state.authToken,
    userId: state.userId,
    contact: state.contact,
    idVerify: state.idVerify,
    address: state.address,
    identity: state.identity,
    isDocumentUploadRequired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      contactActions,
      idVerifyActions,
      addressActions,
      identityActions,
      countriesActions,
      documentsRequiredActions),
      dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'idVerify',
})(IdVerifyContainer));

