import React from 'react';
import PropTypes from 'prop-types';
import PendingApplication from './PendingApplication';
import Common from './PendingApplicationsCommon';

const PendingApplicationsList = (props) => {
  const {
    pendingApplications = [],
    onApplicationSelected,
    isLoading,
    isSubmitting,
  } = props;

  const list = pendingApplications.map((item, index) => {
    return (
      <div key={index}>
        <PendingApplication
          {...item}
          continueApplication={onApplicationSelected}
        />
        <hr />
      </div>
    );
  });

  const hasPendingApplications = list.length > 0;
  let subtitle = Common.LOADING_PENDING_APPLICATIONS;

  if (!isLoading) {
    subtitle = hasPendingApplications
      ? Common.PENDING_APPLICATIONS
      : Common.NO_PENDING_APPLICATIONS;
  }

  const increaseMargin = (!hasPendingApplications && 'increase-margin') || '';

  return (
    <div id="pending-list">
      <h1 className="h1_secondary text-center">Pending Applications</h1>
      {isLoading && (
        <p id="loadingSubtitle" className="text-center">
          {subtitle}
          <span
            id="loadingSpinner"
            className="glyphicon glyphicon-refresh spinning"
          />
        </p>
      )}
      {!isLoading && (
        <div>
          <p id="subtitle" className="text-center">
            {subtitle}
          </p>
          <div id="pendingList">{list}</div>
          <div className="col col-lg-12 text-center">
            {hasPendingApplications && (
              <span id="incompleteMessage">{Common.INCOMPLETE_MESSAGE}</span>
            )}
          </div>
        </div>
      )}
      <div
        className={`col col-lg-6 col-lg-offset-3 start-application ${increaseMargin}`}
      >
        <button
          id="next_btn"
          type="button"
          className="btn btn-raised btn-white do_capitalize"
          onClick={props.startNewApplication}
          disabled={isSubmitting}
        >
          Start A New Application
          {isSubmitting && (
            <span className="glyphicon glyphicon-refresh spinning" />
          )}
        </button>
      </div>
    </div>
  );
};

PendingApplicationsList.propTypes = {
  pendingApplications: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.shape({
        accountType: PropTypes.string.isRequired,
        accountSubtype: PropTypes.string,
        assetTypes: PropTypes.string,
      }),
      id: PropTypes.string.isRequired,
      created: PropTypes.instanceOf(Date).isRequired,
      company: PropTypes.string,
    }),
  ).isRequired,
  onApplicationSelected: PropTypes.func.isRequired,
  startNewApplication: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PendingApplicationsList;
