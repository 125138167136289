import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_SECURITY_CREDENTIALS_SUCCESS:
      return action.registration;
    case actionTypes.FETCH_SECURITY_CREDENTIALS_SUCCESS:
      return action.securityCredentials;
    case actionTypes.PURGE_SECURITY_CREDENTIALS:
      return null;
    default:
      return state;
  }
}
