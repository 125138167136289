import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import FinancialFormHeader from './FinancialFormHeader';
import FinancialFormSection from './FinancialFormSection';
import TradingProfileFormSection from '../TradingProfile/TradingProfileFormSection';
import ExitModal from '../ExitModal';

const FinancialForm = (props) => {
  const {
    error,
    isJointApplication,
    handleSubmit,
    onSubmit,
    onBack,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

  const gridClass = isJointApplication ? 'col col-md-6 col-xs-12' : 'col col-md-12 col-xs-12';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col col-xs-12">
        <FinancialFormHeader />
      </div>
      <div className="col col-xs-12 no-side-padding">
        <div className={gridClass}>
          <FormSection name="financials[0]">
            <FinancialFormSection {...props} sectionIndex={0} />
          </FormSection>
          <FormSection name="tradingProfiles[0]">
            <TradingProfileFormSection {...props} sectionIndex={0} />
          </FormSection>
        </div>
        <div className={gridClass}>
          {isJointApplication &&
            <FormSection name="financials[1]">
              <FinancialFormSection {...props} sectionIndex={1} />
            </FormSection>
          }
          {isJointApplication &&
            <FormSection name="tradingProfiles[1]">
              <TradingProfileFormSection {...props} sectionIndex={1} />
            </FormSection>
          }
        </div>
        {showExitModal && (
          <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
        )}
        {error && <ErrorMessage error={error} />}
        <div className="col col-xs-12">
          <Navigation onBack={onBack} onExitModalOn={onExitModalOn} />
        </div>
      </div>
    </form >
  );
};

FinancialForm.propTypes = {
  isJointApplication: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default FinancialForm;
