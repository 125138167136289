/* eslint-disable react/jsx-indent */
import React from 'react';

const AgreementsDisclaimer1 = () => {
  return (
    <div className="row">
      <div className="col col-xs-12">
        <p>
          This account application, together with all of the applicable agreements,
          acknowledgments, representations, warranties, notices, disclaimers and
          assumptions of risk contained in this application and any of such documents,
          including those set forth on this page (collectively, the “Agreement”), may
          from time to time be modified or amended, in whole or in part, by any
          reasonable method of notice to you, including posting on a TradeStation
          website, platform application you use, or other electronic communication, and
          you agree that any transaction or activity you initiate in any of your accounts
          after such modification or amendment constitutes your unconditional acceptance
          of any such modification or amendment.
        </p>
        <p>
          You represent and affirm that (1) you are a self-directed online investor or
          trader, and the sole reason for your account(s) is for you to invest in and trade,
          online, the market assets offered by such account(s), (2) you have the financial
          ability to withstand a total loss of all funds and other assets you deposit or
          maintain in any of your accounts, (3) all funds deposited or later deposited
          in any of your accounts are and will be your personal funds, and have not been and
          will not be solicited or sourced from any third party, and any withdrawals you
          make from any of your accounts will be transferred solely to an account in your name,
          under your control, and which only you own, and (4) no third party has or will have
          any direct or indirect ownership or other beneficial or financial interest in those
          funds, or any asset or other property purchased with or through the use of such funds,
          or in any of your accounts, in any manner.
        </p>
      </div>
    </div>
  );
};

export default AgreementsDisclaimer1;
