import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const AccountSubType = ({ id, name, text, type }) => {
  return (
    <a href="#" className="btn btn-img radio btn-img-auto form-group col-xs-12">
      {/* eslint-disable-next-line */}
      <label className="row" htmlFor={id}>
        <Field
          id={id}
          component="input"
          type="radio"
          name={name}
          value={type}
        />
        <div className="col col-md-12">
          <div className="btn-label">{text}</div>
        </div>
      </label>
    </a>
  );
};

AccountSubType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default AccountSubType;
