import { domesticZipCodePattern, internationalZipCodePattern, specialCharsPattern } from '../../constants';
import Config from '../../../Config';

const config = new Config();

/* eslint-disable no-useless-escape */
const RESIDENTIAL = 'residential';
const MAILING = 'mailing';

export function validateStreetAddress(value, key, attributes) {
  if (attributes.sameMailingAddress && /^mailing/.test(key)) return null;

  if (!value || value.trim().length < 1) {
    return (/2$/.test(key)) ? null : 'Required';
  }

  if (value.includes('|')) {
    return 'Input cannot include the "|" character';
  }

  const streetAddress = value.trim();
  if (streetAddress.length < 5 || streetAddress.length > 40) {
    return 'Minimum length is 5, maximum 40';
  }

  if (/^residential/.test(key)) {
    // eslint-disable-next-line max-len
    return (/^(((p[\s\.]?[o\s][\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/igm.test(streetAddress))
      ? 'The residential address needs to be non PO Box address'
      : null;
  }

  return null;
}

export function validateSpecialCharacters(value) {
  return (specialCharsPattern.test(value))
      ? 'Invalid characters'
      : null;
}

export function validateAddress(address, type) {
  const sameMailingAddress = (type === RESIDENTIAL);
  const errors = {};

  let error = null;

  if (!address.country) {
    errors.country = 'Required';
  }

  if (!address.address1) {
    errors.address1 = 'Required';
  } else {
    error = validateStreetAddress(address.address1, `${type}address1`, { sameMailingAddress });
    if (error) {
      errors.address1 = error;
    }
    error = validateSpecialCharacters(address.address1);
    if (error) {
      errors.address1 = error;
    }
  }

  if (address.address2) {
    error = validateStreetAddress(address.address2, `${type}address2`, { sameMailingAddress });
    if (error) {
      errors.address2 = error;
    }
    error = validateSpecialCharacters(address.address2);
    if (error) {
      errors.address2 = error;
    }
  }

  if (!address.city) {
    errors.city = 'Required';
  } else if (address.city.length > 30 || address.city.length < 2) {
    errors.city = 'Minimum length is 2, maximum 30';
  } else if (address.city.includes('|')) {
    errors.city = 'Input cannot include the "|" character';
  } else if (address.city) {
    error = validateSpecialCharacters(address.city);
    if (error) {
      errors.city = error;
    }
  }

  if (!address.region) {
    errors.region = 'Required';
  } else if (type === RESIDENTIAL && address.country === 'CA' && address.region !== 'ON') {
    errors.region = 'Canadian region restricted to Ontario only';
  }

  if (address.country === 'US') {
    if (!address.zip) {
      errors.zip = 'Required';
    } else if (!domesticZipCodePattern.test(address.zip)) {
      errors.zip = 'Only the formats 12345, 12345-1234, 123456789 are valid';
    }
  } else if (address.zip && config.physicalAddressTransform && !internationalZipCodePattern.test(address.zip)) {
    errors.zip =
      'Only the formats 12345, 12345-1234, 1234567ed, 1237ED are valid';
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  return null;
}

export default function validate(values) {
  const errors = [];

  if (values && values.addresses.length > 0) {
    values.addresses.forEach((a, ix) => {
      const addressError = { residential: {}, mailing: {} };

      let error = validateAddress(a.residential, RESIDENTIAL);
      if (error) {
        addressError.residential = error;
        errors[ix] = addressError;
      }

      if (a.residential.sameAddress) {
        error = validateAddress(a.mailing, MAILING);
        if (error) {
          addressError.mailing = error;
          errors[ix] = addressError;
        }
      }
    });
  }

  return errors;
}
