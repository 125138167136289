import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class EquitiesModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: props.showEquitiesModal,
    };
    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ showModal: false });
    this.props.handleEquitiesModalToggleOff();
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton>
          <div>
            <h3 className="text-center">Equities Account Required</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              If you wish to open a TradeStation Crypto account,
              you must also have an equities account with TradeStation Securities.
            </p>
            <p>
              A crypto account can only hold cryptocurrencies. In order to
              purchase cryptocurrency using cash, or to sell cryptocurrency for
              cash, you must also have an equities account where your cash
              assets will be held. This process will be seamless to you.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <button
              id="ok_btn"
              onClick={this.handleHide}
              className="btn btn-raised btn-default btn-equities-modal do_capitalize"
            >
              ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

EquitiesModal.propTypes = {
  showEquitiesModal: PropTypes.bool,
  handleEquitiesModalToggleOff: PropTypes.func,
};

export default EquitiesModal;
