import { datadogRum } from '@datadog/browser-rum';

export default function configureDatadog() {
  let environment = null;
  const environments = {
    qa1: 'getstarted-qa.tstdev.io',
    qa2: 'getstarted-internal.tstdev.io',
    staging1: 'getstarted-staging.tradestation.io',
    staging2: 'getstarted-staging-2.tradestation.io',
    production: 'getstarted2.tradestation.com',
  };
  const hostname = window.location.hostname;

  if (hostname === environments.qa1) {
    environment = 'qa:1';
  } else if (hostname === environments.qa2) {
    environment = 'qa:2';
  } else if (hostname === environments.staging1) {
    environment = 'staging:1';
  } else if (hostname === environments.staging2) {
    environment = 'staging:2';
  } else if (hostname === environments.production) {
    environment = 'production';
  }

  return environment
    ? datadogRum.init({
        applicationId: 'de745f96-c925-4a23-83cd-89c7b4785ea3',
        clientToken: 'pub2bf36a7bbbcf2bd9123fba1652e09d07',
        site: 'datadoghq.com',
        service: 'ellis-app',
        env: environment,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      })
    : null;
}
