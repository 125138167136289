import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import IraAccountHeader from './IraAccountHeader';
import IraRenderBeneficiaries from './IraRenderBeneficiaries';
import FuturesIraDisclaimer from '../FuturesIraDisclaimer';
import ExitModal from '../ExitModal';

const IraAccountForm = (props) => {
  const {
    error,
    handleSubmit,
    onSubmit,
    onBack,
    showBeneficiaries,
    iraBeneficiaryRelationshipOptions,
    months,
    days,
    yearsBirthdate,
    primaryTotal,
    contingentTotal,
    handleOnChangeRelationship,
    isFuturesOrFuturesOptions,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IraAccountHeader header="IRA Account" isPrimary />
      {isFuturesOrFuturesOptions &&
        <FuturesIraDisclaimer />
      }
      {showBeneficiaries &&
      <div>
        <IraAccountHeader
          header={isFuturesOrFuturesOptions ? 'Primary Beneficiaries - Equity IRA' : 'Primary Beneficiaries'}
          subText="If more than one beneficiary is listed, make sure percentage is noted and totals 100%."
          isPrimary={false}
        />
        <FieldArray
          name="iraPrimaryBeneficiaries"
          component={IraRenderBeneficiaries}
          iraBeneficiaryRelationshipOptions={iraBeneficiaryRelationshipOptions}
          months={months}
          days={days}
          yearsBirthdate={yearsBirthdate}
          total={primaryTotal}
          beneficiaryType="primary"
          handleOnChangeRelationship={handleOnChangeRelationship}
        />

        <IraAccountHeader
          header={isFuturesOrFuturesOptions ? 'Contingent Beneficiaries - Equity IRA' : 'Contingent Beneficiaries'}
          subText="Replaces the noted Primary beneficiary if Primary predeceases the Contingent."
          isPrimary={false}
        />

        <FieldArray
          name="iraContingentBeneficiaries"
          component={IraRenderBeneficiaries}
          iraBeneficiaryRelationshipOptions={iraBeneficiaryRelationshipOptions}
          months={months}
          days={days}
          yearsBirthdate={yearsBirthdate}
          total={contingentTotal}
          beneficiaryType="contingent"
          handleOnChangeRelationship={handleOnChangeRelationship}
        />
      </div>
      }
      {showExitModal && (
        <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
      )}
      {error && <ErrorMessage error={error} />}
      <Navigation onBack={onBack} onExitModalOn={onExitModalOn} />
    </form>
  );
};

IraAccountForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  showBeneficiaries: PropTypes.bool.isRequired,
  iraBeneficiaryRelationshipOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  months: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  days: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  yearsBirthdate: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  primaryTotal: PropTypes.number.isRequired,
  contingentTotal: PropTypes.number.isRequired,
  handleOnChangeRelationship: PropTypes.func.isRequired,
  isFuturesOrFuturesOptions: PropTypes.bool.isRequired,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default IraAccountForm;
