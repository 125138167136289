import Config from '../Config';
import * as cookies from '../lib/cookieManager';

const TIMER_START_COOKIE_NAME = 'timer_t';
const config = new Config();

let currentTime = 0;
let timer;
const limit = config.tokenTime;

function SetCurrentTime() {
  const cookie = cookies.getCookie(TIMER_START_COOKIE_NAME);
  if (cookie) {
    const startDate = new Date(cookie);
    const currentDate = new Date();
    const diff = currentDate - startDate;
    currentTime = Math.floor(diff / 1000);
    if (currentTime >= limit) {
      currentTime = 0;
      resetTimerTime();
    }
  } else {
    resetTimerTime();
  }
}

function resetTimerTime() {
  const startDate = new Date();
  cookies.setCookie(TIMER_START_COOKIE_NAME, startDate);
}

function triggerTimer() {
  currentTime = limit - 1;
}

function checkTimeInterval(refresh) {
  currentTime += 1;
  if (currentTime === limit) {
    refresh();
    resetTimerTime();
  }
}

function initTimer(refresh) { // initialize timer
  if (!timer) {
    timer = setInterval(checkTimeInterval, 1000, refresh);
  }
  SetCurrentTime();
}

function resetTimer() { // reset timer
  if (currentTime !== 0) {
    currentTime = 0;
  }
}

function stopTimer() {
  clearInterval(timer);
}

export {
  initTimer,
  resetTimer,
  checkTimeInterval,
  stopTimer,
  triggerTimer,
};
