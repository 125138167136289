import React from 'react';
import IdVerifyResubmitContainer from '../../containers/IdVerifyResubmitContainer';
import ProgressBar2 from '../ProgressBar2';

const IdVerifyResubmitPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <IdVerifyResubmitContainer />
        </div>
      </div>
    </section>
  );
};

export default IdVerifyResubmitPage;
