import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import ErrorMessage from '../ErrorMessage';
import Navigation from '../Navigation';
import TrustedContact from './TrustedContact';
import ExitModal from '../ExitModal';

const TradingAuthorizationForm = (props) => {
  const {
    error,
    handleSubmit,
    onSubmit,
    onBack,
    showTrustedContact,
    trustedContactSelected,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

  const questionsStyle = {
    paddingTop: '20px',
  };

  const imgStyle = {
    marginTop: '24px',
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col col-md-6 col-xs-12" style={questionsStyle}>
        <div className="col col-xs-12">
          {showTrustedContact &&
            <div className="row">
              <div className="help-circle">
                <a data-tip data-for="trustedPerson">
                  <i className="material-icons">help</i>
                </a>
                <ReactTooltip id="trustedPerson" place="right" class="tooltip">
                  <span>
                    Please be advised that by providing contact information for a trusted contact person
                    age 18 or older, you are authorizing TradeStation Securities, Inc. to contact the
                    aforementioned trusted contact person and disclose information about your account
                    in order to address possible financial exploitation, to confirm the specifics of
                    your current contact information, health status, or the identity of any legal guardian,
                    executor, trustee or holder of a power of attorney, or as otherwise permitted by
                    FINRA Rule 2165. TradeStation Securities, Inc. is under no obligation to contact
                    the trusted contact person, if one is provided.
                  </span>
                </ReactTooltip>
              </div>
              <p className="panel-question">
                Would you like to provide us with contact information for a trusted contact person age 18 or older?
              </p>
              <div className="row">
                <div className="col col-xs-12">
                  <div className="form-group form-group-inputs">
                    <div className="radio">
                      <label htmlFor="provideTrustedPersonTrue">
                        <Field
                          name="provideTrustedPerson"
                          id="provideTrustedPersonTrue"
                          component="input"
                          type="radio"
                          value="true"
                        />
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col col-xs-12">
                  <div className="form-group form-group-inputs">
                    <div className="radio">
                      <label htmlFor="provideTrustedPersonFalse">
                        <Field
                          name="provideTrustedPerson"
                          id="provideTrustedPersonFalse"
                          component="input"
                          type="radio"
                          value="false"
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {showTrustedContact && trustedContactSelected &&
            <br />
          }
          {showTrustedContact && trustedContactSelected &&
            <TrustedContact {...props} />
          }
        </div>
      </div>
      <div className="col col-md-6 col-xs-12">
        <div className="ccol col-md-8 col-md-offset-2 hidden-xs hidden-sm text-center">
          <img
            src="images/trusted-contact-alt.png"
            style={imgStyle}
            alt="Trusted Contact"
            className="centered hidden-sm hidden-xs"
          />
        </div>
      </div>
      {showExitModal && (
        <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
      )}
      <div className="col col-xs-12">
        {error && <ErrorMessage error={error} />}
        <Navigation onBack={onBack} onExitModalOn={onExitModalOn} />
      </div>
    </form >
  );
};

TradingAuthorizationForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  showTrustedContact: PropTypes.bool.isRequired,
  trustedContactSelected: PropTypes.bool.isRequired,
  showExitModal: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
};

export default TradingAuthorizationForm;
