import React from 'react';
import InlineFundsContainer from '../../containers/InlineFundsContainer';
import ProgressBar2 from '../ProgressBar2';

const InlineFundsPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appDepositFunds" >
            <InlineFundsContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InlineFundsPage;
