import React from 'react';
import AgreementsFormContainer from '../../containers/AgreementsFormContainer';
import ProgressBar2 from '../ProgressBar2';

const AgreementsPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appAgreements" className="col col-md-12 col-lg-8 col-lg-offset-2">
            <AgreementsFormContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgreementsPage;
