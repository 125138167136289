import Config from '../../../Config';

const config = new Config();

/* eslint-disable import/prefer-default-export */
export const postDocumentValidationInfo = async (applicationId, authToken, data) => {
  const response = await fetch(`${config.apiUrl}/applications/${applicationId}/documentValidation`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    mode: 'cors',
  });
  return response;
};
