import accountTypeTexts from '../components/AccountType/accountTypesTexts';
import personaTypesTexts from '../components/TradingProfile/personaTypesTexts';
import mapCustomer from './mapCustomer';
import Config from '../Config';
import {
  ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME,
  ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH,
  ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION,
} from '../components/AssetClass/assetTypes';
import {
  NONE,
  UNDER_ONE,
  ONE_TO_FIVE,
  OVER_FIVE,
} from '../components/TradingProfile/tradingProfileTypes';

import {
  ACCOUNT_TYPE_JOINT,
  ACCOUNT_TYPE_INDIVIDUAL,
  IRA_TRADITIONAL,
  IRA_ROTH,
  IRA_SEP,
  IRA_SIMPLE,
  ACCOUNT_TYPE_ENTITY,
  IRA_ROTH_INHERITED,
  IRA_TRADITIONAL_INHERITED,
} from '../components/AccountType/accountTypes';

const config = new Config();

const Ga4ECommerceItemNames = Object.freeze({
  equitiesAccount: 'Equities Account',
  futuresAccount: 'Futures Account',
  futuresOptionsAccount: 'Futures Options Account',
  cryptoAccount: 'Crypto Account',
});

export const DOMESTIC_CAT_VALUE = 'miles001';
export const INTERNATIONAL_CAT_VALUE = 'miles000';

export function formatAccountType(accountType) {
  const accountTypeText = accountTypeTexts.find((item) => {
    return item.value === accountType;
  });

  if (accountTypeText === undefined) {
    return 'Not Applicable';
  }

  return accountTypeText.text;
}

export function formatAssetTypes(assetTypes) {
  if (!assetTypes) return null;

  return assetTypes
    .map((assetType) => {
      if (!assetType) return null;
      return assetType[0].toUpperCase() + assetType.slice(1);
    })
    .join(', ');
}

export function formatAssetTypesIR(assetTypes, accountType) {
  if (!assetTypes) return null;

  return assetTypes.map((assetType) => {
    if (!assetType) return null;

    return {
      subTotal: 0.0,
      category: assetType[0].toUpperCase() + assetType.slice(1),
      subCategory: accountType.split(' ').join('_'),
      sku: 'signup',
      quantity: 1,
    };
  });
}

function prepSKU(accountType, accountName) {
  const isJointApplication = accountType.indexOf('Joint') === 0;
  const isEntityApplication = accountType.indexOf('Entity') === 0;

  let acctTypeText = accountType;
  if (isJointApplication) acctTypeText = 'Joint';
  if (isEntityApplication) acctTypeText = 'Entity';
  return `${acctTypeText} - ${accountName}`;
}

function prepCategory(commissionSchedule, isDomestic, assetType) {
  const isCrypto = assetType.indexOf('crypto') === 0;
  if (isCrypto) return 'TS Crypto';
  if (!isDomestic) return 'International';

  let commText = '';
  switch (commissionSchedule) {
    case 'tsgo':
      commText = 'TS Go';
      break;
    case 'tsselect':
      commText = 'TS Select';
      break;
    default:
      commText = commissionSchedule;
  }

  return commText;
}

function prepAccountName(assetType, assetClass) {
  const type = `${assetType[0].toUpperCase() + assetType.slice(1)}`;
  const options = `${assetClass.tradeStockOptions ? ' Options ' : ' '}`;
  const margin = `${assetClass.marginAccount ? 'Margin' : 'Cash'}`;
  let name;

  switch (assetType) {
    case 'equities':
      name = `${type}${options}${margin} Account`;
      break;
    case 'futures':
      name = 'Futures Account';
      break;
    case 'futuresoptions':
      name = 'Futures Options Account';
      break;
    case 'crypto':
      name = 'Crypto Cash Account';
      break;
    default:
      break;
  }

  return name;
}

export function formatAssetTypesEc(
  assetTypes,
  accountType,
  assetClass,
  isDomestic,
) {
  if (!assetTypes || !assetClass) return null;

  return assetTypes.map((assetType) => {
    if (!assetType || !assetClass) return null;
    if (assetType === 'cryptowaitlist') return null;

    const accountName = prepAccountName(assetType, assetClass);

    return {
      sku: prepSKU(accountType, accountName),
      name: accountName,
      category: prepCategory(assetClass.commissionSchedule, isDomestic, assetType),
      price: 0,
      quantity: 1,
    };
  });
}

export const formatECommerceGA4Items = (
  assetTypes,
  accountType,
  assetClass,
  isDomestic,
  optionsLevel,
) => {
  if (!assetTypes || !assetClass) return null;
  return assetTypes.map((assetType) => {
    if (!assetType || !assetClass) return null;
    if (assetType === 'cryptowaitlist') return null;
    const itemName = formatEcommerceItemName(assetType);

    return {
      item_name: itemName,
      item_category: 'Account',
      item_category2: formatItemCategoryTwo(accountType),
      item_category3: isDomestic ? 'TS Select' : 'International',
      item_category4: formatEcommerceItemCategory4(assetClass.marginAccount, itemName),
      item_category5: formatEcommerceItemCategory5(optionsLevel, itemName),
      quantity: 1,
    };
  });
};

export function formatCustomerType(isExistingCustomer) {
  return isExistingCustomer ? 'Existing_Customer' : 'New_Customer';
}

export function formatCustomerTypeIR(isExistingCustomer) {
  return isExistingCustomer ? 'Existing' : 'New';
}

export const calculateAge = (birthdate) => {
  const bdayAsDate = new Date(birthdate);
  const cur = new Date();
  const diff = cur - bdayAsDate; // This is the difference in milliseconds
  const age = Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25
  return age;
};

export const formatRiskTolerance = (riskToleranceValues) => {
  if (!riskToleranceValues || riskToleranceValues.length === 0) return null;
  if (riskToleranceValues.includes(ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION)) { return 3; }
  if (riskToleranceValues.includes(ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH)) { return 2; }
  if (riskToleranceValues.includes(ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME)) return 1;
  return null;
};

export const formatTradingExperience = (choosenOption) => {
  if (choosenOption === NONE) return 1;
  if (choosenOption === UNDER_ONE) return 2;
  if (choosenOption === ONE_TO_FIVE) return 3;
  if (choosenOption === OVER_FIVE) return 4;
  return null;
};

export const formatOptionsLevel = (optionsStrategies) => {
  if (optionsStrategies) {
    const amountOfStrategies = optionsStrategies.length;
    if (amountOfStrategies === 0) return 0;
    if (amountOfStrategies === 2) return 1;
    if (amountOfStrategies === 4) return 2;
    if (amountOfStrategies === 5) return 3;
    if (amountOfStrategies === 6) return 4;
    if (amountOfStrategies === 8) return 5;
  }
  return null;
};

export function formatPersona(persona) {
  if (!persona) return null;

  const personaTypeText = personaTypesTexts.find((item) => {
    return item.value === persona;
  });

  if (personaTypeText === undefined) {
    return '';
  }

  return personaTypeText.persona;
}

export function formatM2Type(address, identity) {
  return mapCustomer.isCustomerDomestic(address, identity)
    ? DOMESTIC_CAT_VALUE
    : INTERNATIONAL_CAT_VALUE;
}

export const postApplicationAnalytics = async (applicationId, authToken, data) => {
  if (config.writeAnalytics) {
    const response = await fetch(
      `${config.apiUrl}/applications/${applicationId}/analytics`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
        body: data,
        mode: 'cors',
      },
    );
    return response;
  }
  return null;
};

const formatEcommerceItemName = (assetType) => {
  let name;
  switch (assetType) {
    case 'equities':
      name = Ga4ECommerceItemNames.equitiesAccount;
      break;
    case 'futures':
      name = Ga4ECommerceItemNames.futuresAccount;
      break;
    case 'futuresoptions':
      name = Ga4ECommerceItemNames.futuresOptionsAccount;
      break;
    case 'crypto':
      name = Ga4ECommerceItemNames.cryptoAccount;
      break;
    default:
      break;
  }
  return name;
};

const formatItemCategoryTwo = (accountType) => {
  let formattedValue;
  switch (true) {
    case accountType.indexOf(ACCOUNT_TYPE_JOINT) === 0:
      formattedValue = 'Joint';
      break;
    case ACCOUNT_TYPE_INDIVIDUAL === accountType:
      formattedValue = 'Individual';
      break;
    case IRA_TRADITIONAL === accountType:
      formattedValue = 'Traditional IRA';
      break;
    case IRA_TRADITIONAL_INHERITED === accountType:
      formattedValue = 'Traditional IRA';
      break;
    case IRA_SEP === accountType:
      formattedValue = 'SEP IRA';
      break;
    case IRA_SIMPLE === accountType:
      formattedValue = 'Simple IRA';
      break;
    case IRA_ROTH === accountType:
      formattedValue = 'Roth IRA';
      break;
    case IRA_ROTH_INHERITED === accountType:
      formattedValue = 'Roth IRA';
      break;
    case accountType.indexOf(ACCOUNT_TYPE_ENTITY) === 0:
      formattedValue = 'Entity';
      break;
    default:
      formattedValue = null;
      break;
  }
  return formattedValue;
};

const formatEcommerceItemCategory4 = (isMarginAccount, itemName) => {
  if (itemName === Ga4ECommerceItemNames.equitiesAccount) {
    return isMarginAccount ? 'Margin' : 'Cash';
  }
  return 'N/A';
};

const formatEcommerceItemCategory5 = (optionsLevel, itemName) => {
  if (itemName === Ga4ECommerceItemNames.equitiesAccount) {
    return optionsLevel === null ? 'Not Enabled' : `${optionsLevel}`;
  }
  return 'N/A';
};

export const aopAnalyticsSteps = {
  ACCOUNT_TYPE: { name: 'ACCOUNT_TYPE', value: 'AOP Step 3 Custom Event' },
  APPLICATION: { name: 'APPLICATION', value: 'AOP Step 4 Custom Event' },
  PERSONAL_INFO: { name: 'PERSONAL_INFO', value: 'AOP Step 4 Custom Event' },
  EMPLOYMENT: { name: 'EMPLOYMENT', value: 'AOP Step 5 Custom Event' },
  FINANCIAL: { name: 'FINANCIAL', value: 'AOP Step 6 Custom Event' },
  ADDITIONAL_INFO: { name: 'ADDITIONAL_INFO', value: 'AOP Step 7 Custom Event' },
  SUMMARY: { name: 'SUMMARY', value: 'AOP Step 8 Custom Event' },
  AGREEMENTS: { name: 'AGREEMENTS', value: 'AOP Step 9 Custom Event' },
  THANK_YOU: { name: 'THANK_YOU', value: 'AOP Step 10 Custom Event' },
};
