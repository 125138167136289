import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inlineButtons, InlineFundingButtons } from './InlineButtonsTypes';
import * as assetTypes from '../../components/AssetClass/assetTypes';
import InlineFundingNavigation from '../InlineFundingNavigation';
import Config from '../../Config';

const config = new Config();

const InlineFunding = (props) => {
  const { registration, address, accountType, onBack, onOptionClick, displayAdditionalOptions } = props;
  const [isDisplayingAdditionalOptions, setIsDisplayingAdditionalOptions] = useState(displayAdditionalOptions);
  const isExistingCustomer = registration ? registration.isExistingCustomer : false;
  const isDomestic = address && address.length > 0 && address[0].residentialCountry === 'US';
  const isCrypto = accountType && accountType.assetTypes
    ? accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO) ||
    accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_CRYPTO_WAITLIST)
    : false;
  const isAnEquitiesAccount = accountType && accountType.assetTypes
    ? accountType.assetTypes.includes(assetTypes.ASSET_CLASS_TYPES_EQUITIES)
    : false;

  const enableAdditionalOptionsBtn = () => {
    return !isDisplayingAdditionalOptions && config.inlineFunding === 'V3';
  };

  const onBackHandler = () => {
    return isDisplayingAdditionalOptions ? backToDefaultOptions : onBack;
  };

  const backToDefaultOptions = () => {
    setIsDisplayingAdditionalOptions(false);
  };

  const redirectToAdditionalOptions = () => {
    setIsDisplayingAdditionalOptions(true);
  };

  const getButtons = () => {
    const buttons = isDisplayingAdditionalOptions ? getAdditionalOptionsButtons() : getButtonsForInitialOptions();
    return buttons.map((button, index) => {
      return (
        <div className="inline-funds-button-container">
          <div className="col col-sm-12 col-md-12 col-lg-12">
            <button
              type="submit"
              id={`${InlineFundingButtons[button].id}_${isDomestic ? 'us' : 'int'}`}
              onClick={() => onOptionClick(InlineFundingButtons[button], isDisplayingAdditionalOptions)}
              className={index > 0 ?
                `${InlineFundingButtons[button].className} inline-funds-button-second`
                : `${InlineFundingButtons[button].className} inline-funds-button-primary`}
            >
              {InlineFundingButtons[button].label}
            </button>
          </div>
          {
            (!isDomestic && InlineFundingButtons[button].id === 'link_bank_btn') &&
            <div className="col col-sm-12 col-md-12 col-lg-12">
              <h5 className="button-botton-text">
                Available for bank accounts held in the U.S only
              </h5>
            </div>
          }
        </div>
      );
    });
  };

  const getButtonsForInitialOptions = () => {
    let buttons;
    const isInternational = !isDomestic;
    switch (true) {
      case isDomestic && isExistingCustomer:
        buttons = inlineButtons.existingDomesticCustomerOptions;
        break;
      case isDomestic && !isExistingCustomer:
        buttons = inlineButtons.newDomesticCustomerOptions;
        break;
      case isInternational && isExistingCustomer:
        buttons = inlineButtons.existingInternationalCustomerOptions;
        break;
      case isInternational && !isExistingCustomer:
        buttons = inlineButtons.newInternationalCustomerOptions;
        break;
      default:
        buttons = [];
        break;
    }
    if (config.inlineFunding === 'V3' && isCrypto && !buttons.includes(inlineButtons.depositCryptoOptions)) {
      const start = buttons.length - 1;
      if (isInternational && isExistingCustomer) {
        buttons.splice(start, 0, inlineButtons.depositCryptoOptions);
      }
      if (isInternational && !isExistingCustomer) {
        buttons.splice(start, 0, inlineButtons.depositCryptoOptions);
      } else if (!buttons.includes(inlineButtons.depositCryptoOptions)) {
        buttons.splice(buttons.length, 0, inlineButtons.depositCryptoOptions);
      }
    }
    if (isDomestic && isAnEquitiesAccount && !buttons.includes(inlineButtons.equitiesOnlyOptions)) {
      buttons.splice(buttons.length - 1, 0, inlineButtons.equitiesOnlyOptions);
    }
    return buttons;
  };

  const getAdditionalOptionsButtons = () => (isDomestic ?
    inlineButtons.domesticAdditionalOptions : inlineButtons.internationalAdditionalOptions);

  const craftBackButtonName = () => {
    return isDisplayingAdditionalOptions ? 'additional-funding-options-back-btn' : 'inline-funding-back-btn';
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col col-md-12 col-lg-8 col-lg-offset-2">
          <h1 className="text-center inline-funds-title">
            Let&apos;s Fund Your Account
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
          <h1 className="control-label inline-funds-subtitle">
            How would you like to fund your account?
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
          {
            getButtons()
          }
          <br />
          {enableAdditionalOptionsBtn() &&
            <div className="inline-funds-button-container">
              <btn
                id="additional-options-btn"
                className="btn btn-info slimh_element fitv_element slimv_element reset_capitals additional-options"
                onClick={redirectToAdditionalOptions}
                target="_blank"
                rel="noopener noreferrer"
                role="button"
              >
                Additional Options<i className="material-icons">arrow_right</i>
              </btn>
            </div>
          }
        </div>

      </div>
      <div className="row navigation-container">
        <InlineFundingNavigation backButtonId={craftBackButtonName()} onBack={onBackHandler()} />
      </div>
    </div>
  );
};

InlineFunding.propTypes = {
  registration: PropTypes.shape(),
  address: PropTypes.shape(),
  onBack: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  accountType: PropTypes.shape(),
  displayAdditionalOptions: PropTypes.bool.isRequired,
};

export default InlineFunding;
