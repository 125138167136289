import React from 'react';
import IntroContainer from '../../containers/IntroContainer';

const IntroPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <div className="container">
        <div className="row">
          <div id="appIntro" className="col col-md-12 col-lg-10 col-lg-offset-1" >
            <IntroContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroPage;
