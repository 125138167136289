import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import IdVerifyResubmit from '../components/IdVerifyResubmit/IdVerifyResubmit';
import * as contactActions from '../actions/contactActions';
import * as idVerifyActions from '../actions/idVerifyActions';
import * as countriesActions from '../actions/countriesActions';
import * as RouteNavigator from './RouteNavigator';
import * as contactSearchAndFormat from './contactSearchAndFormat';

export class IdVerifyResubmitContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      contact,
      idVerify,
      countries,
    } = this.props;

    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!idVerify) actions.fetchIdVerify(applicationId, authToken);
    if (!countries) actions.fetchCountries();
  }

  // eslint-disable-next-line
  handleBack() {
    RouteNavigator.push('/personal-info');
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    return (
      <IdVerifyResubmit
        {...this.props}
        onBack={this.handleBack}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

IdVerifyResubmitContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  userId: PropTypes.number,
  contact: PropTypes.shape(),
  idVerify: PropTypes.shape(),
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  actions: PropTypes.shape({
    fetchContact: PropTypes.func.isRequired,
    fetchIdVerify: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToInitialValues(state) {
  const contacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const idVerifies = (state.idVerify && state.idVerify.length > 0) ? state.idVerify : null;
  let isJointApp = false;
  let primaryNeedsResubmit = false;
  let primaryResubmitValues = [];
  let jointNeedsResubmit = false;
  let jointResubmitValues = [];
  let primaryAccountOwner = '';
  let jointAccountOwner = '';

  if (contacts && contacts.length > 0) {
    const primaryContact = contactSearchAndFormat.findContactByType('primary', contacts);
    primaryAccountOwner = contactSearchAndFormat.formatContactName(primaryContact);
    if (contacts.length > 1) {
      const jointContact = contactSearchAndFormat.findContactByType('joint', contacts);
      jointAccountOwner = contactSearchAndFormat.formatContactName(jointContact);
    }
  }

  if (contacts && contacts.length > 0 && idVerifies && idVerifies.length > 0) {
    if (contacts.length > 1) {
      // joint account
      isJointApp = true;
      const primary = contacts[0].type === 'primary' ? contacts[0] : contacts[1];
      const joint = contacts[1].type === 'joint' ? contacts[1] : contacts[0];

      if (idVerifies.length > 1) {
        // both accounts need resubmit
        if (idVerifies[0].aditionalInformation && idVerifies[0].aditionalInformation.length > 0 &&
          (idVerifies[0].aditionalInformation.includes('SSN') || idVerifies[0].aditionalInformation.includes('ADD') ||
          idVerifies[0].aditionalInformation.includes('DOB'))) {
          primaryNeedsResubmit = true;
          primaryResubmitValues = idVerifies[0].aditionalInformation;
        }
        if (idVerifies[1].aditionalInformation && idVerifies[1].aditionalInformation.length > 0 &&
          (idVerifies[1].aditionalInformation.includes('SSN') || idVerifies[1].aditionalInformation.includes('ADD') ||
          idVerifies[1].aditionalInformation.includes('DOB'))) {
          jointNeedsResubmit = true;
          jointResubmitValues = idVerifies[1].aditionalInformation;
        }
      } else if (idVerifies.length === 1) {
        // only one account needs resubmit in joint app
        // compare contactId in idVerifies to contactId in contacts
        const idVerify = idVerifies[0];
        if (idVerify.aditionalInformation && idVerify.aditionalInformation.length > 0) {
          if (primary.id === idVerify.contactId) {
              primaryNeedsResubmit = true;
              primaryResubmitValues = idVerify.aditionalInformation;
          } else if (joint.id === idVerify.contactId) {
              jointNeedsResubmit = true;
              jointResubmitValues = idVerify.aditionalInformation;
          }
        }
      }
    } else if (idVerifies[0] && idVerifies[0].aditionalInformation && idVerifies[0].aditionalInformation.length > 0) {
      // individual account
      primaryNeedsResubmit = true;
      primaryResubmitValues = idVerifies[0].aditionalInformation;
    }
  }

  const values = {
    primaryAccountOwner,
    jointAccountOwner,
    contacts,
    idVerifies,
    isJointApp,
    primaryNeedsResubmit,
    primaryResubmitValues,
    jointNeedsResubmit,
    jointResubmitValues,
  };

  return values;
}

function mapStateToProps(state) {
  return {
    initialValues: mapStateToInitialValues(state),
    applicationId: state.applicationId,
    authToken: state.authToken,
    userId: state.userId,
    contact: state.contact,
    idVerify: state.idVerify,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(
      {},
      contactActions,
      idVerifyActions,
      countriesActions),
      dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'idVerify',
})(IdVerifyResubmitContainer));

