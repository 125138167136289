import React from 'react';

const RegistrationCredentialsFormLoading = () => {
  return (
    <div>
      <div className="row">
        <div className="col col-md-12 text-center">
          <h1 className="h1_secondary text-center">Account Setup</h1>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12 text-center">
          <img src="images/1488.gif" alt="spinner" />
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-8 col-lg-offset-2 text-center">
          <h1 className="control-label inline-funds-subtitle">
            Please wait while we create your user profile...
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col col-md-12">&nbsp;</div>
      </div>
    </div>
  );
};

export default RegistrationCredentialsFormLoading;
