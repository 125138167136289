import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import IdVerifyResubmitIndividual from './IdVerifyResubmitIndividual';
import IdVerifyResubmitJoint from './IdVerifyResubmitJoint';
import ExitModal from '../ExitModal';

const IdVerifyResubmit = (props) => {
  const {
    initialValues,
    onBack,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

return (
  <div>
    <h1 id="page-header" className="h1_secondary text-center">
      We couldn&apos;t verify your identity
    </h1>
    <div id="appIdVerify" className="col col-md-12 col-lg-8 col-lg-offset-2">
      <div className="idVerifyResubmitPageDiv">
        <div className="idVerifyResubmitInstructionsDiv">
          <p className="text-center col col-md-12 col-lg-8 col-lg-offset-2 idVerifyResubmitParagraph">
            We were unable to verify the identity information provided for applicant(s) listed below.
            Please ensure that your information was entered correctly and try again.
          </p>
        </div>

        {(initialValues.primaryNeedsResubmit && !initialValues.isJointApp) && (
          <IdVerifyResubmitIndividual initialValues={initialValues} />
        )}

        {(initialValues.isJointApp) && (
          <IdVerifyResubmitJoint initialValues={initialValues} />
        )}
      </div>
      <div className="idVerifyResubmitButton">
        <button
          onClick={onBack}
          className={'btn btn-raised btn-default do_capitalize triple-button-style'}
        >
          Review Information
        </button>
      </div>
      {showExitModal && (
        <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
      )}
      <div className="col col-xs-12">
        <Navigation showBack={false} showNext={false} onExitModalOn={onExitModalOn} />
      </div>
    </div>
  </div>
  );
};

IdVerifyResubmit.propTypes = {
    onBack: PropTypes.func.isRequired,
    initialValues: PropTypes.shape(),
    showExitModal: PropTypes.bool,
    onExitModalOn: PropTypes.func.isRequired,
    onExitModalOff: PropTypes.func.isRequired,
};

export default IdVerifyResubmit;
