import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYMENT_SUCCESS:
      return action.employment;
    case actionTypes.SUBMIT_EMPLOYMENT_SUCCESS:
      return action.employment;
    default:
      return state;
  }
}
