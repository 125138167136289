import React from 'react';

const FinancialFormHeader = () => {
  return (
    <div className="row">
      <div className="col col-md-12">
        <h1 className="h1_secondary text-center">Financials &#38; Trading Experience</h1>
      </div>
    </div>
  );
};

export default FinancialFormHeader;
