import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchCampaignRequest() {
  return {
    type: actionTypes.FETCH_CAMPAIGN_REQUEST,
  };
}

export function fetchCampaignSuccess(campaign) {
  return {
    type: actionTypes.FETCH_CAMPAIGN_SUCCESS,
    campaign,
  };
}

export function fetchCampaignError(error) {
  return {
    type: actionTypes.FETCH_CONTACT_ERROR,
    error,
  };
}

export function fetchCampaign(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchCampaignRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/campaign`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchCampaignSuccess(json)))
      .catch(error => dispatch(fetchCampaignError(error)));
  };
}

export function submitCampaignError(error) {
  return { type: actionTypes.SUBMIT_CAMPAIGN_ERROR, error };
}

export function submitCampaignRequest() {
  return { type: actionTypes.SUBMIT_CAMPAIGN_REQUEST };
}

export function submitCampaignSuccess(campaign) {
  return { type: actionTypes.SUBMIT_CAMPAIGN_SUCCESS, campaign };
}

// eslint-disable-next-line no-underscore-dangle
function _submitCampaign(campaign, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitCampaignRequest());

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(campaign),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/campaign`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitCampaignSuccess(json)))
      .catch((error) => {
        dispatch(submitCampaignError(error));
        return Promise.reject(error);
      });
  };
}

export function submitCampaign(campaign, applicationId, authToken) {
  return _submitCampaign(campaign, applicationId, authToken, 'POST');
}

export function updateCampaign(campaign, applicationId, authToken) {
  return _submitCampaign(campaign, applicationId, authToken, 'PUT');
}
