import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const CheckboxSameJointAddress = ({ isResidential, sectionIndex, handleCopyFromPrimary }) => {
  const copyFromPrimary = isResidential
    ? `addresses[${sectionIndex}].residential.copyFromPrimary`
    : `addresses[${sectionIndex}].mailing.copyFromPrimary`;

  return (
    <div className="form-group label-floating small">
      <div className="checkbox small">
        <label htmlFor={copyFromPrimary}>
          <Field
            id={copyFromPrimary}
            type="checkbox"
            name="copyFromPrimary"
            component="input"
            value={!copyFromPrimary}
            onChange={event => handleCopyFromPrimary(event)}
          />
          Same as Primary Account Holder
        </label>
      </div>
    </div>
  );
};

CheckboxSameJointAddress.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  isResidential: PropTypes.bool.isRequired,
  handleCopyFromPrimary: PropTypes.func.isRequired,
};

export default CheckboxSameJointAddress;
