import React from 'react';
import ApprovalStatusContainer from '../../containers/ApprovalStatusContainer';
import EntityProgressBar from '../EntityProgressBar';

const ApprovalEntityPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <EntityProgressBar />
      <div className="container">
        <div className="row">
          <div id="applicationPage" className="col col-md-12 col-lg-12">
            <ApprovalStatusContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApprovalEntityPage;
