import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const InlineFundingNavigation = (
  {
    onBack,
    backLabel = 'Back',
    backButtonId,
    hideBackButton,
  },
) => {
  const hideBackButtonStyle = hideBackButton ? 'btn-hidden' : '';
  return (
    <div className="form-navigation justify-content-between">
      <div className={'inline-funding-navigation-container'}>
        <div className="col-xs-6 col-md-8">
          <button
            id={backButtonId}
            type="button"
            className={`btn back-button-inline-funding do_capitalize ${hideBackButtonStyle}`}
            onClick={onBack}
          >
            <i className="material-icons icon-back">arrow_left</i>
            {backLabel}
          </button>
        </div>
        <div className="col-xs-6 col-md-4">
          <div className="navbar-header assistance-container">
            <p className="assistance-label">Need assistance? &nbsp;</p>
            <a
              id="contact-us-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tradestation.com/contact-us/"
            >
              Contact us.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

InlineFundingNavigation.propTypes = {
  onBack: PropTypes.func,
  backButtonId: PropTypes.string,
  backLabel: PropTypes.string,
  hideBackButton: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.isSubmitting,
  };
}

export default connect(mapStateToProps)(InlineFundingNavigation);
