import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchDocumentsRequiredRequest() {
  return {
    type: actionTypes.FETCH_DOCUMENTS_REQUIRED_REQUEST,
  };
}

export function fetchDocumentsRequiredSuccess(documentsRequired) {
  return {
    type: actionTypes.FETCH_DOCUMENTS_REQUIRED_SUCCESS,
    documentsRequired,
  };
}

export function fetchDocumentsRequiredError(error) {
  return {
    type: actionTypes.FETCH_DOCUMENTS_REQUIRED_ERROR,
    error,
  };
}

export function fetchDocumentsRequired(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchDocumentsRequiredRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/documentsRequired/?applicationId=${applicationId}`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchDocumentsRequiredSuccess(json)))
      .catch(error => dispatch(fetchDocumentsRequiredError(error)));
  };
}
