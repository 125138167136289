import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../actions/userActions';
import LogOff from '../components/LogOff/LogOff';
import signOutUrl from '../lib/signOutUrl';

const redirectToAccountType = () => {
  window.location = signOutUrl('/account-type');
};

export class LogOffContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.logInWithDifferentUser = this.logInWithDifferentUser.bind(this);
  }

  logInWithDifferentUser() {
    this.props.actions.userLogout(this.props.authToken).then(redirectToAccountType);
  }

  render() {
    return (
      <LogOff
        {...this.props}
        logInWithDifferentUser={this.logInWithDifferentUser}
      />
    );
  }
}

LogOffContainer.propTypes = {
  actions: PropTypes.shape({
    userLogout: PropTypes.func.isRequired,
  }).isRequired,
  applicationId: PropTypes.string,
  authToken: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    applicationId: state.applicationId,
    authToken: state.authToken,
    userId: state.userId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      userActions,
    ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogOffContainer);
