import * as actionTypes from './actionTypes';
import fetchEntities from './fetchEntitiesAction';

const entityName = 'documentValidationRequired';
const payloadName = 'idVerify';

// eslint-disable-next-line import/prefer-default-export
export function fetchIdVerify(applicationId, authToken) {
  const actionsTypes = {
    request: actionTypes.FETCH_IDVERIFY_REQUEST,
    success: actionTypes.FETCH_IDVERIFY_SUCCESS,
    error: actionTypes.FETCH_IDVERIFY_ERROR,
  };
  return fetchEntities(entityName, payloadName, actionsTypes, applicationId, authToken);
}
