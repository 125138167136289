import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export const LogoffWarningModal = (
  {
    showModal,
    toggleWarning,
    timeLeft,
  },
) => {
  return (
    <Modal show={showModal}>
      <Modal.Header>
        <div>
          <p className="warningPopup">
            Your session is about to expire! <br className="visible-xs" /> {timeLeft} seconds left...
          </p>
          <p className="warningPopup2">
            As a security precaution, your session will automatically end due to inactivity.
            Click OK to resume your application or you will be automatically logged out.
          </p>
        </div>
      </Modal.Header>
      <Modal.Footer>
        <div className="text-center intro-legacy-message warningPopupDiv">
          <button
            onClick={toggleWarning}
            className="btn btn-raised btn-default btn-crypto-modal"
          >
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

LogoffWarningModal.propTypes = {
  showModal: PropTypes.bool,
  toggleWarning: PropTypes.func,
  timeLeft: PropTypes.number,
};

export default LogoffWarningModal;
