import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_REGISTRATION_SUCCESS:
      return action.registration;
    case actionTypes.FETCH_REGISTRATION_SUCCESS:
      return action.registration;
    default:
      return state;
  }
}
