const reasonCodes = {
    R804: 'Document image doesn\'t meet the minimum color requirements',
    R810: 'Document pattern and layout integrity check failed',
    R819: 'Document submitted is captured from a digital screen',
    R820: 'Document headshot has been modified',
    R822: 'First name extracted from document does not match input first name',
    R823: 'Last name extracted from document does not match input last name',
    R824: 'Address extracted from document does not match input address',
    R825: 'DOB extracted from document does not match input DOB',
    R826: 'Document Number extracted from document does not match input number',
    R827: 'Document is expired. Try again with a valid ID document.',
    R831: 'Cannot extract the minimum information from barcode',
    R832: 'Document number extracted from front and back of document does not match',
    R833: 'Cannot extract the minimum required information from MRZ',
    R834: 'Self-portrait fails liveness check',
    R835: 'More than one face detected in the selfie',
    R836: 'Document image does not correlate with self-portrait',
    R838: 'Minimum amount of information cannot be extracted from document',
    R839: 'Name extracted from document does not match with barcode or MRZ',
    R840: 'Address extracted from document does not match with barcode or MRZ',
    R841: 'DOB on document does not match DOB on barcode or MRZ',
    R842: 'Expiration date on document does not match date on barcode or MRZ',
    R843: 'Issue date on document does not match date on barcode or MRZ',
    R844: 'Dates on document are not valid',
    R845: 'Minimum age criteria not met',
    R850: 'Self-portrait or the headshot is not usable for Facial Match',
    R851: 'Document number is an example number, and is not valid',
    R852: 'Submitted document is a temporary ID',
    R853: 'Unable to classify the ID or this is an unsupported ID type',
    R855: 'First name did not match with any known Nick name or Alias',
    R856: 'Obstructions on the face affecting the liveness',
    R857: 'No face found in the selfie frame',
    R858: 'The age on the document doesn\'t correlate with the selfie predicted age',
};

export default reasonCodes;

