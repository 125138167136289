import validatePhone from '../validations/PhoneValidation';

export function findCountry(countryCode, countries) {
  if (!countries || !countries.find) return {};
  return countries.find((item) => { return item.countryCode === countryCode; });
}

export function findRegion(countryCode, regionCode, countries) {
  if (!countries || countries.length === 0 || !countries.find || !countryCode) return {};
  const country = countries.find((c) => { return c.countryCode === countryCode; });
  const region = country.regions.find((r) => { return r.regionCode === regionCode; });
  return region;
}

export function findContactById(contactId, contacts) {
  if (!contacts || !contacts.find) return {};
  return contacts.find((c) => { return c.id === contactId; });
}

export function findContactByType(contactType, contacts) {
  if (!contacts || !contacts.find) return {};
  return contacts.find((c) => { return c.type === contactType; });
}

export function findEntityByContactId(contactId, entities) {
  return entities.find((a) => { return a.contactId === contactId; });
}

export function isTselCountry(countries, countryCode) {
  const country = findCountry(countryCode, countries);
  return (country) ? country.isTSEL : false;
}

export function formatContactName(contact) {
  const { firstName, middleInitial, lastName } = contact;
  return (middleInitial)
    ? `${firstName} ${middleInitial} ${lastName}`
    : `${firstName} ${lastName}`;
}

export function isContactDataMissingOrInvalid(contact, country) {
  let missingData = false;
  if (!contact.phone || !contact.countryOfResidence) {
    missingData = true;
  } else if (country && country.regions && country.regions.length > 0 && !contact.regionOfResidence) {
    missingData = true;
  } else if (country && validatePhone(contact.phone, country.countryCode)) {
    missingData = true;
  }
  return missingData;
}

export function contactMissingFields(contact) {
  const missingFields = [];
  if (!contact.phone) missingFields.push('phone');
  if (!contact.countryOfResidence) {
    missingFields.push('countryOfResidence');
    missingFields.push('regionOfResidence');
  }
  if (!contact.regionOfResidence) missingFields.push('regionOfResidence');
  return missingFields;
}

export function mapContact(contact, countries) {
  let isTSEL = false;
  if (countries && countries.length > 0) {
    isTSEL = isTselCountry(countries, contact.countryOfResidence);
  }
  return {
    id: contact ? contact.id : null,
    type: contact ? contact.type : 'joint',
    firstName: contact ? contact.firstName : '',
    middleInitial: contact ? contact.middleInitial : '',
    lastName: contact ? contact.lastName : '',
    email: contact ? contact.email : '',
    phone: contact ? contact.phone : '',
    countryOfResidence: contact ? contact.countryOfResidence : '',
    isTSEL,
  };
}

export function isContactInCountryRegion(accountType, contacts, contactType, country, region) {
  let isInCountryRegion = false;

  if (contacts && contacts.length > 0) {
    const contact = findContactByType(contactType, contacts);
    if (contact) {
      isInCountryRegion = contact.countryOfResidence === country;
      if (isInCountryRegion && region) {
        isInCountryRegion = contact.regionOfResidence === region;
      }
    }
  }

  return isInCountryRegion;
}

export function shouldRemoveRegion(accountType, contacts, constantType, country, region) {
  if (contacts && contacts.length > 0) {
    const isEquities = accountType && accountType.assetTypes.includes('equities');
    if (isEquities && !isContactInCountryRegion(accountType, contacts, constantType, country, region)) {
      return true;
    }
  }

  return false;
}
