import React from 'react';
import PropTypes from 'prop-types';

const FuturesIraDisclaimer = (props) => {
  const {
    page,
    iraHasOnlyFutures,
  } = props;
  const paragraphClass = page === 'summary' ? 'futuresIraMessageBoxSummaryPage' : 'futuresIraMessageBox';
  const imgClass = page === 'summary' ? 'futuresIraInfoIconSummaryPage' : 'futuresIraInfoIcon';
  const onlyFuturesStyle = iraHasOnlyFutures ? 'iraOnlyFuturesStyle' : '';

  if (page === 'funding') {
    return (
      <p className={`${paragraphClass} futuresFundingMessageBox`}>
        <img
          className={`img-responsive ${imgClass} futuresFundingMessageBoxIcon`}
          src="images/info-icon.png"
          alt="Information Icon"
        />
        To complete the setup of your Futures IRA account(s), you will need to ensure you have completed
        the application process with the custodian, Midland Trust. If you have not yet done so, please complete
        the application process by clicking below.
      </p>
    );
  }
  return (
    <p className={`${paragraphClass} ${onlyFuturesStyle}`}>
      <img className={`img-responsive ${imgClass}`} src="images/info-icon.png" alt="Information Icon" />
      Beneficiary Selection for Futures &amp; Futures Options IRA accounts is available via the Custodian
      (Midland Trust) and can only be selected after you complete this application and your account is approved.
    </p>
  );
};

FuturesIraDisclaimer.propTypes = {
    page: PropTypes.string,
    iraHasOnlyFutures: PropTypes.bool,
  };

export default FuturesIraDisclaimer;
