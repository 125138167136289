import * as assetTypes from './assetTypes';

export const assetClassTexts = [
  {
    value: '',
    text: '',
  },
  {
    value: assetTypes.ASSET_CLASS_TYPES_EQUITIES,
    text: 'Equities & Options',
  },
  {
    value: assetTypes.ASSET_CLASS_TYPES_CRYPTO,
    text: 'Crypto',
  },
  {
    value: assetTypes.ASSET_CLASS_TYPES_FUTURES,
    text: 'Futures',
  },
  {
    value: assetTypes.ASSET_CLASS_TYPES_FUTURES_OPTIONS,
    text: 'Futures Options',
  },
  {
    value: assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_PER_SHARE,
    text: 'Per-Share',
  },
  {
    value: assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_PER_TRADE,
    text: 'Per-Trade',
  },
  {
    value: assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSGO,
    text: 'TS GO',
  },
  {
    value: assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT,
    text: 'TS SELECT',
  },
  {
    value: assetTypes.ASSET_CLASS_COMMISSION_SCHEDULE_UNBUNDLED,
    text: 'Unbundled',
  },
  {
    value: assetTypes.ASSET_CLASS_ACCOUNT_MARGIN,
    text: 'Margin',
  },
  {
    value: assetTypes.ASSET_CLASS_ACCOUNT_CASH,
    text: 'Cash',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME,
    text: 'Income',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION,
    text: 'Speculation',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH,
    text: 'Growth',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_STRADDLES,
    text: 'Straddles',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_CALLS,
    text: 'Selling Uncovered Calls',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_PUTS,
    text: 'Selling Uncovered Puts',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS,
    text: 'Call and Put Spreads',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS,
    text: 'Covered Puts',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS,
    text: 'Buying Calls and Puts',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS,
    text: 'Selling Covered Calls',
  },
  {
    value: assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS,
    text: 'Protective Puts',
  },
];

export const investmentRiskTolerance = (optionsInvestmentGoals) => {
  const levels = {};
  levels[assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME] = 1;
  levels[assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH] = 2;
  levels[assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION] = 3;

  const levelsDisplay = {};
  levelsDisplay[1] = 'Income';
  levelsDisplay[2] = 'Growth';
  levelsDisplay[3] = 'Speculation';

  const selectedLevels = [];
  for (let i = 0; i < optionsInvestmentGoals.length; i += 1) {
    selectedLevels.push(levels[optionsInvestmentGoals[i]]);
  }

  return levelsDisplay[Math.max(...selectedLevels)];
};

export const stockOptionsTradingLevel = (optionsStrategies) => {
  const levels = {};
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_STRADDLES] = 5;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_CALLS] = 5;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_PUTS] = 4;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS] = 3;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS] = 2;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS] = 2;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS] = 1;
  levels[assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS] = 1;

  const selectedLevels = [];
  for (let i = 0; i < optionsStrategies.length; i += 1) {
    selectedLevels.push(levels[optionsStrategies[i]]);
  }

  return `Level ${Math.max(...selectedLevels)}`;
};
