import * as recaptcha from 'recaptcha-v3';
import Config from '../Config';

const config = new Config();

// eslint-disable-next-line import/prefer-default-export
export function attachRecaptchaToken(request, action) {
  return new Promise((resolve) => {
    const result = Object.assign({}, request);
    recaptcha.load(config.recaptchaSiteKey)
      .then((recaptchaInstance) => {
        recaptchaInstance.execute(action)
        .then((token) => {
          result.authorization = token;
          resolve(result);
        })
        .catch(() => {
          resolve(result);
        });
      })
      .catch(() => {
        resolve(result);
      });
  });
}
