import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextBox from '../TextBox';
import DropDownList from '../DropDownList';

const Contact = (props) => {
  const {
    countries,
    regions,
    handlePhoneChange,
    phoneCountry,
    phoneStatus,
    jointPhone,
  } = props;
  return (
    <div className="contact">
      <div className="row">
        <div className="col col-lg-4 col-sm-4 col-xs-12">
          <TextBox
            name="firstName"
            className="form-control"
            label="First Name"
            maxLength={30}
          />
        </div>
        <div className="col col-lg-2 col-sm-2 col-xs-12">
          <TextBox
            name="middleInitial"
            className="form-control"
            label="M.I."
            maxLength={1}
          />
        </div>
        <div className="col col-lg-6 col-sm-6 col-xs-12">
          <TextBox
            name="lastName"
            className="form-control"
            label="Last Name"
            maxLength={30}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-6 col-sm-6 col-xs-12">
          <DropDownList
            id="countryOfResidence"
            fieldName="countryOfResidence"
            className="form-control"
            label="Country of Legal Residence"
            options={countries}
            disabled
          />
        </div>
        <div className="col col-lg-6 col-sm-6 col-xs-12">
          <DropDownList
            id="regionOfResidence"
            fieldName="regionOfResidence"
            className="form-control"
            label="State or Province"
            options={regions}
          />
        </div>
        <div className="col col-lg-6 col-sm-6 col-xs-12">
          <TextBox
            type="email"
            name="email"
            className="form-control"
            label="Email Address"
            maxLength={50}
          />
        </div>
        <div className="col col-lg-6 col-sm-6 col-xs-12">
          { phoneCountry === 'je' &&
            <PhoneInput
              id="phone"
              name="phone"
              label="Phone Number"
              maxLength={25}
              inputClass={phoneStatus === 'false' ? 'phoneInput phoneError' : 'phoneInput'}
              countryCodeEditable={false}
              enableTerritories
              disableDropdown
              placeholder="Phone Number"
              priority={{ je: 0, gb: 1 }}
              country={phoneCountry}
              value={jointPhone}
              onChange={phone => handlePhoneChange(phone)}
            />
          }
          { phoneCountry !== 'je' &&
            <PhoneInput
              id="phone"
              name="phone"
              label="Phone Number"
              maxLength={25}
              inputClass={phoneStatus === 'false' ? 'phoneInput phoneError' : 'phoneInput'}
              countryCodeEditable={false}
              enableTerritories
              disableDropdown
              placeholder="Phone Number"
              priority={{ gb: 0, je: 1 }}
              country={phoneCountry}
              value={jointPhone}
              onChange={phone => handlePhoneChange(phone)}
            />
          }
          <div className="form-group label-floating has-error">
            <div className="messages">
              {phoneStatus === 'false' &&
                <p className="help-block error phoneErrorMessage">Not a valid Phone Number</p>
              }
              {phoneStatus === 'empty' &&
                <p className="help-block error phoneRequiredMessage">Required</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  handlePhoneChange: PropTypes.func,
  phoneCountry: PropTypes.string,
  phoneStatus: PropTypes.string,
  jointPhone: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default Contact;

