import React from 'react';

const TSCOM_IMPORTANT_INFORMATION = 'https://www.tradestation.com/important-information/';
const PRIVACY_POLICY = 'privacy';
const PRIVACY_POLICY_HREF = `${TSCOM_IMPORTANT_INFORMATION}${PRIVACY_POLICY}`;

const GDPRDisclaimer = () => {
  return (
    <div>
      By clicking below, you acknowledge our&nbsp;
      <a
        className="btn btn-info slimh_element fitv_element slimv_element reset_capitals"
        tabIndex="-1"
        href={PRIVACY_POLICY_HREF}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Notice&nbsp;
      </a>
      <span>
        and agree to receive marketing offers from TradeStation Securities, Inc. and its affiliates in the form of
        emails, phone calls, pre-recorded messages, text messages, and autodialed calls at the email address
        and phone number provided above, even if the phone number is present on a state or national Do Not Call list.
        You are not required to provide this consent as a condition of doing business with us and you can withdraw consent at any time. Data rates may apply.
      </span>
    </div>
  );
};

export default GDPRDisclaimer;
