import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useIdleTimer } from 'react-idle-timer';
import * as userActions from '../actions/userActions';
import signOutUrl from '../lib/signOutUrl';
import Footer from './Footer';
import LogoffWarningModal from '../components/LogoffWarningModal';
import HeaderContainer from '../containers/HeaderFormContainer';
import TokenTimerContainer from '../containers/TokenTimerContainer';
import configureDatadog from '../lib/configureDatadog';
import Config from '../Config';

configureDatadog();

const config = new Config();
const timeout = config.limitTimeMilliSeconds;
const promptBeforeIdle = config.warningTimeMilliSeconds;

const paddingTop = {
  paddingTop: '0px',
};

const App = (props) => {
  const isAgreementsPage = window.location.pathname === '/agreements';
  const hideHeaderFor = ['/intro', '/logoff'];
  const hideFooterFor = ['/logoff'];
  const hideHeader = hideHeaderFor.includes(window.location.pathname);
  const hideFooter = hideFooterFor.includes(window.location.pathname);

  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setOpen(false);
    if (props.authToken) {
      props.actions.userLogout(props.authToken)
        .then(() => {
          window.location = signOutUrl('/logout.html');
        });
    }
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    if (props.authToken) {
      setOpen(true);
    }
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    events: ['keydown', 'mousedown', 'touchstart', 'MSPointerDown'],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <div>
      {!hideHeader &&
        <HeaderContainer
          user={props.user}
          authToken={props.authToken}
          userId={props.userId}
          location={window.location.pathname}
        />
      }
      <div className="notranslate" style={paddingTop}>
        {props.children}
      </div>
      {(!config.cryptoEnable || !isAgreementsPage) && !hideFooter &&
        <Footer />
      }
      {config.auth0Enabled &&
        <TokenTimerContainer authToken={props.authToken} />
      }
      <LogoffWarningModal
        showModal={open}
        toggleWarning={handleStillHere}
        timeLeft={remaining}
      />
    </div>
  );
};

App.propTypes = {
  user: PropTypes.shape(),
  authToken: PropTypes.string,
  userId: PropTypes.number,
  children: PropTypes.element,
  actions: PropTypes.shape({
    userLogout: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
    userActions),
    dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
