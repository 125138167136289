import React from 'react';
import RegistrationCredentialsFormContainer from '../../containers/RegistrationCredentialsFormContainer';
import ProgressBar2 from '../ProgressBar2';

const RegistrationCredentialsPage = () => {
  return (
    <div>
      <section className="form-content-section-primary" >
        <ProgressBar2 />
        <div className="container">
          <div className="row">
            <div className="col col-md-12 col-lg-8 col-lg-offset-2">
              <div className="panel panel-clear">
                <div className="panel-body">
                  <div className="row">
                    <div id="appRegistrationCredentials" className="col-md-12">
                      <RegistrationCredentialsFormContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegistrationCredentialsPage;
