import React from 'react';
import PropTypes from 'prop-types';

const IdVerifyIndividual = (props) => {
  const {
    initialValues,
    startWebSDK,
    webSDKResult,
    resubmitReasons,
  } = props;

  const nameDiv = {
    borderTop: '1px solid #c2c7cf',
    margin: 'auto',
    marginBottom: '1%',
  };

  const idVerifyButton = {
    float: 'right',
  };

  const idVerified = {
    color: 'green',
    float: 'right',
    paddingTop: (initialValues.isJointApp ? '2.2%' : '2%'),
  };

  const customerName = {
    float: 'left',
    display: 'inline-block',
    width: '70%',
  };

  const statusIcon = {
    display: 'inline-block',
    float: 'left',
    marginTop: '4%',
    marginRight: '3%',
  };

  return (
    <div className="row docVTab" style={nameDiv}>
      <div style={customerName} className="jointAccountTab">
        <i className="material-icons icon-success" style={statusIcon}>
          {(webSDKResult === 'accept' || webSDKResult === 'reject') ? 'check_circle' : ''}
        </i>
        <div className="resubmitNameText">
          <h4 className={'h2_subHeading h2_subHeadingBorderless text-center nameText'}>
            {initialValues.primaryAccountOwner}
          </h4>
          <br />
          { webSDKResult === 'resubmit' && resubmitReasons && (
            <div className="resubmitDescription">
              {resubmitReasons.map(reason => (<p className="resubmitText">{reason}</p>))}
            </div>
          )}
        </div>
      </div>
      {(webSDKResult === 'none' || webSDKResult === 'resubmit' || webSDKResult === 'error') && (
        <button
          id="next_btn"
          onClick={startWebSDK}
          className={'btn btn-raised btn-default do_capitalize triple-button-style idVerifyButton'}
          style={idVerifyButton}
        >
          Verify Identity
        </button>)}
      {(webSDKResult === 'accept') && (
        <h4 style={idVerified}>Received</h4>
      )}
      {(webSDKResult === 'reject') && (
        <h4 style={idVerified}>Received</h4>
      )}
    </div>
  );
};

IdVerifyIndividual.propTypes = {
  startWebSDK: PropTypes.func.isRequired,
  webSDKResult: PropTypes.string.isRequired,
  initialValues: PropTypes.shape(),
  resubmitReasons: PropTypes.shape(),
};

export default IdVerifyIndividual;
