/* eslint-disable max-len */
import Config from '../Config';

const PW_STRING = 'Password must be a valid string';
const PW_INVALID = 'Password fails validation';
export const PW_INCLUDES_FIRSTNAME = 'Password must not include your first name';
export const PW_INCLUDES_LASTNAME = 'Password must not include your last name';
export const PW_INCLUDES_USERNAME = 'Password must not include your username';
export const PW_INCLUDES_EMAIL = 'Password must not include any part of your email address';
export const PW_LENGTH = 'Password length minimum 9 and maximum 23';
export const PW_REQUIRES_NO_SPACES = 'Password requires no leading, trailing or embedded spaces';
export const PW_REQUIRES_UPPER = 'Password requires at least one uppercase character';
export const PW_REQUIRES_LOWER = 'Password requires at least one lowercase character';
export const PW_REQUIRES_DIGIT = 'Password requires at least one digit';
export const PW_REQUIRES_SYMBOL = 'Password requires at least one symbol';
export const PW_CONTAINS_UNSUPPORTED_SYMBOL = 'Password contains an unsupported symbol.';

const config = new Config();
// eslint-disable-next-line no-useless-escape
const REGEX_V2 = /^(?=.*[a-z])(?=(.*[A-Z]))(?=(.*[0-9]))(?=.*[!"#$%&()*+,\-.\/\;<=>?@[\]^_`{|}~])[\w!"#$%&()*+,\-.\/\;<=>?@[\]^_`{|}~]{9,23}$/g;

export default function validatePassword(credentials) {
  const { firstName, lastName, email, username, password } = credentials;

  const errors = [];

  let pw;
  if (password && typeof password === 'string' && password.length > 0) {
    pw = password;
  } else {
    errors.push(PW_STRING);
    return errors;
  }

  if (pw.length < 9 || pw.length > 23) {
    errors.push(PW_LENGTH);
  }

  if (/\s/g.test(pw)) {
    errors.push(PW_REQUIRES_NO_SPACES);
  }

  if (!/[A-Z]/.test(pw)) {
    errors.push(PW_REQUIRES_UPPER);
  }

  if (!/[a-z]/.test(pw)) {
    errors.push(PW_REQUIRES_LOWER);
  }

  if (!/[0-9]/.test(pw)) {
    errors.push(PW_REQUIRES_DIGIT);
  }

  // eslint-disable-next-line no-useless-escape
  if (config.passwordRedesignEnabled && !/[`!@#$%^&*()_+\-=\[\]{};"\|,.<>\/?~]/.test(pw)) {
    errors.push(PW_REQUIRES_SYMBOL);
  }

  if (config.passwordRedesignEnabled && /[^\w`!@#$%^&*()_+\-=[\]{};"|,.<>/?~ ]/.test(pw)) {
    errors.push(PW_CONTAINS_UNSUPPORTED_SYMBOL);
  }

  if (firstName) {
    if (pw.toLowerCase().includes(firstName.toLowerCase())) {
      errors.push(PW_INCLUDES_FIRSTNAME);
    }
  }

  if (lastName) {
    if (pw.toLowerCase().includes(lastName.toLowerCase())) {
      errors.push(PW_INCLUDES_LASTNAME);
    }
  }

  if (username) {
    if (pw.toLowerCase().includes(username.toLowerCase())) {
      errors.push(PW_INCLUDES_USERNAME);
    }
  }

  if (email) {
    const em = email.toLowerCase().split('@');
    if (em && em.length > 0) {
      if (pw.toLowerCase().includes(em[0])) {
        errors.push(PW_INCLUDES_EMAIL);
      }
    } else {
      errors.push('Could not parse email');
    }
  }

  const matches = pw.match(REGEX_V2);
  if (!matches) {
    errors.push(PW_INVALID);
  }

  return errors;
}
