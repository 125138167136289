import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import DropDownList from '../../components/DropDownList';
import TextBox from '../../components/TextBox';
import normalizeMoney from './normalizeMoney';


const FinancialFormSection = (props) => {
  const {
    sectionIndex,
    annualIncomeOptions,
    netWorthOptions,
    sourceOfIncomeOptions,
    sourceOfAssetsForTradingOptions,
    showSpecificAnnualIncome,
    showSpecificTotalNetWorth,
    showSpecificLiquidNetWorth,
  } = props;

  const isPrimary = sectionIndex === 0;

  return (
    <div>
      <div className="row">
        <div>
          <div className="col col-md-12">
            <h1 className="h2_subHeading ">Financials <span className="blue-text">
              {isPrimary ? '' : '(Joint)'}</span></h1>
            <p>
              We are required to collect certain financial information for tax purposes.
            </p>
          </div>
        </div>
        <div className="col col-md-12">
          <DropDownList
            fieldName="annualIncome"
            className="form-control"
            label="Approximate Annual Income:"
            options={annualIncomeOptions}
          />
          {showSpecificAnnualIncome[sectionIndex] &&
            <TextBox
              name="annualIncomeSpecified"
              className="form-control"
              label="Please specify (in USD):"
              maxLength={9}
              normalize={normalizeMoney}
            />
          }
          <DropDownList
            id="totalNetWorth"
            fieldName="totalNetWorth"
            className="form-control"
            label="Approximate Total Net Worth:"
            options={netWorthOptions}
            tooltip
          />
          <ReactTooltip id="totalNetWorth" place="left" class="tooltip" effect="solid">
            <span>
              Total Net Worth equals all investment, checking, savings, real estate holdings and other
              assets, minus all obligations, debts and liabilities (excluding assets and liabilities relating
              to family residence).
            </span>
          </ReactTooltip>
          {showSpecificTotalNetWorth[sectionIndex] &&
            <TextBox
              name="totalNetWorthSpecified"
              className="form-control"
              label="Please specify (in USD):"
              maxLength={9}
              normalize={normalizeMoney}
            />
          }
          <DropDownList
            id="liquidNetWorth"
            fieldName="liquidNetWorth"
            className="form-control"
            label="Approximate Liquid Net Worth:"
            options={netWorthOptions}
            tooltip
          />
          <ReactTooltip id="liquidNetWorth" place="left" class="tooltip" effect="solid" >
            <span>
              Liquid Net Worth includes cash and marketable securities, minus all obligations, debts and liabilities.
            </span>
          </ReactTooltip>
          {showSpecificLiquidNetWorth[sectionIndex] &&
            <TextBox
              name="liquidNetWorthSpecified"
              className="form-control"
              label="Please specify (in USD):"
              maxLength={9}
              normalize={normalizeMoney}
            />
          }
          <DropDownList
            fieldName="sourceOfIncome"
            className="form-control"
            label="What is your Source of Income:"
            options={sourceOfIncomeOptions[sectionIndex]}
          />
          <DropDownList
            id="sourceOfAssetsForTrading"
            fieldName="sourceOfAssetsForTrading"
            className="form-control"
            label="How will you fund your account?"
            options={sourceOfAssetsForTradingOptions}
            tooltip
          />
          <ReactTooltip id="sourceOfAssetsForTrading" place="left" class="tooltip" effect="solid">
            <span>
              Please select the source of assets to be deposited.
            </span>
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

FinancialFormSection.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  annualIncomeOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  netWorthOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  sourceOfIncomeOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }))),
  sourceOfAssetsForTradingOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  showSpecificAnnualIncome: PropTypes.arrayOf(PropTypes.bool),
  showSpecificTotalNetWorth: PropTypes.arrayOf(PropTypes.bool),
  showSpecificLiquidNetWorth: PropTypes.arrayOf(PropTypes.bool),
};

export default FinancialFormSection;
