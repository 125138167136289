import React from 'react';
import DepositFundsContainer from '../../containers/DepositFundsContainer';
import ProgressBar2 from '../ProgressBar2';

const DepositFundsPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appDepositFunds" >
            <DepositFundsContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DepositFundsPage;
