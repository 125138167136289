import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';
import { sortCountriesAndRegions } from '../lib/sortFilterMapCountriesRegions';

const config = new Config();

export function fetchCountriesRequest() {
  return {
    type: actionTypes.FETCH_COUNTRIES_REQUEST,
  };
}

export function fetchCountriesSuccess(countries) {
  return {
    type: actionTypes.FETCH_COUNTRIES_SUCCESS,
    countries,
  };
}

export function fetchCountriesError(error) {
  return {
    type: actionTypes.FETCH_COUNTRIES_ERROR,
    error,
  };
}

export function fetchCountries() {
  return (dispatch) => {
    dispatch(fetchCountriesRequest());
    return fetch(`${config.apiUrl}/countries`)
      .then(checkHttpError)
      .then(responseToJson)
      .then(sortCountriesAndRegions)
      .then(data => dispatch(fetchCountriesSuccess(data)))
      .catch(error => dispatch(fetchCountriesError(error)));
  };
}
