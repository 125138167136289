import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import IdVerifySDK from './IdVerifySDK';
import IdVerifyIndividual from './IdVerifyIndividual';
import IdVerifyJointAppPrimaryOwner from './IdVerifyJointApps';
import ExitModal from '../ExitModal';

const IdVerify = (props) => {
  const {
    initialValues,
    onBack,
    showNextBtn,
    webSDKInProgress,
    accountTypeInProgress,
    startWebSDK,
    endWebSDK,
    webSDKResult,
    webSDKResultJoint,
    handleSubmit,
    onSubmit,
    resubmitReasons,
    resubmitReasonsJoint,
    loadingScreenSDK,
    showExitModal,
    onExitModalOn,
    onExitModalOff,
  } = props;

const instructionsDiv = {
  overflow: 'auto',
  marginBottom: '2%',
};

const instructionsText = {
  textAlign: 'left',
  fontFamily: 'Hind',
};

const pageDiv = {
  marginBottom: '3%',
  marginTop: '1.4%',
};

return (
  <div>
    {/* eslint-disable no-mixed-operators */}
    <h1 id="page-header" className="h1_secondary text-center">
      Identity Verification {(webSDKInProgress && initialValues.isJointApp) ? (
      `- ${(accountTypeInProgress === 'primary' ? initialValues.primaryAccountOwner :
      initialValues.jointAccountOwner)}`) : ''}
    </h1>
    <div id="appIdVerify" className="col col-md-12 col-lg-8 col-lg-offset-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        {!webSDKInProgress && (
        <div style={pageDiv}>
          <div style={instructionsDiv}>
            {((!initialValues.isJointApp && webSDKResult === 'none') || (initialValues.isJointApp &&
            (initialValues.bothNeedDocV && (webSDKResult === 'none' || webSDKResultJoint === 'none') &&
            (webSDKResult !== 'resubmit' && webSDKResultJoint !== 'resubmit') && (webSDKResult !== 'error'
            && webSDKResultJoint !== 'error')) || !initialValues.bothNeedDocV && ((initialValues.primaryNeedsDocV
            && webSDKResult === 'none') || (initialValues.jointNeedsDocV && webSDKResultJoint === 'none')))) &&
            (<p
              className="text-center col col-md-12 col-lg-8 col-lg-offset-2 docVInstructions"
              style={instructionsText}
            >
              As an additional security measure, we need to verify the identity of the account owner(s) listed below
              by capturing a copy of a valid government-issued ID, such as:
              <br />
              <br />
              {/* eslint-disable react/no-unescaped-entities */}
              &ensp; - Driver's License <br />
              &ensp; - State issued Identification Card <br />
              &ensp; - U.S Passport / Passport Card <br />
              &ensp; - Military ID <br />
            </p>)}
            {showNextBtn &&
            (<p className="text-center col col-md-12 col-lg-8 col-lg-offset-2">
              Please click <b>Next</b> to resume your account application.
            </p>)}
            {(webSDKResult === 'error' || webSDKResultJoint === 'error') && (
              <p className="text-center col col-md-12 col-lg-8 col-lg-offset-2">
                Oops, something went wrong.  Please save your application and try again later.
              </p>)}
            {(webSDKResult === 'resubmit' || webSDKResultJoint === 'resubmit') && (
              <p className="text-center col col-md-12 col-lg-8 col-lg-offset-2">
                One or more issues were detected. See details below and try again.
              </p>
            )}
          </div>

          {(initialValues.primaryNeedsDocV && !initialValues.isJointApp) && (
            <IdVerifyIndividual
              initialValues={initialValues}
              startWebSDK={startWebSDK}
              webSDKResult={webSDKResult}
              resubmitReasons={resubmitReasons}
            />
          )}

          {(initialValues.isJointApp) && (
            <IdVerifyJointAppPrimaryOwner
              initialValues={initialValues}
              startWebSDK={startWebSDK}
              webSDKResult={webSDKResult}
              webSDKResultJoint={webSDKResultJoint}
              resubmitReasons={resubmitReasons}
              resubmitReasonsJoint={resubmitReasonsJoint}
            />
          )}
        </div>)}

        {webSDKInProgress && (
          <IdVerifySDK endWebSDK={endWebSDK} loadingScreenSDK={loadingScreenSDK} />
        )}
        {showExitModal && (
          <ExitModal onExitModalOn={onExitModalOn} onExitModalOff={onExitModalOff} />
        )}
        <div className="col col-xs-12">
          <Navigation onBack={onBack} showNext={showNextBtn} onExitModalOn={onExitModalOn} />
        </div>
      </form>
    </div>
  </div>
  );
};

IdVerify.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    showNextBtn: PropTypes.bool.isRequired,
    startWebSDK: PropTypes.func.isRequired,
    webSDKResult: PropTypes.string.isRequired,
    webSDKResultJoint: PropTypes.string.isRequired,
    endWebSDK: PropTypes.func.isRequired,
    webSDKInProgress: PropTypes.bool.isRequired,
    accountTypeInProgress: PropTypes.string.isRequired,
    initialValues: PropTypes.shape(),
    resubmitReasons: PropTypes.shape(),
    resubmitReasonsJoint: PropTypes.shape(),
    loadingScreenSDK: PropTypes.bool.isRequired,
    showExitModal: PropTypes.bool,
    onExitModalOn: PropTypes.func.isRequired,
    onExitModalOff: PropTypes.func.isRequired,
};

export default IdVerify;
