import { isContactInCountryRegion, findContactByType, findEntityByContactId } from './contactSearchAndFormat';
import * as accountTypes from '../components/AccountType/accountTypes';
import * as assetTypes from '../components/AssetClass/assetTypes';
import * as constants from '../lib/constants';
import Config from '../Config';

const config = new Config();

export function switchedToJoint(selectedAccountType, initialAccountType) {
  let switched = false;
  if (selectedAccountType && selectedAccountType.indexOf('joint') > -1) {
    if (initialAccountType.indexOf('individual') > -1) {
      switched = true;
    }
  }
  return switched;
}

export function getAssetClassExceptionMessage(contacts, accountType) {
  let regionOrCountry = null;
  let assetClass = 'Equity';
  if (contacts && contacts.length > 0) {
    // eslint-disable-next-line max-len
    if (isContactInCountryRegion(accountType, contacts, constants.PRIMARY, constants.CANADA, constants.ONTARIO)) {
      regionOrCountry = 'Canada';
      // eslint-disable-next-line max-len
    } else if (isContactInCountryRegion(accountType, contacts, constants.PRIMARY, constants.INDIA)) {
      regionOrCountry = 'India';
      assetClass = 'margin, options, or futures';
    } else {
      regionOrCountry = null;
    }
  }

  return (regionOrCountry)
    ? `At this time, TradeStation does not offer ${assetClass} trading to customers in ${regionOrCountry}.`
    : null;
}

const isMarginAccount = (assetClass) => {
  return typeof assetClass.equitiesAccountMargin === 'boolean'
    ? assetClass.equitiesAccountMargin
    : false;
};

export function setInvestmentRiskMarks() {
  return Object.assign({}, constants.riskToleranceMarks);
}

export function setOptionsStrategiesMarks(selectedAccountType, valuesAssetClass) {
  const marks = Object.assign({}, constants.optionStrategiesMarks);

  const isCashAccount = !isMarginAccount(valuesAssetClass);
  const isIra = selectedAccountType && selectedAccountType.indexOf('ira') > -1;
  if (isIra && !isCashAccount) {
    Object.assign(marks, { 100: { disabled: true, label: '5' } });
  }

  if (isCashAccount) {
    Object.assign(marks, { 75: { disabled: true, label: '4' } });
    Object.assign(marks, { 100: { disabled: true, label: '5' } });
  }

  return marks;
}

export function setInvestmentGoals(optionsInvestmentGoals) {
  let investmentObjectives = 0;

  if (optionsInvestmentGoals.includes(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION)) {
    investmentObjectives = 100;
  } else if (optionsInvestmentGoals.includes(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH)) {
    investmentObjectives = 50;
  } else {
    investmentObjectives = 0;
  }

  return investmentObjectives;
}

export function mapInvestmentGoals(selectedGoals) {
  const investmentGoal = [];

  switch (selectedGoals) {
    case 50:
      investmentGoal.push(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH);
      investmentGoal.push(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME);
      break;
    case 100:
      investmentGoal.push(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION);
      investmentGoal.push(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH);
      investmentGoal.push(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME);
      break;
    default:
      investmentGoal.push(assetTypes.ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME);
  }

  return investmentGoal;
}

export function setInvestmentObjective(riskLevel, optionsLevel, previousOptionsLevel) {
  let newRiskLevel = riskLevel;
  if (optionsLevel > previousOptionsLevel) {
    switch (optionsLevel) {
      case 100:
      case 75:
        newRiskLevel = 100;
        break;
      case 50:
      case 25:
        newRiskLevel = 50;
        break;
      default:
        break;
    }
  }

  return Math.max(riskLevel, newRiskLevel);
}

export function setOptionsLevel(optionsLevel, riskLevel, previousRiskLevel, isIra) {
  let newOptionsLevel = optionsLevel;
  if (riskLevel < previousRiskLevel) {
    switch (riskLevel) {
      case 100:
        newOptionsLevel = isIra ? 75 : 100;
        break;
      case 50:
        newOptionsLevel = 50;
        break;
      case 0:
        newOptionsLevel = 0;
        break;
      default:
        break;
    }
  }

  return Math.min(optionsLevel, newOptionsLevel);
}

export function setOptionsStrategies(optionsStrategies) {
  let strategies = 0;

  if (optionsStrategies.includes(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_STRADDLES)) {
    strategies = 100;
  } else if (optionsStrategies.includes(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_PUTS)) {
    strategies = 75;
  } else if (optionsStrategies.includes(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS)) {
    strategies = 50;
  } else if (optionsStrategies.includes(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS)) {
    strategies = 25;
  } else {
    strategies = 0;
  }

  return strategies;
}

export function mapOptionsStrategies(selectedStrategies) {
  const optionsStrategies = [];

  switch (selectedStrategies) {
    case 25: // Level 2
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS);
      break;
    case 50: // Level 3
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS);
      break;
    case 75: // Level 4
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_PUTS);
      break;
    case 100: // Level 5
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_PUTS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_CALLS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_STRADDLES);
      break;
    default: // Level 1
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS);
      optionsStrategies.push(assetTypes.ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS);
      break;
  }

  return optionsStrategies;
}

const checkRegionRequired = (region, country) => {
  return region && country.regions && country.regions.length > 0;
};

export const canTradeEquities = (contact, country) => {
  let equitiesAllowed = country && country.isEquitiesAllowed;

  if (equitiesAllowed && checkRegionRequired(contact.regionOfResidence, country)) {
    const equitiesAllowedRegions = country.isEquitiesAllowedRegions;
    if (equitiesAllowedRegions && equitiesAllowedRegions.length > 0) {
      equitiesAllowed = equitiesAllowedRegions.map(r => r.regionCode).includes(contact.regionOfResidence);
    }
  }

  return equitiesAllowed;
};

export const canTradeFutures = (contact, country, accountType) => {
  let futuresAllowed = country && country.isFuturesAllowed;

  if (futuresAllowed && !config.futuresIraEnabled) {
    futuresAllowed = accountType !== accountTypes.ACCOUNT_TYPE_IRA;
  }

  if (futuresAllowed && checkRegionRequired(contact.regionOfResidence, country)) {
    const futuresAllowedRegions = country.isFuturesAllowedRegions;
    if (futuresAllowedRegions && futuresAllowedRegions.length > 0) {
      futuresAllowed = futuresAllowedRegions.map(r => r.regionCode).includes(contact.regionOfResidence);
    }
  }

  return futuresAllowed;
};

export const canTradeOptions = (country) => {
  const disallowedCountries = ['IN'];
  return !disallowedCountries.includes(country);
};

export const canTradeMargin = (country) => {
  const disallowedCountries = ['IN'];
  return !disallowedCountries.includes(country);
};

export const canTradeCrypto = (contact, country, accountType, hasCryptoAccount) => {
  let cryptoAllowed;
  if (config.allowMultipleCrypto && country) {
    cryptoAllowed =
      country.isCryptoAllowed
      && country.isEquitiesAllowed
      && (config.isCryptoAvailableForIRA || accountType !== accountTypes.ACCOUNT_TYPE_IRA);
  } else {
    cryptoAllowed = country &&
      country.isCryptoAllowed
      && country.isEquitiesAllowed
      && (config.isCryptoAvailableForIRA || accountType !== accountTypes.ACCOUNT_TYPE_IRA)
      && !hasCryptoAccount;
  }

  return cryptoAllowed;
};

export const isCryptoWaitListApplication = (contacs, addresses, selectedAccountType, country) => {
  let isWaitList = false;
  const primaryContact = findContactByType('primary', contacs);
  const primaryAddress = addresses ? findEntityByContactId(primaryContact.id, addresses) : null;
  const jointContact = findContactByType('joint', contacs);
  const jointAddress = (jointContact && addresses) ? findEntityByContactId(jointContact.id, addresses) : null;
  if (country.isCryptoAllowed) {
    const cryptoAllowedRegions = country.isCryptoAllowedRegions;
    if (cryptoAllowedRegions && cryptoAllowedRegions.length > 0) {
      if (primaryContact) {
        isWaitList = !cryptoAllowedRegions.map(r => r.regionCode).includes(primaryContact.regionOfResidence);
      }
      if (primaryAddress) {
        isWaitList =
          isWaitList || !cryptoAllowedRegions.map(r => r.regionCode).includes(primaryAddress.residentialRegion);
      }
      if (jointContact && selectedAccountType && selectedAccountType.indexOf('joint') > -1) {
        isWaitList =
          isWaitList || !cryptoAllowedRegions.map(r => r.regionCode).includes(jointContact.regionOfResidence);
      }
      if (jointAddress && selectedAccountType && selectedAccountType.indexOf('joint') > -1) {
        isWaitList =
          isWaitList || !cryptoAllowedRegions.map(r => r.regionCode).includes(jointAddress.residentialRegion);
      }
    }
  }
  return isWaitList;
};

export const isCryptoWaitListContact = (contact, country) => {
  let isWaitList = false;
  if (country.isCryptoAllowed) {
    const cryptoAllowedRegions = country.isCryptoAllowedRegions;
    if (cryptoAllowedRegions && cryptoAllowedRegions.length > 0) {
      isWaitList = !cryptoAllowedRegions.map(r => r.regionCode).includes(contact.regionOfResidence);
    }
  }
  return isWaitList;
};

export const isTSGOAvailable = (contacts, employments) => {
  let tsgoAvailable = false;
  if (contacts && contacts.length > 0) {
    contacts.forEach((contact) => {
      if (tsgoAvailable) tsgoAvailable = contact.countryOfResidence === 'US';
    });
  }
  if (employments && employments.length > 0) {
    employments.forEach((employment) => {
      if (tsgoAvailable) tsgoAvailable = !employment.isPro;
    });
  }
  return tsgoAvailable;
};

export const getInitialAssets = (contact, country, hasCryptoAccount, promoCodeSession) => {
  const assets = [];

  if (promoCodeSession && promoCodeSession === config.SNIDER_OFFER_CODE) {
    if (canTradeEquities(contact, country)) {
      assets.push(assetTypes.ASSET_CLASS_TYPES_EQUITIES);
    }
  } else {
    if (canTradeEquities(contact, country)) {
      assets.push(assetTypes.ASSET_CLASS_TYPES_EQUITIES);
    }
    if (canTradeCrypto(contact, country, accountTypes.ACCOUNT_TYPE_INDIVIDUAL, hasCryptoAccount)
      && !isCryptoWaitListContact(contact, country)) {
      assets.push(assetTypes.ASSET_CLASS_TYPES_CRYPTO);
    }
    if (canTradeFutures(contact, country, accountTypes.ACCOUNT_TYPE_INDIVIDUAL)) {
      assets.push(assetTypes.ASSET_CLASS_TYPES_FUTURES);
    }
  }

  return assets;
};
