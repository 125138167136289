import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as actionTypes from '../actions/actionTypes';
import accountTypeReducer from './accountTypeReducer';
import agreementsReducer from './agreementsReducer';
import authTokenReducer from './authTokenReducer';
import countriesReducer from './countriesReducer';
import contactReducer from './contactReducer';
import documentsReducer from './documentsReducer';
import documentsRequiredReducer from './documentsRequiredReducer';
import financialReducer from './financialReducer';
import registrationReducer from './registrationReducer';
import assetClassReducer from './assetClassReducer';
import applicationReducer from './applicationReducer';
import applicationErrorsReducer from './applicationErrorsReducer';
import applicationIdReducer from './applicationIdReducer';
import addressReducer from './addressReducer';
import identityReducer from './identityReducer';
import idVerifyReducer from './idVerifyReducer';
import employmentReducer from './employmentReducer';
import tradingProfileReducer from './tradingProfileReducer';
import tradingAuthorizationReducer from './tradingAuthorizationReducer';
import salesRepsReducer from './salesRepsReducer';
import hearAboutUsReducer from './hearAboutUsReducer';
import securityCredentialsReducer from './securityCredentialsReducer';
import isSubmittingReducer from './isSubmittingReducer';
import isSignedReducer from './isSignedReducer';
import industriesReducer from './industriesReducer';
import pendingApplicationsReducer from './pendingApplicationsReducer';
import userIdReducer from './userIdReducer';
import iraAccountReducer from './iraAccountReducer';
import userReducer from './userReducer';
import trustedContactReducer from './trustedContactReducer';
import campaignReducer from './campaignReducer';
import recaptchaReducer from './recaptchaReducer';
import { clearCryptoSession } from '../lib/cryptoSessionHelper';
import * as storageKeys from '../actions/storageKeys';

function clearLocalCache() {
  sessionStorage.removeItem(storageKeys.AUTH_TOKEN_KEY);
  sessionStorage.removeItem(storageKeys.APPLICATION_ID_KEY);
  clearCryptoSession();
}

const appReducer = combineReducers({
  accountType: accountTypeReducer,
  agreements: agreementsReducer,
  assetClass: assetClassReducer,
  authToken: authTokenReducer,
  contact: contactReducer,
  countries: countriesReducer,
  documents: documentsReducer,
  documentsRequired: documentsRequiredReducer,
  employment: employmentReducer,
  financial: financialReducer,
  applicationId: applicationIdReducer,
  application: applicationReducer,
  applicationErrors: applicationErrorsReducer,
  registration: registrationReducer,
  address: addressReducer,
  identity: identityReducer,
  idVerify: idVerifyReducer,
  form: formReducer,
  tradingProfile: tradingProfileReducer,
  tradingAuthorization: tradingAuthorizationReducer,
  salesReps: salesRepsReducer,
  hearAboutUs: hearAboutUsReducer,
  securityCredentials: securityCredentialsReducer,
  isSubmitting: isSubmittingReducer,
  isSigned: isSignedReducer,
  industries: industriesReducer,
  pendingApplications: pendingApplicationsReducer,
  userId: userIdReducer,
  user: userReducer,
  trustedContact: trustedContactReducer,
  iraAccount: iraAccountReducer,
  campaign: campaignReducer,
  isRecaptchaVerified: recaptchaReducer,
});

export default (state, action) => {
  if (action.type === actionTypes.USER_LOGOUT_SUCCESS) {
    clearLocalCache();
    return appReducer(undefined, action);
  } else if (action.type === actionTypes.CLEAR_APPLICATION_DATA) {
    return appReducer({
      authToken: state.authToken,
      userId: state.userId,
      user: state.user,
      agreements: state.agreements,
      countries: state.countries,
      industries: state.industries,
      salesReps: state.salesReps,
      hearAboutUs: state.hearAboutUs,
      pendingApplications: state.pendingApplications,
    }, action);
  }

  return appReducer(state, action);
};
