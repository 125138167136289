import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import { attachRecaptchaToken } from './attachRecaptchaToken';
import Config from '../Config';

const config = new Config();

export function fetchAccountTypeRequest() {
  return {
    type: actionTypes.FETCH_ACCOUNT_TYPE_REQUEST,
  };
}

export function fetchAccountTypeSuccess(accountType) {
  return {
    type: actionTypes.FETCH_ACCOUNT_TYPE_SUCCESS,
    accountType,
  };
}

export function fetchAccountTypeError(error) {
  return {
    type: actionTypes.FETCH_ACCOUNT_TYPE_ERROR,
    error,
  };
}

export function fetchAccountType(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchAccountTypeRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/accounttype`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchAccountTypeSuccess(json)))
      .catch(error => dispatch(fetchAccountTypeError(error)));
  };
}

export function submitAccountTypeError(error) {
  return { type: actionTypes.SUBMIT_ACCOUNT_TYPE_ERROR, error };
}

export function submitAccountTypeRequest(accountType) {
  return { type: actionTypes.SUBMIT_ACCOUNT_TYPE_REQUEST, accountType };
}

export function submitAccountTypeSuccess(accountType) {
  return { type: actionTypes.SUBMIT_ACCOUNT_TYPE_SUCCESS, accountType };
}

// eslint-disable-next-line no-underscore-dangle
function _submitAccountType(accountType, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitAccountTypeRequest(accountType));
    return attachRecaptchaToken(accountType, 'accounttype')
      .then((data) => {
        const options = {
          method,
          headers: {
            Accept: 'application/json',
            Authorization: authToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          mode: 'cors',
        };
        return fetch(`${config.apiUrl}/applications/${applicationId}/accounttype`, options);
      })
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitAccountTypeSuccess(json)))
      .catch((error) => {
        dispatch(submitAccountTypeError(error));
        return Promise.reject(error);
      });
  };
}

export function submitAccountType(accountType, applicationId, authToken) {
  return _submitAccountType(accountType, applicationId, authToken, 'POST');
}

export function updateAccountType(accountType, applicationId, authToken) {
  return _submitAccountType(accountType, applicationId, authToken, 'PUT');
}
