import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_PENDING_APPLICATIONS_ERROR:
      return [];
    case actionTypes.FETCH_PENDING_APPLICATIONS_REQUEST:
      return null;
    case actionTypes.FETCH_PENDING_APPLICATIONS_SUCCESS:
      return action.pendingApplications;
    default:
      return state;
  }
}
