import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const renderField = (props) => {
  const {
    input,
    beneficiaryType,
  } = props;


  const beneficiaryTitle = `${beneficiaryType === 'primary' ? 'Primary' : 'Contingent'} Beneficiary`;

  return (
    <div>
      {input.value && input.value.length > 0 &&
        <h2 className="contactHeading">{input.value}</h2>
      }
      <h2 className="contactSubHeading">{beneficiaryTitle}</h2>
    </div>
  );
};

renderField.propTypes = {
  input: PropTypes.shape(),
  beneficiaryType: PropTypes.string.isRequired,
};

const IraBeneficiaryHeading = ({ name, beneficiaryType }) => {
  return (
    <Field
      name={name}
      component={renderField}
      beneficiaryType={beneficiaryType}
    />
  );
};

IraBeneficiaryHeading.propTypes = {
  name: PropTypes.string.isRequired,
  beneficiaryType: PropTypes.string.isRequired,
};

export default IraBeneficiaryHeading;

