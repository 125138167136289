let prevUsername = '';

function shouldCheckUsernameAvailability(username, props) {
  let result = true;
  const usernameError = !!(props && props.asyncErrors && props.asyncErrors.username);
  if (!username) result = false;
  if (prevUsername === username && !usernameError) result = false;
  if (result) prevUsername = username;
  return result;
}

// eslint-disable-next-line import/prefer-default-export
export { shouldCheckUsernameAvailability };
