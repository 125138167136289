/* eslint-disable react/jsx-indent */
import React from 'react';

const AgreementsCryptoDisclaimer = () => {
  return (
    <div className="row">
      <div className="col col-xs-12">
        <p>
          So that you can withdraw cash from your TradeStation Securities
          Equities account to purchase Crypto in your TradeStation Crypto
          account, and deposit in your TradeStation Securities Equities account
          cash proceeds you receive from sales of Crypto in your TradeStation
          Crypto account, you acknowledge and agree that your TradeStation
          Crypto account will be linked to your TradeStation Securities Account.
          For linked Individual Retirement Accounts (“IRAs”), the IRA custodian
          does not consider withdrawals from the TradeStation Securities IRA
          Equities account to purchase Crypto in your TradeStation Crypto IRA
          account to be distributions, nor does the IRA custodian consider the
          deposit of cash proceeds in your TradeStation Securities IRA Equities
          account that you receive from sales of Crypto in your TradeStation
          Crypto IRA account to be contributions for tax reporting purposes.
        </p>
        <p>
          If you are opening an Individual Retirement Account (“IRA”), by
          signing this account application, you acknowledge and agree that: (a)
          no TradeStation company has provided, and no TradeStation company will
          provide, you with any investment, financial, tax or legal advice in
          connection with your decision to open your IRA, the type of IRA, your
          selection of the custodian with whom you open your IRA , or any
          investment or transaction in your IRA; (b) you have made or will make
          your own independent investigations and assessments with respect to
          your IRA, your selection of the type of account, the custodian with
          whom you open your IRA account and any investments and transactions in
          your IRA; (c) no TradeStation company has solicited, and no
          TradeStation company will solicit, you to acquire any investment or
          enter into any transaction in your IRA; (d) no TradeStation company is
          a fiduciary and no TradeStation company will make any recommendations
          to you regarding your IRA, the type of IRA you select, your selection
          of the custodian with whom you open your IRA account or any investment
          or transaction in your IRA; and (e) no TradeStation company is
          responsible for, and you will hold harmless each TradeStation company
          and its affiliates from, any and all tax consequences and other
          liability or losses that you incur in your IRA.<b> You understand and agree
          that the IRA Custodian agreement you agreed to, accepted, and acknowledged
          to open your TradeStation Securities Equities IRA applies to your TradeStation
          Crypto IRA. You further agree that the beneficiary information you provide for your
          TradeStation Securities Equities IRA applies to your TradeStation Crypto IRA.</b>
        </p>
      </div>
    </div>
  );
};

export default AgreementsCryptoDisclaimer;
