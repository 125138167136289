import { browserHistory } from 'react-router';

export function getURLParameterByName(name) {
  const url = window.location ? window.location.search : '';
  const param = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regexS = `[\\?&]${param}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);

  // ensure offer code is only 8 characters
  if (name === 'offer' && results) {
    results[1] = results[1].substring(0, 8);
  }

  return results == null ? null : results[1];
}

export function push(redirectPath, ignoreSummaryRedirect = false) {
  let uriRedirect = getURLParameterByName('redirectTo');
  if (uriRedirect === '/summary' && ignoreSummaryRedirect) {
    uriRedirect = null;
  }
  browserHistory.push(!uriRedirect ? redirectPath : uriRedirect);
}

export function getURLPathName() {
  const url = window.location;
  let pathName = url.pathname;
  pathName = pathName.replace(/\//g, '');
  return pathName;
}
export default {};
