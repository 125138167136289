const DocumentsSettings = {
  DownloadableFiles: {
    // eslint-disable-next-line
    tradingAuthorizationNonTsel: 'http://www.tradestation.com/~/media/Files/TradeStation/Account%20Forms/Single%20Forms/Universal/Account%20Management/Third%20Party%20Trading%20Authorization.pdf',
    // eslint-disable-next-line
    tradingAuthorizationTsel: 'http://www.tradestation.com/~/media/Files/TradeStation/Account%20Forms/Single%20Forms/Universal/Account%20Management/Third%20Party%20Trading%20Authorization%20TSEL.pdf',
    // eslint-disable-next-line
    exemptCommodityTradingLetterNonTsel: 'http://www.tradestation.com/~/media/Files/TradeStation/Account%20Forms/Single%20Forms/Universal/Account%20Kit%20Forms/Exempt%20Commodity%20Trading.pdf',
    // eslint-disable-next-line
    exemptCommodityTradingLetterTsel: 'http://www.tradestation.com/~/media/Files/TradeStation/Account%20Forms/Single%20Forms/Universal/Account%20Kit%20Forms/Exempt%20Commodity%20Trading.pdf',
    // eslint-disable-next-line
    sepIRA: 'http://www.tradestation.com/single-forms/sep-ira-5305-application/',
    // eslint-disable-next-line
    simpleIRA: 'http://www.tradestation.com/single-forms/simple-ira-5304-application/',
    // eslint-disable-next-line
    deathCertificate: 'http://www.tradestation.com/single-forms/transfer-on-death-account-agreement/',
  },
  PROOF_OF_IDENTITY: 'proofOfIdentity',
  PROOF_OF_ADDRESS: 'proofOfAddress',
  TRADING_AUTHORIZATION_TSEL: 'tradingAuthTsel',
  TRADING_AUTHORIZATION_NON_TSEL: 'tradingAuthNonTsel',
  TRADING_ADVISORY_TSEL: 'tradingAdvisoryTsel',
  TRADING_ADVISORY_NON_TSEL: 'tradingAdvisoryNonTsel',
  EMPLOYER_AUTHORIZATION: 'employerAuthorization',
  SEP_IRA: 'sepIRA',
  DEATH_CERTIFICATE: 'deathCertificate',
  SIMPLE_IRA: 'simpleIRA',
};

export default DocumentsSettings;
