import * as actionTypes from '../actions/actionTypes';

export default function (state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_TRADING_PROFILE_SUCCESS:
      return action.tradingProfile;
    case actionTypes.UPSERT_TRADING_PROFILE_SUCCESS:
      return action.tradingProfile;
    default:
      return state;
  }
}
