import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const AgreementsList = (props) => {
  const {
    groupTitle,
    list,
    name,
    id,
  } = props;

  return (
    <div>
      <div className="col col-xs-12 no-side-padding check-container">
        <div className="col col-xs-1 no-side-padding check-button">
          <div className="form-group">
            <div className="checkbox agreements-checkbox">
              {/* eslint-disable-next-line */}
              <label htmlFor={id}>
                <Field
                  type="checkbox"
                  id={id}
                  name={name}
                  component="input"
                  value="False"
                />
              </label>
            </div>
          </div>
        </div>
        <div className="col col-xs-11 no-side-padding check-title">
          {groupTitle}
        </div>
      </div>
      <div className="col-xs-12 no-side-padding">
        <ul className="col col-xs-12 agreements-list">
          {
            list.map(({ key, title, url }) => (
              <li key={key}>
                <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

AgreementsList.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    url: PropTypes.string,
  })),
};

export default AgreementsList;
