export function setSessionStorage(sessionKey, sessionValue, sessionStorage = window.sessionStorage) {
  if (sessionStorage) {
    sessionStorage.setItem(sessionKey, sessionValue);
  }
}

export function getSessionStorage(sessionKey, sessionStorage = window.sessionStorage) {
  let result = null;
  if (sessionStorage) {
    result = sessionStorage.getItem(sessionKey);
  }
  return result;
}

export function clearSessionStorage(sessionKey, sessionStorage = window.sessionStorage) {
  if (sessionStorage) {
    sessionStorage.removeItem(sessionKey);
  }
}
