
import { phone } from 'phone';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


function isDigit(char) {
  return /^\d$/.test(char);
}

export default function validatePhone(value, country = null) {
  let isValid = true;

  if (country && country === 'US') {
    const validateUsPhone = phone(value, 'USA');
    if (validateUsPhone.length === 0) {
      isValid = false;
    }

    if (value.length > 9) {
      if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, country), country)) {
        isValid = false;
      }
    }
  }

  const firstCharOk = (value.charAt(0) === '+' || isDigit(value.charAt(0)));
  const lastCharOk = isDigit(value.charAt(value.length - 1));

  const digits = value.replace(/\D/g, '');
  const digitsOk = ((digits.length > 9) && (digits.length < 26));

  const hasNoSpecialCharacters = /^\d+$/.test(value);

  return ((firstCharOk && lastCharOk && digitsOk && hasNoSpecialCharacters) === false || !isValid)
    ? 'Not a valid Phone Number'
    : undefined;
}
