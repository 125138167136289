import {
  formatCustomerType,
  formatAccountType,
  formatAssetTypes,
  formatPersona,
  formatM2Type,
  formatAssetTypesEc,
  formatAssetTypesIR,
  formatCustomerTypeIR,
  aopAnalyticsSteps,
  postApplicationAnalytics,
  calculateAge,
  formatRiskTolerance,
  formatOptionsLevel,
  formatTradingExperience,
  formatECommerceGA4Items,
} from './analyticsHelper';
import mapCustomer from './mapCustomer';

const setStepsPushed = (appId, steps, lclStorage) => {
  if (lclStorage && lclStorage.setItem) {
    lclStorage.setItem(`AOP-Steps-Pushed-${appId}`, JSON.stringify(steps));
  }
};

export const fillAllSteps = (appId, lclStorage = window.localStorage) => {
  setStepsPushed(appId, aopAnalyticsSteps, lclStorage);
};

export const pushToAnalytics = (
  stepName,
  analyticsData,
  global = window,
  lclStorage = window.localStorage,
) => {
  const applicationId = analyticsData.applicationId;
  const authToken = analyticsData.authToken;

  const stepsPushed =
    applicationId &&
      lclStorage &&
      lclStorage.getItem &&
      lclStorage.getItem(`AOP-Steps-Pushed-${applicationId}`)
      ? Object.assign(
        {},
        JSON.parse(lclStorage.getItem(`AOP-Steps-Pushed-${applicationId}`)),
      )
      : {};

  postApplicationAnalytics(
    applicationId,
    authToken,
    JSON.stringify({
      eventTime: new Date().toISOString(),
      eventCategory: 'Analytics',
      eventType: 'Google Analytics',
      eventName: `${stepName}`,
      eventJSON: { stepsPushed },
    }),
  );

  if (!stepsPushed[stepName]) {
    const customerType = formatCustomerType(analyticsData.registration ?
      analyticsData.registration.isExistingCustomer : false);
    const accountType = formatAccountType(analyticsData.accountType ? analyticsData.accountType.accountType : null);
    const assetTypes = formatAssetTypes(analyticsData.accountType ? analyticsData.accountType.assetTypes : null);
    const isAssetClassPopulated = !!analyticsData.assetClass;
    const isFinancialPopulated = analyticsData.financial && analyticsData.financial.length > 0;
    const isTradingProfilePopulated = analyticsData.tradingProfile && analyticsData.tradingProfile.length > 0;
    const tellUsAboutYourself = isTradingProfilePopulated ? analyticsData.tradingProfile[0].tellUsAboutYourself : null;
    const financial = isFinancialPopulated ? analyticsData.financial[0] : null;
    const identity = analyticsData.identity && analyticsData.identity.length > 0 ? analyticsData.identity[0] : null;
    const optionsLevel = isAssetClassPopulated ? formatOptionsLevel(analyticsData.assetClass.optionsStrategies) : null;
    const riskTolerance = isAssetClassPopulated
      ? formatRiskTolerance(analyticsData.assetClass.optionsInvestmentGoals)
      : null;
    const age = analyticsData.identity
      ? calculateAge(identity.birthDate)
      : null;
    const margin = isAssetClassPopulated ? analyticsData.assetClass.marginAccount : null;
    const totalNetWorth = isFinancialPopulated ? parseInt(financial.totalNetWorth, 10) : null;
    const liquidNetWorth = isFinancialPopulated ? parseInt(financial.liquidNetWorth, 10) : null;
    const incomeLevel = isFinancialPopulated ? parseInt(financial.annualIncome, 10) : null;
    const stocksExperience = isTradingProfilePopulated
      ? formatTradingExperience(analyticsData.tradingProfile[0].yearsExperienceStocks)
      : null;
    const optionsExperience = isTradingProfilePopulated
      ? formatTradingExperience(analyticsData.tradingProfile[0].yearsExperienceOptions)
      : null;
    const futuresExperience = isTradingProfilePopulated
      ? formatTradingExperience(analyticsData.tradingProfile[0].yearsExperienceFutures)
      : null;
    const persona = formatPersona(tellUsAboutYourself);
    const address = analyticsData.address && analyticsData.address.length > 0 ? analyticsData.address[0] : null;
    const m2Type = formatM2Type(address, identity);

    const assetTypesEc = formatAssetTypesEc(
      analyticsData.accountType ? analyticsData.accountType.assetTypes : null,
      formatAccountType(analyticsData.accountType ? analyticsData.accountType.accountType : null),
      analyticsData.assetClass ? analyticsData.assetClass : null,
      mapCustomer.isCustomerDomestic(address, identity),
    );
    const ecommerceGa4Items = formatECommerceGA4Items(
      analyticsData.accountType ? analyticsData.accountType.assetTypes : null,
      analyticsData.accountType ? analyticsData.accountType.accountType : null,
      analyticsData.assetClass ? analyticsData.assetClass : null,
      mapCustomer.isCustomerDomestic(address, identity),
      optionsLevel,
    );

    const assetTypesIR = formatAssetTypesIR(
      analyticsData.accountType ? analyticsData.accountType.assetTypes : null,
      formatAccountType(
        analyticsData.accountType ? analyticsData.accountType.accountType : null,
      ),
    );
    const customerTypeIR = formatCustomerTypeIR(
      analyticsData.registration
        ? analyticsData.registration.isExistingCustomer
        : false,
    );

    const pushToDataLayer = (
      event,
      variables,
      analytics,
      eCommerce,
      eCommerceGA4,
    ) => {
      const haveGlobalDataLayer = global.dataLayer && global.dataLayer.push;
      postApplicationAnalytics(
        applicationId,
        authToken,
        JSON.stringify({
          eventTime: new Date().toISOString(),
          eventCategory: 'Analytics',
          eventType: 'Google Analytics',
          eventName: event,
          eventJSON: { variables, eCommerce, eCommerceGA4 },
        }),
      );
      if (haveGlobalDataLayer) {
        if (eCommerce) {
          global.dataLayer.push({
            event,
            variables,
            analytics,
            transactionId: eCommerce.transactionId,
            transactionAffiliation: eCommerce.transactionAffiliation,
            transactionTotal: eCommerce.transactionTotal,
            transactionTax: eCommerce.transactionTax,
            transactionShipping: eCommerce.transactionShipping,
            transactionProducts: eCommerce.transactionProducts,
          });
          publishPurchaseEvent(global, eCommerceGA4);
        } else {
          global.dataLayer.push({
            event,
            variables,
            analytics,
          });
        }
      }
    };

    const setAndSendStepInfo = () => {
      let eCommerce = null;
      let eCommerceGA4 = null;
      const calculatedCustomerValue = window.customerValue;
      if (stepName === aopAnalyticsSteps.THANK_YOU.name) {
        eCommerce = {
          transactionId: applicationId,
          transactionAffiliation: 'TradeStation',
          transactionTotal: calculatedCustomerValue,
          transactionTax: 0,
          transactionShipping: 0,
          transactionProducts: assetTypesEc,
        };
        eCommerceGA4 = {
          transaction_id: applicationId,
          currency: 'USD',
          value: calculatedCustomerValue,
          affiliation: 'TradeStation',
          coupon: analyticsData.registration.promoCode,
          items: ecommerceGa4Items,
        };
      }
      pushToDataLayer(
        aopAnalyticsSteps[stepName].value,
        {
          applicationId,
          customerType,
          accountType,
          assetTypes,
          persona,
          m2Type,
          assetTypesIR,
          customerTypeIR,
        },
        {
          optionsLevel,
          totalNetWorth,
          liquidNetWorth,
          incomeLevel,
          stocksExperience,
          optionsExperience,
          futuresExperience,
          riskTolerance,
          age,
          margin,
          customerType,
          assetTypes,
          accountType,
        },
        eCommerce,
        eCommerceGA4,
      );
      stepsPushed[stepName] = aopAnalyticsSteps[stepName].value;
      setStepsPushed(applicationId, stepsPushed, lclStorage);
    };

    setAndSendStepInfo();
  }
};

const publishPurchaseEvent = (global, eCommerceGA4) => {
  global.dataLayer.push({
    event: 'purchase',
    ecommerce: eCommerceGA4,
  });
};

export default pushToAnalytics;
