import * as personaTypes from './personaTypes';

export const personaTypesTexts = [
  {
    value: '',
    text: '',
    persona: '',
  },
  {
    value: personaTypes.DAY_TRADER,
    text: 'I make my living trading',
    persona: 'P1 - Day Trader',
  },
  {
    value: personaTypes.EMPLOYED_TRADER,
    text: 'Employed, but trade on the side',
    persona: 'P2 - Employed Trader',
  },
  {
    value: personaTypes.ASPIRING_DAY_TRADER,
    text: 'Still learning, but want to trade full-time',
    persona: 'P3 - Aspiring Day Trader',
  },
  {
    value: personaTypes.INVESTOR,
    text: 'I’m a long-term investor',
    persona: 'P4 - Investor',
  },
  {
    value: personaTypes.PASSIVE_INVESTOR,
    text: 'I have a 3rd party manage my funds',
    persona: 'P5 - Passive Investor',
  },
];

export default personaTypesTexts;
