import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class LoggedAccountErrorModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: props.showLoggedAccountErrorModal,
    };

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.setState({ showModal: false });
    this.props.handleLoggedAccountErrorModalToggleOff();
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>We could not find your account. Please enter your personal information to create a new account.</p>
        </Modal.Body>
        <Modal.Footer>

          <div className="text-center">
            <button
              id="ok_btn"
              onClick={this.handleHide}
              className="btn btn-raised btn-default do_capitalize btn-md"
            >
              OK
            </button>
          </div>

        </Modal.Footer>
      </Modal>
    );
  }
}

LoggedAccountErrorModal.propTypes = {
  showLoggedAccountErrorModal: PropTypes.bool.isRequired,
  handleLoggedAccountErrorModalToggleOff: PropTypes.func,
};

export default LoggedAccountErrorModal;
