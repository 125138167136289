import React from 'react';
import PropTypes from 'prop-types';

const IdVerifyResubmitIndividual = (props) => {
  const {
    initialValues,
  } = props;

  return (
    <div className="row docVTab idVerifyResubmitNameDiv">
      <div className="jointAccountTab idVerifyResubmitCustomerName">
        <div style={{ display: 'inline-block' }} >
          <h4 className={'h2_subHeading h2_subHeadingBorderless text-center idVerifyResubmitNameText'}>
            {initialValues.primaryAccountOwner}
          </h4>
          <br />
          <h5 className="idVerifyResubmitSubheader">(Primary Account Holder)</h5>
        </div>
      </div>
    </div>
  );
};

IdVerifyResubmitIndividual.propTypes = {
  initialValues: PropTypes.shape(),
};

export default IdVerifyResubmitIndividual;
