import Config from '../Config';

const config = new Config();

const validate = (values, props) => {
  const errors = {};
  if (values && props) {
    if (values.primaryAccountOwnerSignature) {
      const signature = values.primaryAccountOwnerSignature.trim();
      if (!signature) {
        errors.primaryAccountOwnerSignature = 'Account Owner\'s signature is required.';
      } else if (signature !== values.primaryAccountOwner) {
        errors.primaryAccountOwnerSignature = 'The signature must match with the account owner\'s name.';
      }
    } else {
      errors.primaryAccountOwnerSignature = 'Account Owner\'s signature is required.';
    }

    if (values.isJointApplication) {
      if (values.jointAccountOwnerSignature) {
        const signature = values.jointAccountOwnerSignature.trim();
        if (!signature) {
          errors.jointAccountOwnerSignature = 'Account Owner\'s signature is required.';
        } else if (signature !== values.jointAccountOwner) {
          errors.jointAccountOwnerSignature = 'The signature must match with the account owner\'s name.';
        }
      } else {
        errors.jointAccountOwnerSignature = 'Account Owner\'s signature is required.';
      }
    }

    if (!values.agreementsAgreementsAccepted) {
      errors.agreementsAgreementsAccepted = 'Required.';
      errors.acknowledgeError = 'All agreements are required to be reviewed and checked.';
    }

    if (config.exchangeAgreementsEnabled && !values.exchangeAgreementsAccepted) {
      errors.exchangeAgreementsAccepted = 'Required.';
      errors.acknowledgeError = 'All agreements are required to be reviewed and checked.';
    }

    if (values.futuresSelected || values.futuresOptionsSelected) {
      if (!values.arbitrationProvision) {
        errors.arbitrationProvision = 'Required.';
        errors.arbitrationError = 'You must accept or decline...';
      }
    }

    if (values.cryptoSelected) {
      if (!values.cryptoAgreementsAccepted) {
        errors.acknowledgeError = 'All agreements are required to be reviewed and checked.';
      }
    }

    if (props.showRecaptchav2 && !props.isRecaptchaVerified) {
      errors.recaptchaError = 'Recaptcha verification is required.';
    }
  }
  return errors;
};

export default validate;
