import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchTrustedContactRequest() {
  return {
    type: actionTypes.FETCH_TRUSTED_CONTACT_REQUEST,
  };
}

export function fetchTrustedContactSuccess(trustedContact) {
  return {
    type: actionTypes.FETCH_TRUSTED_CONTACT_SUCCESS,
    trustedContact,
  };
}

export function fetchTrustedContactError(error) {
  return {
    type: actionTypes.FETCH_TRUSTED_CONTACT_ERROR,
    error,
  };
}

export function fetchTrustedContact(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchTrustedContactRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/trustedContact`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchTrustedContactSuccess(json)))
      .catch(error => dispatch(fetchTrustedContactError(error)));
  };
}

export function submitTrustedContactError(error) {
  return { type: actionTypes.SUBMIT_TRUSTED_CONTACT_ERROR, error };
}

export function submitTrustedContactRequest(trustedContact) {
  return { type: actionTypes.SUBMIT_TRUSTED_CONTACT_REQUEST, trustedContact };
}

export function submitTrustedContactSuccess(trustedContact) {
  return { type: actionTypes.SUBMIT_TRUSTED_CONTACT_SUCCESS, trustedContact };
}

// eslint-disable-next-line no-underscore-dangle
function _submitTrustedContact(trustedContact, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitTrustedContactRequest(trustedContact));

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(trustedContact),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/trustedContact`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitTrustedContactSuccess(json)))
      .catch((error) => {
        dispatch(submitTrustedContactError(error));
        return Promise.reject(error);
      });
  };
}

export function deleteTrustedContactError(error) {
  return { type: actionTypes.DELETE_TRUSTED_CONTACT_ERROR, error };
}

export function deleteTrustedContactRequest() {
  return { type: actionTypes.DELETE_TRUSTED_CONTACT_REQUEST };
}

export function deleteTrustedContactSuccess() {
  return { type: actionTypes.DELETE_TRUSTED_CONTACT_SUCCESS };
}

export function deleteTrustedContact(applicationId, authToken) {
  return (dispatch) => {
    dispatch(deleteTrustedContactRequest());

    const options = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/trustedContact`, options)
      .then(checkHttpError)
      .then(() => dispatch(deleteTrustedContactSuccess()))
      .catch((error) => {
        dispatch(deleteTrustedContactError(error));
        return Promise.reject(error);
      });
  };
}

export function submitTrustedContact(trustedContact, applicationId, authToken) {
  return _submitTrustedContact(trustedContact, applicationId, authToken, 'POST');
}

export function updateTrustedContact(trustedContact, applicationId, authToken) {
  return _submitTrustedContact(trustedContact, applicationId, authToken, 'PUT');
}
