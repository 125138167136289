/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import GenericError from '../GenericError';

const AgreementsArbitrationFutures = ({ futuresAgreement }) => {
  return (
    <div className="agreements-arbitration">
      <div className="row">
        <div className="col col-md-12">
          <p>
            <b>ELECTION OF ARBITRATION FOR DISPUTES RELATING TO FUTURES ACCOUNTS: </b> If
            you are applying for a <b>Futures</b> or <b>Futures Options</b> account, you
            acknowledge and affirm that you have read the<b>&nbsp;pre-dispute arbitration
            </b><b> provisions</b> set forth in&nbsp;
            <a target="_blank" rel="noopener noreferrer" href={futuresAgreement} >
              section 38 of the TradeStation Securities, Inc. Account Agreement for Futures,
            </a>
            &nbsp; and that you are making the following election:
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-4 col-md-offset-2">
          <div className="form-group">
            <div className="radio">
              <label htmlFor="tradeStockOptionsTrue">
                <Field
                  name="arbitrationProvision"
                  id="tradeStockOptionsTrue"
                  component="input"
                  type="radio"
                  value="true"
                />
                I (We) Accept the Arbitration Provision
              </label>

            </div>
          </div>
        </div>
        <div className="col col-md-4">
          <div className="form-group">
            <div className="radio">
              <label htmlFor="arbitrationProvisionFalse">
                <Field
                  name="arbitrationProvision"
                  id="arbitrationProvisionFalse"
                  component="input"
                  type="radio"
                  value="false"
                />
                I (We) Decline the Arbitration Provision
              </label>

            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12 agreements-acknowledge-error">
          <GenericError name="arbitrationError" />
        </div>
      </div>
    </div>
  );
};

AgreementsArbitrationFutures.propTypes = {
  futuresAgreement: PropTypes.string,
};

export default AgreementsArbitrationFutures;
