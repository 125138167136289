export const ASSET_CLASS_TYPES_EQUITIES = 'equities';
export const ASSET_CLASS_TYPES_FUTURES = 'futures';
export const ASSET_CLASS_TYPES_FUTURES_OPTIONS = 'futuresoptions';
export const ASSET_CLASS_TYPES_CRYPTO = 'crypto';
export const ASSET_CLASS_TYPES_CRYPTO_WAITLIST = 'cryptowaitlist';

export const ASSET_CLASS_COMMISSION_SCHEDULE_PER_SHARE = 'pershare';
export const ASSET_CLASS_COMMISSION_SCHEDULE_PER_TRADE = 'pertrade';
export const ASSET_CLASS_COMMISSION_SCHEDULE_TSGO = 'tsgo';
export const ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT = 'tsselect';
export const ASSET_CLASS_COMMISSION_SCHEDULE_UNBUNDLED = 'unbundled';

export const ASSET_CLASS_ACCOUNT_MARGIN = 'margin';
export const ASSET_CLASS_ACCOUNT_CASH = 'cash';

export const ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_INCOME = 'invest-objective-income';
export const ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_SPECULATION = 'invest-objective-speculation';
export const ASSET_CLASS_OPTIONS_INVESTMENT_GOALS_ACTIVE_GROWTH = 'invest-objective-growth';

export const ASSET_CLASS_OPTIONS_STRATEGIES_STRADDLES = 'options-straddles';
export const ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_CALLS = 'options-sell-uncovered-calls';
export const ASSET_CLASS_OPTIONS_STRATEGIES_SELL_UNCOVERED_PUTS = 'options-sell-uncovered-puts';
export const ASSET_CLASS_OPTIONS_STRATEGIES_CALL_PUT_SPREADS = 'options-call-put-spreads';
export const ASSET_CLASS_OPTIONS_STRATEGIES_COVERED_PUTS = 'options-uncovered-puts';
export const ASSET_CLASS_OPTIONS_STRATEGIES_BUY_CALLS_PUTS = 'options-buy-calls-puts';
export const ASSET_CLASS_OPTIONS_STRATEGIES_SELL_COVERED_CALLS = 'options-sell-covered-calls';
export const ASSET_CLASS_OPTIONS_STRATEGIES_PROTECTIVE_PUTS = 'options-protective-puts';
