import React from 'react';
import PropTypes from 'prop-types';

const IraAccountHeader = (props) => {
  const {
    header,
    subText,
    isPrimary,
  } = props;
  return (
    <div className="row">
      <div className="col col-md-12">
        <h1 className={isPrimary ? 'h1_secondary text-center' : 'h2_subHeading'}>{header}</h1>
        <p className="text-center ">{subText}</p>
      </div>
    </div>
  );
};

IraAccountHeader.propTypes = {
  header: PropTypes.string,
  subText: PropTypes.string,
  isPrimary: PropTypes.bool,
};

export default IraAccountHeader;
