import React from 'react';
import PersonalInfoFormContainer from '../../containers/PersonalInfoFormContainer';
import ProgressBar2 from '../ProgressBar2';

const PersonalInfoPage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          <div id="appPersonalInfo" className="col col-md-12 col-lg-8 col-lg-offset-2 no-side-padding">
            <h1 id="page-header" className="h1_secondary text-center">
              Personal Information
            </h1>
            <PersonalInfoFormContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalInfoPage;
