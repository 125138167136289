import * as actionTypes from './actionTypes';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';
import Config from '../Config';

const config = new Config();

export function fetchTradingAuthorizationRequest() {
  return {
    type: actionTypes.FETCH_TRADING_AUTHORIZATION_REQUEST,
  };
}

export function fetchTradingAuthorizationSuccess(tradingAuthorization) {
  return {
    type: actionTypes.FETCH_TRADING_AUTHORIZATION_SUCCESS,
    tradingAuthorization,
  };
}

export function fetchTradingAuthorizationError(error) {
  return {
    type: actionTypes.FETCH_TRADING_AUTHORIZATION_ERROR,
    error,
  };
}

export function fetchTradingAuthorization(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchTradingAuthorizationRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/tradingAuthorization`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchTradingAuthorizationSuccess(json)))
      .catch(error => dispatch(fetchTradingAuthorizationError(error)));
  };
}

export function submitTradingAuthorizationError(error) {
  return { type: actionTypes.SUBMIT_TRADING_AUTHORIZATION_ERROR, error };
}

export function submitTradingAuthorizationRequest(tradingAuthorization) {
  return { type: actionTypes.SUBMIT_TRADING_AUTHORIZATION_REQUEST, tradingAuthorization };
}

export function submitTradingAuthorizationSuccess(tradingAuthorization) {
  return { type: actionTypes.SUBMIT_TRADING_AUTHORIZATION_SUCCESS, tradingAuthorization };
}

// eslint-disable-next-line no-underscore-dangle
function _submitTradingAuthorization(tradingAuthorization, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitTradingAuthorizationRequest(tradingAuthorization));

    const options = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tradingAuthorization),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/tradingAuthorization`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(submitTradingAuthorizationSuccess(json)))
      .catch((error) => {
        dispatch(submitTradingAuthorizationError(error));
        return Promise.reject(error);
      });
  };
}

export function submitTradingAuthorization(tradingAuthorization, applicationId, authToken) {
  return _submitTradingAuthorization(tradingAuthorization, applicationId, authToken, 'POST');
}

export function updateTradingAuthorization(tradingAuthorization, applicationId, authToken) {
  return _submitTradingAuthorization(tradingAuthorization, applicationId, authToken, 'PUT');
}
