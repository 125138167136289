let prevEmail = null;
let prevFirstName = null;
let prevLastName = null;

function shouldCheckAccountAvailability(email, firstName, lastName, props) {
  const fieldErrors = props && props.syncErrors &&
    (props.syncErrors.email || props.syncErrors.firstName || props.syncErrors.lastName);
  if (!email || !firstName || !lastName || fieldErrors
    || (email === prevEmail && firstName === prevFirstName && lastName === prevLastName)) {
    return false;
  }
  prevEmail = email;
  prevFirstName = firstName;
  prevLastName = lastName;
  return true;
}

// eslint-disable-next-line import/prefer-default-export
export { shouldCheckAccountAvailability };

