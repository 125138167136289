import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBrowserOrigin } from '../lib/browser';
import { getSalesRepFromURL, validatePromoCode } from './RegistrationCredentialsFormContainer';
import * as RouteNavigator from './RouteNavigator';
import * as storageKeys from '../actions/storageKeys';
import * as salesRepsActions from '../actions/salesRepsActions';
import * as sessionStorageHelper from '../lib/sessionStorageHelper';
import Intro from '../components/Intro/Intro';
import optimizeHelper from '../lib/optimizeHelper';
import Config from '../Config';

const config = new Config();

const startApplicationForNewCustomer = () => {
  const promoCodeParam = RouteNavigator.getURLParameterByName(storageKeys.PROMO_CODE_PARAM_KEY);
  const salesRepParam = RouteNavigator.getURLParameterByName(storageKeys.SALES_REP_PARAM_KEY);
  let urlParams = '';

  if (promoCodeParam || salesRepParam) {
    urlParams = document.location.search ? document.location.search : '';
  }

  const uri = '/registration'.concat(`${urlParams}`);
  RouteNavigator.push(uri);
};

const newApplicationForExistingCustomer = () => {
  const redirectTo = getBrowserOrigin();

  window.location =
    encodeURI(`${config.apiUrl}/auth/tradestation?redirectTo=`.concat(`${redirectTo}/account-type`));
};


const continueApplicationForExistingCustomer = () => {
  const redirectTo = getBrowserOrigin();

  window.location =
    encodeURI(`${config.apiUrl}/auth/tradestation?redirectTo=`.concat(`${redirectTo}/pending-application`));
};

const redirectToLegacyUrl = () => {
  const urlParams = document.location.search ? document.location.search : '';
  const LEGACY_AOP_WEBSITE = `${config.legacyAOPUrl}/newaccount/getstarted.aspx${urlParams}`;
  window.location.assign(LEGACY_AOP_WEBSITE);
};

export class IntroContainer extends React.Component {
  componentDidMount() {
    if (!this.props.salesReps) {
      this.props.actions.fetchSalesReps();
    }
    if (this.props.applicationId) {
      RouteNavigator.push('/account-type');
    }
    if (!this.props.applicationId && this.props.authToken) {
      RouteNavigator.push('/pending-applications');
    }
    optimizeHelper.notify();
  }

  render() {
    return (
      <Intro
        {...this.props}
        startApplicationForNewCustomer={startApplicationForNewCustomer}
        newApplicationForExistingCustomer={newApplicationForExistingCustomer}
        continueApplicationForExistingCustomer={continueApplicationForExistingCustomer}
        redirectToLegacyUrl={redirectToLegacyUrl}
      />
    );
  }
}

IntroContainer.propTypes = {
  applicationId: PropTypes.string,
  authToken: PropTypes.string,
  userId: PropTypes.number,
  salesReps: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  actions: PropTypes.shape({
    startApplicationForNewCustomer: PropTypes.func,
    newApplicationForExistingCustomer: PropTypes.func,
    continueApplicationForExistingCustomer: PropTypes.func,
    redirectToLegacyUrl: PropTypes.func,
    fetchSalesReps: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToInitialValues() {
  return {};
}

function mapStateToProps(state) {
  const salesRepParam = getSalesRepFromURL(state.salesReps);
  const promoCodeParam = RouteNavigator.getURLParameterByName(storageKeys.PROMO_CODE_PARAM_KEY);
  if (salesRepParam) sessionStorageHelper.setSessionStorage(storageKeys.SALES_REP_SESSION, salesRepParam);
  if (validatePromoCode(promoCodeParam)) {
    sessionStorageHelper.setSessionStorage(storageKeys.PROMO_CODE_SESSION, promoCodeParam);
  }

  return {
    initialValues: mapStateToInitialValues(state),
    applicationId: state.applicationId,
    authToken: state.authToken,
    userId: state.userId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      salesRepsActions,
      ), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroContainer);
