import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../actions/userActions';
import { initTimer, resetTimer, stopTimer } from '../lib/tokenTimer';


export class TokenTimerContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleTokenRefresh = this.handleTokenRefresh.bind(this);
  }

  componentDidMount() {
    if (this.props.authToken) {
      initTimer(this.handleTokenRefresh);
    }
  }

  handleTokenRefresh() {
    this.props.actions.userTokenRefresh(this.props.authToken)
      .then(() => {
        resetTimer();
      })
      .catch(() => {
        stopTimer();
      });
  }

  render() {
    return null;
  }
}

TokenTimerContainer.propTypes = {
  authToken: PropTypes.string,
  actions: PropTypes.shape({
    userTokenRefresh: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    authToken: state.authToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
    userActions),
    dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenTimerContainer);
