import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import Config from '../../Config';
import ExitModal from '../ExitModal';

const config = new Config();

const EntityApplication = ({
  onBack,
  handleSubmit,
  onSubmit,
  showExitModal,
  onExitModalOn,
  onExitModalOff,
  handleOnClick,
  isDownloaded,
}) => {
  const styleDownloadButton = isDownloaded
    ? 'back-button visited'
    : 'btn-raised';
  return (
    <form id="entityApplicationForm" onSubmit={handleSubmit(onSubmit)}>
      <section className="form-content-section-primary">
        <div className="col col-xs-12 col-md-12 col-md-offset-0 instructions">
          <div>
            <h2 className="h2_subHeading"> Instructions for Completion</h2>
          </div>
          <div className="col col-xs-12 col-md-6 col-md-offset-0">
            <div className="row">
              <div className="information-row">
                <div className="instruction-container">
                  <div className="dot text-center">
                    <p className="instructions-number">1</p>
                  </div>
                  <div className="instructions-text-container">
                    <p className="instructions-text">
                      Download the application below.
                    </p>
                  </div>
                </div>
                <div className="instruction-container">
                  <div className="dot text-center">
                    <p className="instructions-number">2</p>
                  </div>
                  <div className="instructions-text-container">
                    <p className="instructions-text">
                      Fill in the interactive fields to with the required
                      information.
                    </p>
                  </div>
                </div>
                <div className="instruction-container">
                  <div className="dot text-center">
                    <p className="instructions-number">3</p>
                  </div>
                  <div className="instructions-text-container">
                    <p className="instructions-text">
                      Once you have completed the required fields, print and
                      sign
                      <br />
                      the application.
                    </p>
                  </div>
                </div>
                <div className="instruction-container">
                  <div className="dot text-center">
                    <p className="instructions-number">4</p>
                  </div>
                  <div className="instructions-text-container">
                    <p className="instructions-text">
                      Send the signed copy to us at:
                      <br />
                      <a
                        className="email-link"
                        href="mailto:newaccountservices@tradestation.com"
                      >
                        newaccountservices@tradestation.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row hidden-xs hidden-sm entity-download-row-button text-center">
                <button
                  type="button"
                  className={`btn btn-default do_capitalize ${styleDownloadButton}`}
                  id="downloadApplication"
                  onClick={handleOnClick}
                >
                  <a
                    className="link-text-button"
                    href={config.entityAccountApplicationKit}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleOnClick}
                  >
                    Download Application
                  </a>
                </button>
              </div>
              <div className="row hidden-xs hidden-smtext-center">
                {isDownloaded && (
                  <div className="download-success text-center">
                    <p>
                      <i className="material-icons">check_circle</i> Success!
                      Your download is complete.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col col-xs-12 col-md-6 col-md-offset-0">
            <div className="row information-row">
              <div className="card">
                <div className="card-body">
                  <div className="paragraph-text">
                    <p className="corporate-subtitle">
                      Additional Instructions for Corporate Accounts:
                    </p>
                    <ul>
                      <li className="list-item">
                        Ensure that any other individuals who will have direct
                        or indirect control of the account fill out the requisite fields
                        on the forms titled: <i>Associated Person</i> and <i>Trusted Contact</i>, which
                        are included in the PDF application.
                      </li>
                      <li className="list-item">
                        Complete and sign the supplementary forms titled <i>Certificate of Corporate Secretary </i>
                        and <i>Additional Instructions for Corporate Accounts</i>.
                      </li>
                    </ul>
                    <p>
                      The following documentation is also required, so please be
                      sure to include these when submitting your application:
                    </p>
                    <ul>
                      <li className="list-item">
                        Certified Articles or Certificate of Incorporation
                      </li>
                      <li className="list-item">
                        Corporate resolutions with current officer and directions
                      </li>
                      <li className="list-item">Corporate Bylaws</li>
                      <li className="list-item">Shareholder Register</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row hidden-lg hidden-md entity-download-row-button text-center">
            <a
              className="link-text-button"
              href={config.entityAccountApplicationKit}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleOnClick}
            >
              <button
                type="button"
                className={`btn btn-default do_capitalize ${styleDownloadButton}`}
                id="downloadApplication"
                onClick={handleOnClick}
              >
                Download Application
              </button>
            </a>

            {isDownloaded && (
              <div className="text-center test">
                <p>
                  <i className="material-icons">check_circle</i> Success! Your
                  download is complete.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
      {showExitModal && (
        <ExitModal
          onExitModalOn={onExitModalOn}
          onExitModalOff={onExitModalOff}
        />
      )}
      <div className="row">
        <div className="col col-xs-12">
          <Navigation
            onBack={onBack}
            showNext={!!isDownloaded}
            showBack={!isDownloaded}
            onExitModalOn={onExitModalOn}
            saveButtonId="save_btn_application"
            backButtonId="back_btn_application"
            nextButtonId="finish_btn_application"
            submit="Finish"
            isEntityPage
          />
        </div>
      </div>
    </form>
  );
};

EntityApplication.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showExitModal: PropTypes.bool,
  isDownloaded: PropTypes.bool,
  onExitModalOn: PropTypes.func.isRequired,
  onExitModalOff: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default EntityApplication;
