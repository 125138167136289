import Config from '../../Config';

const config = new Config();

export const inlineButtons = Object.freeze({
  newDomesticCustomerOptions: ['linkBankAccount', 'wireTransfer'],
  existingDomesticCustomerOptions: ['onlineTransfer', 'linkBankAccount', 'wireTransfer'],
  newInternationalCustomerOptions: ['wireTransfer', 'linkBankAccount'],
  existingInternationalCustomerOptions: ['onlineTransfer', 'wireTransfer', 'linkBankAccount'],
  domesticAdditionalOptions: ['checkDeposit'],
  internationalAdditionalOptions: ['checkDeposit', 'transferService'],
  depositCryptoOptions: 'depositCrypto',
  inlineButtons: ['checkDeposit'],
  equitiesOnlyOptions: ['transferAccount'],
});

export const InlineFundingButtons = {
  linkBankAccount:
  {
    id: 'link_bank_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'link bank account',
    url: `${config.fundingUrl}/external/add-automatic`,
    isRedirected: true,
  },
  wireTransfer: {
    id: 'wire_transfer_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'wire transfer',
    url: `${config.fundingUrl}/transfers/wire/in`,
    isRedirected: true,
  },
  onlineTransfer: {
    id: 'online_transfer_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'online transfer',
    url: `${config.fundingUrl}/transfers`,
    isRedirected: true,
  },
  depositCrypto: {
    id: 'deposit_crypto_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'Deposit Crypto',
    isRedirected: false,
  },
  transferAccount: {
    id: 'transfer_account_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'Transfer Account',
    isRedirected: false,
  },
  checkDeposit: {
    id: 'check_deposit_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'Check Deposit',
    url: `${config.fundingUrl}/transfers/check/in`,
    isRedirected: true,
  },
  transferService: {
    id: 'transfer_service_btn',
    className: 'btn inline-btn do_capitalize',
    label: 'Transfer Service',
    isRedirected: false,
  },
};
