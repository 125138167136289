export const HEAR_ABOUT_US_SOURCE_OTHER = 'other';
export const HEAR_ABOUT_US_EDUCATIONAL_PARTNER = 'educational-partner';
export const HEAR_ABOUT_US_SALES_REP = 'tradestation-rep';
export const HEAR_ABOUT_US_OTA = 'online-trading-academy';

export const isSalesRepRequired = (hearAboutUs) => {
  return hearAboutUs === HEAR_ABOUT_US_SALES_REP;
};

export const isOtherRequired = (hearAboutUs) => {
  return hearAboutUs === HEAR_ABOUT_US_SOURCE_OTHER || hearAboutUs === HEAR_ABOUT_US_EDUCATIONAL_PARTNER;
};

export const mapHearAboutUsOptions = (hearAboutUs) => {
  let options = [{ value: '', text: '' }];

  if (hearAboutUs) {
    const hearAboutUsSorted = hearAboutUs.filter(h => h.key !== HEAR_ABOUT_US_OTA).sort((a, b) => {
      return (a.sort > b.sort) || (a.sort === b.sort) - 1;
    });

    options = options.concat(hearAboutUsSorted.map((h) => { return { value: h.key, text: h.title }; }));
  }

  return options;
};
