import React from 'react';
import PropTypes from 'prop-types';

const InlineFundingNavigationTransition = (props) => {
  const {
    onNewTransferButtonClicked,
    onLetsGoButtonClicked,
    assistenceText,
    noBorder,
  } = props;
  const noBorderClass = noBorder ? 'no-border-funding-nav' : '';
  return (
    <div className="form-navigation justify-content-between">
      <div className={`inline-funding-navigation-container ${noBorderClass}`}>
        <div className="text-center">
          <p className="assistance-container-ready">{assistenceText}</p>
          <button
            type="button"
            id="newTransfer"
            className="btn btn-lg btn-default back-button do_capitalize"
            onClick={onNewTransferButtonClicked}
          >
            New Transfer
          </button>
          <button
            type="button"
            id="letsGo"
            className="btn btn-lg btn-raised btn-default do_capitalize"
            onClick={onLetsGoButtonClicked}
          >
            Let&#39;s Go&#33;
          </button>
        </div>
      </div>
    </div>
  );
};

InlineFundingNavigationTransition.propTypes = {
  onNewTransferButtonClicked: PropTypes.func,
  onLetsGoButtonClicked: PropTypes.func,
  assistenceText: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default InlineFundingNavigationTransition;
