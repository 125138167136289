import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError, formValueSelector, touch, change } from 'redux-form';
import async from 'async';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as applicationActions from '../actions/applicationActions';
import * as contactActions from '../actions/contactActions';
import * as documentsActions from '../actions/documentsActions';
import * as documentsRequiredActions from '../actions/documentsRequiredActions';
import * as countriesActions from '../actions/countriesActions';
import * as identityActions from '../actions/identityActions';
import * as contactSearchAndFormat from './contactSearchAndFormat';
import * as RouteNavigator from './RouteNavigator';
import * as identificationTypes from '../components/PersonalInfo/Identity/identificationTypes';
import DocumentsForm from '../components/Documents/DocumentsForm';
import DocumentsFormValidation from '../validations/DocumentsFormValidation';
import DocumentsSettings from '../components/Documents/DocumentsSettings';
import optimizeHelper from '../lib/optimizeHelper';
import Config from '../Config';

const config = new Config();
const MAX_UPLOADS = config.maxUploadFiles;

const PROOF_OF_IDENTITY = DocumentsSettings.PROOF_OF_IDENTITY;
const PROOF_OF_ADDRESS = DocumentsSettings.PROOF_OF_ADDRESS;
const EMPLOYER_AUTHORIZATION = DocumentsSettings.EMPLOYER_AUTHORIZATION;
const TRADING_AUTHORIZATION_NON_TSEL = DocumentsSettings.TRADING_AUTHORIZATION_NON_TSEL;
const TRADING_AUTHORIZATION_TSEL = DocumentsSettings.TRADING_AUTHORIZATION_TSEL;
const TRADING_ADVISORY_NON_TSEL = DocumentsSettings.TRADING_ADVISORY_NON_TSEL;
const TRADING_ADVISORY_TSEL = DocumentsSettings.TRADING_ADVISORY_TSEL;
const SEP_IRA = DocumentsSettings.SEP_IRA;
const DEATH_CERTIFICATE = DocumentsSettings.DEATH_CERTIFICATE;
const SIMPLE_IRA = DocumentsSettings.SIMPLE_IRA;
const ID_PASSPORT = identificationTypes.ID_PASSPORT;

const mapValuesToDocument = (document, files) => {
  const d = {};
  d.id = document.id || null;
  d.contactId = document.contact.id || null;
  d.ids = files.map(i => i.id);
  return d;
};

const mapValuesToDocuments = (values, filesUploaded) => {
  const documents = [];
  values.docs.forEach((d, sectionIndex) => {
    const filesRequired = [];
    filesUploaded.map((f) => {
      if (d.documentsRequired.includes(f.type) && f.sectionIndex === sectionIndex) {
        filesRequired.push(f);
      }
      return true;
    });
    if (d.documentsRequired.length > 0 && filesRequired.length > 0) {
      documents.push(mapValuesToDocument(d, filesRequired));
    }
  });
  return documents;
};

function calculateProgress(progress, uploaded, length) {
  const ratio = 100 / length;
  const current = ((progress || 0) * ratio) / 100;
  return Math.round((uploaded * ratio) + current);
}

export class DocumentsFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
      filesUploaded: [],
      submittingFile: false,
      itemProgress: [
        {
          proofOfIdentity: 0,
          proofOfAddress: 0,
          employerAuthorization: 0,
          tradingAuthNonTsel: 0,
          tradingAuthTsel: 0,
          tradingAdvisoryNonTsel: 0,
          tradingAdvisoryTsel: 0,
          sepIRA: 0,
          deathCertificate: 0,
          simpleIRA: 0,
        },
        {
          proofOfIdentity: 0,
          proofOfAddress: 0,
          employerAuthorization: 0,
          tradingAuthNonTsel: 0,
          tradingAuthTsel: 0,
          tradingAdvisoryNonTsel: 0,
          tradingAdvisoryTsel: 0,
          sepIRA: 0,
          deathCertificate: 0,
          simpleIRA: 0,
        },
      ],
      errors: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileSubmit = this.handleFileSubmit.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.clearFilesUploaded = this.clearFilesUploaded.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.countries.length === 0) {
      this.props.actions.fetchCountries();
    }
    if (!this.props.application) {
      this.props.actions.fetchApplication(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.accountType) {
      this.props.actions.fetchAccountType(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.contact) {
      this.props.actions.fetchContact(this.props.applicationId, this.props.authToken);
    }
    if (!this.props.identity) {
      this.props.actions.fetchIdentity(this.props.applicationId, this.props.authToken);
    }

    this.props.actions.fetchDocumentsRequired(this.props.applicationId, this.props.authToken);
    this.props.actions.fetchDocuments(this.props.applicationId, this.props.authToken);
    optimizeHelper.notify();
  }

  componentWillReceiveProps(nextProps) {
    const filesUploaded = [];
    // eslint-disable-next-line
    const docs = nextProps.originalDocuments;
    if (docs && this.state.filesUploaded && this.state.filesUploaded.length === 0) {
      // eslint-disable-next-line
      docs.forEach((d, sectionIndex) => {
        d.forEach((i) => {
          filesUploaded.push({
            sectionIndex,
            id: i.documentId,
            key: i.documentPath,
            type: i.documentType,
            documentSubType: i.documentSubType });
        });
        this.setState({ filesUploaded });
      });
    }
  }

  handleErrors(property, item, idSubType = null, sectionIndex = null) {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [property]: item,
        idSubType,
        sectionIndex,
      },
    }));
  }

  handleFileRemove(sectionIndex, documentType, id) {
    const currentIds = this.state.filesUploaded;
    const map = currentIds.map(i => i.id);
    const index = map.indexOf(id);

    if (index > -1) {
      currentIds.splice(index, 1);
    }
    this.setState({ filesUploaded: currentIds });

    const touchedFields = [`docs[${sectionIndex}].${documentType}`];
    this.props.dispatch(touch('documents', touchedFields));
  }

  handleFileSubmit(sectionIndex, key, files, idSubtype, callback) {
    const filesUploaded = [];
    const itemProgress = this.state.itemProgress;
    let uploaded = files.length;

    const k = key.slice(8);
    itemProgress[sectionIndex][k] = 20;
    this.setState({ submittingFile: false });
    this.setState({ itemProgress, submittingFile: true });

    async.each(files, (file, cb) => {
      const documents = new FormData();
      documents.append(k, file);
      documents.append('documentSubType', idSubtype);
      const keyId = file.preview;
      this.props.actions.submitDocumentFile(documents, this.props.applicationId, this.props.authToken)
        .then((result) => {
          const downloadPath = '/applications/{0}/documentFiles/{1}/download';
          filesUploaded.push({
            sectionIndex,
            type: result.documentType,
            id: result.fileId,
            key: keyId,
            newPath: downloadPath.replace('{0}', this.props.applicationId).replace('{1}', result.fileId) });
          uploaded -= 1;
          itemProgress[sectionIndex][k] = calculateProgress(itemProgress[sectionIndex][k], uploaded, files.length);
          this.setState({ itemProgress });
          cb(null);
        })
        .catch((error) => { cb(error); });
    }, (err) => {
      if (err) {
        callback('error uploading file:'.concat(err));
      } else {
        const currentIds = this.state.filesUploaded;
        filesUploaded.forEach((item) => {
          currentIds.push(item);
        });
        this.setState({ filesUploaded: currentIds });
      }

      itemProgress[sectionIndex][k] = 100;
      this.setState({ itemProgress, submittingFile: false });

      setTimeout(() => {
        itemProgress[sectionIndex][k] = 0;
        this.setState({ itemProgress, submittingFile: false });
        callback(null, filesUploaded);
      }, 500);
    });
  }

  handleSubmit(values) {
    const documents = mapValuesToDocuments(values, this.state.filesUploaded);
    const submit = !this.props.documentsExist ?
      this.props.actions.submitDocuments :
      this.props.actions.updateDocuments;

    if (documents && documents.length > 0) {
      return submit(documents, this.props.applicationId, this.props.authToken)
        .then(() => { RouteNavigator.push('/summary'); })
        .catch((error) => { throw new SubmissionError({ _error: error.message }); });
    }

    // We wanted to put this return as part of an 'else' of the previous 'if', however Linter complains.
    return this.props.actions.deleteDocuments(this.props.applicationId, this.props.authToken)
      .then(() => { RouteNavigator.push('/summary'); })
      .catch((error) => { throw new SubmissionError({ _error: error.message }); });
  }

  // eslint-disable-next-line
  handleBack(values) {
    RouteNavigator.push('/additional-info');
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  clearFilesUploaded(fileType, fileSubtype, sectionIndex) {
    /* Purpose: If Passport is selected after ID Card is uploaded, clear ID card files.
    If fileTypes match (ex. proofOfIdentity), clear file subtypes that don't match
    the subtype currently selected on the UI (Passport or Drivers License/ID Card) */
    const remainingFiles = this.state.filesUploaded.filter(file => fileSubtype.includes(file.documentSubType) ||
      file.type !== fileType || file.sectionIndex !== sectionIndex);
    this.setState({ filesUploaded: remainingFiles });

    // clear documents form value for relevant fields
    const touchedFields = [`docs[${sectionIndex}].${fileType}`];
    this.props.dispatch(change('documents', touchedFields, []));

    // clear originalDocuments form value for relevant fields
    const originalDocs = this.props.originalDocuments[sectionIndex];
    const remainingDocs = originalDocs.filter(file => file.documentType !== fileType ||
      fileSubtype.includes(file.documentSubType));
    const originalDocumentsField = [`docs[${sectionIndex}].originalDocuments`];
    this.props.dispatch(change('documents', originalDocumentsField, remainingDocs));

    // clear relevant errors
    this.handleErrors(touchedFields, [null], null, sectionIndex);

    // call validation after clearing files to prevent user from clicking submit
    setTimeout(() => {
      this.props.change('callValidation', Date.now());
    }, 1);
  }

  render() {
    return (
      <DocumentsForm
        {...this.props}
        submittingFile={this.state.submittingFile}
        itemProgress={this.state.itemProgress}
        onFileRemove={this.handleFileRemove}
        onFileSubmit={this.handleFileSubmit}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        onHandleErrors={this.handleErrors}
        errors={this.state.errors}
        clearFilesUploaded={this.clearFilesUploaded}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

DocumentsFormContainer.propTypes = {
  application: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  identity: PropTypes.shape(),
  originalDocuments: PropTypes.shape(),
  documentsExist: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    fetchApplication: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchDocuments: PropTypes.func.isRequired,
    fetchDocumentsRequired: PropTypes.func.isRequired,
    submitDocumentFile: PropTypes.func.isRequired,
    submitDocuments: PropTypes.func.isRequired,
    updateDocuments: PropTypes.func.isRequired,
    deleteDocuments: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  change: PropTypes.func,
};

function findDocumentsById(contactId, requiredDocuments) {
  return requiredDocuments.find((r) => { return r.contactId === contactId; });
}

function findRequiredDocumentsByType(contactType, requiredDocuments) {
  if (requiredDocuments) return requiredDocuments.find((r) => { return r.contactType === contactType; });
  return null;
}

function setOriginalDocuments(documents, requiredDocs) {
  let originalDocuments = [];
  if (documents) {
    requiredDocs.forEach((required) => {
      const original = documents.filter(f => f.documentType === required);
      if (original) {
        originalDocuments = originalDocuments.concat(original);
      }
    });
  }
  return originalDocuments;
}

function mapDocument(document, documentRequired, contact) {
  const originalDocuments = document && document.filesInfo ? document.filesInfo : [];
  const originalDocs = originalDocuments.length ? originalDocuments.map(o => o.documentType) : [];
  let uploadedPassport = false;

  for (let i = 0; i < originalDocuments.length; i += 1) {
    if (originalDocuments[i].documentType === PROOF_OF_IDENTITY &&
    originalDocuments[i].documentSubType === ID_PASSPORT) {
      uploadedPassport = true;
    }
  }

  return {
    id: document ? document.id : null,
    sort: contact.type === 'primary' ? 0 : contact.id,
    contact: contactSearchAndFormat.mapContact(contact),
    documentsRequired: documentRequired.requiredDocsUpload,
    originalDocuments: setOriginalDocuments(document ? document.filesInfo : [], documentRequired.requiredDocs),
    notRequiredForApprovalDocuments: documentRequired.notRequiredForApprovalDocs,
    maxFiles: {
      proofOfIdentity: config.maxUploadFilesProofOfIdentity,
      proofOfAddress: MAX_UPLOADS,
      employerAuthorization: MAX_UPLOADS,
      tradingAuthNonTsel: MAX_UPLOADS,
      tradingAuthTsel: MAX_UPLOADS,
      tradingAdvisoryNonTsel: MAX_UPLOADS,
      tradingAdvisoryTsel: MAX_UPLOADS,
      sepIRA: MAX_UPLOADS,
      deathCertificate: MAX_UPLOADS,
      simpleIRA: MAX_UPLOADS,
      restriction: MAX_UPLOADS,
    },
    itemVisibility: {
      showProofOfIdentity: false,
      showProofOfAddress: false,
      showEmployerAuthorization: false,
      showTradingAuthNonTsel: false,
      showTradingAuthTsel: false,
      showTradingAdvisoryNonTsel: false,
      showTradingAdvisoryTsel: false,
      showSepIRA: false,
      showDeathCertificate: false,
      showSimpleIRA: false,
    },
    itemStatus: {
      showProofOfIdentityOK: false,
      showProofOfAddressOK: false,
      showEmployerAuthorizationOK: false,
      showTradingAuthNonTselOK: false,
      showTradingAuthTselOK: false,
      showTradingAdvisoryNonTselOK: false,
      showTradingAdvisoryTselOK: false,
      showUserDocumentsOK: false,
      showAuthorizationDocumentsOK: false,
      showIRADocumentsOK: false,
      showSepIraOK: false,
      showDeathCertificateOK: false,
      showSimpleIraOK: false,
    },
    proofOfAddress: originalDocs.includes(PROOF_OF_IDENTITY) ? [] : null,
    proofOfIdentity: originalDocs.includes(PROOF_OF_ADDRESS) ? [] : null,
    employerAuthorization: originalDocs.includes(EMPLOYER_AUTHORIZATION) ? [] : null,
    tradingAdvisoryNonTsel: originalDocs.includes(TRADING_ADVISORY_NON_TSEL) ? [] : null,
    tradingAdvisoryTsel: originalDocs.includes(TRADING_ADVISORY_TSEL) ? [] : null,
    tradingAuthNonTsel: originalDocs.includes(TRADING_AUTHORIZATION_NON_TSEL) ? [] : null,
    tradingAuthTsel: originalDocs.includes(TRADING_AUTHORIZATION_TSEL) ? [] : null,
    sepIRA: originalDocs.includes(SEP_IRA) ? [] : null,
    deathCertificate: originalDocs.includes(DEATH_CERTIFICATE) ? [] : null,
    simpleIRA: originalDocs.includes(SIMPLE_IRA) ? [] : null,
    identificationType: uploadedPassport ?
    identificationTypes.MITEK_PASSPORT : identificationTypes.MITEK_DRIVERS_LICENSE,
  };
}

export function mapStateToInitialValues(state) {
  const dbApplication = (state && state.application) ? state.application : null;
  const dbAccountType = (state && state.accountType) ? state.accountType : null;
  const dbContacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;
  const dbDocuments = (state && state.documents && state.documents.length > 0) ? state.documents : null;
  const dbDocumentsRequired = (state && state.documentsRequired && state.documentsRequired.length > 0) ?
    state.documentsRequired
    : null;

  const isReturnedApplication = (dbApplication && dbApplication.status.indexOf('returned') > -1);
  const isJointApplication = (dbAccountType && dbAccountType.accountType.indexOf('joint') > -1);
  const isEntityApplication = (dbAccountType && dbAccountType.accountType.indexOf('entity') > -1);
  const docs = [];
  if (dbDocuments) {
    if (docs.length < 1) {
      const contact = contactSearchAndFormat.findContactByType('primary', dbContacts);
      const requiredDocuments = findRequiredDocumentsByType('primary', dbDocumentsRequired);
      const document = findDocumentsById(contact.id, dbDocuments);
      if (contact && requiredDocuments) {
        docs.push(mapDocument(document, requiredDocuments, contact));
      }
    }
    if (docs.length < 2 && isJointApplication) {
      const contact = contactSearchAndFormat.findContactByType('joint', dbContacts);
      const requiredDocuments = findRequiredDocumentsByType('joint', dbDocumentsRequired);
      const document = findDocumentsById(contact.id, dbDocuments);
      if (contact && requiredDocuments) {
        docs.push(mapDocument(document, requiredDocuments, contact));
      }
    }
  }

  if (dbContacts && dbContacts.length > 0 && dbDocumentsRequired && dbDocumentsRequired.length > 0) {
    if (docs.length < 1) {
      const contact = contactSearchAndFormat.findContactByType('primary', dbContacts);
      const requiredDocuments = findRequiredDocumentsByType('primary', dbDocumentsRequired);
      if (contact && requiredDocuments) {
        docs.push(mapDocument(null, requiredDocuments, contact));
      }
    }

    if (docs.length < 2 && isJointApplication) {
      const contact = contactSearchAndFormat.findContactByType('joint', dbContacts);
      const requiredDocuments = findRequiredDocumentsByType('joint', dbDocumentsRequired);
      if (contact && requiredDocuments) {
        docs.push(mapDocument(null, requiredDocuments, contact));
      }
    }
  }

  docs.sort((a, b) => {
    return a.sort - b.sort;
  });

  return {
    isReturnedApplication,
    isJointApplication,
    isEntityApplication,
    docs,
  };
}

function getCurrentDocumentCount(docs, documentType) {
  let documentCount = 0;

  if (docs && docs.originalDocuments) {
    documentCount += docs.originalDocuments.filter(x => x.documentType === documentType).length;
  }

  if (docs && docs[documentType]) {
    switch (documentType) {
      case PROOF_OF_IDENTITY:
        documentCount += docs.proofOfIdentity.length;
        break;
      case PROOF_OF_ADDRESS:
        documentCount += docs.proofOfAddress.length;
        break;
      case EMPLOYER_AUTHORIZATION:
        documentCount += docs.employerAuthorization.length;
        break;
      case TRADING_AUTHORIZATION_NON_TSEL:
        documentCount += docs.tradingAuthNonTsel.length;
        break;
      case TRADING_AUTHORIZATION_TSEL:
        documentCount += docs.tradingAuthTsel.length;
        break;
      case TRADING_ADVISORY_NON_TSEL:
        documentCount += docs.tradingAdvisoryNonTsel.length;
        break;
      case TRADING_ADVISORY_TSEL:
        documentCount += docs.tradingAdvisoryTsel.length;
        break;
      case SEP_IRA:
        documentCount += docs.sepIRA.length;
        break;
      case DEATH_CERTIFICATE:
        documentCount += docs.deathCertificate.length;
        break;
      case SIMPLE_IRA:
        documentCount += docs.simpleIRA.length;
        break;
      default:
        break;
    }
  }

  return documentCount;
}

function getDocumentsCount(docs) {
  return {
    proofOfIdentity: getCurrentDocumentCount(docs, PROOF_OF_IDENTITY),
    proofOfAddress: getCurrentDocumentCount(docs, PROOF_OF_ADDRESS),
    employerAuthorization: getCurrentDocumentCount(docs, EMPLOYER_AUTHORIZATION),
    tradingAuthNonTsel: getCurrentDocumentCount(docs, TRADING_AUTHORIZATION_NON_TSEL),
    tradingAuthTsel: getCurrentDocumentCount(docs, TRADING_AUTHORIZATION_TSEL),
    tradingAdvisoryNonTsel: getCurrentDocumentCount(docs, TRADING_ADVISORY_NON_TSEL),
    tradingAdvisoryTsel: getCurrentDocumentCount(docs, TRADING_ADVISORY_TSEL),
    sepIRA: getCurrentDocumentCount(docs, SEP_IRA),
    deathCertificate: getCurrentDocumentCount(docs, DEATH_CERTIFICATE),
    simpleIRA: getCurrentDocumentCount(docs, SIMPLE_IRA),
  };
}

function getRemainingDocumentsToUpload(maxAllowed, currentDocumentCount) {
  const maxAllowedProofOfIdentity = config.maxUploadFilesProofOfIdentity;
  return {
    proofOfIdentity: maxAllowedProofOfIdentity - currentDocumentCount.proofOfIdentity,
    proofOfAddress: maxAllowed - currentDocumentCount.proofOfAddress,
    employerAuthorization: maxAllowed - currentDocumentCount.employerAuthorization,
    tradingAuthNonTsel: maxAllowed - currentDocumentCount.tradingAuthNonTsel,
    tradingAuthTsel: maxAllowed - currentDocumentCount.tradingAuthTsel,
    tradingAdvisoryNonTsel: maxAllowed - currentDocumentCount.tradingAdvisoryNonTsel,
    tradingAdvisoryTsel: maxAllowed - currentDocumentCount.tradingAdvisoryTsel,
    sepIRA: maxAllowed - currentDocumentCount.sepIRA,
    deathCertificate: maxAllowed - currentDocumentCount.deathCertificate,
    simpleIRA: maxAllowed - currentDocumentCount.simpleIRA,
    restriction: maxAllowed,
  };
}

function isDocumentRequired(documentsRequired, documentType) {
  return documentsRequired.includes(documentType);
}

function setItemStatus(docs, currentDocumentCount) {
  const itemStatus = {
    showProofOfIdentityOK: currentDocumentCount.proofOfIdentity > 0,
    showProofOfAddressOK: currentDocumentCount.proofOfAddress > 0,
    showEmployerAuthorizationOK: currentDocumentCount.employerAuthorization > 0,
    showTradingAuthNonTselOK: currentDocumentCount.tradingAuthNonTsel > 0,
    showTradingAuthTselOK: currentDocumentCount.tradingAuthTsel > 0,
    showTradingAdvisoryNonTselOK: currentDocumentCount.tradingAdvisoryNonTsel > 0,
    showTradingAdvisoryTselOK: currentDocumentCount.tradingAdvisoryTsel > 0,
    showSepIraOK: currentDocumentCount.sepIRA > 0,
    showDeathCertificateOK: currentDocumentCount.deathCertificate > 0,
    showSimpleIraOK: currentDocumentCount.simpleIRA > 0,
  };

  itemStatus.showUserDocumentsOK =
    (isDocumentRequired(docs.documentsRequired, PROOF_OF_IDENTITY) ?
      itemStatus.showProofOfIdentityOK : true)
    && (isDocumentRequired(docs.documentsRequired, PROOF_OF_ADDRESS) ?
      itemStatus.showProofOfAddressOK : true)
    && (isDocumentRequired(docs.documentsRequired, EMPLOYER_AUTHORIZATION) ?
      itemStatus.showEmployerAuthorizationOK : true);

  itemStatus.showAuthorizationDocumentsOK =
    (isDocumentRequired(docs.documentsRequired, TRADING_AUTHORIZATION_NON_TSEL) ?
      itemStatus.showTradingAuthNonTselOK : true)
    && (isDocumentRequired(docs.documentsRequired, TRADING_AUTHORIZATION_TSEL) ?
      itemStatus.showTradingAuthTselOK : true)
    && (isDocumentRequired(docs.documentsRequired, TRADING_ADVISORY_NON_TSEL) ?
      itemStatus.showTradingAdvisoryNonTselOK : true)
    && (isDocumentRequired(docs.documentsRequired, TRADING_ADVISORY_TSEL) ?
      itemStatus.showTradingAdvisoryTselOK : true);

  itemStatus.showIRADocumentsOK =
    (isDocumentRequired(docs.documentsRequired, SEP_IRA) ?
      itemStatus.showSepIraOK : true)
    || (isDocumentRequired(docs.documentsRequired, DEATH_CERTIFICATE) ?
      itemStatus.showDeathCertificateOK : true)
    || (isDocumentRequired(docs.documentsRequired, SIMPLE_IRA) ?
      itemStatus.showSimpleIraOK : true);

  return itemStatus;
}

function setItemVisibility(docs) {
  const visibility = {
    showProofOfIdentity: isDocumentRequired(docs.documentsRequired, PROOF_OF_IDENTITY),
    showProofOfAddress: isDocumentRequired(docs.documentsRequired, PROOF_OF_ADDRESS),
    showEmployerAuthorization: isDocumentRequired(docs.documentsRequired, EMPLOYER_AUTHORIZATION),
    showTradingAuthNonTsel: isDocumentRequired(docs.documentsRequired, TRADING_AUTHORIZATION_NON_TSEL),
    showTradingAuthTsel: isDocumentRequired(docs.documentsRequired, TRADING_AUTHORIZATION_TSEL),
    showTradingAdvisoryNonTsel: isDocumentRequired(docs.documentsRequired, TRADING_ADVISORY_NON_TSEL),
    showTradingAdvisoryTsel: isDocumentRequired(docs.documentsRequired, TRADING_ADVISORY_TSEL),
    showSepIRA: isDocumentRequired(docs.documentsRequired, SEP_IRA),
    showDeathCertificate: isDocumentRequired(docs.documentsRequired, DEATH_CERTIFICATE),
    showSimpleIRA: isDocumentRequired(docs.documentsRequired, SIMPLE_IRA),
  };

  visibility.showAuthorizationDocuments = false;
  visibility.showIRADocuments = visibility.showSepIRA || visibility.showDeathCertificate || visibility.showSimpleIRA;

  return visibility;
}

function mapStateToProps(state) {
  const contactName = [];
  const isTsel = [false, false];
  const isDomestic = [false, false];
  const ssn = [null, null];
  const maxFiles = [];
  const itemStatus = [];
  const itemVisibility = [];
  const originalDocuments = [];
  const identificationType = [];
  const initialValues = mapStateToInitialValues(state);
  const documentsExist = (state && state.documents && state.documents.length > 0);

  const selector = formValueSelector('documents');
  const docs = selector(state, 'docs');

  if (state.identity) {
    ssn[0] = state.identity[0].identificationSSN;
    ssn[1] = state.identity.length > 1 ? state.identity[1].identificationSSN : null;
  }

  if (docs && docs.length > 0) {
    for (let i = 0; i < docs.length; i += 1) {
      const currentDocumentCount = getDocumentsCount(docs[i]);
      contactName[i] = contactSearchAndFormat.formatContactName(docs[i].contact);
      isTsel[i] = contactSearchAndFormat.isTselCountry(state.countries, docs[i].contact.countryOfResidence);
      isDomestic[i] = docs[i].contact.countryOfResidence === 'US' && (ssn[i] && ssn[i].length > 0);
      maxFiles[i] = getRemainingDocumentsToUpload(MAX_UPLOADS, currentDocumentCount);
      itemStatus[i] = setItemStatus(docs[i], currentDocumentCount);
      itemVisibility[i] = setItemVisibility(docs[i]);
      originalDocuments[i] = setOriginalDocuments(docs[i].originalDocuments, docs[i].documentsRequired);
      identificationType[i] = docs[i].identificationType;
    }
  }

  const props = {
    applicationId: state.applicationId,
    authToken: state.authToken,
    contactName,
    countries: state.countries,
    documentsExist: !!documentsExist,
    initialValues,
    isReturnedApplication: !!initialValues.isReturnedApplication,
    isEntityApplication: !!initialValues.isEntityApplication,
    isJointApplication: !!initialValues.isJointApplication,
    isTsel,
    isDomestic,
    itemStatus,
    itemVisibility,
    maxFiles,
    originalDocuments,
    identificationType,
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      applicationActions,
      accountTypeActions,
      documentsActions,
      documentsRequiredActions,
      countriesActions,
      contactActions,
      identityActions),
    dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'documents',
  validate: DocumentsFormValidation,
})(DocumentsFormContainer));
