import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm, SubmissionError, change } from 'redux-form';
import { deburr } from 'lodash';
import * as registrationActions from '../actions/registrationActions';
import * as identityActions from '../actions/identityActions';
import * as addressActions from '../actions/addressActions';
import * as employmentActions from '../actions/employmentActions';
import * as countriesActions from '../actions/countriesActions';
import * as industriesActions from '../actions/industriesActions';
import * as contactActions from '../actions/contactActions';
import * as accountTypeActions from '../actions/accountTypeActions';
import * as employmentTypes from '../components/Employment/employmentTypes';
import * as assetClassActions from '../actions/assetClassActions';
import EmploymentForm from '../components/Employment/EmploymentForm';
import EmploymentFormValidation from '../validations/EmploymentFormValidation';
import * as RouteNavigator from './RouteNavigator';
import optimizeHelper from '../lib/optimizeHelper';
import pushToAnalytics from '../lib/analytics';
import { aopAnalyticsSteps } from '../lib/analyticsHelper';

import {
  ASSET_CLASS_COMMISSION_SCHEDULE_TSGO,
  ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT,
} from '../components/AssetClass/assetTypes';
import {
  findContactById,
  findContactByType,
  formatContactName,
  mapContact,
} from './contactSearchAndFormat';
import { mapRegionOptions, filterAndSortAllowedCountriesAndRegions } from '../lib/sortFilterMapCountriesRegions';
import Config from '../Config';

const config = new Config();

export const employmentStatusOptions = [
  { value: '', text: '' },
  { value: employmentTypes.EMPLOYMENT_STATUS_EMPLOYED, text: 'Employed' },
  { value: employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED, text: 'Self-Employed' },
  { value: employmentTypes.EMPLOYMENT_STATUS_RETIRED, text: 'Retired' },
  { value: employmentTypes.EMPLOYMENT_STATUS_STUDENT, text: 'Student' },
  { value: employmentTypes.EMPLOYMENT_STATUS_HOMEMAKER, text: 'Homemaker' },
  { value: employmentTypes.EMPLOYMENT_STATUS_UNEMPLOYED, text: 'Unemployed' },
];

export const mapCountryOptions = (countries) => {
  let options = [{ value: '', text: '' }];

  if (countries) {
    options = options.concat(countries
      .map((country) => {
        return {
          value: country.countryCode,
          text: country.country,
        };
      }),
    );
  }

  return options;
};

export const mapIndustryOptions = (industries) => {
  let options = [{ value: '', text: '' }];

  if (industries) {
    options = options.concat(industries
      .map((industry) => {
        return {
          value: industry.industryCode,
          text: industry.industry,
        };
      }),
    );
  }

  return options;
};

export const mapJobTitleOptions = (industries, selectedIndustry) => {
  let options = [{ value: '', text: '' }];

  if (industries) {
    const industry = industries.find(c => c.industryCode === selectedIndustry);

    if (industry && industry.jobs) {
      options = options.concat(industry.jobs
        .map((job) => {
          return {
            value: job.jobCode,
            text: job.job,
          };
        }),
      );
    }
  }

  return options;
};


const mapValuesToEmployment = (values) => {
  const employment = {
    employmentAddress1: '',
    employmentAddress2: '',
    employmentCity: '',
    employmentCountry: '',
    employmentName: '',
    employmentRegion: '',
    employmentStatus: '',
    employmentZip: '',
    industry: '',
    isBrokerage: false,
    isPro: false,
    isPublic: false,
    brokerageCompanyName: '',
    publicSymbol: '',
    publicCompanyName: '',
    title: '',
    id: values.id,
    contactId: values.contact.id || null,
    jobDescription: '',
  };

  employment.employmentStatus = values.employmentStatus;
  employment.isBrokerage = values.isBrokerage;
  employment.isPro = values.isPro;
  employment.isPublic = values.isPublic;

  if (config.nonProQuestionEnabled) {
    employment.isMarketDataForBusiness = values.isMarketDataForBusiness;
    employment.isRegisteredWithSecurities = values.isRegisteredWithSecurities;
    employment.isInvestmentAdvisor = values.isInvestmentAdvisor;
    if (values.isRegisteredWithSecurities === 'true') {
      employment.crdNumber = values.crdNumber;
    }
  }

  if (
    values.employmentStatus === employmentTypes.EMPLOYMENT_STATUS_EMPLOYED ||
    values.employmentStatus === employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED
  ) {
    employment.employmentAddress1 = config.physicalAddressTransform
      ? deburr(values.employmentAddress1)
      : values.employmentAddress1;
    employment.employmentAddress2 = config.physicalAddressTransform
      ? deburr(values.employmentAddress2)
      : values.employmentAddress2;
    employment.employmentCity = config.physicalAddressTransform
      ? deburr(values.employmentCity)
      : values.employmentCity;
    employment.employmentCountry = values.employmentCountry;
    employment.employmentName = values.employmentName;
    employment.employmentRegion = values.employmentRegion;
    employment.employmentZip = values.employmentZip;
    employment.industry = values.industry;
    employment.title = values.title;
    employment.jobDescription = values.jobDescription;
  }

  if (values.isBrokerage === 'true') {
    employment.brokerageCompanyName = values.brokerageCompanyName;
  }

  if (values.isPublic === 'true') {
    employment.publicSymbol = values.publicSymbol;
    employment.publicCompanyName = values.publicCompanyName;
  }
  return employment;
};

export const mapValuesToEmployments = (values) => {
  const employments = [];
  values.employments.forEach((i) => {
    employments.push(mapValuesToEmployment(i));
  });

  return employments;
};

function handleError(error) {
  if (error.response) {
    return error.response.json()
      .then((json) => {
        let errorMessage = json.error.message;
        if (errorMessage.includes('snapshot')) {
          errorMessage = 'Unprocessable Entity';
        }
        throw new SubmissionError({ _error: errorMessage });
      });
  }
  throw new SubmissionError({ _error: error.message });
}

const meetTsgoConditions = (state) => {
  let meetConditions = false;

  meetConditions =
    state.assetClass &&
    state.assetClass.commissionSchedule === ASSET_CLASS_COMMISSION_SCHEDULE_TSGO &&
    state.form &&
    state.form.employment &&
    state.form.employment.values &&
    state.form.employment.values.employments;

  if (meetConditions) {
    if (state.form.employment.values.employments[0]) {
      meetConditions = state.form.employment.values.employments[0].isPro === 'true';
    }
    if (!meetConditions && state.form.employment.values.employments[1]) {
      meetConditions = state.form.employment.values.employments[1].isPro === 'true';
    }
  }

  return meetConditions;
};

export class EmploymentFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExitModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChangeIndustry = this.handleOnChangeIndustry.bind(this);
    this.handleExitModelOn = this.handleExitModelOn.bind(this);
    this.handleExitModelOff = this.handleExitModelOff.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      applicationId,
      authToken,
      countries,
      employment,
      contact,
      industries,
      registration,
      accountType,
      assetClass,
      identity,
      address,
    } = this.props;

    if (countries.length === 0) actions.fetchCountries();
    if (industries.length === 0) actions.fetchIndustries();
    if (!contact) actions.fetchContact(applicationId, authToken);
    if (!employment) actions.fetchEmployment(applicationId, authToken);
    if (!registration || !accountType || !assetClass || !identity || !address) {
      Promise.all([
        actions.fetchRegistration(applicationId, authToken),
        actions.fetchAccountType(applicationId, authToken),
        actions.fetchAssetClass(applicationId, authToken),
        actions.fetchIdentity(applicationId, authToken),
        actions.fetchAddress(applicationId, authToken),
      ]).then(() => {
        pushToAnalytics(
          aopAnalyticsSteps.EMPLOYMENT.name,
          { applicationId, authToken, registration, accountType, assetClass, address, identity },
        );
      });
    } else {
      pushToAnalytics(
        aopAnalyticsSteps.EMPLOYMENT.name,
        { applicationId, authToken, registration, accountType, assetClass, address, identity },
      );
    }

    window.scrollTo(0, 0);
    optimizeHelper.notify();
  }

  handleSubmit(values) {
    const employments = mapValuesToEmployments(values);
    const submit = !this.props.employmentExists ?
      this.props.actions.submitEmployment :
      this.props.actions.updateEmployment;
    const {
      shouldShowTSGONote,
      assetClass,
      applicationId,
      authToken,
    } = this.props;
    const newAssetClass = { ...assetClass, commissionSchedule: ASSET_CLASS_COMMISSION_SCHEDULE_TSSELECT };

    return shouldShowTSGONote ?
      this.props.actions.updateAssetClass(newAssetClass, applicationId, authToken)
        .then(() => {
          submit(employments, applicationId, authToken)
            .then(() => {
              RouteNavigator.push('/financial');
            })
            .catch(handleError);
        }).catch(handleError)
      :
      submit(employments, applicationId, authToken)
        .then(() => {
          RouteNavigator.push('/financial');
        })
        .catch(handleError);
  }

  // eslint-disable-next-line
  handleBack() {
    RouteNavigator.push('/personal-info');
  }

  handleOnChangeIndustry(event) {
    if (event) {
      this.props.dispatch(change('employment', 'title', ''));
    }
  }

  handleExitModelOn() {
    this.setState({ showExitModal: true });
  }

  handleExitModelOff() {
    this.setState({ showExitModal: false });
  }

  render() {
    return (
      <EmploymentForm
        {...this.props}
        employmentStatusOptions={employmentStatusOptions}
        industryOptions={mapIndustryOptions(this.props.industries)}
        countries={mapCountryOptions(this.props.countries)}
        onSubmit={this.handleSubmit}
        onBack={this.handleBack}
        handleOnChangeIndustry={this.handleOnChangeIndustry}
        showExitModal={this.state.showExitModal}
        onExitModalOn={this.handleExitModelOn}
        onExitModalOff={this.handleExitModelOff}
      />
    );
  }
}

EmploymentFormContainer.propTypes = {
  applicationId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  employmentExists: PropTypes.bool,
  actions: PropTypes.shape({
    fetchCountries: PropTypes.func.isRequired,
    fetchIndustries: PropTypes.func.isRequired,
    fetchEmployment: PropTypes.func.isRequired,
    fetchContact: PropTypes.func.isRequired,
    fetchAccountType: PropTypes.func.isRequired,
    submitEmployment: PropTypes.func.isRequired,
    updateEmployment: PropTypes.func.isRequired,
    fetchRegistration: PropTypes.func.isRequired,
    fetchAssetClass: PropTypes.func.isRequired,
    fetchIdentity: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    updateAssetClass: PropTypes.func.isRequired,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  })),
  industries: PropTypes.arrayOf(PropTypes.shape({
    industry: PropTypes.string.isRequired,
    industryCode: PropTypes.string.isRequired,
  })),
  employment: PropTypes.shape(),
  accountType: PropTypes.shape(),
  contact: PropTypes.shape(),
  dispatch: PropTypes.func,
  registration: PropTypes.shape(),
  assetClass: PropTypes.shape(),
  shouldShowTSGONote: PropTypes.bool,
  identity: PropTypes.shape(),
  address: PropTypes.shape(),
};

function mapEmployment(employment, contact) {
  return {
    id: employment.id,
    sort: contact.type === 'primary' ? 0 : contact.id,
    employmentStatus: employment.employmentStatus,
    industry: employment.industry,
    title: employment.title,
    employmentName: employment.employmentName,
    employmentAddress1: employment.employmentAddress1,
    employmentAddress2: employment.employmentAddress2,
    employmentCountry: employment.employmentCountry,
    employmentCity: employment.employmentCity,
    employmentRegion: employment.employmentRegion,
    employmentZip: employment.employmentZip,
    isBrokerage: employment.isBrokerage ? 'true' : 'false',
    isPublic: employment.isPublic ? 'true' : 'false',
    isPro: employment.isPro ? 'true' : 'false',
    publicCompanyName: employment.publicCompanyName,
    publicSymbol: employment.publicSymbol,
    brokerageCompanyName: employment.brokerageCompanyName,
    isMarketDataForBusiness: employment.isMarketDataForBusiness ? 'true' : 'false',
    isRegisteredWithSecurities: employment.isRegisteredWithSecurities ? 'true' : 'false',
    isInvestmentAdvisor: employment.isInvestmentAdvisor ? 'true' : 'false',
    crdNumber: employment.crdNumber,
    contact: mapContact(contact),
    jobDescription: employment.jobDescription,
  };
}

function newEmployment(contact) {
  return {
    id: null,
    sort: contact && contact.type === 'primary' ? 0 : 1,
    employmentStatus: '',
    industry: '',
    title: '',
    employmentName: '',
    employmentAddress1: '',
    employmentAddress2: '',
    employmentCountry: '',
    employmentCity: '',
    employmentRegion: '',
    employmentZip: '',
    isBrokerage: 'false',
    isPublic: 'false',
    isPro: 'false',
    publicCompanyName: '',
    publicSymbol: '',
    brokerageCompanyName: '',
    isMarketDataForBusiness: 'false',
    isRegisteredWithSecurities: 'false',
    isInvestmentAdvisor: 'false',
    crdNumber: '',
    contact: mapContact(contact),
    jobDescription: '',
  };
}

export function mapStateToInitialValues(state) {
  const dbAccountType = (state && state.accountType) ? state.accountType : null;
  const dbEmployments = (state && state.employment && state.employment.length > 0) ? state.employment : null;
  const dbContacts = (state && state.contact && state.contact.length > 0) ? state.contact : null;

  const isJointApplication = (dbAccountType && dbAccountType.accountType.indexOf('joint') === 0);
  const isEntityApplication = (dbAccountType && dbAccountType.accountType.indexOf('entity') === 0);

  const employments = [];
  if (dbEmployments && dbEmployments.length > 0) {
    dbEmployments.forEach((employment) => {
      if (dbContacts && dbContacts.length > 0) {
        const contact = findContactById(employment.contactId, dbContacts);
        employments.push(mapEmployment(employment, contact));
      }
    });
  }

  if (dbContacts && dbContacts.length > 0) {
    if (employments.length < 1) {
      const contact = findContactByType('primary', dbContacts);
      employments.push(newEmployment(contact));
    }

    if (employments.length < 2 && isJointApplication) {
      const contact = findContactByType('joint', dbContacts);
      employments.push(newEmployment(contact));
    }
  }

  employments.sort((a, b) => {
    return a.sort - b.sort;
  });
  return { isJointApplication, isEntityApplication, employments };
}

export function mapStateToProps(state) {
  const contactName = [];
  const showEmploymentForm = [];
  const showIsPublic = [];
  const showIsBrokerage = [];
  const showCrdNumber = [];
  const employmentCountryValue = [];
  const employmentIndustry = [];
  const jobTitleOptions = [];
  const regions = [];
  const initialValues = mapStateToInitialValues(state);
  const employmentExists = (state && state.employment && state.employment.length > 0);
  const contactExists = (state && state.contact && state.contact.length > 1);
  const selector = formValueSelector('employment');
  const employments = selector(state, 'employments');
  const assetClass = state.assetClass;
  const shouldShowTSGONote = meetTsgoConditions(state);
  const isNotExistingCustomer = state.registration && !state.registration.isExistingCustomer;

  let allowedCountries = state.countries;
  if (state.countries && state.countries.length > 0) {
    allowedCountries = filterAndSortAllowedCountriesAndRegions(state.countries);
  }

  if (employments && employments.length > 0) {
    for (let i = 0; i < employments.length; i += i + 1) {
      contactName[i] = formatContactName(employments[i].contact);
      employmentCountryValue[i] = employments[i].employmentCountry;
      employmentIndustry[i] = employments[i].industry;

      if (employments[i].employmentStatus === employmentTypes.EMPLOYMENT_STATUS_EMPLOYED
        || employments[i].employmentStatus === employmentTypes.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
        showEmploymentForm[i] = true;
      }

      if (employments[i].isPublic === 'true') {
        showIsPublic[i] = true;
      }

      if (employments[i].isBrokerage === 'true') {
        showIsBrokerage[i] = true;
      }

      if (employments[i].isRegisteredWithSecurities === 'true') {
        showCrdNumber[i] = true;
      }

      if (employments[i].industry) {
        jobTitleOptions[i] = mapJobTitleOptions(state.industries, employments[i].industry);
      }

      if (employments[i].employmentCountry) {
        regions[i] = mapRegionOptions(state.countries, employments[i].employmentCountry, true);
      }
      if (config.nonProQuestionEnabled && isNotExistingCustomer) {
        if (employments[i].isMarketDataForBusiness === 'false' &&
          employments[i].isRegisteredWithSecurities === 'false' &&
          employments[i].isInvestmentAdvisor === 'false') {
            employments[i].isPro = 'false';
        } else {
          employments[i].isPro = 'true';
        }
      }
    }
  }

  return {
    employmentExists: !!employmentExists,
    initialValues,
    isEntityApplication: !!initialValues.isEntityApplication,
    isJointApplication: !!initialValues.isJointApplication,
    applicationId: state.applicationId,
    authToken: state.authToken,
    countries: allowedCountries,
    employmentCountry: employmentCountryValue,
    industries: state.industries,
    contactName,
    showEmploymentForm,
    showIsPublic,
    showIsBrokerage,
    showCrdNumber,
    contactExists,
    jobTitleOptions,
    regions,
    assetClass,
    shouldShowTSGONote,
    registration: state.registration,
    accountType: state.accountType,
    address: state.address,
    identity: state.identity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      employmentActions,
      countriesActions,
      industriesActions,
      contactActions,
      registrationActions,
      accountTypeActions,
      assetClassActions,
      identityActions,
      addressActions,
    ),
      dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'employment',
  validate: EmploymentFormValidation,
})(EmploymentFormContainer));
