import React from 'react';
import AccountTypeFormContainer from '../../containers/AccountTypeFormContainer';
import ProgressBar2 from '../ProgressBar2';
import Config from './../../Config';

const config = new Config();

const AccountTypePage = () => {
  return (
    <section className="form-content-section-primary flow-text">
      <ProgressBar2 />
      <div className="container">
        <div className="row">
          {
            config.entityAccountEnabled ?
              <div id="appAccountType" className="col col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
                <AccountTypeFormContainer />
              </div>
          :
              <div id="appAccountType" className="col col-md-12 col-lg-8 col-lg-offset-2">
                <AccountTypeFormContainer />
              </div>

          }
        </div>
      </div>
    </section>
  );
};

export default AccountTypePage;
