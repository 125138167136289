import React from 'react';

export default function EmplomentTsgoNote() {
  return (
    <div className="row">
      <div className="col col-xs-12">
        <div className="col col-xs-12">
          <p className="employment-note" id="employment-note">
            <b>Note: </b>
            TS GO is not available for professional subscribers,
            your account will automatically be updated to TS SELECT pricing.
          </p>
        </div>
      </div>
    </div>
  );
}
