import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { bindActionCreators } from 'redux';
import HeaderForm from '../components/Header';
import signOutUrl from '../lib/signOutUrl';
import * as userActions from '../actions/userActions';

export function logoutDescription(user) {
  let description = '';

  if (user) {
    description = user.firstName
      ? `${user.firstName} ${user.lastName}`
      : user.username;
  }
  return description;
}

export class HeaderFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleItemLogout = this.handleItemLogout.bind(this);
  }

  handleItemLogout() {
    this.props.actions.userLogout(this.props.authToken)
      .then(() => {
        window.location = signOutUrl('/logout.html?manual');
      })
      .catch((error) => { throw new SubmissionError({ _error: error.message }); });
  }

  render() {
    return (
      <HeaderForm
        showUserMenu={this.props.showUserMenu}
        userDescription={this.props.userDescription}
        onItemLogout={this.handleItemLogout}
        location={this.props.location}
      />
    );
  }
}

HeaderFormContainer.propTypes = {
  authToken: PropTypes.string,
  showUserMenu: PropTypes.bool,
  userDescription: PropTypes.string,
  actions: PropTypes.shape({
    userLogout: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    authToken: state.authToken,
    userId: state.userId,
    showUserMenu: state.authToken !== null,
    userDescription: logoutDescription(state.user),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      userActions),
    dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'header',
})(HeaderFormContainer));
