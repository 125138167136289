import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const CheckboxDoNotContact = ({ sectionIndex }) => {
  const dontContact = `addresses[${sectionIndex}].residential.dontContact`;

  return (
    <div className="form-group label-floating small">
      <div className="checkbox small">
        <label htmlFor={dontContact}>
          <Field
            type="checkbox"
            id={dontContact}
            name="dontContact"
            component="input"
            value={dontContact}
          />
          I do not wish to receive marketing communications
        </label>
      </div>
    </div>
  );
};

CheckboxDoNotContact.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
};

export default CheckboxDoNotContact;
