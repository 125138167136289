import React from 'react';
import ReactTooltip from 'react-tooltip';

const TooltipBrokerDealer = () => {
  return (
    <div className="help-circle">
      <a data-tip data-for="brokerDealer">
        <i className="material-icons title-help">help</i>
      </a>
      <ReactTooltip id="brokerDealer" place="left" class="tooltip">
        <span>As a registered broker-dealer, TradeStation is required by federal law to verify your identity.</span>
      </ReactTooltip>
    </div>
  );
};

export default TooltipBrokerDealer;
