import React from 'react';
import PropTypes from 'prop-types';

const IdVerifyJointApps = (props) => {
  const {
    initialValues,
    startWebSDK,
    webSDKResult,
    webSDKResultJoint,
    resubmitReasons,
    resubmitReasonsJoint,
  } = props;

  const customerName = {
    float: 'left',
    display: 'inline-block',
    width: '70%',
  };

  const nameDiv = {
    borderTop: '1px solid #c2c7cf',
    margin: 'auto',
    marginBottom: '1%',
  };

  const idVerifyButton = {
      float: 'right',
  };

  const idVerified = {
    color: 'green',
    float: 'right',
    paddingTop: (initialValues.isJointApp ? '2.2%' : '2%'),
  };

  const nameText = {
    marginBottom: '-7%',
    float: 'left',
  };

  const statusIcon = {
    display: 'inline-block',
    float: 'left',
    marginTop: '3.5%',
    marginRight: '3%',
  };

  return (
    <div>
      {(initialValues.primaryNeedsDocV && initialValues.isJointApp) && (
        <div className="row docVTab" style={nameDiv}>
          <div style={customerName} className="jointAccountTab">
            <i className="material-icons icon-success" style={statusIcon}>
              {(webSDKResult === 'accept' || webSDKResult === 'reject') ? 'check_circle' : ''}
            </i>
            <div className="jointDocVDiv">
              <h4 className={'h2_subHeading h2_subHeadingBorderless text-center'} style={nameText}>
                {initialValues.primaryAccountOwner}
              </h4>
              <br />
              { initialValues.isJointApp && (
                <h5 style={{ paddingTop: '2%' }} >(Primary Account)</h5>
              )}
              { webSDKResult === 'resubmit' && resubmitReasons && (
                <div className="resubmitDescriptionJoint">
                  {resubmitReasons.map(reason => (<p className="resubmitText">{reason}</p>))}
                </div>
              )}
            </div>
          </div>
          {(webSDKResult === 'none' || webSDKResult === 'resubmit' || webSDKResult === 'error') && (
            <button
              id="next_btn"
              onClick={startWebSDK}
              className={'btn btn-raised btn-default do_capitalize triple-button-style idVerifyButton'}
              style={idVerifyButton}
            >
              Verify Identity
            </button>)}
          {(webSDKResult === 'accept') && (
            <h4 style={idVerified}>Received</h4>
          )}
          {(webSDKResult === 'reject') && (
            <h4 style={idVerified}>Received</h4>
          )}
        </div>
      )}

      {(initialValues.jointNeedsDocV && initialValues.isJointApp) && (
        <div className="row docVTab" style={nameDiv}>
          <div style={customerName} className="jointAccountTab">
            <i className="material-icons icon-success" style={statusIcon}>
              {(webSDKResultJoint === 'accept' || webSDKResultJoint === 'reject') ? 'check_circle' : ''}
            </i>
            <div className="jointDocVDiv">
              <h4 className={'h2_subHeading h2_subHeadingBorderless text-center'} style={nameText}>
                {initialValues.jointAccountOwner}
              </h4>
              <br />
              { initialValues.isJointApp && (
                <h5 style={{ paddingTop: '2%' }} >(Joint Account)</h5>
              )}
              { webSDKResultJoint === 'resubmit' && resubmitReasonsJoint && (
                <div className="resubmitDescriptionJoint">
                  {resubmitReasonsJoint.map(reason => (<p className="resubmitText">{reason}</p>))}
                </div>
              )}
            </div>
          </div>
          {(webSDKResultJoint === 'none' || webSDKResultJoint === 'resubmit' || webSDKResultJoint === 'error') && (
            <button
              id="next_btn"
              onClick={() => startWebSDK('joint')}
              className={'btn btn-raised btn-default do_capitalize triple-button-style idVerifyButton'}
              style={idVerifyButton}
            >
              Verify Identity
            </button>)}
          {(webSDKResultJoint === 'accept') && (
            <h4 style={idVerified}>Received</h4>
          )}
          {(webSDKResultJoint === 'reject') && (
            <h4 style={idVerified}>Received</h4>
          )}
        </div>
      )}
    </div>
  );
};

IdVerifyJointApps.propTypes = {
  startWebSDK: PropTypes.func.isRequired,
  webSDKResult: PropTypes.string.isRequired,
  webSDKResultJoint: PropTypes.string.isRequired,
  initialValues: PropTypes.shape(),
  resubmitReasons: PropTypes.shape(),
  resubmitReasonsJoint: PropTypes.shape(),
};

export default IdVerifyJointApps;
