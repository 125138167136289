import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error }) => {
  return (
    <div className="alert alert-danger alert-dismissible fade in" role="alert">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <strong>Warning!</strong> {error}
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorMessage;
