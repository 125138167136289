import * as actionTypes from './actionTypes';
import Config from '../Config';
import checkHttpError from './checkHttpError';
import responseToJson from './responseToJson';

const config = new Config();

export function fetchAssetClassRequest() {
  return {
    type: actionTypes.FETCH_ASSET_CLASS_REQUEST,
  };
}

export function fetchAssetClassSuccess(assetClass) {
  return {
    type: actionTypes.FETCH_ASSET_CLASS_SUCCESS,
    assetClass,
  };
}

export function fetchAssetClassError(error) {
  return {
    type: actionTypes.FETCH_ASSET_CLASS_ERROR,
    error,
  };
}

export function fetchAssetClass(applicationId, authToken) {
  return (dispatch) => {
    dispatch(fetchAssetClassRequest());

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
      },
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/assetclass`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then(json => dispatch(fetchAssetClassSuccess(json)))
      .catch(error => dispatch(fetchAssetClassError(error)));
  };
}

export function submitAssetClassError(error) {
  return { type: actionTypes.SUBMIT_ASSET_CLASS_ERROR, error };
}

export function submitAssetClassRequest(assetClass) {
  return { type: actionTypes.SUBMIT_ASSET_CLASS_REQUEST, assetClass };
}

export function submitAssetClassSuccess(assetClass) {
  return { type: actionTypes.SUBMIT_ASSET_CLASS_SUCCESS, assetClass };
}

// eslint-disable-next-line no-underscore-dangle
function _submitAssetClass(assetClass, applicationId, authToken, method) {
  return (dispatch) => {
    dispatch(submitAssetClassRequest(assetClass));
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      body: JSON.stringify(assetClass),
      mode: 'cors',
    };

    return fetch(`${config.apiUrl}/applications/${applicationId}/assetclass`, options)
      .then(checkHttpError)
      .then(responseToJson)
      .then((json) => { dispatch(submitAssetClassSuccess(json)); })
      .catch((error) => {
        dispatch(submitAssetClassError(error));
        return Promise.reject(error);
      });
  };
}

export function submitAssetClass(assetClass, applicationId, authToken) {
  return _submitAssetClass(assetClass, applicationId, authToken, 'POST');
}

export function updateAssetClass(assetClass, applicationId, authToken) {
  return _submitAssetClass(assetClass, applicationId, authToken, 'PUT');
}
