import React from 'react';
import PropTypes from 'prop-types';
import Common from './PendingApplicationsCommon';
import pendingAccountTypes from './pendingAccountTypes';

const PendingApplication = (props) => {
  const { account, company } = props;
  const accountSubtypeDisplay = account.accountSubtype && (
    <p>{account.accountSubtype}</p>
  );
  const companyDisplay = company && <p>{company}</p>;

  function checkAccountType(accountType) {
    if (pendingAccountTypes[accountType]) {
      return pendingAccountTypes[accountType];
    }
    return accountType;
  }

  function craftPendingApplicationHeader() {
    const accountType = checkAccountType(account.accountType);
    return account.assetTypes ?
      `${accountType} - ${account.assetTypes}` :
      `${accountType}`;
  }

  return (
    <div>
      <div className="row">
        <div className="col col-lg-12">
          <h4>
            <b>{craftPendingApplicationHeader()}</b>
          </h4>
        </div>
      </div>
      <div className="row vertical-center">
        <div className="col col-xs-6">
          {accountSubtypeDisplay}
          {companyDisplay}
          <span>Started on {props.created.toLocaleDateString()}</span>
        </div>
        <div className="col col-xs-6 complete-application">
          <button
            type="button"
            id="pending-app-complete-button"
            className="btn btn-raised btn-default btn-lg complete-app-btn"
            onClick={() => props.continueApplication(props)}
          >
            <span className="do_capitalize">{Common.COMPLETE_APPLICATION}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

PendingApplication.propTypes = {
  account: PropTypes.shape({
    accountType: PropTypes.string.isRequired,
    accountSubtype: PropTypes.string,
    assetTypes: PropTypes.string,
  }),
  created: PropTypes.instanceOf(Date).isRequired,
  company: PropTypes.string,
  continueApplication: PropTypes.func.isRequired,
};

export default PendingApplication;
