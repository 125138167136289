import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import AccountSubType from './AccountSubType';
import Contact from '../Contact/Contact';
import JointContactHeading from '../Contact/JointContactHeading';

import * as accountTypes from './accountTypes';

const JointAccountType = ({
  isReturnedApplication,
  countries,
  regions,
  handlePhoneChange,
  phoneCountry,
  phoneStatus,
  jointPhone,
}) => {
  return (
    <div className="row">
      <div className="col col-xs-12">
        <div className="panel panel-clear no-panel-bottom">
          <div className="panel-body no-panel-bottom">
            <div className="row">
              <div className="col-md-12">
                <p className="panel-question text-center">What type of joint account would you like to open?</p>
                <div className="row">
                  <div className="col col-sm-10 col-sm-offset-1 col-xs-8 col-xs-offset-2">
                    <div className="row">
                      <div className="col col-xs-12 col-sm-6 inside-button">
                        <AccountSubType
                          id="joint-rights-of-survivorship"
                          name="jointType"
                          text="Rights of survivorship"
                          type={accountTypes.JOINT_WITH_RIGHTS_OF_SURVIVORSHIP}
                        />
                        <div className="help-circle help-circle-floater">
                          <a data-tip data-for="jointSurvivorship">
                            <i className="material-icons">help</i>
                          </a>
                          <ReactTooltip id="jointSurvivorship" place="left" class="tooltip" >
                            <span>Each account owner has an equal, undivided interest
                              in the account’s cash and securities.
                              Account ownership passes to the survivor(s)
                              if any account owner is declared dead or incompetent.
                            </span>
                          </ReactTooltip>
                        </div>
                      </div>
                      <div className="col col-xs-12 col-sm-6 inside-button">
                        <AccountSubType
                          id="joint-tenants-in-common"
                          name="jointType"
                          text=" Tenants-in-common"
                          type={accountTypes.JOINT_AS_TENANTS_IN_COMMON}
                        />
                        <div className="help-circle help-circle-floater">
                          <a data-tip data-for="jointTenants">
                            <i className="material-icons">help</i>
                          </a>
                          <ReactTooltip id="jointTenants" place="left" class="tooltip">
                            <span>Each account owner has a percentage interest in the account.
                              If any account owner is declared dead or incompetent, that owner’s
                              percentage interest is retained by the deceased owner’s estate.
                            </span>
                          </ReactTooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormSection name="jointContact">
                  <JointContactHeading />
                  {!isReturnedApplication &&
                    <Contact
                      countries={countries}
                      regions={regions}
                      handlePhoneChange={handlePhoneChange}
                      phoneCountry={phoneCountry}
                      phoneStatus={phoneStatus}
                      jointPhone={jointPhone}
                    />
                  }
                </FormSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

JointAccountType.propTypes = {
  isReturnedApplication: PropTypes.bool.isRequired,
  handlePhoneChange: PropTypes.func,
  phoneCountry: PropTypes.string,
  phoneStatus: PropTypes.string,
  jointPhone: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  regions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default JointAccountType;
