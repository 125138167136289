import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { bindActionCreators } from 'redux';
import * as userActions from '../actions/userActions';
import { getBrowserOrigin } from '../lib/browser';
import Config from '../Config';
import signOutUrl from '../lib/signOutUrl';

const config = new Config();
const REDIRECT_URL = `${config.apiUrl}/auth/tradestation?redirectTo=`;
const ACCOUNT_TYPE_URL = '/account-type';

class DuplicateRemediationModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: true,
    };

    this.handleHide = this.handleHide.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
  }

  handleHide() {
    this.setState({ showModal: false });
  }

  // eslint-disable-next-line class-methods-use-this
  redirectToLoginPage() {
    const redirectTo = getBrowserOrigin();
    window.location = encodeURI(REDIRECT_URL.concat(redirectTo).concat(ACCOUNT_TYPE_URL));
  }

  handleLogout() {
    this.props.actions.userLogout(this.props.authToken)
      .then(() => {
        window.location = signOutUrl('/account-type');
      })
      .catch((error) => { throw new SubmissionError({ _error: error.message }); });
  }

  handleLogin() {
   if (this.props.authToken) {
      this.handleLogout();
   } else {
      this.redirectToLoginPage();
   }
  }

  render() {
    const page = this.props.page;
    const onRegistrationPage = page === 'registration';
    const backdrop = onRegistrationPage ? true : 'static';

    return (
      <Modal
        className="duplicateModal"
        show={this.state.showModal}
        onHide={this.handleHide}
        backdrop={backdrop}
        keyboard={onRegistrationPage}
      >
        <Modal.Header closeButton={onRegistrationPage}>
          <img
            className="img-responsive duplicateModalIcon"
            src="images/duplicate-remediation-modal-icon.png"
            alt="Person Icon"
          />
          <h2 className="text-center duplicateModalHeader">Welcome Back!</h2>
        </Modal.Header>
        <Modal.Body className="duplicateModalBody">
          {onRegistrationPage && <p className="text-center duplicateModalText">
            The information you have provided suggests that you already have a relationship with TradeStation.
            To expedite your new account application process please login to continue.
          </p>}
          {page === 'personal-info' && <p className="text-center duplicateModalTextPersonalInfo">
            The information provided suggests that you already have a relationship with TradeStation.
            To expedite your new account application process, please log in with your pre-existing credentials to
            continue. For your protection, new credentials created at the start of this application will be discarded.
          </p>}
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center duplicateModalFooter">
            <button
              className="btn btn-raised btn-default do_capitalize intro-btn intro duplicateModalButton"
              onClick={this.handleLogin}
            >
              Login
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

DuplicateRemediationModal.propTypes = {
    authToken: PropTypes.string,
    actions: PropTypes.shape({
        userLogout: PropTypes.func.isRequired,
      }).isRequired,
    page: PropTypes.string,
};

function mapStateToProps(state) {
    return {
      authToken: state.authToken,
    };
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Object.assign({},
      userActions),
      dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateRemediationModal);
